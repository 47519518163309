import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import swal from 'sweetalert';
import PopUp from "./PopUp";
import { Grid } from 'antd-mobile';
import LoadingSpinner from "./../../LoadingSpinner";
import AppHeader from './AppHeader';
import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'
var moment = require('moment');

var isEmpty = function(data) {
    if(typeof(data) === 'object'){
        if(JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]'){
            return true;
        }else if(!data){
            return true;
        }
        return false;
    }else if(typeof(data) === 'string'){
        if(!data.trim()){
            return true;
        }
        return false;
    }else if(typeof(data) === 'undefined'){
        return true;
    }else{
        return false;
    }
}


class ThreeGameSetPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "home";

        this.state = {
            bullet : 0,
            today_event : 0,
            fast : 0,
            toggle : false,
            seen: false,
            step: "form",
            message : "",
            alertId: '',
            lottery:[
                {

                }
            ],
            wallet : [
                {
                    wallet_id: 1,
                    credit : 0
                },
                {
                    wallet_id: 2,
                    credit : 0
                },
                {
                    wallet_id: 3,
                    credit : 0
                }
            ],
            event : {},
            form : { mycart: [], amount : "", commission : 0, total : 0 , rmycart: [] },
            settrade: {
                "thaidate":"29-11-21 13:11",
                "set":"1,600.24",
                "value":"64,409.60M",
                "created_at":"2021-11-29 12:41:10",
                "number":"49"
            },
            setting : [],
            twogame : [ {id: 0 ,title : '', max_amount : 0, min_amount: 0, amount :0, gameplay_type: 0, game_type: 2, complete : 0}],
            editform : false,
            isEvent : false,
            setPaginate : 0,
            isLoading : true,
         };

        this.togglesPop     = this.togglesPop.bind(this);
        this.fastAction     = this.fastAction.bind(this);
        this.betAction      = this.betAction.bind(this);
        this.submitAction   = this.submitAction.bind(this);
        this.backAction     = this.backAction.bind(this);
        this.endSessionAction = this.endSessionAction.bind(this);

        this.showSuccessAlert   = this.showSuccessAlert.bind(this);
        this.showErrorAlert     = this.showErrorAlert.bind(this);
        this.removeAlert        = this.removeAlert.bind(this);
        this.setReverse         = this.setReverse.bind(this);

        this.btnfastType           = this.btnfastType.bind(this);


        this.previewIndviAmountEdit       = this.previewIndviAmountEdit.bind(this);
        this.previewIndviAmountDelete   = this.previewIndviAmountDelete.bind(this);
        this.previewIndviAmountSave     = this.previewIndviAmountSave.bind(this);

        this.inputEditChangeHandler = this.inputEditChangeHandler.bind(this);

        this.btnfastTimeSelect = this.btnfastTimeSelect.bind(this);

        this.check3dExtract = this.check3dExtract.bind(this);

        this.paginateChange = this.paginateChange.bind(this);
        this.paginateClick = this.paginateClick.bind(this);

    }

    // percentage(partialValue, totalValue) {
    //    return Math.round((100 * partialValue) / totalValue);
    // }

    percentage(partialValue, totalValue) {
       return ((100 * partialValue) / totalValue);
    }

    currencyFormat(num) {
        return num;
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    count(str, find) {
        return (str.split(find)).length - 1;
    }

    check3dExtract(num) {
        let numgroup = [];
        let num1 = num[0];
        let num2 = num[1];
        let num3 = num[2];

        // let numarr = [ num[0], num[1], num[2] ];

        // // console.log("number check")
        // // console.log(num1+num2+num3)

        // numarr.includes(num[0])

        let countlength = this.count(num, num1);

        let countlength2 = this.count(num, num1);

        if(countlength2 > countlength) {
            countlength = countlength2;
        }

        console.log(countlength + "----");

        if(countlength == 3 ) {
            //000

            numgroup.push( num1+num2+num3 );
        }

        if(countlength == 2 ) {
            // 002
            // 020
            // 200
            // numgroup.push( num1+num2+num3 );

            // numgroup.push( num1+num3+num2 );
            // numgroup.push( num3+num1+num2 );

            let first = num1+num2+num3;
            numgroup.push(  num1+num2+num3 );
            let second = num1+num3+num2;
            numgroup.push(  num1+num3+num2 );
            // second = num1+num3+num2;

            let third  = num3+num1+num2;
            numgroup.push(  num3+num1+num2 );

            if(first == second) {
                numgroup.push(  num3+num1+num2 );
                // numgroup[1] = num3+num2+num1;
            }


            if(second == third) {
                numgroup.push(  num2+num3+num1 );
                // numgroup[1] = num2+num3+num1;
            }

        }

        if(countlength == 1 ) {
            // 018
            // 081
            // 108
            // 180
            // 801
            // 810

            // numgroup.push( num1+num3+num2 );

            numgroup.push( num1+num3+num2 );
            numgroup.push( num2+num1+num3 );

            numgroup.push( num2+num3+num1 );
            numgroup.push( num3+num1+num2 );
            numgroup.push( num3+num2+num1 );


        }

        // console.log("--------");
        // console.log(numgroup);
        // console.log("--------");

        return numgroup;
        //title[1]+title[0]+title[2]
    }

    btnfastTimeSelect(e, id) {
        // this.setState()
        // id
        this.setState({ step : "form", today_event : id });

        // this.fetchData(window.BPdata.apiUrlCMS+'/3d/formdata?token='+localStorage.getItem(window.BPdata.time)+"&type="+id);

        this.fetchData(window.BPdata.apiUrlCMS+'/3d/formdata?type='+this.state.today_event);
    }

    previewIndviAmountEdit(e,  id) {
        // alert(e)
        // alert(id)
        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        // console.log(mycart);

        mycart[id]["edit_amount"] = 1;

        this.setState(prevState => ({
            form : {
                ...prevState.form,
                mycart: mycart
            },
        }))

        //this.setState({editform : true});
    }

    previewIndviAmountSave(e, id) {
        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        // console.log(mycart);

        mycart[id]["edit_amount"] = 0;



        let total = 0;
        let commission = 0;
        // let commission_percent = this.state.setting[3].option_value;
        let commission_percent = this.state.lottery[this.state.today_event].lottery_commission;
        // console.log(commission_percent);

        mycart.forEach(function(each,key){

            let amount = Number(each.amount);

            // let commission_amount = Math.round((commission_percent/ 100) * amount);
            let commission_amount = ((commission_percent/ 100) * amount);

            commission += commission_amount ;
            total += amount;


        })

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


        this.setState(prevState => ({
            form : {
                ...prevState.form,
                mycart: mycart,
                commission : commission,
                total : total
            },
        }))

    }


    previewIndviAmountDelete(e, id) {

        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        delete mycart[id];


        let amount = this.state.form.amount;
        let number  = Number(amount.replace(/^0+/, ''));

        let total = 0;
        let commission = 0;
        // let commission_percent = this.state.setting[4].option_value;
        let commission_percent = this.state.lottery[this.state.today_event].lottery_commission;

        // let mycart = [...this.state.form.mycart];

        mycart.forEach(function(each,key){

                //let commission_amount = Math.round((commission_percent/ 100) * number);
                let commission_amount = ((commission_percent/ 100) * each.amount);
                mycart[each.num_id] = { "id" : each.id,  "num_id" : each.num_id, "title" : each.title, "complete" : each.complete , "amount" : each.amount, "commission" : commission_amount, "product_type" : 1 , "edit_amount" : 0 , "max_amount" : each.max_amount , "total_amount" : each.total_amount };

                commission += commission_amount ;
                total += parseInt(each.amount);

            })

            Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

            Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


        this.setState({
            form : {
                mycart: mycart,
                rmycart: this.state.form.rmycart,
                amount : this.state.form.amount,
                commission : commission,
                total : total
            }
        });

        // this.setState(prevState => ({
        //     form : {
        //         ...prevState.form,
        //         mycart: mycart
        //     },
        // }))

        // alert("ok");
    }

    inputEditChangeHandler(e, id, amount, max_amount, total_amount ) {

        // console.log(e)
        // console.log(id)
        // console.log(amount)
        // let form = {...this.state.form};
        // form[e.target.name] = e.target.value;
        // this.setState({
        //     form
        // });

        let value = e.target.value;
        let min_amount = this.state.setting[0].option_value;

        if(isNaN(e.target.value)) {
            this.showErrorAlert("Set bet minimum amount must be "+ min_amount +" Ks ", 'single');
            return ;
        }

        // if(Number(value) < min_amount) {
        //     this.showErrorAlert("Minimum amount should have "+ (Number(min_amount)) +" Ks ", 'single');
        //     return ;
        // }

        let temp_max_amount = (Number(value) + Number(total_amount));

        // console.log(temp_max_amount)
        // console.log(max_amount < temp_max_amount)
        // console.log(max_amount-total_amount)

        if(Number(max_amount) < temp_max_amount) {

            this.showErrorAlert("သတ်မှတ်ထားသော ပမာဏထက် ကျော်လွန်နေပါသည်။", 'single');

            // this.showErrorAlert("This number set amount almost full. Maximum amount should have "+ (Number(max_amount)-Number(total_amount)) +" Ks ", 'single');
            return ;
        }

        // alert(value)

        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        // console.log(mycart[id]);

        let total = 0;
        let commission = 0;
        // let commission_percent = this.state.setting[4].option_value;
        let commission_percent = this.state.lottery[this.state.today_event].lottery_commission;

        

        //let commission_amount = Math.round((commission_percent/ 100) * value);
        let commission_amount = ((commission_percent/ 100) * value);

        mycart[id]["amount"] = value;
        mycart[id]["commission"] = commission_amount;

        this.setState(prevState => ({
            form : {
                ...prevState.form,
                mycart: mycart
            },
        }))

    }

    btnfastType(e,type) {

        let filter;
        let filter2;

        let twogame = [...this.state.twogame];
        // twogame[key]["complete"] = e.target.checked;

        let pre_mycart = this.state.form.mycart;
        let mycart = [...this.state.form.mycart];

        let total = 0;
        let commission = 0;

        let self = this;

        twogame.forEach(function(each,key){

             if(type <= 10) {


                let same_number = "000" ;

                if(type == 1) {
                    same_number = "000" ;
                    self.setState(prevState => ({
                        setPaginate : 0
                    }))



                } else if (type == 2) {
                    same_number = "111";
                    self.setState(prevState => ({
                        setPaginate : 1
                    }))

                } else if (type == 3) {
                    same_number = "222";
                    self.setState(prevState => ({
                        setPaginate : 2
                    }))

                } else if (type == 4) {
                    same_number = "333";
                    self.setState(prevState => ({
                        setPaginate : 3
                    }))

                } else if (type == 5) {
                    same_number = "444";
                    self.setState(prevState => ({
                        setPaginate : 4
                    }))

                } else if (type == 6) {
                    same_number = "555";
                    self.setState(prevState => ({
                        setPaginate : 5
                    }))

                } else if (type == 7) {
                    same_number = "666";
                    self.setState(prevState => ({
                        setPaginate : 6
                    }))

                } else if (type == 8) {
                    same_number = "777";
                    self.setState(prevState => ({
                        setPaginate : 7
                    }))

                } else if (type == 9) {
                    same_number = "888";
                    self.setState(prevState => ({
                        setPaginate : 8
                    }))

                } else if (type == 10) {
                    same_number = "999";
                    self.setState(prevState => ({
                        setPaginate : 9
                    }))

                }


                if(each.title == same_number ) {
                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id" : each.id, "num_id" : each.num_id,  "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1, "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};
                }

                // filter = 49;
                // filter2 = 100;

                // if(key > filter && key < filter2) {
                //     twogame[key]["complete"] = 1;
                //     mycart[each.num_id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1, "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount };
                // }


            }   else if (type > 10 && type <= 20) {

                filter = 100;

                let paginate_type = 0 ;

                if(type == 11) {
                    paginate_type = 0 ;
                    self.setState(prevState => ({
                        setPaginate : 0
                    }))
                } else if (type == 12) {
                    paginate_type = 1;
                    self.setState(prevState => ({
                        setPaginate : 1
                    }))
                } else if (type == 13) {
                    paginate_type = 2;
                    self.setState(prevState => ({
                        setPaginate : 2
                    }))
                } else if (type == 14) {
                    paginate_type = 3;
                    self.setState(prevState => ({
                        setPaginate : 3
                    }))
                } else if (type == 15) {
                    paginate_type = 4;
                    self.setState(prevState => ({
                        setPaginate : 4
                    }))
                } else if (type == 16) {
                    paginate_type = 5;
                    self.setState(prevState => ({
                        setPaginate : 5
                    }))
                } else if (type == 17) {
                    paginate_type = 6;
                    self.setState(prevState => ({
                        setPaginate : 6
                    }))
                } else if (type == 18) {
                    paginate_type = 7;
                    self.setState(prevState => ({
                        setPaginate : 7
                    }))
                } else if (type == 19) {
                    paginate_type = 8;
                    self.setState(prevState => ({
                        setPaginate : 8
                    }))
                } else if (type == 20) {
                    paginate_type = 9;
                    self.setState(prevState => ({
                        setPaginate : 9
                    }))
                }

                if(each.paginate == paginate_type ) {
                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id" : each.id, "num_id" : each.num_id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1, "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};
                }

            } else if ( type >= 21 && type <= 22 ) {

                //Consetellation Power
                let title = each.title;

                let lastnum = [ "0" ];

                if(type == 21) {
                    lastnum = [ "000", "111", "222", "333", "444", "555", "666", "777", "888", "999" ];
                } else if (type == 22) {
                    lastnum = [ "000", "100", "200", "300", "400", "500", "600", "700", "800", "900" ];
                }


                if(lastnum.indexOf(title) != "-1" ) {

                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id" : each.id, "num_id" : each.num_id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1, "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};
                 }

            }

            else if (type > 22) {


               let same_number = "000" ;

               if(type == 23) {
                   same_number = "000" ;

                   self.setState(prevState => ({
                       setPaginate : 0
                   }))

               } else if (type == 24) {
                   same_number = "100";
                   self.setState(prevState => ({
                       setPaginate : 1
                   }))

               } else if (type == 25) {
                   same_number = "200";
                   self.setState(prevState => ({
                       setPaginate : 2
                   }))

               } else if (type == 26) {
                   same_number = "300";
                   self.setState(prevState => ({
                       setPaginate : 3
                   }))

               } else if (type == 27) {
                   same_number = "400";
                   self.setState(prevState => ({
                       setPaginate : 4
                   }))

               } else if (type == 28) {
                   same_number = "500";
                   self.setState(prevState => ({
                       setPaginate : 5
                   }))

               } else if (type == 29) {
                   same_number = "600";
                   self.setState(prevState => ({
                       setPaginate : 6
                   }))

               } else if (type == 30) {
                   same_number = "700";
                   self.setState(prevState => ({
                       setPaginate : 7
                   }))

               } else if (type == 31) {
                   same_number = "800";
                   self.setState(prevState => ({
                       setPaginate : 8
                   }))

               } else if (type == 32) {
                   same_number = "900";
                   self.setState(prevState => ({
                       setPaginate : 9
                   }))

               }

               // console.log(same_number)

               if(each.title == same_number ) {
                   twogame[key]["complete"] = 1;
                   mycart[each.num_id] = { "id" : each.id, "num_id" : each.num_id, "title" : each.title, "complete" : each.complete , "amount" : 0, "commission" : 0, "product_type" : 1, "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount};
               }


           }



            commission += commission ;
            total += 0;



        })

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


        this.setState(prevState => ({
            fast : type,
            step : 'form',
            form : {
                ...prevState.form,
                mycart: mycart,
                commission : commission,
                total : total
                // rmycart: this.state.form.rmycart,
                // amount : this.state.form.amount,
                // commission : this.state.form.commission,
                // total : this.state.form.total,

            },
            twogame : twogame

        }))


    }

    showSuccessAlert(message, type) {

        let output = "";
        if(type == "multi") {

            for (var i = 0; i < message.length; i++) {
                output += message[i];
            }

        } else {
            output = message;
        }

        // console.log(output)

        let alertId = StatusAlertService.showSuccess(output);
        // console.log(alertId)
        this.setState({ alertId });
    }

    removeAlert(message, type) {

        let output = "";
        if(type == "multi") {

            for (var i = 0; i < message.length; i++) {
                output += message[i];
            }

        } else {
            output = message;
        }

        // console.log(output)

        let alertId = StatusAlertService.showError(output);
        // console.log(alertId)
        this.setState({ alertId });

        // StatusAlertService.removeAlert(this.state.alertId);
    }

    showErrorAlert(message ,type ) {
        // let message = "message";
        // let alertId = StatusAlertService.showError(message);
        // // console.log(alertId)
        // this.setState({ alertId });

        let output = "";
        if(type == "multi") {

            for (var i = 0; i < message.length; i++) {
                output += message[i];
            }

        } else {
            output = message;
        }

        // console.log(output)

        let alertId = StatusAlertService.showError(output);
        // console.log(alertId)
        this.setState({ alertId });

    }

    togglesPop() {
        this.setState({
            seen: !this.state.seen
        });

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

        }

    }

    componentDidMount() {

        window.scrollTo(0, 0);

        if(localStorage.getItem('wallet')) {

          if(JSON.parse(localStorage.getItem("wallet")) != null) {
            let wallet = JSON.parse(localStorage.getItem("wallet"));
            this.setState({wallet: wallet})
          }
        }

        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);

      //      this.setState({ step: "selecttime" });
            this.setState({ step: "form" });

          } else {
             this.props.history.push("/more/login");
            }
        } else {
            this.props.history.push("/more/login");
        }


        this.fetchData(window.BPdata.apiUrlCMS+'/3d/formdata');
        // http://dep.thahtaylay.com/api/v1/cms/2d
    }

    fetchData(url) {

        let dataurl = url;


        axios({
            method: 'get',
            mode: "no-cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            crossDomain: true,
            url: dataurl,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"             : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {
            // console.log(resp.data)
            if(resp.data.data.data) {

                if( (new Date() - new Date(resp.data.event.schedule_at) ) < 0  ){

                  this.setState({
                      twogame: resp.data.data.data,
                      event : resp.data.event,
                      setting : resp.data.setting,
                      lottery:resp.data.lottery,
                      isLoading: false,
                      isEvent : resp.data.is_event,
                  });
                //   console.log('ok')

                    // console.log("--- lottery ---- ")
                    // console.log(resp.data.lottery)


                } else {

                  this.setState({
                      twogame: resp.data.data.data,
                      event : resp.data.event,
                      setting : resp.data.setting,
                      lottery:resp.data.lottery,
                      isLoading: false,
                      isEvent : false,
                  });

                //   console.log("--- lottery ---- ")
                //   console.log(resp.data.lottery)

                }


            } else {

                this.setState({
                    twogame: resp.data.data.data,
                    event : resp.data.event,
                    setting : resp.data.setting,
                    lottery:resp.data.lottery,
                    isLoading: false,
                    isEvent : false,
                });

                // console.log("--- lottery ---- ")
                // console.log(resp.data.lottery)

            }


            if(resp.data.wallet[0]) {

                this.setState({
                    wallet : resp.data.wallet,
                });

                localStorage.setItem("wallet", JSON.stringify(resp.data.wallet) )
            }


        })
        .catch(function(error) {
          //console.log(error.tostring());
        });



    }

    formChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }



    inputChangeHandler(e, key, unique_id, dbid, title, max_amount, total_amount) {
        // alert(dbid);
        let mycarts = [];

        let pre_twogame = this.state.twogame;

        let twogame = [...this.state.twogame];
        twogame[key]["complete"] = e.target.checked;

        // console.log(twogame)

        let mycart      = [...this.state.form.mycart];

        let rmycart     = [...this.state.form.rmycart];

        if(e.target.checked == false){
            // console.log(e.target.checked)
            delete mycart[dbid]
        } else {

            //toggle R
            if(this.state.toggle == true) {

                let index = pre_twogame.findIndex(obj => obj.title==(title[1]+title[0]+title[2]));
                // let fullindex = twogame.find(obj => obj.title==(each.title[1]+each.title[0]));

                // item = twogame.find(item=>item.title==(each.title[1]+each.title[0]));
                let chosenum = pre_twogame[index];


                rmycart[chosenum["num_id"] ] = { "id" : chosenum["id"] , "num_id" : chosenum["num_id"] , "title" :chosenum["title"] , "complete" : chosenum["complete"] ,   "product_type" : chosenum["product_type"] };

                // selftmycart not working
                mycart[chosenum["num_id"]] = { "id" : chosenum["id"] , "num_id" : chosenum["num_id"], "title" :chosenum["title"] , "complete" : chosenum["complete"] ,   "product_type" : chosenum["product_type"] , "edit_amount" : 0, "max_amount" :  chosenum["max_amount"] , "total_amount" : chosenum["total_amount"] };



                if(chosenum){
                    chosenum["complete"] = true;
                }

            }

            //straight data insert
            mycart[dbid] = { "id" : unique_id, "num_id" : dbid, "title" : title, "complete" : e.target.checked , "amount" : 0, "commission" : 0, "product_type" : 1 , "edit_amount" : 0, "max_amount" : max_amount, "total_amount" : total_amount};

            // console.log(chosenum["max_amount"])
            // console.log(chosenum["total_amount"])
        }

        // mycart.push({ "id" : dbid, "title" : title, "complete" : e.target.checked});

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        // Object.keys(mycart).forEach(key => mycart[key] == "null" && delete mycart[key]);

        // this.setState({
        //     twogame
        // });




        this.setState(prevState => ({
            rcondi : 1,
            form : {
                mycart: mycart,
                rmycart: rmycart,
                amount : this.state.form.amount,
                commission : this.state.form.commission,
                total : this.state.form.total

            },
            twogame: twogame
        }))


        // console.log(this.state.form.mycart)
        // console.log(this.state.mycart);
    }

    setReverse(e, toggle){

        // console.log(toggle)

        let item;
        var index;

        let pre_mycart  = this.state.form.mycart;
        let mycart      = [...this.state.form.mycart];

        let pre_rmycart = this.state.form.rmycart;
        let rmycart     = [...this.state.form.rmycart];

        let twogame = this.state.twogame;

        let artwogame = [...this.state.twogame];

        let self = this;

        Object.keys(pre_rmycart).forEach(key => pre_rmycart[key] === undefined && delete pre_rmycart[key]);

        Object.keys(pre_rmycart).forEach(key => pre_rmycart[key] == null && delete pre_rmycart[key]);

        Object.keys(pre_mycart).forEach(key => pre_mycart[key] === undefined && delete pre_mycart[key]);

        Object.keys(pre_mycart).forEach(key => pre_mycart[key] == null && delete pre_mycart[key]);

        //toggle false statement and R on
        if(this.state.toggle == false) {



            pre_mycart.forEach(function(each,key){

                // let retitle = each.title[0];
                // console.log(retitle)

                //two game index and mycart key
                // console.log("pre -------")
                // console.log(each.title[1]+each.title[0]+each.title[2])
                // console.log(self.check3dExtract(each.title))

                let numgroup = self.check3dExtract(each.title);

                if(numgroup.length > 1) {

                    for (var i = numgroup.length - 1; i >= 0; i--) {
                        // Things[i]

                        index = twogame.findIndex(obj => obj.title==(numgroup[i]));
                        // let fullindex = twogame.find(obj => obj.title==(each.title[1]+each.title[0]));

                        // item = twogame.find(item=>item.title==(each.title[1]+each.title[0]));
                        let chosenum = artwogame[index];


                        rmycart[chosenum["num_id"] ] = { "id" : chosenum["id"] , "num_id" : chosenum["num_id"],  "title" :chosenum["title"] , "complete" : chosenum["complete"] ,   "product_type" : chosenum["product_type"] , "edit_amount" : 0, "max_amount" : chosenum["max_amount"]  , "total_amount" : chosenum["total_amount"] };

                        // selftmycart not working
                        mycart[chosenum["num_id"]] = { "id" : chosenum["id"] , "num_id" : chosenum["num_id"], "title" :chosenum["title"] , "complete" : chosenum["complete"] ,   "product_type" : chosenum["product_type"] , "edit_amount" : 0, "max_amount" : chosenum["max_amount"]  , "total_amount" : chosenum["total_amount"] };

                        if(chosenum){
                            chosenum["complete"] = true;
                        }

                    }





                }

            })

            // Object.keys(rmycart).forEach(key => rmycart[key] === undefined && delete rmycart[key]);

            // Object.keys(rmycart).forEach(key => rmycart[key] == null && delete rmycart[key]);

            // Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

            // Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

            // console.log(artwogame[index])
            this.setState({
                form : {
                    mycart: mycart,
                    rmycart: rmycart,
                    amount : this.state.form.amount,
                    commission : this.state.form.commission ,
                    total : this.state.form.total
                },
                twogame : artwogame,
                toggle : true
            });

            // console.log(rmycart)

        } else {

            //undefiend clear


            // rmycart loop
            // twogame index
            // twogame uncomplete and mycart remove spec data



            // console.log("twogame ------- ")
            // console.log(pre_rmycart)

            pre_rmycart.forEach(function(each,key){


                    let fullindex = twogame.find(obj => obj.id==(each.id));
                    index = twogame.findIndex(obj => obj.id==(each.id));
                    //console.log(each)

                    // index = twogame.findIndex(obj => obj.id==(each.id));

                    // console.log(key + "full ---" +fullindex.id)

                    // console.log(key + "---" +index)

                    // console.log("listen ----- ")

                    // console.log(artwogame[index])

                    let chosenum = artwogame[index];

                    if(chosenum){
                        chosenum["complete"] = false;
                    }

                    // artwogame[index]["complete"] = 0;

                    delete mycart[fullindex.id];
                    delete rmycart[fullindex.id];




            })

            // Object.keys(rmycart).forEach(key => rmycart[key] === undefined && delete rmycart[key]);

            // Object.keys(rmycart).forEach(key => rmycart[key] == null && delete rmycart[key]);

            // Object.keys(mycart).forEach(key => (mycart[key] === undefined) && delete mycart[key]);
            // Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);



            this.setState({
                form : {
                    mycart: mycart,
                    rmycart: rmycart,
                    amount : this.state.form.amount,
                    commission : this.state.form.commission ,
                    total : this.state.form.total
                },
                twogame : artwogame,
                toggle : false
            });

        }

    }

    betAction() {

        // console.log(this.state.form.mycart)

        // console.log("----------")

        let self = this;

        if(this.state.form.amount >0 || this.state.form.amount != ""){

            let min_amount = this.state.setting[0].option_value;

            if(isNaN(this.state.form.amount)) {
                // this.showErrorAlert("Set bet minimum amount must be "+ min_amount +" Ks ", 'single');
                this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် လျော့နည်းနေပါသည်။", 'single');
                return ;
            }

            if(Number(this.state.form.amount) < min_amount) {
                // this.showErrorAlert("Set bet minimum amount must be "+ min_amount +" Ks ", 'single');
                this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် လျော့နည်းနေပါသည်။", 'single');
                return ;
            }

            // let twod_maximum_amount = this.state.twogame[0]['max_amount'];
            // let maximum_amount = twod_maximum_amount;

            // if(this.state.form.amount > maximum_amount) {
            //     // this.showErrorAlert("Set bet maximum amount must be "+ maximum_amount +" Ks ", 'single');
            //     this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် ကျော်လွန်နေပါသည်။", 'single');
            //     return ;
            // }

            console.log(this.state.form.mycart)


            if(this.state.form.mycart.length < 1) {
                this.showErrorAlert("Please select numbers!!! ", 'single');
                return ;
            }

            if(this.state.form.amount[this.state.form.amount.length-1] != 0) {
                this.showErrorAlert("အလျော်အစားတွက်ချက်ရာတွင် အခက်အခဲရှိပါသောကြောင့် 50 ဆတိုးကိန်းများသာ ထိုးပေးပါရန်" +  this.state.form.amount[this.state.form.amount.length-1], 'single');
                return ;
            }

            if( this.state.form.amount[this.state.form.amount.length-2] != 0 && this.state.form.amount[this.state.form.amount.length-2] != 5) {
                this.showErrorAlert("အလျော်အစားတွက်ချက်ရာတွင် အခက်အခဲရှိပါသောကြောင့် 50 ဆတိုးကိန်းများသာ ထိုးပေးပါရန်" + this.state.form.amount[this.state.form.amount.length-2], 'single');
                return ;
            }

            let pre_mycart = this.state.form.mycart;

            let mycart = [...this.state.form.mycart];

            let amount = this.state.form.amount;
            let number  = Number(amount.replace(/^0+/, ''));

            let total = 0;
            let commission = 0;
            // let commission_percent = this.state.setting[4].option_value;
            let commission_percent = this.state.lottery[this.state.today_event].lottery_commission;


            Object.keys(pre_mycart).forEach(key => pre_mycart[key] === undefined && delete pre_mycart[key]);

            Object.keys(pre_mycart).forEach(key => pre_mycart[key] == null && delete pre_mycart[key]);

            pre_mycart.forEach(function(each,key){

                //let commission_amount = Math.round((commission_percent/ 100) * number);
                let commission_amount = ((commission_percent/ 100) * number);

                mycart[each.num_id] = { "id" : each.id, "num_id" : each.num_id, "title" : each.title, "complete" : each.complete , "amount" : number, "commission" : commission_amount, "product_type" : 1 , "edit_amount" : 0, "max_amount" : each.max_amount , "total_amount" : each.total_amount };

                commission += commission_amount ;
                total += number;

            })

            Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

            Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


            this.setState({
                form : {
                    mycart: mycart,
                    rmycart: this.state.form.rmycart,
                    amount : this.state.form.amount,
                    commission : commission,
                    total : total
                }
            });


            this.setState({ step : "list" });


            // console.log(this.state.form.mycart)
        } else {

            this.showErrorAlert("Set bet amount!!!", 'single');
            return ;
        }


    }

    endSessionAction() {
        this.setState({ step : "form" });

        // this.fetchData(window.BPdata.apiUrlCMS+'/3d/formdata?token='+localStorage.getItem(window.BPdata.time)+"&type="+id);

        this.fetchData(window.BPdata.apiUrlCMS+'/3d/formdata?type='+this.state.today_event);

        this.setState({form : { mycart: [], rmycart :[], amount : "", commission : 0, total : 0 } });

    }

    backAction() {
        this.setState({ step : "form" });
    }

    fastAction() {

        if(this.state.fast > 0) {

            this.fetchData(window.BPdata.apiUrlCMS+'/3d/formdata');

            this.setState(prevState => ({
                fast : 0,
                step : 'fast',
                form : {
                    ...prevState.form,
                    mycart: [],

                }
            }))


            // form : { mycart: [], amount : 0, commission : 0, total : 0 , rmycart: [] },

        } else {

            this.setState({ step : "fast" , fast : 0});

        }

        // if(this.state.fast == true) {
        //     this.setState({ step : "form" });
        // } else {
        //     this.setState({ step : "fast" , fast : 0});
        // }

    }

    submitAction() {
        // alert("ok");
        if(!this.state.event.id) {
            this.showErrorAlert("Something was wrong!!!", 'single');
            return ;
        }

        if(this.state.form.mycart.length < 1) {
            this.showErrorAlert("Please select numbers!!!", 'single');
            return ;
        }

        let twod_maximum_amount = this.state.twogame[0]['max_amount'];
        let maximum_amount = twod_maximum_amount;

        if(this.state.form.amount > maximum_amount) {
            // this.showErrorAlert("Set bet maximum amount must be "+ maximum_amount +" Ks ", 'single');
            this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် ကျော်လွန်နေပါသည်။", 'single');
            return ;
        }

        let mycarts = [];
        let formdata = [];

        mycarts = this.state.form.mycart.filter(function (el) {
              return el != null;
            });

        let message = "";
        mycarts.forEach(function(each,key){

            // console.log(each.max_amount-(each.amount+each.total_amount) + "-" + key)
            if( (Number(each.max_amount)-(Number(each.amount)+Number(each.total_amount))) < 0 ) {
                message += each.title+", ";
            }
        })

        if(message != "") {
            swal("Please check these numbers " + message)
            // this.showErrorAlert(message, 'single');
            return ;
        }

        this.setState({
            isLoading: true,
        });

        if(this.state.bullet == 1) {

            swal("Please internet connection");
            return;
        }

        if(this.state.bullet == 0) {

            this.setState({
                    bullet : 1,
            });
        }

        formdata = {
            game_type : 2, mycart : mycarts,
            amount : this.state.form.amount,
            commission : this.state.form.commission,
            total : this.state.form.total ,
            contest_id :  this.state.event.id };

        var self = this;
        const dataurl = window.BPdata.apiUrlCMS+"/checkout";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"        : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(function (response) {

            // console.log(response.data.status_code)

            if(response.data.status_code == 404) {
                self.removeAlert(response.data.message, 'multi');
            }

            if(response.data.status_code == 200) {
                // self.showSuccessAlert(response.data.message, 'multi');
                self.setState({ step : "message", toggle : false });

                
                
            }

            self.setState({
                    bullet : 0,
                    isLoading: false,
            });

            if(response.data.time) {

                localStorage.setItem(window.BPdata.time, response.data.time);

            }

             // self.showSuccessAlert(response.data.message, 'multi');

            // console.log(response.data.message)

        })
        .catch(function (error) {
            console.log(error);
        });
    }

    childui(item) {

        let self = this;
        let amount = this.state.form.amount;
        let ui = []
            //console.log("-------------"+data)

            if(Array.isArray(item) === false) {
                return ui;
            } else {

                let arlength = item.length;

                if(arlength  > 0){

                    this.state.form.mycart.forEach(function(each,key){


                        ui.push(<tr key={"ro-"+each.id}>

                            { ( Number(each.max_amount) < (Number(each.amount) + Number(each.total_amount)) ) ? <td  className="text-center pointer color-blue"> {each.title} </td> : <td  className="text-center pointer"> {each.title}</td>  }
                            <td colSpan="1" className="text-center">
                                {(each.edit_amount) ?
                                    <input value={ each.amount } onChange={(e) => self.inputEditChangeHandler.call(self, e, each.num_id, each.amount, each.max_amount, each.total_amount )}  name="amount"  className="form-control" />  :  each.amount   }

                            </td>
                            {/*<td colSpan="1" className="text-center">

                                { ((each.max_amount - each.total_amount) <= 0 ) ? "Limited" : (each.max_amount - each.total_amount) }

                            </td>*/}
                            <td colSpan="1" className="text-center">

                                { each.commission }

                            </td>

                            <td>
                                {(each.edit_amount) ?
                                    <b className="color-blue" onClick={(e) => self.previewIndviAmountSave.call(e, each, each.num_id)}><img src="/img/_save.png" height="30" /> </b> :



                                    <b className="color-blue" onClick={(e) => self.previewIndviAmountEdit.call(e, each, each.num_id)} ><img src="/img/_edit.png" height="30" /> &nbsp;&nbsp; </b> }
                                    <span className=""  onClick={(e) => self.previewIndviAmountDelete.call(e, each, each.num_id)} >&nbsp;&nbsp;<img src="/img/_delete.png" height="30" /></span>
                            </td>

                        </tr>)

                    })

                    return ui
                }
        }

        return ui;
    }




    fastBlock() {
        return  <div className="row">

                <div className="grid-100 text-left">
                    <i className="pe-7s-back pe-2x pe-va " onClick={this.backAction}></i>
                </div>


                <div className="grid-100 text-left">
                    <br />
                    <h3 className="fast-titleset mb-20 ml-20">
                    { window.lang.betgroup.same_number }
                    </h3>
                    <br />
                    <br />
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 21)} className="btn btn-secondary ml-20 mr-10">{ window.lang.betgroup.same_numbers}</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 1)} className="btn btn-secondary mr-10">000</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 2)} className="btn btn-secondary mr-10">111</span>

                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 3)} className="btn btn-secondary mr-10">222</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 4)} className="btn btn-secondary">333</span>
                    </span>
                    <br />
                    <br />
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 5)} className="btn btn-secondary ml-20 mr-10">444</span>
                    </span>

                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 6)} className="btn btn-secondary mr-10">555</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 7)} className="btn btn-secondary mr-10">666</span>

                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 8)} className="btn btn-secondary mr-10">777</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 9)} className="btn btn-secondary mr-10">888</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 10)} className="btn btn-secondary">999</span>
                    </span>
                    <br />
                    <br />
                    <br />
                    <h3 className="fast-titleset mt-10 mb-20 ml-20">
                        { window.lang.betgroup.hundred_number }
                    </h3>
                    <br />
                    <br />
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 22)} className="btn btn-secondary ml-20 mr-10">{ window.lang.betgroup.hundred_numbers } </span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 23)} className="btn btn-secondary mr-10">000</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 24)} className="btn btn-secondary mr-10">100</span>

                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 25)} className="btn btn-secondary mr-10">200</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 26)} className="btn btn-secondary mr-10">300</span>
                    </span>
                    <br />
                    <br />
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 27)} className="btn btn-secondary ml-20 mr-10">400</span>
                    </span>

                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 28)} className="btn btn-secondary mr-10">500</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 29)} className="btn btn-secondary mr-10">600</span>

                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 30)} className="btn btn-secondary mr-10">700</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 31)} className="btn btn-secondary mr-10">800</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 32)} className="btn btn-secondary mr-10">900</span>
                    </span>
                    <br />
                    <br />
                    <h3 className="fast-titleset mt-30 mb-20 ml-20">
                        { window.lang.betgroup.select_number }
                    </h3>
                    <br />
                    <br />
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 11)} className="btn btn-secondary ml-20 mr-10">000-099</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 12)} className="btn btn-secondary mr-10">100-199</span>

                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 13)} className="btn btn-secondary mr-10">200-299</span>
                    </span>

                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 14)} className="btn btn-secondary mr-10">300-399</span>
                    </span>
                    <br />
                    <br />

                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 15)} className="btn btn-secondary ml-20 mr-10">400-499</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 16)} className="btn btn-secondary mr-10">500-599</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 17)} className="btn btn-secondary mr-10">600-699</span>

                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 18)} className="btn btn-secondary mr-10">700-799</span>
                    </span>
                    <br />
                    <br />
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 19)} className="btn btn-secondary ml-20 mr-10">800-899</span>
                    </span>
                    <span className="grid-30 fast-three-choose">
                        <span onClick={(e) => this.btnfastType.call(this, e, 20)} className="btn btn-secondary mr-10">900-999</span>
                    </span>

                </div>

        </div>
    }

    paginateClick(paginate) {

        this.setState({setPaginate: paginate });

        // console.log(paginate);
        //
        // this.setState(prevState => ({
        //     setPaginate : paginate,
        //     fast : 0,
        //     form : {
        //         ...prevState.form,
        //
        //     }
        // }))
    }

    paginateChange(event){
        // console.log(event.target.value+"-----")

        let paginate = event.target.value;

        this.setState(prevState => ({
            setPaginate : Number(paginate),
            fast : 0,
            form : {
                ...prevState.form,

            }
        }))

    }

    selecttimeBlock() {
        return <div className="row">
            <div className="grid-100">
                <h2>Select the time</h2>
                <br />
                <br />
                <button className="btn btn-primary grid-100" onClick={(e) => this.btnfastTimeSelect.call(this, e, 1)} >12:01 PM</button>
                <br />
                <br />
                <button className="btn btn-primary grid-100" onClick={(e) => this.btnfastTimeSelect.call(this, e, 2)} >4:30 PM</button>
            </div>
        </div>
    }

    formBlock() {
        return   <div className="row">

                    { ( this.state.isEvent == true ) ?
                        <div >

                        {(this.state.wallet[0]) ?
                                    <div className="row pb-10">
                                         <div className="adm-card row mt-10">
                                        <div className="adm-card-body grid-100">
                                            <div className="row">
                                                <div className="grid-10 left text-center">
                                                  </div>
                                                  <div className="grid-40 left text-left pl-20 ">
                                                      { window.lang.title.main_wallet }
                                                  </div>
                                                  <div className="grid-40 left text-right">
                                                     <span className='walletcolor'>
                                                     { (this.state.wallet) ? this.currencyFormat(this.state.wallet[0].credit) : 0 }  Ks

                                                     </span>
                                                  </div>
                                                  <div className="grid-10 left text-center">
                                                    <Link to="/more/wallet-store">
                                                      &nbsp; <img src="/img/plus.png" height="18px" />
                                                    </Link>
                                                  </div>
                                            </div>

                                        </div>
                                </div>
                            </div>
                            : "" }



                            <div className="row p-10">
                                <div className="grid-60 left">
                                    { (this.state.fast > 0) ?
                                    <button className="btn btn-primary grid-100" onClick={this.fastAction}>{ window.lang.betgroup.fast }</button>
                                    : <button className="btn btn-primary grid-100" onClick={this.fastAction}>{ window.lang.betgroup.fast }</button>
                                    }
                                </div>
                                <div className="grid-10 left">
                                    &nbsp;
                                </div>
                                <div className="grid-20 left">

                                    <button className={`btn grid-100 ${this.state.toggle ? 'btn-success' : 'btn-primary'}`} onClick={(e) => this.setReverse.call(this, e, 'on')}  >R</button>
                                </div>
                            </div>

                            <div className="row p-10 pt-10">
                                <div className="grid-70 left text-left">
                                    <input type="text" placeholder={window.lang.BetListDetail.amount} className="form-control text-right grid-80"  name="amount" onChange={(e) => this.formChangeHandler.call(this, e)} value={this.state.form.amount} style={{ width : "95%" }} />
                                    <br />
                                    { window.lang.betgroup.close_at } {moment(this.state.event.expire_date, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')}
                                    <br />



                                    <br />
                                </div>
                                <div className="grid-20 left text-left">
                                    <button className="btn btn-yellow   pointer grid-100" onClick={this.betAction} style={{ 'float':'center',"margin": "8px 0"}}>{ window.lang.betgroup.bet }</button>
                                    <br />
                                    <br />
                                    <select id="paginate" className="bg-light-purple" onChange={this.paginateChange} value={this.state.setPaginate}>
                                      <option value="0">000 - 099</option>
                                      <option value="1">100 - 199</option>
                                      <option value="2">200 - 299</option>
                                      <option value="3">300 - 399</option>
                                      <option value="4">400 - 499</option>
                                      <option value="5">500 - 599</option>
                                      <option value="6">600 - 699</option>
                                      <option value="7">700 - 799</option>
                                      <option value="8">800 - 899</option>
                                      <option value="9">900 - 999</option>
                                   </select>
                                </div>
                            </div>
                            <br />

                            <div className="row grid-95 text-left" >
                                <div className="popover__wrapper pl-20">
                                  <a href="#" className="pl-20">
                                    <b className="popover__title color-white flex verticle-align-middle"><img src="/img/color_meaning.png" /> &nbsp;&nbsp;{ window.lang.betgroup.color_meaning } </b>
                                  </a>
                                  <div className="popover__content">
                                    <div className="popover__message">

                                        {window.lang.betgroup.color_box_one} &nbsp;
                                        <span className='color-yellow'>{window.lang.betgroup.color_three}</span> <br/>
                                        {window.lang.betgroup.color_box_two} &nbsp;
                                        <span className='color-green'>{window.lang.betgroup.color_two}</span>
                                        <br />
                                        {window.lang.betgroup.color_box_three}
                                        <span className='color-red'>{window.lang.betgroup.color_four}</span> &nbsp;
                                        <br />
                                        {window.lang.betgroup.color_box_four}&nbsp;

                                        <span className='color-black'>{window.lang.betgroup.color_one}</span>


                                    </div>
                                  </div>
                                </div>
                            </div>

                            <br />

                             <div className="row pb-10">
                                    {this.state.twogame.map((item, i) => (
                                        ((item.paginate == this.state.setPaginate) ?
                                        (this.percentage(item.total_amount,item.max_amount) < 100) ?
                                        <span className="grid-15"><label role="checkbox" className={`bp-checkbox-button ${item.complete ? 'is-checked' : ''}`}  aria-checked="true" key={i}><input type="checkbox" className="bp-checkbox-button__original" value="000" onClick={(e) => this.inputChangeHandler.call(this, e, i, item.id,item.num_id, item.title, item.max_amount, item.total_amount)}  defaultChecked={!! item.complete}/>
                                        <span className="bp-checkbox-button__inner">{item.title}
                                            
                                            { this.progressBarUi(this.percentage(item.total_amount,item.max_amount),i) }

                                        </span>
                                        </label>
                                        </span>
                                        :
                                        <span className="grid-15 "><label role="checkbox" className={`bp-checkbox-button ${item.complete ? 'is-checked' : ''}`}  aria-checked="true" key={i}>
                                        <span className="bg-gray bp-checkbox-button__inner disabled">{item.title}
                                            
                                            { this.progressBarUi(this.percentage(item.total_amount,item.max_amount),i) }

                                        </span>
                                        </label>
                                        </span>
                                        : ""
                                        )

                                    ))}
                            </div>
                            <div className="row pb-10">
                                <div className="grid-100 text-center">
                                    {(this.state.setPaginate > 0) ? <button className="btn btn-yellow   pointer grid-30 center" onClick={(e) => this.paginateClick.call(e, this.state.setPaginate-1)} >{window.lang.form.from_arrow}</button> : "" } &nbsp;
                                    {(this.state.setPaginate < 9) ?
                                    <button className="btn btn-yellow   pointer grid-30 center"  onClick={(e) => this.paginateClick.call(e, this.state.setPaginate+1)} >{window.lang.form.to_arrow}</button> : "" }
                                </div>
                            </div>
                        </div>
                    :  window.lang.messages.not_available_yet  }
        </div>
    }

    progressBarUi(percent,i) {

        let ui = []

        if(percent <= 50) {
            if(percent < 20) {
                percent = 20;
            }
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success" key={"pro-"+i}>
                    <div className="bp-progress-bar">
                    <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-yellow" style={{ width: percent+"%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        } else if( percent> 50 && percent < 90) {
            if(percent < 60) {
                percent = 60;
            }
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success" key={"pro-"+i}>
                    <div className="bp-progress-bar">
                    <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-green" style={{ width: percent+"%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        } else if( percent > 90 && percent < 100) {
            if(percent < 95) {
                percent = 80;
            }
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success">
                    <div className="bp-progress-bar">
                    <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-red" style={{ width: percent+"%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        } else {
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success">
                    <div className="bp-progress-bar">
                    <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-black" style={{ width: "100%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        }
        return ui;
    }

    listBlock() {
        return  <div className="row">

                <div className="grid-95 text-right">
                    <span class="text-primary btn btn-yellow btn-small" onClick={this.backAction}><i className="pe-7s-plus pe-2x pe-va pointer"></i> {window.lang.BetListDetail.add}</span>

                </div>

                <div className="grid-95 text-center">
                    {window.lang.BetListDetail.odds}:{this.state.lottery[0].lottery_multiple}
                </div>

                <div className="grid-95 ml-10">
                <table className="table  table-border league-table" >
                    <thead>
                        <tr>
                            <th>3D</th>
                            <th>{ window.lang.betgroup.bet_amount }</th>
                            <th>{ window.lang.betgroup.commission }</th>
                            <th>{ window.lang.betgroup.action }</th>
                        </tr>
                    </thead>
                    <tbody>

                        {this.childui(this.state.form.mycart)}

                        <tr>
                            <td colSpan="2">{ window.lang.betgroup.commission_total } (?)</td>
                            <td>{this.currencyFormat(this.state.form.commission)} Ks</td>
                            <td></td>
                        </tr>

                        <tr>
                            <td colSpan="2">{ window.lang.betgroup.total }</td>
                            <td>{this.currencyFormat(this.state.form.total)} Ks</td>
                            <td></td>
                        </tr>

                    </tbody>
                </table>
                </div>

                <button className="btn btn-yellow  pointer grid-95" onClick={this.submitAction}>{ window.lang.betgroup.bet_amount_submit }</button>
                <br />
                <br />
        </div>
    }

    messageBlock() {
        return  <div className="row">

                <div className="adm-card row grid-90 bg-noise ">

                    <div className="adm-card-body grid-100 ">
                        <div className="grid-100 text-center ptp-10 pbp-10">

                            <img src="/img/_success.png" height="100px"/>
                            <br />
                            <br />
                            <p>{window.lang.BetListDetail.success}</p>
                        </div>


                    </div>
                </div>
                <br />
                <br />

                <button className="btn btn-yellow   pointer grid-90" onClick={this.endSessionAction}>{window.lang.BetListDetail.btn}</button>
                <br />
                <br />
        </div>
    }

    viewHandler() {


        switch(this.state.step) {
            case "selecttime": return this.selecttimeBlock();
            case "form": return this.formBlock();
            case "list": return this.listBlock();
            case "message": return this.messageBlock();
            case "fast": return this.fastBlock();
            default:      return "";
          }
    }

    render() {
        return (

            <div className="page">
                <div className="page-content text-center">
                <AppHeader backLink="/three-game" titleType="3" title={window.lang.title.threed} isLoading="false" />

                    <div className="main-content two_home">
                    <br />
                    {this.state.seen ? <PopUp toggle={this.togglesPop} /> : null}
                    {/*<button onClick={this.showErrorAlert}>Show success alert</button> */}
                    <div className="grid"><StatusAlert/></div>

                    {/* { this.viewHandler() } */}
                    {this.state.isLoading ? <div className="grid-100 text-center"><LoadingSpinner /></div> : this.viewHandler() }


                    </div>
                </div>

                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
            </div>
        );
    }
}

export default ThreeGameSetPage;
