import React from 'react';
import { Grid } from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';
import AppHeader from './AppHeader';

let checklogin = () => {
     if(localStorage.getItem(window.BPdata.time)) {
         if(localStorage.getItem(window.BPdata.time) !== "0" ) {
             return true;
         }
     }
     return false;
 }

export default () => (
  <div className="page">
      <div className="page-content">
          <div className="main-content text-center">
                    <br />
                    <br />
                    <h2 class="color-theme-gold" style={{ "fontSize": "24px"}}>ThaHtayLay App ကို IOS သမားများအတွက် ဖုန်းထဲကို အက်ပလီကေးရှင်းထည့်နည်း</h2>
                    <span><br /><br />

                    ✅ပထမဦးဆုံး safari browser ထဲကိုဝင်ပြီး <a href="https://thahtaylay.com" target="_blank" className="color-blue">https://thahtaylay.com</a> ကိုရိုက်ရှာကာ ဝက်ဆိုဒ်ထဲသို့ဝင်ပါ။<br />
                    ၁။ safari browser ရဲ့ share button ကိုနှိပ်ပါ <br />
                    ၂။ Add to Home Screen ကိုနှိပ်ပါ <br />
                    ၃။ လူကြီးမင်းရဲ့ဖုန်းထဲသို့ ThaHtayLay icon လေးရောက်ရှိနေတာ တွေ့ပါလိမ့်မယ် <br /></span>
                    <br />
                    <br />
                    <img src="/img/guide/iosss1.jpg" className="img-responsive" /><br />
                    <br />
                    <img src="/img/guide/iosss2.jpg" className="img-responsive" /><br />
                    <br />
                    <img src="/img/guide/iosss3.jpg" className="img-responsive" /><br />
                    <br />
                    <a href="https://thahtaylay.com" target="_blank" className="color-blue">https://thahtaylay.com</a> 
          </div>
        </div>

        <footer  className="App-footer">

            <div className="App-footer-inner">
              {/* {window.BPdata.footerMenu} */}
              <Grid data={window.lang.footer}
                  columnNum={5}
                  renderItem={dataItem => (

                    <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                          { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                    </Link>
                  )}
                />

            </div>
        </footer>
  </div>
);
