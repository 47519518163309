import React, { useEffect, useState } from 'react';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// Initialize Firebase


const NotiTestPage = () => {
  const [token, setToken] = useState(null);
  const [permission, setPermission] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyDnIAPFA01b1FS_KOrz1g4LgxveeCzMtlQ",
      authDomain: "thahtaylay-96fff.firebaseapp.com",
      projectId: "thahtaylay-96fff",
      storageBucket: "thahtaylay-96fff.appspot.com",
      messagingSenderId: "918243368430",
      appId: "1:918243368430:web:0ca0f9a3b13b1d48d402ff",
      measurementId: "G-XQ1SQ4W65H"
    };

    // const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    
    async function requestPermissionAndGetToken() {
      try {
          const permission = await Notification.requestPermission();
          if (permission === 'granted') {
              const token = await getToken(messaging, { vapidKey: 'BKryqU9GSjInSR18uCGtt7x_j8eGWDGi6nOMzmiB-1djg-EKz2uJ0hKgF2vyPtBSxczyAPGCEfkcroGdXbu7So8' });
              console.log('FCM Token:', token);
          } else {
              console.log('Permission not granted');
          }
      } catch (error) {
          console.error('Error getting permission or token', error);
      }
    }

    requestPermissionAndGetToken();
    // messaging.requestPermission()
    //   .then(() => {
    //     console.log('Notification permission granted.');
    //     setPermission(true);
    //     return messaging.getToken();
    //   })
    //   .then(firebaseToken => {
    //     console.log(firebaseToken);
    //     setToken(firebaseToken);
    //   })
    //   .catch(error => {
    //     console.error(error);
    //     setPermission(false);
    //   });

    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
      // setMessage(payload.notification.title);
    });
  }, []);

  return (
    <div>
      <p>Token: {token}</p>
      <p>Permission: {permission ? 'Granted' : 'Denied'}</p>
      <p>Message: {message}</p>
    </div>
  );
};

export default NotiTestPage;
