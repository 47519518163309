/* eslint no-dupe-keys: 0 */
import React from 'react';

//import Swiper from 'swiper/dist/js/swiper.min.js';

import Carousel from 're-carousel'
// import {
//   Link
// } from 'react-router-dom';

import axios from 'axios';

let NUM_ROWS = 4;
// let pageIndex = 0;

class NewsVideoSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      wallet : [
          {
              wallet_id: 1,
              credit : 0
          },
          {
              wallet_id: 2,
              credit : 0
          },
          {
              wallet_id: 3,
              credit : 0
          }
      ],
      banners: [],
      virtualVideoData: {
        slides: [
          {
            "slider_id": "31",
            "slider_name": "ဂ်ာမနီ 1-1အီတလီ (pen 6-5 )",
            "slider_link": "https://www.youtube.com/watch?v=JXZ3FmTk_eI",
            "news_embed": "http://www.youtube.com/embed/JXZ3FmTk_eI",
            "news_image": "https://img.youtube.com/vi/JXZ3FmTk_eI/mqdefault.jpg",
            "slider_description": "<p>ဂ်ာမနီ 1-1အီတလီ (pen 6-5 )</p>",
            "created_at": "2018-12-19 09:39:07",
            "updated_at": "2018-12-19 15:12:36"
          }
        ]
      },
      leagueMenu: 'NEWS',
      date: new Date()
    };



    // console.log(this.props)
    // if(this.props.videoData) {
    //   // console.log()
    //   console.log( this.props.videoData)
    //   this.state.virtualVideoData.slides = this.props.videoData
    // }

   
    
  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  // componentWillMount() {
  //   this.fetchData();
  // }

  componentDidMount() {
    if(localStorage.getItem('banners')) {
        
      if(JSON.parse(localStorage.getItem("banners")) != null) {
        let video_slides = JSON.parse(localStorage.getItem("banners"));
        this.setState({banners: video_slides})
      }
    } 

    //setTimeout(() => {
      this.videoSliders();
    //}, 600);
    
    // this.timerID = setInterval(
    //   () => this.tick(),
    //   1000
    // );
    

  }

  async videoSliders() {
    await this.fetchData();

    // new Swiper('.swiper-video-container', {
    //   // ...
    //   loop: true,
    //   // slidesPerView: 'auto',
    //   pagination: {
    //     el: '.swiper-vieo-pagination',
    //   },
    // });
  }

  // tick() {
  //   this.setState({
  //     date: new Date()
  //   });
  // }

  fetchData() {
        
    const url = window.BPdata.apiUrlCMS+'/home?limit='+NUM_ROWS+'&start=10&featured=0';

    axios.get(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: "same-origin", // include, *same-origin, omit
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-Trident-Token"   : window.BPdata.token,
          "BP-Agent"          : window.BPdata.rand_compound,
          "BP-Time"           : localStorage.getItem(window.BPdata.time)
          // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    .then(resp => {

      let data = resp.data.banners;

      localStorage.setItem("banners", JSON.stringify(data) )

      if(resp.data.wallet[0]) {

          this.setState({
              wallet : resp.data.wallet,
          });

          localStorage.setItem("wallet", JSON.stringify(resp.data.wallet) )
      }
      // console.log(localStorage.getItem("banners")+"-----------");

      if(JSON.parse(localStorage.getItem("banners"))) {
        let video_slides = resp.data.banners;
        this.setState({banners: video_slides})
      }

        
      })
      .catch(function(error) {
        // console.log(error);
      });   
  }
  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    
    return  <div className="swiper-video-container" style={{height:'200px', padding: '12px'}}>
        <Carousel loop auto >
            {this.state.banners.map((slide, index) => (
                <img className="swiper-slide"
                key={index} style={{left: `${this.state.banners.offset}px`}}  src={window.BPdata.apiAssetUrl+'/'+slide.slider_link} />
             ))}
        </Carousel>
        </div>
  }
}

export default NewsVideoSlider;