import React, { Component } from "react";

export default class PopUp extends Component {

  handleClick = () => {
   this.props.toggle();
  };

  render() {

    return (
      <div className="ui-modal-overlay" onClick={this.handleClick}>
       <div className="ui-modal">
         <div className="ui-modal-content">
         <span className="close" onClick={this.handleClick}>&times;    
         </span>
         <p className="color-green">I'm A Pop Up!!!</p>
        </div>
       </div>
     </div>
    );

  }

}