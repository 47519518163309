import React, {Component} from "react";
import axios from 'axios';
import ImageUploading from "react-images-uploading";
//import Comment from "./Comment";
import renderHTML from 'react-render-html';
// var md = require('markdown-it')().use(require('markdown-it-emoji'));
var moment = require('moment');

// md.renderer.rules.emoji = function(token, idx) {
//   return '<i class="em em-' + token[idx].markup + '" aria-role="presentation" aria-label="BIRD"></i>';
// };

const maxNumber = 10;
const maxMbFileSize = 8;

  export default class CommentList extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        error: "",
        comments: this.props.comments,
        // loading: this.props.loading,
        toggle : 0,
        tag     : 0,
        // rcomment: {
        //   salt: localStorage.getItem(window.BPdata.time),
        //   message: "",
        //   parent_id : props.comment.id,
        //   type_id : props.comment.type_id
        // }
        rcomment: {
          salt: localStorage.getItem(window.BPdata.time),
          message: "",
          key_id : 0,
          parent_id : 1,
          type_id : this.props.type_id,
          featured_image : ""
        },
        featured_image : ""
      };

      if(props.post_id) {
        this.state.post_id = props.post_id
        this.state.type_id = props.type_id
      }

      this.handleFieldChange  = this.handleFieldChange.bind(this);
      this.replyToggle        = this.replyToggle.bind(this);
      // this.replyCheck         = this.replyCheck.bind(this);
      this.onReplySubmit      = this.onReplySubmit.bind(this);
  
    }

    replyToggle(e) {
      let id = e.currentTarget.dataset.id;
      let parent_id = e.currentTarget.dataset.parent_id;
      let key_id = e.currentTarget.dataset.keyid;

      if(id === this.state.toggle) {
        this.setState({ toggle : 0, tag: 0 });
      } else {
        this.setState({ toggle: id , tag: 0 , rcomment : { salt: localStorage.getItem(window.BPdata.time), message: "",
        parent_id : parent_id, type_id : this.props.type_id , key_id : key_id, featured_image: "" }, featured_image : "" });

      }
      
      console.log(this.state.tag + "sdfsd")
      //console.log(JSON.stringify(e)+"-------")
      //this.setState({ toggle : id});
      // if(this.state.toggle) {
      //   this.setState({ toggle : false });
      // } else {
      //   this.setState({ toggle : true });
      // }
      
    }

    replyCheck(id) {
      // console.log(id + "-------" + this.state.toggle)
      if(id === this.state.toggle ) {
        return true;
      } 
      return false;
       // this.setState({ toggle : id});
    }
    
    fetchCommentData() {
      

      let time = localStorage.getItem(window.BPdata.time);
      var self = this;
        //let dataurl = window.BPdata.apiUrlCMS+'/forum/detail?id='+this.state.post_id+'&start=10&time='+time;
        let dataurl = window.BPdata.apiUrlCMS+'/player/comment/'+this.state.post_id+"/"+this.state.type_id;

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          
          self.forceUpdate();
          self.setState({
            comments: resp.data.data
          });

            
        })
        .catch(function(error) {
          this.setState({ loading: false });
        });   

      // //if(this.props.comment !== null) {
      //   fetch(window.BPdata.apiUrl+'/user/comment/'+this.state.post_id+"/"+this.state.type_id)
      //   .then(res => res.json())
      //   .then(res => {
      //     // console.log(res)
      //     this.forceUpdate();
      //     this.setState({
      //       comments: res
      //     });
      //   })
      //   .catch(err => {
      //     this.setState({ loading: false });
      //   });
      // //}
      
    }

    onChange = (imageList) => {
      // data for submit
        this.fileUpload(imageList);
    };

    fileUpload = (imageList) => {
        let fd = imageList;

        axios.post(window.BPdata.apiUrl+'/user/featureimage/'+this.state.post_id+'/upload', fd).then(res=>
            {
              if(res.data) {
                if(res.data.length>0) {
                    //this.setState({featured_image : row.url})
                    for (let index = 0; index < res.data.length; index++) {
                        let row = res.data[index];
                        this.setState({featured_image : row.shorturl})

                        //restore data and mix image
                        // salt: localStorage.getItem(window.BPdata.time),
                        // message: "",
                        // key_id : 0,
                        // parent_id : 1,
                        // type_id : this.props.type_id,
                        // featured_image : ""
                        this.setState({
                          rcomment: {
                            salt            : localStorage.getItem(window.BPdata.time),
                            message         : this.state.rcomment.message,
                            key_id          : this.state.rcomment.key_id,
                            parent_id       : this.state.rcomment.parent_id,
                            type_id         : this.props.type_id,
                            featured_image  : this.state.featured_image,
                          }
                        })
                    }
                }
              }
                
            }
        );         
    }  

    componentDidMount() {
      // console.log("fetch ========= "+this.props.fetch )

      if(this.props.fetch === 1){
          this.forceUpdate();
          this.fetchCommentData();
      } 
      // this.forceUpdate();
      // this.fetchCommentData();

    }

    componentDidUpdate(prevProps){

      //  if(this.props.fetch === 1){
      //       this.forceUpdate();
      //       this.fetchCommentData();
      //   } 
        
        if(prevProps.comments !== this.props.comments){
            //console.log("ok")
            
            this.forceUpdate();
            this.fetchCommentData();
            // this.setState({          
            //   comments: this.props.comments,
            //   loading: this.props.loading
            // });
        }
       
        
    }

    displayBlock() {
      //let comments = this.state.comments
      
      let ui = []
      let len = this.state.comments.length
      
      //let data
      if( len > 0){
        
        for(let i = 0; i < len; i++ ) {
          let comment= this.state.comments[i];
          ui.push(
            // <span>{comments[i].message}<br /></span>
            // <Comment key={i} comment={this.state.comments[i]} />       
              <div key={i} className="media mb-3  bg-white color-green row">
                <div className="grid-10 left">
                  <img
                    className="mr-3 bg-light rounded"
                    src={`/img/profile.jpg`}
                    alt={comment.username}
                  />
                  
                </div>
                <div className="media-body p-2 mb-10 shadow-sm rounded bg-light border grid-90 right">
                  {/* <small className="float-right text-muted">{time}</small> */}
                  <div className="girds">
                    <b className="mt-0 mb-1 text-muted text-left color-dark-blue">{comment.username}</b>{renderHTML(comment.message)}
                    {(comment.featured_image !== "") ? <a href={"/image/"+comment.id} ><img src={window.BPdata.apiAssetUrl+"/images/user/"+comment.featured_image} /> </a> : "" }
                    
                    {/* {md.render(comment.message)}  */}
                     
                  </div>
                  <div className="girds ">
                    <span onClick={this.replyToggle} data-id={comment.id} data-parent_id={comment.id} data-keyid={comment.key_id} className="color-dark-blue pointer text-left">Reply</span>
                    <i className='text-right'> {moment(comment.created_at, 'YYYY-MM-DD hh:mm:ss').add(6, 'hours').add(30, 'minutes').fromNow()}</i>
                  </div>
                  <div className="girds">
                    {/* <div className="form-group"><br /><div className="grid-80 left"><input type="text" className="form-control"  onChange={this.handleFieldChange} name="message" value={this.state.rcomment.message}/> </div><div className="grid-10 left"><button className="btn btn-success" onClick={this.onReplySubmit}>Submit</button></div> </div> */}
                    { (this.replyCheck(comment.id)) ? <div className="form-group"><br /><div className="grid-70 left pr-10">
                    {/* <input type="text" className="form-control"  onChange={this.handleFieldChange} name="message" value={this.state.rcomment.message} data-parent={comment.username}/>  */}
                    <textarea  onChange={this.handleFieldChange} name="message" value={this.state.rcomment.message} data-parent={comment.username} className="form-control"  style={{height:"18px"}}>{this.state.rcomment.message} </textarea>
                    </div>
                    
                    <div className="grid-20 left"> 
                    {/* <img src="/img/btn-correct.png"  style={{height:"18px"}} onClick={this.onReplySubmit}/>  */}
                    <button className="btn btn-success" onClick={this.onReplySubmit} style={{height:"90%", width: "90%"}}>Submit</button>
                    </div> 
                    <div className="row left">
                          <ImageUploading
                                onChange={this.onChange}
                                maxNumber={maxNumber}
                                maxFileSize={maxMbFileSize}
                                acceptType={["jpg", "gif", "png"]}
                            >
                                {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                // write your building UI
                                <div className="grids">
                                  {/* <img src="/img/camera.png"  />  */}
                                    {/* <button onClick={onImageUpload}>Img</button> */}
                                    <button onClick={onImageUpload}><img src="/img/camera.png"  /> Upload images</button> 
                                    
                                </div>
                                )}
                            </ImageUploading>
                    </div>
                    
                              {(this.state.featured_image !== "") ? <div className="grids"><br/><br/> <img src={window.BPdata.apiAssetUrl+"/images/user/"+this.state.featured_image} /> </div>: "" }</div> : "" }
                  </div>
                </div>
                  { this.childui(comment) }
              </div>
          )
        }
      }

      return ui
    }

    
  
    handleFieldChange = event => {
      const { value, name } = event.target;
      let parent = event.currentTarget.dataset.parent;
  
      //inital add referal 
      if(this.state.tag === 0) {
        this.setState({
          ...this.state,
          rcomment: {
            ...this.state.rcomment,
            [name]: "*"+parent+"* "+value
          },
          tag : 1
        });
      } else {
        this.setState({
          ...this.state,
          rcomment: {
            ...this.state.rcomment,
            [name]: value
          }
        });
      }
      
    };
  
    childui(comment) {
      let parentid = comment.id
      let ui = []
  
      if(comment.child) {
        //let each = arr
        let each = comment.child;
        let arlength = each.length;
          
        if(arlength > 0) {
            
            for (let i = 0; i < arlength; i++) {
                ui.push(
                    <div className="sub-media mb-3 color-green grid-90 clear" >
                        <div className="grid-10 left">
                          <img
                            className="mr-3 bg-light rounded"
                            src={`/img/profile.jpg`}
                            alt={each[i].username}
                          /> 
                        </div>
  
                        <div className="rounded border grid-90 right">
                            <div className="girds">
                              <b className="mt-0 mb-1 text-muted text-left color-black">{each[i].username}</b>{renderHTML(each[i].message)}
                              {(each[i].featured_image !== "") ? <a href={"/image/"+each[i].id} ><img src={window.BPdata.apiAssetUrl+"/images/user/"+each[i].featured_image} /> </a> : "" }
                    
                              {/* {md.render(each[i].message)}  */}
                            </div>
                            <div className="girds">
                              <span onClick={this.replyToggle} data-id={each[i].id} data-parent_id={parentid} data-keyid={each[i].key_id} className="color-dark-blue pointer text-left">Reply</span>
                              <i className='text-right'> {moment(each[i].created_at, 'YYYY-MM-DD hh:mm:ss').add(6, 'hours').add(30, 'minutes').fromNow()}</i>
                            </div>
                            <div className="girds">
                              {/* <div className="form-group"><br /><div className="grid-80 left"><input type="text" className="form-control"  onChange={this.handleFieldChange} name="message" value={this.state.rcomment.message}/> </div><div className="grid-10 left"><button className="btn btn-success" onClick={this.onReplySubmit}>Submit</button></div> </div> */}
                              { (this.replyCheck(each[i].id)) ? <div className="form-group"><br /><div className="grid-70 left pr-10">
                              {/* <input type="text" className="form-control"  onChange={this.handleFieldChange} name="message" value={this.state.rcomment.message} data-parent={each[i].username}/>  */}
                              <textarea  onChange={this.handleFieldChange} name="message" value={this.state.rcomment.message} data-parent={each[i].username} className="form-control"  style={{height:"18px"}}>{this.state.rcomment.message} </textarea>
                              </div><div className="grid-20 left"><button className="btn btn-success" onClick={this.onReplySubmit}>Submit</button></div> 
                              <div className="row left">
                                  <ImageUploading
                                        onChange={this.onChange}
                                        maxNumber={maxNumber}
                                        maxFileSize={maxMbFileSize}
                                        acceptType={["jpg", "gif", "png"]}
                                    >
                                        {({ imageList, onImageUpload, onImageRemoveAll }) => (
                                        // write your building UI
                                        <div className="grids">
                                            <button onClick={onImageUpload} className="btn"><img src="/img/camera.png"  />Upload images</button> 
                                            
                                        </div>
                                        )}
                                    </ImageUploading>
                            </div>
                              {(this.state.featured_image !== "") ? <div className="grids"><br/><br/> <a href={"/image/"+each[i].id} ><img src={window.BPdata.apiAssetUrl+"/images/user/"+this.state.featured_image}   /> </a> </div>: "" }</div> : "" }
                            </div>
                        </div>
  
                      </div>
                  )
              }
            }
        }
        return ui;
      }

      onReplySubmit(e) {
        // prevent default form submission
        e.preventDefault();
    
        // persist the comments on server
        let { rcomment } = this.state;
        fetch( window.BPdata.apiUrl+'/user/comment/'+this.state.rcomment.key_id+'/post', {
          method: "post",
          body: JSON.stringify(rcomment)
        })
          .then(res => res.json())
          .then(res => {
            if (res.error) {
              this.setState({ loading: false, error: res.error });
            } else {
    
              this.setState({
                comments: res,
                rcomment: {
                  salt: localStorage.getItem(window.BPdata.time),
                  message: "",
                  key_id : rcomment.key_id,
                  parent_id : rcomment.parent_id,
                  type_id : this.props.type_id,
                  featured_image  : "",
                },
                featured_image : "",
                toggle : 0, 
                tag: 0
              });
              // let tempState = [res,...this.state.comment.child];
              
              // //tempState.push(res)
    
              // this.setState({
              //   comment: {
              //     id	      : this.state.comment.id,
              //     key_id	  : this.state.comment.key_id,
              //     salt	    : this.state.comment.salt,
              //     message	  : this.state.comment.message,
              //     parent_id	: this.state.comment.parent_id,
              //     type_id	  : this.state.comment.type_id,
              //     created_at	: this.state.comment.created_at,
              //     updated_at	: this.state.comment.updated_at,
              //     username	  : this.state.comment.username,
              //     child : tempState
              //   }
              // })
    
              // this.setState({
              //   rcomment: {
              //     salt: localStorage.getItem(window.BPdata.time),
              //     message: "",
              //     parent_id : rcomment.parent_id,
              //     type_id : res.type_id
              //   }
              // });
    
              //console.log(this.state.comment)
    
            }
          })
          .catch(err => {
            this.setState({
              error: "Something went wrong while submitting form.",
              loading: false
            });
          });
      }
    
    render() {
      // let comments = [];
      // comments = this.props.comments 
      // let loading = this.props.loading 

      return (
        
          <div className="commentList bg-slight-gray mt-4 color-green">
              {/* <h5 className="text-muted row text-right">
                <span className="badge badge-success">{props.comments.length}</span>{" "}
                {window.lang.form.comment} {props.comments.length > 0 ? "s" : ""}
              </h5> */}
              <br />
              {this.state.comments.length === 0 && ! this.state.loading ? (
                <div className="alert text-center alert-info">
                  ဒီ ပိုစ့် အတွက် ပထမဦးဆုံး ထင်မြင်ချက်ကို ရေးလိုက်ပါ။
                </div>
              ) : null}

                { this.displayBlock() }

              {/* {comments.map((comment, index) => (
                <Comment key={index} comment={comment} />
              ))} */}

            </div>
       
    );
  }
}

