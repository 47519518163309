/* eslint no-dupe-keys: 0 */
import React from 'react';

import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { ListView, Grid } from 'antd-mobile';

import ApzHeader from './../../ApzHeader';

// import ScrollMenu from 'react-horizontal-scrolling-menu';

import swal from 'sweetalert';

//import { FacebookProvider, CommentsCount } from 'react-facebook';
//import { askForPermissioToReceiveNotifications } from './../push-notification';
require('./../../../../BPdata.js')

const alert = Modal.alert;
var moment = require('moment');

//var moment = require('moment');


let data = [
  {
    news_image: 'https://zos.alipayobjects.com/rmsportal/dKbkpPXKfvZzWCM.png',
    news_name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
    news_desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
  }
];


let NUM_ROWS = 3;
//let MAX_LENGTH = 90;
// One item component
// selected prop will be passed

class ApzNewsPage extends React.Component {
  constructor(props) {
    super(props);
    window.BPdata.footerMenu = "home";

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      login: 0,
      is_verified: 1,
      selected: 0,
      dataSource,
      isLoading: true,
      leagueMenu: 'NEWS',
      open: false,
      fopen: false,
      advopen: false,
      menu: window.BPdata.mainmenu,
      wallet: [],
      data : [
        {id:    1, title : "Web App", type  : 0, description : "Web App version 1.1.2 is available now", link:"https://thahtaylay.com",
            created_at : "2019-05-21 08:14:30" },
      ],
      seconds: 5,
      isCounting: false
    };



  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  onOpenModal = () => {
    this.setState({ open: true, fopen: true, advopen: true });
  };

  onCloseModal = () => {
    this.setState({ open: false, fopen: false, advopen: false });


    //localStorage.setItem('popup', false);
    // window.BPdata.popup = false
    // console.log(window.BPdata.popup)
  };

  close = () => {
    this.startCountdown();
    setTimeout(() => this.onCloseModal(), 5000)
  };

  startCountdown = () => {
    this.setState({ isCounting: true });
    const intervalId = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.seconds === 0) {
          clearInterval(intervalId);
          this.setState({ isCounting: false });
          return { seconds: 5 }
        }
        return { seconds: prevState.seconds - 1 }
      });
    }, 1000);
  }

  currencyFormat(num) {
    return num;
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  

  componentDidMount() {

    window.scrollTo(0, 0);

    // this.setState({ fopen: true });

    let menu = ["/"];

    localStorage.setItem("route", JSON.stringify(menu));
    //console.log(localStorage.getItem('route'));
    //console.log(window.BPdata.apiUrl)
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);



    const self = this;


    




    // this.setState({ fopen: true });

    let checkday = 0;

    var d = new Date();
    var todaynum = d.getDay();
    var todayhr = d.getHours();

    if (localStorage.getItem('popup')) {
      if (localStorage.getItem('popup') != todaynum) {
        localStorage.setItem('popup', todaynum);
        this.setState({ open: true });

      } else {
        checkday = 1;
      }

    } else {
      localStorage.setItem('popup', todaynum);
      this.setState({ open: true });
    }


    // this.setState({ fopen: true });

    if (checkday == 1) {

      if (localStorage.getItem('fpopup')) {
        if (localStorage.getItem('fpopup') != todaynum) {

          localStorage.setItem('fpopup', todaynum);
          localStorage.setItem('fpopup_time', todayhr);
          this.setState({ fopen: true });

          this.close();

        }

        if (localStorage.getItem('fpopup') == todaynum) {

          if (localStorage.getItem('fpopup_time') != todayhr) {

            localStorage.setItem('fpopup_time', todayhr);
            this.setState({ fopen: true });

            this.close();

          }

        }


      } else {

        localStorage.setItem('fpopup', todaynum);
        this.setState({ fopen: true });

        this.close();

      }

    }


    // this.setState({ open: true });

    // this.setState({ open: true });

    // if(localStorage.getItem('wallet')) {

    //   if(JSON.parse(localStorage.getItem("wallet")) != null) {
    //     let wallet = JSON.parse(localStorage.getItem("wallet"));
    //     this.setState({wallet: wallet})
    //   }
    // }

    //console.log(localStorage.getItem('popup'));
    //this.fetchIndexUserhData();
    clearTimeout(window.BPdata.liveurl);

    if (localStorage.getItem(window.BPdata.time)) {
      if (localStorage.getItem(window.BPdata.time) !== "0") {
        this.setState({ login: 1 })

        this.fetchIndexUserhData(window.BPdata.apiUrlCMS + '/cron');

      }
    } else {
      this.setState({ login: 0 })
    }


    // this.fetchUserData();

    this.getAnnouncementsApi();




    // if (navigator.userAgent.toLowerCase().indexOf("thahtaylay") > -1 ) {
    //   console.log("other")
    // } else {
    //     if(localStorage.getItem('androidpopup')) {
    //       if(localStorage.getItem('androidpopup') === 'reset') {
    //         localStorage.setItem('androidpopup', true);
    //         this.setState({ open: true });
    //       }

    //     } else {
    //       localStorage.setItem('androidpopup', true);
    //       this.setState({ open: true });
    //     }

    // }
  }

  getAnnouncementsApi() {
      var self = this;
      let dataurl = window.BPdata.apiUrlCMS+'/announcements';
      let data = this.state.data;
      axios({
          method: 'get',
          url: dataurl,
          data: data,
          headers: {
            "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound
            // "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(function (response) {
          if(response.data.data) {
              self.setState({
                  data : response.data.data,
              });

              localStorage.setItem("noti", JSON.stringify(response.data.data) )
              //self.setState({  message : "Successfully updated" });
              //console.log(response.data.data)
          } else {
              self.setState({  message : "Something was wrong" });
          }
          //console.log(response.data.success);
      })
      .catch(function (error) {
          //console.log(error);
      });
  }

  fetchIndexUserhData(url) {

    let dataurl = url;



    axios.get(dataurl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: "same-origin", // include, *same-origin, omit
      crossDomain: true,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Trident-Token": window.BPdata.token,
        "BP-Agent": window.BPdata.rand_compound,
        "BP-Time": localStorage.getItem(window.BPdata.time)
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(resp => {
        // console.log(resp.data)

        // {"thaidate":"29-11-21 13:11",
        // "set":"1,600.24",
        // "value":"64,409.60M",
        // "created_at":"2021-11-29 12:41:10",
        // "number":"49"}
        // this.setState({
        //     status_code : resp.data.status_code,
        //
        // });



        if (resp.data.status_code == "404") {

          localStorage.setItem(window.BPdata.time, 0);
          localStorage.setItem('username', "");

          swal({
            title: "Session Expire",
            text: window.lang.errors.contact_cs,
            icon: "warning",
            dangerMode: true,
          })
            .then(willDelete => {
              if (willDelete) {
                localStorage.setItem(window.BPdata.time, 0);
                localStorage.setItem('username', "");
                this.props.history.push("/more/login")
              }
            });

          // swal( window.lang.errors.contact_cs );
          // console.log(resp.data.status_code)



        }


        //this.setState({ isLoading: false });

      })
      .catch(function (error) {
        //console.log(error.tostring());
      });

    if (localStorage.getItem(window.BPdata.time)) {
      if (localStorage.getItem(window.BPdata.time) !== "0") {

        window.BPdata.liveurl = setTimeout(() => this.fetchIndexUserhData(url), 20000);

      }
    }



  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }

  fetchUserData() {
    //localStorage.getItem(window.BPdata.time)
    const url = window.BPdata.apiUrl + '/cms/player/show?transfer_type=0&provider_id=' + window.BPdata.provider_id;
    //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

    ///api/customer?transfer_type=2

    axios({
      method: 'post',
      url: url,
      data: { time: window.BPdata.rand_compound + "kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "X-Trident-Token": window.BPdata.token,
        "BP-Agent": window.BPdata.rand_compound,
        "BP-Time": localStorage.getItem(window.BPdata.time)
      },
    })
      .then(resp => {

        if (resp.data) {
          this.setState({
            companyuserid: resp.data.data.companyuserid,
            username: resp.data.data.first_name,
            wallet: resp.data.customer_wallet,
            is_verified: resp.data.data.is_verified
          });

          localStorage.setItem("sbsetting", JSON.stringify(resp.data.setting))

          localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet))



          // console.log(resp.data);


          this.setState({ login: 1 })

        } else {
          this.setState({ login: 0 })
        }


      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  displayBlock( ) {
    let data = this.state.data
    //console.log(data.length)
    if(data.length  > 0){
        let ui = []
        // data.forEach(function(each){
        for (let i = 0; i < data.length; i++) {
            let each = data[i]
            ui.push(<Link to={"/more/announcement/"+each.id}><div className="news-section flex">
            <div className="news-right">
                <img src="/img/apz/news_content.png" className="news-content" height="40px" alt="profile" />
            </div>
            <div className="news-left">
                {/* <h3 className="news-title mb-10">Q: How to promote and get unlimited times of Plum Blossom VIP.</h3> */}
                <p className="news-text">{each.description}</p>
            </div>
          </div></Link>)


       }
       if(ui.length === 0) {
            ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
       }
        return ui
    }
}

  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    // const menu = Menu(this.state.menu, selected, leagueMenu);

    return (
      <div className="page">


        <div className="page-content apz-main-bg" id="homeicon">
          <ApzHeader backLink="" titleType="1" title={window.lang.apz.notification}  isLoading="false" />


          {/* <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={this.onSelect}
              alignCenter={false}
              wheel={true}
              inertiaScrolling={true}
              // scrollToSelected={true}
            />

            {/* <button onClick={askForPermissioToReceiveNotifications} >
                  Click here to receive notifications
            </button> */}

          <div className="main-content ">

            <div className="row">
              
            {this.displayBlock()}
             
            </div>


            {/* <HomeIconList></HomeIconList> */}

          </div>


          {/* <HighlightsVideoSlider></HighlightsVideoSlider> */}



        </div>

        <footer className="App-footer">

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}
            <Grid data={window.lang.apzfooter}
              columnNum={3}
              renderItem={dataItem => (

                <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{ width: '100%' }}>
                  {(window.BPdata.footerMenu === dataItem.check) ? (dataItem.text == "My Profile") ? <span className="color-white"><img src={dataItem.icon} height="25px" /><br />{(this.checklogin()) ? dataItem.text : "Login / Register"}</span> : <span className="text-white"><img src={dataItem.icon_off} height="25px" /><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon} height="25px" /><br />{dataItem.text} </span>}

                </Link>
              )}
            />

          </div>
        </footer>

      </div>
    );
  }
}

export default ApzNewsPage;
