let rand_one = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
let rand_two = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10);
let salt = "r27bHi9jwClte3W8MypKXXqpMCvIRZErVOttKsz9SNf14xKwtK6J1rjWE9Zc";
// hlvixpdbur27bHi9jwClte3W8MypKXXqpMCvIRZErVOttKsz9SNf14xKwtK6J1rjWE9Zcdzivymys
let token = window.btoa(encodeURIComponent( rand_one + salt  + rand_two ));
let rand_compound = window.btoa(encodeURIComponent( rand_one + "!" + rand_two ));
//let token = encodeURIComponent(escape(window.atob( rand_one + salt  + rand_two )));
// atob(rand_one + salt  + rand_two);

window.BPdata =
    {
        time : "bp-th-time",
        provider_id : 1,
        data :
            {
                path: '/',
                component: 'Home',
            },
        footerMenu : "Home",
        baseUrl : 'https://dep.thahtaylay.com/',
        // baseUrl : 's//webapp.host/',
        //baseUrl : 'https:// dep.thahtaylay.com//',
        apiUrl  : 'https://dep.thahtaylay.com/api/v1',
        apiUrlCMS  : 'https://dep.thahtaylay.com/api/v1/cms',
        apiAPZUrl  : 'https://thl-gawli.myanball.net/api/v1',
        apiAPZUrlCMS  : 'https://thl-gawli.myanball.net/api/v1/cms',
        salt : salt,
        rand_one : rand_one,
        rand_two : rand_two,
        rand_compound : rand_compound,
        token : token,
        dashboardUrl : 'https://dep.thahtaylay.com',
        apiAssetUrl : 'https://dep.thahtaylay.com/uploads',
        popup   : true,
        route : "[\"/\"]",
        preurl: "/",
        liveurl: "",
        mainmenu :[
            { id: 1, name: 'English Premier League',  shortname: 'EPL', mmshortname: 'ပရီးမီးယားလိဂ်',  url : 'premier-league' },
          ],
        lang : "mm"
    }
;


window.enlang =
{
    lang : "en",
    more : {
        faq_title : "We're here to help you with anything and everything on Tha Htay Lay",
        faq_ans : " At Tha Htay Lay everything we expect at a day's start is you.better and happier than yesterday. We have got you covered. Share your concern or check our frequently asked questions listed below.",
        my_account          : 'My Account',
        live_channel1       : 'Live Channel 1',
        live_channel2       : 'Live Channel 2',
        live_channel3       : 'Live Channel 3',
        channelsschedule    : 'Channels Schedule',
        gem_store           : 'Gem Store',
        term_and_conditions : 'Terms & Conditions',
        announcement        : 'Announcement',
        feedback            : 'Feedback',
        changepassword      : 'Change Password',
        faq                 : 'FAQ',
        video               : 'Skill Videos',
        help_center         : 'Help Center',
        wallet_store        : 'Wallet',
        contact_us          : 'Contact Us',
        download_app        : 'Download App',
        share_app           : 'Share App',
        version_check       : 'Version Update Check',
        version_code        : 'Version Code :',
        statement           : 'Statement',
        apztime             : 'Time',
        apzphone            : 'Phone',
        apzshootno          : 'Shoot No',
        apzbetnow           : 'Bet Now',
        apz_rule_one        : 'There are 60 matches per day, and you can play them every 15 minutes.',
        apz_rule_two        : 'When determining a 2D result number, subtract the number multiplied by (16) of the number of the last ball thrown from the total result of all the balls. Then, consider the last two digits of the remaining result number as the 2D result number.',
        apz_rule_three      : 'When determining a 3D result number, subtract the number multiplied by (16) of the number of the last ball thrown from the total result of all the balls. Then, consider the last three digits of the remaining result number as the 3D result number.',
        gawlipyit_play_1    : 'Select the room you want to play in.',
        gawlipyit_play_2    : 'Select (5) numbers of your choice to throw numbers, then click (or press the random button).',
        gawlipyit_play_3    : 'If you get the number you like, click "Bet Now".',
        gawlipyit_play_4    : 'You are allowed to shoot once per 10 seconds. There is no limit to the number of shots.',
        gawlipyit_play_5    : 'If you want to bet on 2D/3D, click the 2D/3D bet area located at the top right of the application.',
        apzrules            : 'Rules',
        apzid               : 'id',
        apzchoicekey        : 'Choose'
    },
    BetListDetail:{
        start:"Lottery Start Time",
        end:"Lottery End Time",
        odds:"Odds",
        number:"Lottery Number",
        add : "Add",
        success:"Lottery successful",
        btn:"Confirmed",
        amount:"Enter the amount",
        apzchoosebet:"Choose Betting Number",
        apzchoosebykeyboard:"Choose By Keyboard",

    },
    apz : {
        close_time:"Close Time",
        result:"Result",
        play_now:"Play Now",
        choose_betting_types:"Choose Betting Types",
        process:"Process",
        finished:"Finished",
        canceled:"Canceled",
        bet:"Bet",
        contest_status : "Contest Status",
        slip_status : "Slip Status",
        notification : "Notification",
        name : "Aung Bar Zay",
    },
    title : {
        date:"Update at",
        off_day : "Date",
        close_day : "Close Day",
        latest_euro_news      : 'LATEST EURO NEWS',
        latest_news           : 'LATEST NEWS',
        latest_highlights     : 'LATEST HIGHLIGHTS',
        league_contest  : 'League Contest',
        bill_contest    : 'Bill Contest',
        tipsters        : 'Tipsters',
        tip             : 'Tips',
        league_history  : 'League History',
        bill_history    : 'Bill History',
        transaction     : 'Transaction',
        contest         : 'Contest',
        leaderboard     : 'Leaderboard',
        history         : 'History',
        euro_news       : 'Euro News',
        euro_standing   : 'Euro Standing',
        euro_team       : 'Euro Team',
        payment         : 'Payment',
        order           : 'Order',
        forum           : 'Forum',
        inbox           : 'Inbox',
        withdrawal      : 'Withdrawal',
        deposit         : 'Deposit',
        withdrawal_history      : 'Withdraw',
        top_up_history         : 'Top Up',
        top_up          : 'Top Up',
        transfer        : 'Transfer',
        bet_list        : 'Bet List',
        result          : 'Result',
        bet_2d          : '2D',
        bet_3d          : '3D',
        winner          : 'Winner',
        close_day       : 'Close Day',
        dream_number    : 'Dream Number',
        main_wallet     : 'Main Wallet',
        game_wallet     : 'Game Wallet',
        commission_wallet     : 'Commission Wallet',
        main_wallet_short     : 'Main Wallet',
        game_wallet_short     : 'Game Wallet',
        commission_wallet_short     : 'Commission',
        promotions            : 'Promotions',
        service               : 'Service',
        twod                  : '2D',
        threed                : '3D',
        my_account            : 'My Account',
        faq                   : 'FAQ',
        wallet_store          : 'Wallet Store',
    },
    form : {
        user            : 'User',
        id              : 'ID',
        withdrawal      : 'Withdrawal',
        deposit         : 'Deposit',
        top_up          : 'Top Up',
        transfer        : 'Transfer',
        gems            : 'Gems',
        point           : 'Point',
        hightest_point  : 'Highest Point',
        rank            : 'Rank',
        join_date       : 'Join Date',
        language        : 'Language',
        login           : 'User Login',
        register        : 'User Register',
        password_reset  : 'Password Reset',
        verify_code     : 'Verify Code',
        logout          : 'Logout',
        title           : 'Title',
        description     : 'Description',
        name            : 'Name',
        bank            : 'Bank',
        acc_phone_num   : 'Phone Number',
        amount          : 'Amount',
        phone           : 'Phone',
        promocode       : 'Promocode',
        email           : 'Email',
        date_of_birth   : 'Date of Birth',
        choose_state    : 'Choose State',
        password        : 'Password',
        old_password    : 'Old Password',
        new_password    : 'New Password',
        re_new_password : 'Retype New Password',
        forgot_password : 'Forgot password?',
        submit          : 'Submit',
        cancel          : 'Cancel',
        new_post        : 'New Post',
        comment         : 'Comment',
        add_bank_account: 'Add Bank',
        bank_list       : 'Bank List',
        from            : 'From',
        to              : 'To',
        from_arrow      : 'Previous',
        to_arrow        : 'Next',
        date            : 'Date',
        que             : {
            confirm     : 'Confirm Box',
            delete      : 'Are you sure to delete?'
        },
        rand : 'rand',
        shoot: 'shoot',
    },
    packages : {
        oneday          : 'One Day',
        oneweek         : 'One Week',
        onemonth        : 'One Month',
        buy             : 'Buy',
    },
   
    footer : [
            {icon: '/img/footer/_home.png',icon_off: '/img/footer/_color_home.png',text: 'Home', check: 'home', url : "/" },
            {icon: '/img/footer/_wallet.png',icon_off: '/img/footer/_color_wallet.png',text: 'My Wallet', check: 'wallet', url : "/more/wallet-store" },
            {icon: '/img/footer/_promotions.png',icon_off: '/img/footer/_color_promotions.png',text: 'Promotions', check: 'promotions', url : "/more/announcements" },
            {icon: '/img/footer/_contact.png',icon_off: '/img/footer/_color_contact.png',text: 'Services', check: 'service', url : "/contact-us" },
            {icon: '/img/footer/_profile.png',icon_off: '/img/footer/_color_profile.png',text: 'My Profile', check: 'myaccount', url : "/more/myaccount" },
          ],
    apzfooter : [
            {icon: '/img/apz/home.png',icon_off: '/img/apz/home_active.png',text: 'Home', check: 'apz-home', url : "/" },
            {icon: '/img/apz/bet_record.png',icon_off: '/img/apz/bet_record_active.png',text: 'Record', check: 'apz-record', url : "/aungparzay/results" },
            {icon: '/img/apz/news.png',icon_off: '/img/apz/news_active.png',text: 'Noti', check: 'apz-news', url : "/aungparzay/news" },
    ],

    homeiconlist : [
            {icon: '/img/homeicon/twod_card.png',icon_off: '/img/homeicon/twod_card.png',text: '2D', check: 'NEWS', url : "/two-game" , extraurl : "/two-game" , linktype : "local"},
            {icon: '/img/homeicon/threed_card.png',icon_off: '/img/homeicon/threed_card.png',text: '3D', check: 'NEWS', url : "/three-game" , extraurl : "/three-game" , linktype : "local"},
            {icon: '/img/homeicon/home-block-live-sports.jpg',icon_off: '/img/homeicon/home-block-live-sports.jpg',text: 'Football', check: 'FOOTBALL', url : "/ws-football-full" , extraurl : "/ws-football" ,  linktype : "local"},
            {icon: '/img/homeicon/pc_live.png',icon_off: '/img/homeicon/pc_live.png',text: 'Live Casino', check: 'NEWS', url : "/bg-live-casino", extraurl : "/bg-live-casino-full" , linktype : "local"},
            {icon: '/img/homeicon/pc_fish1.png',icon_off: '/img/homeicon/pc_fish1.png',text: 'Fishing Master', check: 'Profile', url : "/bg-fishing-master-info", extraurl : "/bg-fishing-master-info", linktype : "local"},
            {icon: '/img/homeicon/aung_bar_zay_n.png',icon_off: '/img/homeicon/aung_bar_zay_n.png',text: 'Aung Bar Zay', check: 'Profile', url : "/aungparzay/home", extraurl : "/aungparzay/home", linktype : "local"},
            // {icon: '/img/homeicon/pc_fish2.png',icon_off: '/img/homeicon/pc_fish2.png',text: 'Xiyou Fishing', check: 'ABOUT', url : "/bg-xiyoufishing", linktype : "local" },
            // {icon: '/img/homeicon/pc_fish3.png',icon_off: '/img/homeicon/pc_fish3.png',text: 'Daisen', check: 'ABOUT', url : "/bg-daisen", linktype : "local" },
    ],
    messages : {

        title : "The Following phone numbers are",
        title_one : "company's service phone numbers",
        company : "ThaHtayLay",
        company_one : "About ThaHtayLay",
        day : "Every day from 9 am to 12 pm without holidays.",
        viber: "Viber",
        connect : "Connect with",
        connect_one : "Deposit and Withdrawal / Service Phone Number",
        time:"Deposit and Withdrawal / Service Time",
        add_one : "You can click here to see ",
        add_two : "what you need to know ",
        last_six_digit_transaction : "Please input last 6 words of transaction ",
        choose_bank     : "Choose Bank",
        choose_top_bank : "Choose Bank for top up",
        choose_withdraw_bank : "Choose Bank for withdrawal",
        cash_amount     : "Cash Amount",
        que_submit      : "Are you sure want to submit?",
        invalid_phone   : "Invalid phone number",
        welcome_message : "Welcome to Thahtaylay",
        sending         : "Sending",
        somethingwrong  : "Something was wrong",
        not_available_yet : "Not Available Yet",
        good        : "Good",
        good_morning: "Morning!",
        good_afternoon: "Afternoon!",
        good_evening: "Evening!",
        good_night: "Night!",
        lucky_number    : "Lucky Number",
        any_trouble_viber : "If any trobule, Please Contact to our viber",
        share_your_friend : "Share to your friend by using these",
        feedback_title          :  "We would like your feedback to improve our ThaHtayLay.",
        feedback_second_title   :  "Please leave your feedback below:",
        minamount         : "At least 3000 kyats must be withdrawn",
        minamount1k       : "At least 1000 kyats must be deposit",
        check_number      : "Please check these numbers ",
        num_day : [
            { text: 'Sunday'},
            { text: 'Monday'},
            { text: 'Tueday'},
            { text: 'Wednesday'},
            { text: 'Thursday'},
            { text: 'Friday'},
            { text: 'Saturday'},
        ],
    },
    errors : {
        contact_cs      : "The session has expired!!!",
        check_bank_acc  : "Please check your Bank Account",
        w_request_processing : "Your withdrawal is being processed. Please wait.",
        check_amount    : "Please check your Amount",
    },
    moneyamountlist :  [
            { text: '1,000', num: '1000'},
            { text: '2,000',  num: '2000'},
            { text: '3,000',  num: '3000'},
            { text: '5,000', num: '5000'},
            { text: '10,000',  num: '10000'},
            { text: '50,000',  num: '50000'},
            { text: '100,000',  num: '100000'},
            { text: '200,000',  num: '200000'},
            { text: '500,000',  num: '500000'},
        ],
    betgroup : {
        select_number_group : "Select Number Group",
        big     : "Big",
        small   : "Small",
        odd     : "Odd",
        even    : "Even",
        same    : "Same",
        round   : "Round",
        head    : "Head",
        tail    : "Tail",
        consetellation : "Consetellation",
        power   : "Power",
        select_number : "Select Number",
        fast    : "Fast",
        clear   : "Clear",
        bet     : "Bet",
        bet_close: "Bet Close",
        bet_amount : "Bet",
        commission : "Commission",
        action     : "Action",
        edit       : "Edit",
        delete     : "Delete",
        commission_total : "Commission Total",
        total            : "Total",
        submit           : "Submit",
        color_meaning    : "Color Meaning",
        color_box_one   : "Below 50%",
        color_one : "Black",
        color_box_two   : "Within 50% to 90%",
        color_two : "Green",
        color_box_three : "Above 90% ",
        color_three : "Yellow",
        color_four : "Red",
        color_box_four : "Bet Amount Full",
        close_at         : "*Close at ",
        select_time      : "Select the time",
        same_number      : "Same Number",
        same_numbers     : "Same Numbers",
        hundred_number   : "Hundred Number",
        hundred_numbers   : "Hundred Numbers",
        lottery_holiday   : "Today is the lottery holiday",
        tom_lottery_holiday : "Tommorrow is the lottery holiday",
        bet_amount_submit   : "Submit",
        win   : "Win",
    }
};

window.mmlang =
{
    lang : "mm",
    more : {
        faq_title : "ကျွန်ုပ်တို့သည် ThaHtayLay ရှိအရာအားလုံးကို လူကြီးမင်းတို့အားကူညီပေးရန် ဤနေရာတွင်ရှိနေပါသည်။",
        faq_ans: "လူကြီးမင်းတို့၏အခက်အခဲများ ကို မျှဝေပေးပါ သို့မဟုတ်  ကျွန်ုပ်တို့ကိုမကြာခဏမေးလေ့ရှိသော မေးခွန်းများကိုအောက်တွင် ဖော်ပြထားပါသည်",
        my_account          : 'ကိုယ်ပိုင်အကောင့်',
        live_channel1       : 'တိုက်ရိုက်ချန်နယ် ၁',
        live_channel2       : 'တိုက်ရိုက်ချန်နယ် ၂',
        live_channel3       : 'တိုက်ရိုက်ချန်နယ် ၃',
        channelsschedule    : 'ချန်နယ်အချိန်ဇယား',
        gem_store           : 'Gem ဆိုင်',
        term_and_conditions : 'စည်းကမ်းချက်များ',
        announcement        : 'အသိပေးကြေညာချက်',
        changepassword      : 'လျှို့ဝှက် နံပတ်ပြောင်းရန်',
        feedback            : 'အကြံပေးခြင်း ',
        faq                 : 'အမြဲမေးလေ့ရှိသော မေးခွန်းများ',
        video               : 'ဗွီဒီယိုများ',
        help_center         : 'မေးလေ့မေးထရှိသောမေးခွန်းများ',
        wallet_store        : 'ပိုက်ဆံအိတ်',
        contact_us          : 'ဆက်သွယ်ရန်',
        download_app        : 'အက်ပ်ကို ဒေါင်းလုဒ်လုပ်ပါ။',
        share_app           : 'အက်ပ်ကို မျှဝေပါ။',
        version_check       : 'ဗားရှင်းမွမ်းမံမှုကို စစ်ဆေးပါ။',
        version_code        : 'ဗားရှင်းကုဒ် :',
        statement           : 'မှတ်တမ်းထုတ်ပြန်ချက်',
        apztime             : 'အချိန်',
        apzphone            : 'ဖုန်းနံပါတ်',
        apzshootno          : 'ဂေါ်လီနံပါတ်',
        apzbetnow           : 'ထိုးရန်',
        apz_rule_one        : 'တစ်ရက်လျှင် ပွဲစဉ် (60)ရှိပြီး (15)မိနစ်ခြားတစ်ကြိမ် ကစားလို့ ရပါသည်။',
        apz_rule_two        : '2D ပေါက်ဂဏန်းအဖြစ် သတ်မှတ်ရာတွင် ဂေါ်လီပစ်ဂဏန်း အားလုံး စုစုပေါင်းရလာဒ်မှ နောက်ဆုံးဂေါ်လီပစ်သူ၏ (16)ယောက်မြှောက် ဂဏန်းကို နှုတ်၍ ကျန်ရလဒ်ဂဏန်း၏ နောက်ဆုံးဂဏန်း (2)လုံးကို 2D ပေါက်ဂဏန်းအဖြစ် သတ်မှတ်ပါသည်။',
        apz_rule_three      : '3D ပေါက်ဂဏန်းအဖြစ် သတ်မှတ်ရာတွင် ဂေါ်လီပစ်ဂဏန်း အားလုံး စုစုပေါင်းရလာဒ်မှ နောက်ဆုံးဂေါ်လီပစ်သူ၏ (16)ယောက်မြှောက် ဂဏန်းကို နှုတ်၍ ကျန်ရလဒ်ဂဏန်း၏ နောက်ဆုံးဂဏန်း (3)လုံးကို 3D ပေါက်ဂဏန်းအဖြစ် သတ်မှတ်ပါသည်။',
        gawlipyit_play_1    : 'လူကြီးမင်းစိတ်ကြိုက်ကစားမည့်အခန်းကိုရွေးချယ်ပါ။',
        gawlipyit_play_2    : 'ဂေါ်လီပစ်ရန် လူကြီးမင်း ကြိုက်နှစ်သက်သည့် ဂဏန်း(5)လုံး ရွေးချယ်၍ နှိပ်ပါ (သို့မဟုတ်) ကျပန်းခလုတ်ကို နှိပ်ပါ။',
        gawlipyit_play_3    : 'လူကြီးမင်းစိတ်ကြိုက်ဂဏန်းရလျှင် “ဂေါ်လီပစ်ရန်” ကိုနှိပ်ပါ။',
        gawlipyit_play_4    : '(10)စက္ကန့်လျှင်တစ်ကြိမ်ပစ်ခွင့်ရှိပါတယ်။အကြိမ်အရေအတွက် ကန့်သတ်ထားခြင်းမရှိပါ။',
        gawlipyit_play_5    : '2D/3D ထိုးမည်ဆိုပါက အက်ပလီကေးရှင်း၏ ညာဘက်အပေါ်ရှိ 2D/3D ထိုးရန် နေရာကိုနှိပ်ပေးပါ။',
        apzrules            : 'စည်းမျဉ်း',
        apzchoicekey        : 'ရွေးရန်',
        apzid               : 'စဉ်'
    },
    BetListDetail:{
        start:"ထီထိုးချိန်",
        end:"ထီထွက်ချိန်",
        odds:"အဆ",
        number:"ပေါက်ဂဏန်း",
        add : "ထပ်တိုးရန်",
        success:"ထီထိုးခြင်း အောင်မြင်ပါသည်။",
        btn:"အတည်ပြုသည်",
        amount:"ငွေပမာဏ ထည့်သွင်းရန်",
        apzchoosebet:"ထိုးမယ့်ဂဏန်း‌ရွေးရန်",
        apzchoosebykeyboard:"ကီးဘုတ်မှတဆင့်ရွေးရန်",
    },
    apz : {
        close_time:"ပိတ်ချိန်",
        result:"ရလဒ်",
        play_now:"ယခုကစားမည်",
        choose_betting_types:"ဂိမ်းအမျိုးအစားများကို ရွေးချယ်ပါ။",
        process:"လုပ်ဆောင်နေသည်",
        finished:"ပြီးပါပြီ",
        canceled:"ဖျက်သိမ်းလိုက်သည်။",
        bet:"ထိုးငွေ",
        contest_status : "ပြိုင်ပွဲအခြေအနေ",
        slip_status : "စလစ်အခြေအနေ",
        notification : "အသိပေးချက်",
        name : "အောင်ဘာဇေ",
    },
    title : {
        date:"အပ်ဒိတ်ချိန်",
        off_day : "ရက်စွဲ",
        close_day : "ပိတ်ရက်များ",
        latest_euro_news        : 'နောက်ဆုံးရ ယူရိုသတင်းများ',
        latest_news             : 'နောက်ဆုံးရသတင်းများ',
        latest_highlights       : 'နောက်ဆုံးရဟိုက်လိုက်များ',
        league_contest  : 'League ပြိုင်ပွဲ',
        bill_contest    : 'ဖုန်းဘေလ်ပြိုင်ပွဲ',
        transaction     : 'ငွေသွင်းငွေထုတ်',
        tipsters        : 'ခန့်မှန်းပေးသူများ',
        tip             : 'ခန့်မှန်းချက်',
        league_history  : 'လိဂ်ပြိုင်ပွဲသမိုင်း',
        bill_history    : 'ဖုန်းဘေလ်ပြိုင်ပွဲသမိုင်း',
        contest         : 'ပြိုင်ပွဲ',
        leaderboard     : 'ရပ်တည်မှုဇယား',
        history         : 'မှတ်တမ်း',
        payment         : 'ငွေပေးချေမှု',
        order           : 'မှာယူခြင်း',
        forum           : 'ဖိုရမ်',
        inbox           : 'စာတိုက်ပုံး',
        withdrawal      : 'ငွေထုတ်ရန်',
        deposit         : 'ငွေဖြည့်ရန်',
        withdrawal_history     : 'ငွေထုတ် မှတ်တမ်း',
        top_up_history         : 'ငွေဖြည့် မှတ်တမ်း',
        top_up          : 'ငွေဖြည့်ရန်',
        transfer        : 'ငွေလွှဲရန်',
        bet_list        : 'ရိုက်ချက်များ',
        result          : 'ပေါက်ကွက်များ',
        bet_2d          : '2D',
        bet_3d          : '3D',
        winner          : 'ထီပေါက်သူများ',
        close_day       : 'ထီပိတ်ရက်များ',
        dream_number     : 'အိမ်မက်ဂဏန်း',
        main_wallet     : 'ပင်မ ပိုက်ဆံအိတ်',
        game_wallet     : 'ဂိမ်း ပိုက်ဆံအိတ်',
        commission_wallet     : 'ကော်မရှင် ပိုက်ဆံအိတ်',
        main_wallet_short     : 'ပင်မ ပိုက်ဆံ',
        game_wallet_short     : 'ဂိမ်း ပိုက်ဆံ',
        commission_wallet_short     : 'ကော်မရှင် ပိုက်ဆံ',
        promotions            : 'ပရိုမိုးရှင်း',
        service               : 'ဝန်ဆောင်မှုဖုန်း',
        twod                  : '2D',
        threed                : '3D',
        my_account            : 'ကိုယ်ပိုင်အကောင့်',
        faq                   : 'FAQ',
        wallet_store          : 'ပိုက်ဆံအိတ်',
    },
    form : {
        user            : 'အသုံးပြုသူ နာမည်',
        id              : 'မှတ်ပုံတင်အမှတ်',
        withdrawal      : 'ငွေထုတ်',
        deposit         : 'ငွေဖြည့်',
        top_up          : 'ငွေဖြည့်',
        transfer        : 'ငွေလွှဲပြောင်း',
        gems            : 'Gems',
        point           : 'ရမှတ်',
        hightest_point  : 'အမြင့်ဆုံးရမှတ်',
        rank            : 'အဆင့်',
        join_date       : 'အသင်းဝင်ရက်စွဲ',
        language        : 'ဘာသာစကား',
        login           : 'အကောင့်၀င်ရန်',
        register        : 'အကောင့်မှတ်ပုံတင်ရန်',
        logout          : 'ထွက်မည်',
        password_reset  : 'စကားဝှက်ကို ပြန်လည်သတ်မှတ်မည်',
        verify_code     : 'ကုဒ်အတည်ပြုပါ',
        title           : 'ခေါင်းစဉ်',
        description     : 'ဖော်ပြချက်',
        name            : 'နာမည်',
        bank            : 'ဘဏ်',
        acc_phone_num   : 'ဖုန်းနံပါတ်',
        date_of_birth   : 'မွေးရက်',
        amount          : 'ပမာဏ',
        phone           : 'ဖုန်းနံပါတ်',
        promocode       : 'လက်ဆောင် စလစ်နံပါတ်',
        email           : 'အီးမေးလ်',
        choose_state    : 'ပြည်နယ် ရွေးချယ်ရန်',
        password        : 'စကားဝှက်',
        old_password    : 'စကားဝှက် အဟောင်းထည့်ပေးရန်',
        new_password    : 'စကားဝှက် အသစ်သတ်မှတ်ပေးရန်',
        re_new_password : 'စကားဝှက် အသစ်ကိုထပ်မံ ရိုက်ထည့်ပေးရန်',
        forgot_password : 'စကားဝှက် မေ့နေပါသလား။',
        submit          : 'လုပ်ဆောင်မည်',
        cancel          : 'မလုပ်ဆောင်ပါ',
        new_post        : 'အသစ်တင်မည်',
        comment         : 'မှတ်ချက်',
        add_bank_account: 'ဘဏ်တိုးရန်',
        bank_list       : 'ဘဏ်စာရင်း',
        from            : 'မှ',
        to              : 'သို့',
        from_arrow      : 'နောက်သို့',
        to_arrow        : 'ရှေ့သို့',
        date            : 'ရက်စွဲ',
        que             : {
            confirm     : 'အတည်ပြုပါ',
            delete      : 'သင်ဖျက်ဖို့သေချာလား။'
        },
        rand : 'ကျပန်း',
        shoot : 'ဂေါ်လီပစ်ရန်',
    },
    packages : {
        oneday          : 'တစ်ရက်စာ',
        oneweek         : 'တစ်ပတ်စာ',
        onemonth        : 'တစ်လစာ',
        buy             : '၀ယ်မယ်',
    },
    footer : [
            {icon: '/img/footer/_home.png',icon_off: '/img/footer/_color_home.png',text: 'မူလနေရာ', check: 'home', url : "/" },
            {icon: '/img/footer/_wallet.png',icon_off: '/img/footer/_color_wallet.png',text: 'ပိုက်ဆံအိတ်', check: 'wallet', url : "/more/wallet-store" },
            {icon: '/img/footer/_promotions.png',icon_off: '/img/footer/_color_promotions.png',text: 'ပရိုမိုးရှင်း', check: 'promotions', url : "/more/announcements" },
            {icon: '/img/footer/_contact.png',icon_off: '/img/footer/_color_contact.png',text: 'ဝန်ဆောင်မှုဖုန်း', check: 'service', url : "/contact-us" },
            {icon: '/img/footer/_profile.png',icon_off: '/img/footer/_color_profile.png',text: 'ကိုယ်ပိုင်အကောင့်', check: 'myaccount', url : "/more/myaccount" },
          ],

    apzfooter : [
            {icon: '/img/apz/home.png',icon_off: '/img/apz/home_active.png',text: 'မူလနေရာ', check: 'apz-home', url : "/" },
            {icon: '/img/apz/bet_record.png',icon_off: '/img/apz/bet_record_active.png',text: 'မှတ်တမ်း', check: 'apz-record', url : "/aungparzay/results" },
            {icon: '/img/apz/news.png',icon_off: '/img/apz/news_active.png',text: 'အသိပေးချက်', check: 'apz-news', url : "/aungparzay/news" },
    ],

    homeiconlist : [
            {icon: '/img/homeicon/twod_card.png',icon_off: '/img/homeicon/twod_card.png',text: '2D', check: 'NEWS', url : "/two-game" , extraurl : "/two-game" , linktype : "local"},
            {icon: '/img/homeicon/threed_card.png',icon_off: '/img/homeicon/threed_card.png',text: '3D', check: 'NEWS', url : "/three-game" ,  extraurl : "/three-game" , linktype : "local"},
            {icon: '/img/homeicon/home-block-live-sports.jpg',icon_off: '/img/homeicon/home-block-live-sports.jpg',text: 'ဘောလုံး', check: 'FOOTBALL', url : "/ws-football" , extraurl : "/ws-football-full" ,  linktype : "local"},
            {icon: '/img/homeicon/pc_live.png',icon_off: '/img/homeicon/pc_live.png',text: 'ကာစီနို', check: 'NEWS', url : "/bg-live-casino", extraurl : "/bg-live-casino-full" ,  linktype : "local"},
            {icon: '/img/homeicon/pc_fish1.png',icon_off: '/img/homeicon/pc_fish1.png',text: 'ငါးဖမ်းဂိမ်း', check: 'Profile', url : "/bg-fishing-master-info", extraurl : "/bg-fishing-master-info" ,  linktype : "local"},
            {icon: '/img/homeicon/aung_bar_zay_n.png',icon_off: '/img/homeicon/aung_bar_zay_n.png',text: 'အောင်ဘာဇေ', check: 'Profile', url : "/aungparzay/home", extraurl : "/aungparzay/home", linktype : "local"},
            // {icon: '/img/homeicon/pc_fish2.png',icon_off: '/img/homeicon/pc_fish2.png',text: 'Xiyou Fishing', check: 'ABOUT', url : "/bg-xiyoufishing", linktype : "local" },
            // {icon: '/img/homeicon/pc_fish3.png',icon_off: '/img/homeicon/pc_fish3.png',text: 'Daisen', check: 'ABOUT', url : "/bg-daisen", linktype : "local" },
    ],
    messages : {

        title : "အောက်ပါ ဖုန်းနံပါတ်များသည်",
        title_one : "ကုမ္ပဏီ၏ ဖုန်းနံပါတ်များ ဖြစ်ပါသည်။",
        company: "ThaHtayLay",
        company_one:"ThaHtayLay",
        day : "နေ့စဉ် မနက်(၉)နာရီမှည(၁၂)နာရီထိ ပိတ်ရက်မရှိ ဝန်ဆောင်မှု ပေးနေပါသည်။",
        time:"ငွေသွင်းငွေထုတ် ဝန်ဆောင်မှုအချိန်",
        connect_one : "ငွေသွင်း ၊ ငွေထုတ်  /  ဝန်ဆောင်မှု ဖုန်းနံပါတ်",
        viber:"ဖြင့်ဆက်သွယ် ပေးပါ",
        connect : "Viber",
        add_one: "နဲ့ ပတ်သက်ပြီး သိရှိလိုသည်များကို",

        add_two : "ဤနေရာတွင် နှိပ်၍ ကြည့်ရှုနိုင်ပါသည်။",
        last_six_digit_transaction : "ကျေးဇူးပြု၍ ဘဏ်လုပ်ငန်းစဉ်နံပါတ်၏ နောက်ဆုံး 6 လုံးကို ထည့်သွင်းပါ။",
        choose_bank     : "ဘဏ်ကိုရွေးချယ်ပါ။",
        choose_top_bank : "ငွေဖြည့်ရန် ဘဏ်တစ်မျိုးကို ရွေးချယ်ပါ။",
        choose_withdraw_bank : "ငွေထုတ်ရန် ဘဏ်တစ်မျိုးကို ရွေးချယ်ပါ။",
        cash_amount     : "ငွေသားပမာဏ",
        que_submit      : "ပေးပို့ချင်တာသေချာပါသလား",
        invalid_phone   : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။",
        welcome_message : "Thahtaylay မှကြိုဆိုပါသည်",
        sending         : "လုပ်ဆောင်နေပါသည်",
        somethingwrong  : "အချက်အလက်များ မှားယွင်းနေပါသည်။",
        your_comment    : 'သင့်ရဲ့မှတ်ချက်',
        not_available_yet : "လုပ်ဆောင်နေပါသည်။",
        good            : "မင်္ဂလာ",
        good_morning    : "နံနက်ခင်းလေးပါ",
        good_afternoon  : "‌နေ့လည်ခင်းလေးပါ",
        good_evening    : "ည‌နေခင်းလေးပါ",
        good_night      : "ညချမ်းလေးပါ",
        lucky_number    : "ကံကောင်းစေသောနံပါတ်",
        any_trouble_viber : "အဆင်မပြေမှုရှိပါက viber သို့ ဆက်သွယ်ပေးပါ။",
        share_your_friend : "ဒါတွေကို အသုံးပြုပြီး သင့်သူငယ်ချင်းကို မျှဝေပါ။",
        feedback_title          :  "သူဌေးလေး အက်ပ်ပလီကေးရှင်းအတွက်အကြံပြုစာ",
        feedback_second_title   :  "App သုံးရတာပိုမိုကောင်းမွန်ဖို့ ဒီနေရာလေးမှာအကြံပေးကြပါစို့",
        minamount         : "အနည်းဆုံးငွေ(၃၀၀၀)ကျပ်မှစ၍ ငွေထုတ်ရပါမည်",
        minamount1k       : "အနည်းဆုံးငွေ(၁၀၀၀)ကျပ်မှစ၍ ငွေသွင်းရပါမည်",
        check_number      : "ဒီနံပါတ်များကို စစ်ဆေးပါရန် ",
        num_day : [
            { text: 'တနင်္ဂနွေနေ့'},
            { text: 'တနင်္လာနေ့'},
            { text: 'အင်္ဂါနေ့'},
            { text: 'ဗုဒ္ဓဟူးနေ့'},
            { text: 'ကြာသပတေးနေ့'},
            { text: 'သောကြာနေ့'},
            { text: 'စနေနေ့'},
        ],
    },
    errors : {
        contact_cs      : "Session Expired ဆက်ရှင် သက်တမ်းကုန်သွားပါပြီ။!!!",
        check_bank_acc  : "သင့်ဘဏ်အကောင့်ကို စစ်ဆေးပါ။",
        w_request_processing : "သင့်ရဲ့ ငွေထုတ်မှု့အားလုပ်ဆောင်နေပါသည် ခေတ္တ စောင့်ဆိုင်းပေးပါရှင့်",
        check_amount    : "သင့်ငွေပမာဏကို စစ်ဆေးပါ။",
    },
    moneyamountlist :  [
            { text: '1,000', num: '1000'},
            { text: '2,000',  num: '2000'},
            { text: '3,000',  num: '3000'},
            { text: '5,000', num: '5000'},
            { text: '10,000',  num: '10000'},
            { text: '50,000',  num: '50000'},
            { text: '100,000',  num: '100000'},
            { text: '200,000',  num: '200000'},
            { text: '500,000',  num: '500000'},
    ],
    betgroup : {
        select_number_group : "နံပါတ်အဖွဲ့ကို ရွေးပါ။",
        big     : "ကြီး",
        small   : "သေး",
        odd     : "မ",
        even    : "စုံ",
        same    : "အပူး",
        round   : "ပတ်လည်",
        head    : "ထိပ်",
        tail    : "နောက်ပိတ်",
        consetellation : "နက္ခတ်",
        power   : "ပါဝါ",
        select_number : "နံပါတ်ကို ရွေးပါ။",
        fast    : "အမြန်ရွေး",
        clear   : "ပယ်ဖျက်ရန်",
        bet     : "ထိုးရန်",
        bet_close: "ထီပိတ်ပြီ",
        bet_amount : "ထိုးငွေ",
        commission : "ကော်မရှင်",
        action     : "လုပ်ဆောင်ချက်",
        edit       : "ပြင်ဆင်ရန်",
        delete     : "ဖျက်ရန်",
        commission_total : "ကော်မရှင်စုစုပေါင်း",
        total            : "စုစုပေါင်း",
        submit           : "လုပ်ဆောင်မည်",
        color_meaning    : "အရောင်အဓိပ္ပါယ်",
        color_box_one   : "၅၀ ရာခိုင်နူန်းအောက်",
        color_one : "အမဲရောင်",
        color_box_two   : " ၅၀ ရာခိုင်နူန်းမှ ၉၀ ရာခိုင်နူန်း အထိ ",
        color_two : "အစိမ်းရောင်",
        color_box_three : "၉၀ ရာခိုင်နူန်းအထက် ",
        color_three : "အဝါရောင်",
        color_four : "အနီရောင်",
        color_box_four : "ထိုး၍မရတော့ပါ",
        close_at         : "*ပိတ်ချိန် ",
        select_time      : "အချိန်ကိုရွေးချယ်ပါ။",
        same_number      : "ထွိုင်ဂဏန်း",
        same_numbers     : "ထွိုင်ဂဏန်းများ",
        hundred_number   : "ရာပြည့်",
        hundred_numbers   : "ရာပြည့်များ",
        lottery_holiday   : "ယနေ့ထီပိတ်ရက်ဖြစ်ပါသည်",
        tom_lottery_holiday : "နက်ဖြန်ထီပိတ်ရက်ဖြစ်ပါသည်",
        bet_amount_submit   : "ထိုးမည်",
        win   : "အနိုင်ရငွေ",
    }
};


window.lang = window.mmlang;
