/* eslint no-dupe-keys: 0 */
import React from 'react';
import Swiper from 'swiper/dist/js/swiper.min.js';

import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { ListView, Grid } from 'antd-mobile';

import NewsVideoSlider from './../NewsVideoSlider';
// import HomeListPage from './HomeListPage';
import HomeIconList from './HomeIconList';

import addNotification from 'react-push-notification';

import AppHeader from './../AppHeader';

// import ScrollMenu from 'react-horizontal-scrolling-menu';

import swal from 'sweetalert';
//import { FacebookProvider, CommentsCount } from 'react-facebook';
//import { askForPermissioToReceiveNotifications } from './../push-notification';
require('./../../../BPdata.js')



let checklogin = () => {
      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              return true;
          }
      }
      return false;
  }

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    window.BPdata.footerMenu = "home";

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      login : 0,
      is_verified : 1,
      selected: 0,
      dataSource,
      isLoading: true,
      // virtual data
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'NEWS',
      open: false,
      fopen: false,
      advopen : false,
      menu : window.BPdata.mainmenu,
      wallet : [], 
      seconds: 5, 
      isCounting: false
    };



  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  onOpenModal = () => {
    this.setState({ open: true, fopen: true,  advopen : true });
  };

  onCloseModal = () => {
    this.setState({ open: false , fopen: false, advopen : false });


    //localStorage.setItem('popup', false);
    // window.BPdata.popup = false
    // console.log(window.BPdata.popup)
  };

  close = () => {
    this.startCountdown();
    setTimeout(() => this.onCloseModal(), 5000  )
  };

  startCountdown = () => {
      this.setState({ isCounting: true });
      const intervalId = setInterval(() => {
          this.setState((prevState) => {
              if (prevState.seconds === 0) {
                  clearInterval(intervalId);
                  this.setState({ isCounting: false });
                  return { seconds: 5 }
              }
              return { seconds: prevState.seconds - 1 }
          });
      }, 1000);
  }

  currencyFormat(num) {
      return num;
      return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  showNotification(title, body) {
    new Notification(title, { body });
  }


  componentDidMount() {
    // this.showNotification("Notification title", "Notification body");
    
    


    window.scrollTo(0, 0);

    // this.setState({ fopen: true });

    let menu = ["/"];

    localStorage.setItem("route", JSON.stringify(menu) );
    //console.log(localStorage.getItem('route'));
    //console.log(window.BPdata.apiUrl)
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);



    const self = this;


    new Swiper('.swiper-video-container', {
      // ...
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      virtual: {
        slides: self.state.videoSlides,
        renderExternal(videoData) {
          // assign virtual slides data
          self.setState({
            virtualVideoData: videoData,
          });
        }
      },
      pagination: {
        el: '.swiper-vieo-pagination',
      },
    });




    // this.setState({ fopen: true });

      let checkday = 0;

      var d = new Date();
      var todaynum  = d.getDay();
      var todayhr   = d.getHours();

      if(localStorage.getItem('popup')) {
        if(localStorage.getItem('popup') != todaynum) {
          localStorage.setItem('popup', todaynum);
          this.setState({ open: true });

        } else {
          checkday = 1;
        }
 
      } else {
        localStorage.setItem('popup', todaynum);
        this.setState({ open: true });
      }

      // this.setState({ open: true });

      // this.setState({ fopen: true });

      if(checkday == 1) {

          if(localStorage.getItem('fpopup')) {
            if(localStorage.getItem('fpopup') != todaynum) {
    
              localStorage.setItem('fpopup', todaynum);
              localStorage.setItem('fpopup_time', todayhr);
              this.setState({ fopen: true });

              this.close();
              
            } 

            if(localStorage.getItem('fpopup') == todaynum) {

              if(localStorage.getItem('fpopup_time') != todayhr) {

                localStorage.setItem('fpopup_time', todayhr);
                this.setState({ fopen: true });

                this.close();
                
              }
              
            }

    
          } else {
    
            localStorage.setItem('fpopup', todaynum);
            this.setState({ fopen: true });

            this.close();
    
          }
          
      }
      

      // this.setState({ open: true });

      // this.setState({ open: true });

      // if(localStorage.getItem('wallet')) {

      //   if(JSON.parse(localStorage.getItem("wallet")) != null) {
      //     let wallet = JSON.parse(localStorage.getItem("wallet"));
      //     this.setState({wallet: wallet})
      //   }
      // }

      //console.log(localStorage.getItem('popup'));
      //this.fetchIndexUserhData();
      clearTimeout(window.BPdata.liveurl);

      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({login: 1})

              this.fetchIndexUserhData(window.BPdata.apiUrlCMS+'/cron');

          }
      } else {
        this.setState({login: 0})
      }


      this.fetchUserData();


      // console.log("---")
      // if (Notification.permission !== 'granted') {
      //   Notification.requestPermission().then((permission) => {
      //       if (permission === 'granted') {
      //           new Notification('Notification title', {
      //               body: 'Notification body',
      //           });
      //       }
      //     });
      // } else {
      //     new Notification('Notification title', {
      //         body: 'Notification body',
      //     });
      // }

      // if (navigator.userAgent.toLowerCase().indexOf("thahtaylay") > -1 ) {
      //   console.log("other")
      // } else {
      //     if(localStorage.getItem('androidpopup')) {
      //       if(localStorage.getItem('androidpopup') === 'reset') {
      //         localStorage.setItem('androidpopup', true);
      //         this.setState({ open: true });
      //       }

      //     } else {
      //       localStorage.setItem('androidpopup', true);
      //       this.setState({ open: true });
      //     }

      // }
  }

  walletBlock() {

    let childui = [];

      if(this.state.login == 1) {
          if(this.state.wallet[0]) {
            childui.push(<div className="row ">
                 <div className="adm-card row mt-10 color-white">
                        <div className="adm-card-body grid-100">
                            <div className="row pocket-wrapper">
                                <div className="grid-10 left text-center pl-10">
                                  <img src="/img/main_wallet.png" width="30" />
                                </div>
                                <div className="grid-40 left text-left pl-10 pocket-session">
                                    { window.lang.title.main_wallet }
                                </div>
                                <div className="grid-30 left text-right pocket-session right-pocket">
                                  <span className='walletcolor'>{ (this.state.wallet) ? this.currencyFormat(this.state.wallet[0].credit) : 0 } Ks</span>
                                </div>
                                <div className="grid-10 left text-right frame-img-center">
                                  <Link to="/more/wallet-store/transfer">
                                    <img src="/img/plus-circle.png" width="23" height="10px" />
                                  </Link>
                                </div>

                            </div>
                            <div className="row">
                                <div className="grid-90  pl-20 pt-4">
                                  <hr class="pocket-line" style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                </div>
                            </div>
                            <div className="row pt-4 pocket-wrapper">
                                <div className="grid-10 left text-center pl-10">
                                    <img src="/img/game_wallet.png" width="30" />
                                </div>
                                <div className="grid-40 left text-left pl-10 pocket-session">
                                    { window.lang.title.game_wallet }

                                </div>
                                <div className="grid-30 left text-right pocket-session right-pocket">
                                  <span className='walletcolor'> { (this.state.wallet) ? this.currencyFormat(this.state.wallet[1].credit) : 0 } Ks</span>
                                </div>
                                <div className="grid-10 left text-right frame-img-center">
                                  <Link to="/more/wallet-store/transfer">
                                    <img src="/img/plus-circle.png" width="23" />
                                  </Link>
                                </div>

                            </div>
                            <div className="row">
                                <div className="grid-90  pl-20 pt-4">
                                  <hr class="pocket-line" style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                </div>
                            </div>
                            <div className="row pocket-wrapper">
                                <div className="grid-10 left text-center pl-10">
                                  <img src="/img/commission_wallet.png" width="30" />
                                </div>
                                <div className="grid-40 left text-left pl-10 pocket-session">
                                    { window.lang.title.commission_wallet } 

                                </div>
                                <div className="grid-30 left text-right pocket-session right-pocket">
                                  <span  className='walletcolor'> { (this.state.wallet) ? this.currencyFormat(this.state.wallet[2].credit) : 0 } Ks</span>
                                </div>
                                <div className="grid-10 left text-right frame-img-center">
                                  <Link to="/more/wallet-store/transfer">
                                    <img src="/img/plus-circle.png" width="23" height="10px" />
                                  </Link>
                                </div>

                            </div>
                        </div>
                </div>
            </div>)
          } else {
            if(this.state.is_verified == 0) {
              childui.push(<div className="row pb-10">
                <br />
                 <div className="adm-card row mt-10">
                        <div className="adm-card-body grid-100">
                            <div className="row">
                                <div className="grid-100 left text-center">
                                    You have been banned
                                </div>
                                <br />
                                <hr />
                            </div>
                        </div>
                </div>
            </div>)
            } else {
              //Please Login
              childui.push("")
            }

          }
      }
      return childui;
  }

  fetchIndexUserhData(url) {

      let dataurl = url;



      axios.get(dataurl, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: "same-origin", // include, *same-origin, omit
        crossDomain: true,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "X-Trident-Token" : window.BPdata.token,
            "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"         : localStorage.getItem(window.BPdata.time)
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer", // no-referrer, *client
        //body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      .then(resp => {
          // console.log(resp.data)

          // {"thaidate":"29-11-21 13:11",
          // "set":"1,600.24",
          // "value":"64,409.60M",
          // "created_at":"2021-11-29 12:41:10",
          // "number":"49"}
          // this.setState({
          //     status_code : resp.data.status_code,
          //
          // });



          if(resp.data.status_code == "404") {

            localStorage.setItem(window.BPdata.time, 0);
            localStorage.setItem('username', "");

            swal({
              title: "Session Expire",
              text: window.lang.errors.contact_cs,
              icon: "warning",
              dangerMode: true,
            })
            .then(willDelete => {
            if (willDelete) {
                localStorage.setItem(window.BPdata.time, 0);
                localStorage.setItem('username', "");
                this.props.history.push("/more/login")
            }
            });

              // swal( window.lang.errors.contact_cs );
              // console.log(resp.data.status_code)



          } 

          if(resp.data.status_code) {
            // console.log("ok")
            // version_code
            if(resp.data.version_code) {
              if(localStorage.getItem(window.BPdata.version_code)) {
                if(localStorage.getItem(window.BPdata.version_code) != resp.data.version_code) {
                  window.localStorage.clear(); 
                  localStorage.setItem("version_code", resp.data.version_code )
                  // console.log("ok")
                  //window.location.replace("https://thahtaylay.com/thl11.php"); 
                }
              } else {
                localStorage.setItem("version_code", resp.data.version_code )
              }
              
            }
          }

          


          //this.setState({ isLoading: false });

      })
      .catch(function(error) {
        //console.log(error.tostring());
      });

      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {

              window.BPdata.liveurl =  setTimeout(() => this.fetchIndexUserhData(url), 30000);

          }
      }



  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }

  fetchUserData() {
        //localStorage.getItem(window.BPdata.time)
        const url = window.BPdata.apiUrl+'/cms/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        ///api/customer?transfer_type=2

        axios({
          method: 'post',
          url: url,
          data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
          headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(resp => {

            if(resp.data) {
                this.setState({
                  companyuserid : resp.data.data.companyuserid,
                  username      : resp.data.data.first_name,
                  wallet        : resp.data.customer_wallet,
                  is_verified   : resp.data.data.is_verified
                });

                localStorage.setItem("sbsetting", JSON.stringify(resp.data.setting) )

                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )



                // console.log(resp.data);


                this.setState({login: 1})

            } else {
                this.setState({login: 0})
            }


          })
          .catch(function(error) {
            // console.log(error);
          });
    }

    welcomeMessage() {

        var day = new Date();
        var hr = day.getHours();
        if (hr == 1) {
          return window.lang.messages.good_night;
        }
        if (hr == 2) {
          return window.lang.messages.good_morning;
        }
        if (hr == 3) {
          return window.lang.messages.good_morning;
        }
        if (hr == 4) {
          return window.lang.messages.good_morning;
        }
        if (hr == 5) {
          return window.lang.messages.good_morning;
        }
        if (hr == 6) {
          return window.lang.messages.good_morning;
        }
        if ((hr == 6) || (hr == 7) || (hr == 8) || (hr == 9) ) {
          return window.lang.messages.good_morning;
        }
        if ( (hr == 10) || (hr == 11) ) {
          return window.lang.messages.good_afternoon;
        }
        if (hr == 12) {
          return window.lang.messages.good_afternoon;
        }
        if ((hr == 13) || (hr == 14)) {
          return window.lang.messages.good_afternoon;
        }
        if ((hr == 15) || (hr == 16) ) {
            return window.lang.messages.good_afternoon;
        }
        if ( (hr == 17) || (hr == 18) ) {
          return window.lang.messages.good_evening;
        }
        if ( (hr == 19) || (hr == 20) || (hr == 21) || (hr == 22)) {
          return window.lang.messages.good_night;
        }
        if (hr == 23) {
          return window.lang.messages.good_night;
        }
        if (hr==0) {
          return window.lang.messages.good_night;
        }

    }

  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items

    return (
      <div className="page">
        <div className="page-content" id="homeicon">
            <AppHeader backLink="" titleType="1" title="Home" isLoading="false" />


            {/* <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={this.onSelect}
              alignCenter={false}
              wheel={true}
              inertiaScrolling={true}
              // scrollToSelected={true}
            />

            {/* <button onClick={askForPermissioToReceiveNotifications} >
                  Click here to receive notifications
            </button> */}



              <div className="main-content ">

                
              
                  <div className="row">
                    <div className="grid-100">
                      <NewsVideoSlider />
                     {/* <div className="grid-100 text-center">
                        Download Tha Htay Lay Mobile App &nbsp;&nbsp;<a className="btn btn-success" href="">Download</a>
                      </div>*/}
                    </div>
                  </div>
                  
                     {
                          ((navigator.userAgent.toLowerCase().indexOf("thahtaylay") > -1 ) ? "" : <Modal open={this.state.open} onClose={this.onCloseModal} center 
                          >
                          <div className="color-black row" style={{ width : "320px", height : "400px"}}>
                            <div className="grid-100 left text-center" >
                              <a href="http://thahtaylay.com" target="_blank" rel="noopener noreferrer" className="selectDisable alert-box-img" ><img src="/img/home-logo.png" alt="Thahtay Lay" height="200px" className="selectDisable alert-box-img" /></a>
                            </div>
                            <div className="grid-100 color-white left text-center" >
                              <h2 style={{ fontSize: "25px",paddingTop:"0px"}} >{ window.lang.messages.good } { this.welcomeMessage() }</h2>
                              <br />
                            </div>

                            {/* <div className="grid-100 left color-red" >
                              
                            </div> */}
                            <div className="grid-100 left adm-card  alert-box-noise " style={{ padding: "30px 0px 30px 0px"}} >
                              <div className="grid-100 text-center color-red" >
                                  <span className="color-white " style={{ fontSize: "22px"}}>{ window.lang.messages.lucky_number }</span>
                              </div>
                              <br />
                              <div className="grid-80 text-left color-red" style={{margin: "0 auto" }}>

                                <span className="twodluckybutton  grid-100" style={{ height: "50px"}} >

                                  <span className="download-white-font  mr-20 pl-20 badget-text" >2D Lucky </span><span  className="download-gold-font pl-20 badget-circle" > {Math.floor(Math.random() * 100).toString().padStart(2, '0')} </span>
                                </span><br/>
                                <span className="threedluckybutton grid-100 "   style={{ height: "50px"}}><span className="download-white-font  mr-20 pl-20 badget-text">3D Lucky </span><span class className="download-gold-font pl-20 badget-circle-3d" > {Math.floor(Math.random() * 1000).toString().padStart(3, '0')}</span></span>
                              </div>
                              
                            </div>

                          </div>
                        </Modal>)
                      }


                      {/*
                          ((navigator.userAgent.toLowerCase().indexOf("thahtaylay") > -1 ) ? "" : <Modal open={this.state.fopen}  
                          onClose={this.onCloseModal}
                          right 
                          classNames={{
                            overlay: 'customOverlay',
                            modal: 'fullimgcustomModal',
                          }}
                          >

                          <div className=''  style={{ width: "100%"}} >
                            
                            
                            <img src="/img/ads/atpbanner-mobile-april.jpg"  className='splashscreen' />
                            
                          </div>

                            <button  onClick={this.onCloseModal} style={{   color: "#000", border: "1px solid #000", padding : "5px", paddingLeft : "10px",paddingRight  : "10px",borderRadius: "15px" , background: "#f2eaea", opacity: "0.6"}} >
                              {this.state.isCounting && <span style={{ color: "#000"}}>ကျော်မည် {this.state.seconds}</span>}
                            </button>
                          
                          
                        </Modal>)
                        */}




                {/* <ListView
                  ref={el => this.lv = el}
                  dataSource={this.state.dataSource}
                  renderHeader={() => <div className="list-header"><Link to="/feature"><img src="/img/trending.jpg" className="list-img-circle" alt="Trending" /><span>TRENDING NEWS</span></Link></div>}
                  // renderFooter={() => (<div style={{ padding: 30, textAlign: 'center' }}>
                  //   {this.state.isLoading ? 'Loading...' : 'Loaded'}
                  // </div>)}
                  renderRow={row}
                  renderSeparator={separator}
                  className="am-list"
                  useBodyScroll
                  // onScroll={() => { console.log('scroll'); }}
                  scrollRenderAheadDistance={500}
                /> */}

                {/*<HomeListPage pageIndexTotal="20" perPage="100" isLoading="false" />*/}

                {/* { window.screen.width }<br />
                { window.screen.height }<br /> */}
                { this.walletBlock() }


              
                <HomeIconList></HomeIconList>

              </div>

              {/* <HighlightsVideoSlider></HighlightsVideoSlider> */}


        </div>

        <footer  className="App-footer">

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}
            <Grid data={window.lang.footer}
                columnNum={5}
                renderItem={dataItem => (

                  <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                        { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                  </Link>
                )}
              />

          </div>
        </footer>

      </div>
    );
  }
}

export default HomePage;
