import React from 'react';
import axios from 'axios';

import {
    Link
  } from 'react-router-dom';
import { Grid,Modal } from 'antd-mobile';

import swal from 'sweetalert';
import AppHeader from './../AppHeader';

import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'

const alert = Modal.alert;
var moment = require('moment');

const options = [
    { value: 'flavor', label: 'flavor' },
    { value: 'yummy', label: 'yummy' },
    { value: 'red', label: 'red' },
    { value: 'green', label: 'green' },
    { value: 'yellow', label: 'yellow' },
];

class WalletWithdrawalStore extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "wallet";
        this.state = {
                        viber_ph_1 : "",
                        viber_ph_2 : "",
                        bullet : 0,
                        message: "",
                        wallet : [
                            {
                                wallet_id: 1,
                                credit : 0
                            },
                            {
                                wallet_id: 2,
                                credit : 0
                            }
                        ],
                        data : {},
                        step: "form",
                        league : false,
                        advertise : {advertise_image:""},
                        isLoading: false,
                        check: 0,
                        payment: [
                              { id : 1, shortcode : "K Pay" , image : "/img/banks/active/kbz.png", non_image : "/img/banks/in-active/kbz.png" },
                              { id : 2, shortcode : "Wave Moneny" , image : "/img/banks/active/wave.png", non_image : "/img/banks/in-active/wave.png"  },
                              { id : 3, shortcode : "Aya Pay" , image : "/img/banks/active/aya_pay.png", non_image : "/img/banks/in-active/aya_pay.png" },
                              { id : 4, shortcode : "CB Pay" , image : "/img/banks/active/cb_pay.png", non_image : "/img/banks/in-active/cb_pay.png"  }
                        ],
                        form :   {

                                "description":"",
                                "salt":localStorage.getItem(window.BPdata.time),
                                "to_user_id":1,
                                "fromgame_id":1,
                                "togame_id":23,
                                "transfer_type":2,
                                "provider_id": 1,
                                "customerbank":{
                                    "bank_acc_name":"",
                                    "bank_type":"",
                                    "bank_num":"",
                                    "id": 0
                                },
                                "dagonbank": {
                                    "id":0,
                                    "holdername":"",
                                    "bank_type":0,
                                    "cardnum":""
                                },
                                transaction_date: moment().format("YYYY-MM-DD hh:mm A"),
                                "amount":"",
                                "bank_transaction_id":""
                        },
                         permit : {
                            transfer_types : [
                                { id : 0, name : "-"},
                                { id : 1, name : "Deposit"},
                                { id : 2, name : "Withdrawal"},
                                { id : 3, name : "Transfer"}
                            ],
                            bank_accounts : [
                                { id : 1, bank_type: 1, shortcode : "AYA", holdername : "", cardnum : "" }
                            ],
                            specfic_bank_accounts : [
                                { id : 1, bank_type: 1, shortcode : "AYA", holdername : "", cardnum : "" }
                            ],
                            customer_banks : [
                                 { id : 1, bank_type: 1,bank_acc_name : "", bank_num : "" }
                            ],
                            specfic_cus_bank_accounts : [
                                  { id : 1, bank_type: 1,bank_acc_name : "", bank_num : "" }
                            ],
                            from_customer : {
                                id : 0,
                                first_name : "",
                                date_of_birth : ""
                            },
                            to_customer : {
                                id : 0,
                                first_name : "",
                                date_of_birth : ""
                            },
                            last10trans : [{
                                id  : 44,
                                player_id :  802,
                                to_player_id  :  802,
                                from_total : 0,
                                percentage : 0,
                                bonus_type : 0,
                                amount : 330,
                                transfer_type : 1,
                                description : "",
                                staff_id   : 6,
                                cusbank_bank_acc_name :  "sdfd",
                                cusbank_bank_num  :  "32232323",
                                cusbank_bank_type  : 1,
                                dagonbank_id  :  1,
                                fromgame_id : "",
                                togame_id   : "",
                                transaction_date  : "2021-09-08 10:21:00",
                                approve_status : 0,
                                provider_id : 3,
                                bank_transaction_id : "2333232"
                            } ],
                            approve_statuses : [
                                { id : 0, name : "New"},
                                { id : 1, name : "Pending"},
                                { id : 2, name : "Confirm"},
                                { id : 3, name : "Cancel"},
                            ],
                            bank_types : [
                                { id : 1, shortcode : "K Pay" , image : "/img/banks/active/kbz.png", non_image : "/img/banks/in-active/kbz.png" },
                                { id : 2, shortcode : "Wave Moneny" , image : "/img/banks/active/wave.png", non_image : "/img/banks/in-active/wave.png"  },
                                { id : 3, shortcode : "Aya Pay" , image : "/img/banks/active/aya_pay.png", non_image : "/img/banks/in-active/aya_pay.png" },
                                { id : 4, shortcode : "CB Pay" , image : "/img/banks/active/cb_pay.png", non_image : "/img/banks/in-active/cb_pay.png"  }
                            ],
                            payment: [
                                  { id : 1, shortcode : "K Pay" , image : "/img/banks/active/kbz.png", non_image : "/img/banks/in-active/kbz.png" },
                                  { id : 2, shortcode : "Wave Moneny" , image : "/img/banks/active/wave.png", non_image : "/img/banks/in-active/wave.png"  },
                                  { id : 3, shortcode : "Aya Pay" , image : "/img/banks/active/aya_pay.png", non_image : "/img/banks/in-active/aya_pay.png" },
                                  { id : 4, shortcode : "CB Pay" , image : "/img/banks/active/cb_pay.png", non_image : "/img/banks/in-active/cb_pay.png"  }
                            ],
                        },
                        hints : {
                            array : [],
                            string : [],
                        },
                        bankaccount : { },
                        alertId: '',
                        messageAlert: '',
                    };
        this.setAmount      = this.setAmount.bind(this);

        this.changeBankType = this.changeBankType.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);


        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);
        this.sendTransactionApi      = this.sendTransactionApi.bind(this);
        this.showMessage = this.showMessage.bind(this);
    }

    filterBankType(bank_type) {
        let bank_type_group = this.state.permit.bank_types.filter(function (el, id) {
              return el.id == bank_type;
            });

        // console.log(bank_type_group[0])

        // console.log(bank_type_group[0]);

        return bank_type_group[0];
        //console.log(customer_bank)
    }

    listBlock() {
        return <div className="row pt-30 " >




                { this.state.permit.customer_banks.map(({ id, bank_type, bank_acc_name, bank_num, created_at }, index) =>

                    <div className="adm-card row grid-90 bg-noise mt-10 pt-20">

                              <div className="adm-card-body grid-100">
                                    <div className="grid-50 left">
                                      <img src={this.filterBankType(bank_type).image}  height="100%"  onClick={(e) => this.onBankTypeChangeImgNew.call(this, e, bank_type, id) }/>
                                    </div>
                                    <div className="grid-50 right">
                                        {bank_acc_name} <br />
                                        {bank_num} <br />
                                        {this.filterBankType(bank_type).shortcode}

                                    </div>

                              </div>

                              <div className="adm-card-body row">

                                  <div className="grid-90  pl-20 pt-4 pb-4">
                                    <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                  </div>
                              </div>

                              <div className="adm-card-body grid-100 pt-4 pb-4 ">

                                      <div className="grid-50 left text-left">
                                            {<span onClick={() => this.deleteData(id)} className="ml-20 btn-small btn-yellow">Delete</span>}

                                      </div>
                                      <div className="grid-50 right">
                                        <b className="color-theme-gold">
                                          {moment.utc(created_at).local().format('DD-MM-YYYY') } </b><br/>
                                        <span>{moment.utc(created_at).local().format('hh:mm A') }</span> <br />

                                      </div>

                              </div>

                    </div>

                )}

        </div>
    }

    deleteData(bank_id) {
        //localStorage.getItem(window.BPdata.time)
        let self = this;
        const url = window.BPdata.apiUrlCMS+'/player/bank-delete?provider_id='+window.BPdata.provider_id+'&bank_id='+bank_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        axios({
          method: 'post',
          url: url,
          data: { token : localStorage.getItem(window.BPdata.time) },
          headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(response => {




                if(response.data.status_code == "200") {

                    // this.setState(prevState => ({
                    //     form : {
                    //         ...prevState.form,
                    //         bank_type : 1,
                    //         holdername : "",
                    //         card_number : "",
                    //         provider_id :  window.BPdata.provider_id
                    //     }
                    // }))


                     swal("Successfully submited");


                    this.fetchData();
                     // }

                     // self.props.history.push("/more");
                }


                if(response.data.status_code == "404") {

                    //this.isloading = "false";
                    // $(".display-none").css('display','flex');

                    //console.log(response.data.message);

                    let message = "";
                    if(response.data.message.length > 0) {
                        for (var i = 0; i < response.data.message.length; i++) {
                            message += response.data.message[i] + " | ";
                        }
                        swal(message);

                    } else {
                        swal( window.lang.errors.contact_cs );
                    }

                }




          })
          .catch(function(error) {
            // console.log(error);
          });


    }



    onCusBankChange(e) {
        // console.log("ok")

        // let bank_type = e.target.value;

        let bank_accounts = [];

        let selectcustomerbank = {};

        let customerbank_id = e.target.value;

        for(let i = 0; i< this.state.permit.customer_banks.length; i++){

            // console.log(dagonbank_id + this.permit.specfic_bank_accounts[i].id)
            if(customerbank_id == this.state.permit.customer_banks[i].id) {


                selectcustomerbank.id = this.state.permit.customer_banks[i].id;
                selectcustomerbank.bank_acc_name  = this.state.permit.customer_banks[i].bank_acc_name;
                selectcustomerbank.bank_type  = this.state.permit.customer_banks[i].bank_type;
                selectcustomerbank.bank_num  = this.state.permit.customer_banks[i].bank_num;
            }

        }

        this.setState(prevState => ({
            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                customerbank: selectcustomerbank,
            }
        }))


        e.preventDefault();
    }

    onBankTypeChangeImg(e,id) {

        let bank_type;
        if(e === undefined){
            bank_type = 1;
        } else {
            bank_type = e.target.value;
        }

        bank_type = id;


        // console.log(e)

        let find = 0;
        let dagonbank = this.state.form.dagonbank;
        let bank_accounts = [];
        let specfic_cus_bank_accounts = [];
        let specfic_bank_accounts = [];

        let selectcustomerbank = {};

        // let bank_type = this.form.customerbank.bank_type;

        // console.log(this.permit.customer_banks.length + "-----")
        for(let i = 0; i<this.state.permit.customer_banks.length; i++){

            let selectcustomerbank = {};

            if(bank_type == this.state.permit.customer_banks[i].bank_type) {

                selectcustomerbank.id = this.state.permit.customer_banks[i].id;
                selectcustomerbank.bank_acc_name  = this.state.permit.customer_banks[i].bank_acc_name;
                selectcustomerbank.bank_type  = this.state.permit.customer_banks[i].bank_type;
                selectcustomerbank.bank_num  = this.state.permit.customer_banks[i].bank_num;

                specfic_cus_bank_accounts.push(selectcustomerbank)

                find = 1;

                // console.log( this.state.permit.customer_banks[i].bank_type)
                // console.log("find ------")
                // console.log(specfic_cus_bank_accounts)

            }

            // console.log(this.state.permit.bank_accounts[i].shortcode+"--------")
        }

        {/*console.log(this.state.permit.customer_banks)*/}

        for(let j = 0; j<this.state.permit.bank_accounts.length; j++){

            if(bank_type == this.state.permit.bank_accounts[j].bank_type) {
                bank_accounts.push({ id : this.state.permit.bank_accounts[j].id, bank_type: this.state.permit.bank_accounts[j].bank_type, shortcode : this.state.permit.bank_accounts[j].shortcode, holdername :  this.state.permit.bank_accounts[j].holdername, cardnum :  this.state.permit.bank_accounts[j].cardnum })
            }

            // console.log(this.permit.bank_accounts[i].shortcode+"--------")
        }



        if(specfic_cus_bank_accounts.length > 0) {
            selectcustomerbank.id = specfic_cus_bank_accounts[0].id;
            selectcustomerbank.bank_acc_name  = specfic_cus_bank_accounts[0].bank_acc_name;
            selectcustomerbank.bank_type  = specfic_cus_bank_accounts[0].bank_type;
            selectcustomerbank.bank_num  = specfic_cus_bank_accounts[0].bank_num;
        }

        if(bank_accounts.length > 0){
                dagonbank.id = bank_accounts[0].id;
                dagonbank.holdername = bank_accounts[0].holdername;
                dagonbank.bank_type = bank_accounts[0].bank_type;
                dagonbank.cardnum = bank_accounts[0].cardnum;
        }



        // this.setState({ permit : { bank_accounts : [] }});
        // this.setState({ permit : { bank_accounts : bank_accounts }});
        specfic_bank_accounts = [];
        specfic_bank_accounts = bank_accounts;

        {/*specfic_cus_bank_accounts = [];
        specfic_cus_bank_accounts = specfic_cus_bank_accounts; */}

        if(find == 0) {
            selectcustomerbank.id = "";
            selectcustomerbank.bank_acc_name = "";
            selectcustomerbank.bank_type  = bank_type;
            selectcustomerbank.bank_num = "";
        }



        this.setState(prevState => ({

            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                customerbank: selectcustomerbank,
                dagonbank : dagonbank
            },
            permit : {
                ...prevState.permit,
                specfic_cus_bank_accounts : specfic_cus_bank_accounts,
                specfic_bank_accounts : specfic_bank_accounts
            }
        }))

        if(e === undefined){
            return ;
        } else {
            e.preventDefault();
        }
        {/*
        console.log(this.state.permit.specfic_cus_bank_accounts)*/}
      {/*  console.log(this.state.form.customerbank)

        return */}
        this.setState( { step: "form" });


    }



    onBankTypeChangeImgNew(e,bank_type, id) {

        // let bank_type;
        // if(e === undefined){
        //     bank_type = 1;
        // } else {
        //     bank_type = e.target.value;
        // }
        //
        // bank_type = id;


        // console.log(e)
        // alert(bank_type + "--" + id)
        let find = 0;
        let dagonbank = this.state.form.dagonbank;
        let bank_accounts = [];
        let specfic_cus_bank_accounts = [];
        let specfic_bank_accounts = [];

        let selectcustomerbank = {};

        // let bank_type = this.form.customerbank.bank_type;

        // console.log(this.permit.customer_banks.length + "-----")
        for(let i = 0; i<this.state.permit.customer_banks.length; i++){

            let selectcustomerbank = {};

            if(bank_type == this.state.permit.customer_banks[i].bank_type) {

                selectcustomerbank.id = this.state.permit.customer_banks[i].id;
                selectcustomerbank.bank_acc_name  = this.state.permit.customer_banks[i].bank_acc_name;
                selectcustomerbank.bank_type  = this.state.permit.customer_banks[i].bank_type;
                selectcustomerbank.bank_num  = this.state.permit.customer_banks[i].bank_num;

                specfic_cus_bank_accounts.push(selectcustomerbank)

                find = 1;

                // console.log( this.state.permit.customer_banks[i].bank_type)
                // console.log("find ------")
                // console.log(specfic_cus_bank_accounts)

            }

            // console.log(this.state.permit.bank_accounts[i].shortcode+"--------")
        }

        {/*console.log(this.state.permit.customer_banks)*/}

        for(let j = 0; j<this.state.permit.bank_accounts.length; j++){

            if(bank_type == this.state.permit.bank_accounts[j].bank_type) {
                bank_accounts.push({ id : this.state.permit.bank_accounts[j].id, bank_type: this.state.permit.bank_accounts[j].bank_type, shortcode : this.state.permit.bank_accounts[j].shortcode, holdername :  this.state.permit.bank_accounts[j].holdername, cardnum :  this.state.permit.bank_accounts[j].cardnum })
            }

            // console.log(this.permit.bank_accounts[i].shortcode+"--------")
        }



        if(specfic_cus_bank_accounts.length > 0) {
            selectcustomerbank.id = specfic_cus_bank_accounts[0].id;
            selectcustomerbank.bank_acc_name  = specfic_cus_bank_accounts[0].bank_acc_name;
            selectcustomerbank.bank_type  = specfic_cus_bank_accounts[0].bank_type;
            selectcustomerbank.bank_num  = specfic_cus_bank_accounts[0].bank_num;
        }

        if(bank_accounts.length > 0){
                dagonbank.id = bank_accounts[0].id;
                dagonbank.holdername = bank_accounts[0].holdername;
                dagonbank.bank_type = bank_accounts[0].bank_type;
                dagonbank.cardnum = bank_accounts[0].cardnum;
        }



        // this.setState({ permit : { bank_accounts : [] }});
        // this.setState({ permit : { bank_accounts : bank_accounts }});
        specfic_bank_accounts = [];
        specfic_bank_accounts = bank_accounts;

        {/*specfic_cus_bank_accounts = [];
        specfic_cus_bank_accounts = specfic_cus_bank_accounts; */}

        if(find == 0) {
            selectcustomerbank.id = "";
            selectcustomerbank.bank_acc_name = "";
            selectcustomerbank.bank_type  = bank_type;
            selectcustomerbank.bank_num = "";
        }



        this.setState(prevState => ({
            step: "form",
            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                customerbank: selectcustomerbank,
                dagonbank : dagonbank
            },
            permit : {
                ...prevState.permit,
                specfic_cus_bank_accounts : specfic_cus_bank_accounts,
                specfic_bank_accounts : specfic_bank_accounts
            }
        }))

        console.log(this.state.form.customerbank)

        // this.setState( { step: "form" });

        if(e === undefined){
            return ;
        } else {
            e.preventDefault();
        }

    }

    onBankTypeChange(e) {
        let bank_type;
        if(e === undefined){
            bank_type = 1;
        } else {
            bank_type = e.target.value;
        }


        // console.log(e)

        let find = 0;
        let dagonbank = this.state.form.dagonbank;
        let bank_accounts = [];
        let specfic_cus_bank_accounts = [];
        let specfic_bank_accounts = [];

        let selectcustomerbank = {};

        // let bank_type = this.form.customerbank.bank_type;

        // console.log(this.permit.customer_banks.length + "-----")
        for(let i = 0; i<this.state.permit.customer_banks.length; i++){

            let selectcustomerbank = {};

            if(bank_type == this.state.permit.customer_banks[i].bank_type) {

                selectcustomerbank.id = this.state.permit.customer_banks[i].id;
                selectcustomerbank.bank_acc_name  = this.state.permit.customer_banks[i].bank_acc_name;
                selectcustomerbank.bank_type  = this.state.permit.customer_banks[i].bank_type;
                selectcustomerbank.bank_num  = this.state.permit.customer_banks[i].bank_num;

                specfic_cus_bank_accounts.push(selectcustomerbank)

                find = 1;

                // console.log( this.state.permit.customer_banks[i].bank_type)
                // console.log("find ------")
                // console.log(specfic_cus_bank_accounts)

            }

            // console.log(this.state.permit.bank_accounts[i].shortcode+"--------")
        }

        {/*console.log(this.state.permit.customer_banks)*/}

        for(let j = 0; j<this.state.permit.bank_accounts.length; j++){

            if(bank_type == this.state.permit.bank_accounts[j].bank_type) {
                bank_accounts.push({ id : this.state.permit.bank_accounts[j].id, bank_type: this.state.permit.bank_accounts[j].bank_type, shortcode : this.state.permit.bank_accounts[j].shortcode, holdername :  this.state.permit.bank_accounts[j].holdername, cardnum :  this.state.permit.bank_accounts[j].cardnum })
            }

            // console.log(this.permit.bank_accounts[i].shortcode+"--------")
        }



        if(specfic_cus_bank_accounts.length > 0) {
            selectcustomerbank.id = specfic_cus_bank_accounts[0].id;
            selectcustomerbank.bank_acc_name  = specfic_cus_bank_accounts[0].bank_acc_name;
            selectcustomerbank.bank_type  = specfic_cus_bank_accounts[0].bank_type;
            selectcustomerbank.bank_num  = specfic_cus_bank_accounts[0].bank_num;
        }

        if(bank_accounts.length > 0){
                dagonbank.id = bank_accounts[0].id;
                dagonbank.holdername = bank_accounts[0].holdername;
                dagonbank.bank_type = bank_accounts[0].bank_type;
                dagonbank.cardnum = bank_accounts[0].cardnum;
        }



        // this.setState({ permit : { bank_accounts : [] }});
        // this.setState({ permit : { bank_accounts : bank_accounts }});
        specfic_bank_accounts = [];
        specfic_bank_accounts = bank_accounts;

        {/*specfic_cus_bank_accounts = [];
        specfic_cus_bank_accounts = specfic_cus_bank_accounts; */}

        if(find == 0) {
            selectcustomerbank.id = "";
            selectcustomerbank.bank_acc_name = "";
            selectcustomerbank.bank_type  = bank_type;
            selectcustomerbank.bank_num = "";
        }



        this.setState(prevState => ({
            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                customerbank: selectcustomerbank,
                dagonbank : dagonbank
            },
            permit : {
                ...prevState.permit,
                specfic_cus_bank_accounts : specfic_cus_bank_accounts,
                specfic_bank_accounts : specfic_bank_accounts
            }
        }))

        if(e === undefined){
            return ;
        } else {
            e.preventDefault();
        }
        {/*
        console.log(this.state.permit.specfic_cus_bank_accounts)*/}
      {/*  console.log(this.state.form.customerbank)

        return */}
    }

    showMessage() {


        // swal("Please check bank transaction id");

        swal("Request Withdrawal", {
            title: "Request Withdrawal",
            text: this.state.message,
          buttons: {
            // cancel: "Run away!",
            ok: {

              value: "catch",
            }
          },
        })
        .then((value) => {
          switch (value) {

            case "catch":
                this.props.history.push("/more/wallet-store");
              // swal("Gotcha!", "Pikachu was caught!", "success");
              break;

            default:
              swal("Got away safely!");
          }
        });

    }

    showSuccessAlert(message) {
        const alertId = StatusAlertService.showSuccess(message);
        this.setState({ alertId });
    }

    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }

    setAmount(text){

        let bank_type = this.state.form.bank_type;

        this.setState({
                form :  {
                    bank_type : bank_type,
                    amount : text
                }
            });
    }

    inputNumberChangeHandler(e) {
        let form = {...this.state.form};
        if(Number(e.target.value)) {
            form[e.target.name] = Number(e.target.value);
            this.setState({
                form
            });
        }

    }

    onListBankTypeChange(e, id) {

        let bank_accounts = [];

        let selectcustomerbank = {};

        let customerbank_id = id;

        for(let i = 0; i< this.state.permit.customer_banks.length; i++){

            // console.log(dagonbank_id + this.permit.specfic_bank_accounts[i].id)
            if(customerbank_id == this.state.permit.customer_banks[i].id) {


                selectcustomerbank.id = this.state.permit.customer_banks[i].id;
                selectcustomerbank.bank_acc_name  = this.state.permit.customer_banks[i].bank_acc_name;
                selectcustomerbank.bank_type  = this.state.permit.customer_banks[i].bank_type;
                selectcustomerbank.bank_num  = this.state.permit.customer_banks[i].bank_num;
            }

        }

        this.setState(prevState => ({
            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                customerbank: selectcustomerbank,
            }
        }))


        e.preventDefault();

    }

    inputChangeHandler(e) {

        let form = {...this.state.form};

        // if(e.target.name == "bank_type" ) {
        //     customerbank.bank_type
        // }

        form[e.target.name] = e.target.value;
        this.setState({
            form
        });

        if( e.target.name == "amount" )  {
            this.minAmountMessage(e.target.value);
        }


    }

    sendTransactionApi() {
        var self = this;
        let formdata = this.state.form;
        // let dataurl = window.BPdata.apiUrl+'/user/login';
        const dataurl = window.BPdata.apiUrlCMS+"/transaction/create";

        if(this.state.form.customerbank.id) {
            console.log("")
        } else {
            swal(window.lang.errors.check_bank_acc)

            // this.showErrorAlert("Please check your Bank Account", 'single');
            // alert("Please check your Bank Account")
            return;
        }


        if(this.state.bullet == 1) {

            swal(window.lang.errors.w_request_processing);

            return;
        }

        if(this.state.bullet == 0) {

            this.setState({
                    bullet : 1,
            });
        }


        if(this.state.form.amount <= 1000) {

            //သင့်ငွေပမာဏကို စစ်ဆေးပါ။
            //Please check your Amount
            swal(window.lang.errors.check_amount)
            // this.showErrorAlert("Please check your Amount", 'single');
            // alert("Please check your Amount")
            return;
        }

        axios({
            method: 'post',
            url: dataurl,
            data: { body : formdata },
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(function (response) {

            // console.log(response.data.data.message)

            if(response.data.status_code == 200) {

                // alert("200");

                self.setState({
                    message : response.data.message ,
                    // step : "profile",
                });
                // localStorage.setItem(window.BPdata.time, response.data.data.token );
                // localStorage.setItem('username', response.data.data.username );
                // localStorage.setItem('reset', 1);
                // self.props.history.push("/");

                if(response.data.time) {

                    localStorage.setItem(window.BPdata.time, response.data.time);

                }
                
                self.showMessage();

            }

            if(response.data.status_code == 404) {
                if(response.data.message.length > 0){
                     swal(response.data.message);
                     self.setState({
                             bullet : 0,
                     });
                } else {
                    swal( window.lang.errors.contact_cs );
                }

            }

            //console.log(localStorage.getItem(window.BPdata.time))
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    fetchData() {
        //localStorage.getItem(window.BPdata.time)
        let self = this;
        const url = window.BPdata.apiUrlCMS+'/player/show?provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        axios({
          method: 'post',
          url: url,
          data: { token : localStorage.getItem(window.BPdata.time) },
          headers: { 'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
             },
        })
        .then(resp => {

            if(resp.data.data) {

                this.setState(prevState => ({
                  wallet : resp.data.customer_wallet,
                  permit : {
                    ...prevState.permit,
                    customer_banks : resp.data.data.customer_banks,
                    specfic_bank_accounts : resp.data.bank_accounts,
                    bank_accounts : resp.data.bank_accounts,
                    bank_types : resp.data.bank_types,
                  }
                }))

                // console.log(resp.data.data.customer_banks)

                if (resp.data.data.customer_banks.length == 0) {
                    this.props.history.push("/more/wallet-store/add-bank");
                }


                self.onBankTypeChange();


                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )

            } else {

                swal( window.lang.errors.contact_cs );

            }

          })
          .catch(function(error) {
            // console.log(error);
          });


    }

    changeBankType(step) {

        this.setState({
                form :  {
                    bank_type : step
                }
            });

        return;

    }

    minAmountMessage(amount) {

        // let amount = this.state.form.amount;

        if( amount < 3000 ) {

            this.setState({ messageAlert : window.lang.messages.minamount });

        } else {

            this.setState({ messageAlert : "" });

        }
        // this.setState({ step : step});

    }

    changeSubMenu(step) {
        // console.log("sdf");

        if(step == "show") {
            this.setState({ show : "true"});
        } else {
            this.setState({ show : "false"});
        }

        this.setState({ step : step});

        this.fetchData();

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
          if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );

              }
              //window.BPdata.preurl =  menu[menu.length-1];
              window.BPdata.preurl =  menu[menu.length-2];
              //console.log(window.BPdata.preurl)
          }
        }
      }


    componentDidMount() {

        // this.showMessage();

        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);
            this.setState({ step: "form" });
          } else {
             this.props.history.push("/more/sign-up");
            }
        } else {
            this.props.history.push("/more/sign-up");
        }

        if(localStorage.getItem('contest_advertise')) {

            if(JSON.parse(localStorage.getItem("contest_advertise")) != null) {
              let contest_advertise = JSON.parse(localStorage.getItem("contest_advertise"));
              this.setState({advertise: contest_advertise})
            }
        }

        this.fetchData();

        if(localStorage.getItem('sbsetting')) {
            if(localStorage.getItem('sbsetting') !== "0" ) {

                let sbsetting = JSON.parse(localStorage.getItem("sbsetting"));

                // this.setState({
                //   viber_ph_1 : sbsetting[9]
                // })

                this.setState(prevState => ({
                    viber_ph_1 : sbsetting[8].option_value,
                    viber_ph_2 : sbsetting[9].option_value
                }))

                // console.log( sbsetting )

                return true;
            }
        }



        // this.apifetchurl();


    }

    UNSAFE_componentWillUpdate() {
        //this.hisUrl('/more');
    }


    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "form": return this.formBlock();
                case "list": return this.listBlock();
                default:        return "";
              }
        }

    }

    formBlock() {
        return  <div className="row text-left">

                    <div className="grid-90 text-left page-contest">

                                <div class="row">
                                    <div className="grid-60 left">

                                        <span className="text-primary btn btn-yellow btn-small" onClick={() => this.changeSubMenu('list')}>{window.lang.form.bank_list}</span>

                                    </div>

                                    <div className="grid-40 right text-right">
                                        <Link to="/more/wallet-store/add-bank" className=" btn btn-yellow   pointer btn-small color-white">
                                          {window.lang.form.add_bank_account}
                                        </Link>
                                    </div>

                                </div>

                                <div class="row m-10">
                                    <br />
                                    <div className="row text-center">
                                        { window.lang.messages.choose_withdraw_bank }

                                    </div>
                                    <br />

                                    <div className="row text-center paymentlist">

                                      <div className="grid-100">
                                        {this.state.payment.map((dataItem, i) => (

                                                (dataItem.id == this.state.form.customerbank.bank_type ) ? <img src={dataItem.image} alt={window.lang.title.forum} style={{height:"80px",marginBottom:"5px",marginRight:"15px", borderRadius: '15px'}} className="bg-gray"  onClick={(e) => this.onBankTypeChangeImg.call(this, e, dataItem.id)}  name="bank_type"  /> : <img src={dataItem.non_image} alt={window.lang.title.forum} style={{height:"80px",marginBottom:"5px",marginRight:"15px", borderRadius: '15px'}} className="bg-gray"  onClick={(e) => this.onBankTypeChangeImg.call(this, e, dataItem.id)} name="bank_type"  />

                                        ))}
                                      </div>

                                    </div>

                                    <br />

                                    <div className="row">

                                      <label class="control-label">{ window.lang.form.name }</label>
                                      <input required="required" type="text" name="bank_num" placeholder="Account Name" disabled="disabled" class="form-control" value={this.state.form.customerbank.bank_acc_name} />

                                      <label class="control-label">{ window.lang.form.acc_phone_num }</label>
                                      <select class="form-control" value={this.state.form.customerbank.id} onChange={(e) => this.onCusBankChange.call(this, e)}>
                                          {this.state.permit.specfic_cus_bank_accounts.map(({ id, bank_num }, index) => <option value={id} >{bank_num}</option>)}
                                        {/*<option value="3624">23432432234324324</option>*/}
                                      </select>

                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">{ window.lang.form.amount }</label>
                                    <input placeholder={window.lang.BetListDetail.amount} name="amount" type="number" value="0" class="form-control"  onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.amount} /> <br />
                                    { this.state.messageAlert }
                                </div>

                                    <div>
                                    <br/>
                                </div>
                                <br />
                                <input className="btn btn-yellow   pointer grid-100" value={window.lang.form.submit} onClick={this.sendTransactionApi}/>

                                <div className="card row">
                                    <div className="el-card__body">
                                        <br />

                                        <div className="adm-card row grid-100 bg-noise">


                                            <div className="adm-card-body grid-100 pt-4 pb-4">

                                                    <div className="grid-100 left text-left pl-20">
                                                      <br />
                                                        { window.lang.messages.any_trouble_viber }
                                                      <br />
                                                    </div>

                                            </div>

                                            <div className="adm-card-body row">

                                                <div className="grid-90  pl-20 pt-4 pb-4">
                                                  <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                                </div>
                                            </div>

                                            <div className="adm-card-body row">
                                                    <div className="grid-50 left text-left pl-20">
                                                        <a href={"viber://contact?number=%2B"+this.state.viber_ph_1} style={{ color: "#fff", fontSize: "15px"}}>
                                                            +{this.state.viber_ph_1}
                                                        </a>
                                                    </div>
                                                    <div className="grid-40 right color-blue">
                                                        <a href={"viber://contact?number=%2B"+this.state.viber_ph_1}  >
                                                        <img src="/img/list/viber_group.png" alt="" height="30px"/>
                                                        </a>

                                                    </div>

                                            </div>
                                            <div className="adm-card-body row">
                                                    <div className="grid-50 left text-left  pl-20">
                                                        <a href={"viber://contact?number=%2B"+this.state.viber_ph_2}  style={{ color: "#fff", fontSize: "15px"}}>
                                                            +{this.state.viber_ph_2}
                                                        </a>
                                                    </div>
                                                    <div className="grid-40 right color-blue">
                                                        <a href={"viber://contact?number=%2B"+this.state.viber_ph_2}  >
                                                        <img src="/img/list/viber_group.png" alt="" height="30px"/>
                                                        </a>

                                                    </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                    </div>
                </div>

    }

    render() {
        return (

            <div className="page">
                
                <div className="page-content text-center">
                <AppHeader backLink="/more/wallet-store" titleType="3" title={window.lang.title.withdrawal} isLoading="false" />
                    <div className="main-content text-center">
                        {(this.state.message !== "") ? <div className="grid"><StatusAlert/></div>: ""}

                        { this.viewHandler() }


                    </div>

                    <br />

                </div>
                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
            </div>
        );
    }
}

export default WalletWithdrawalStore;
