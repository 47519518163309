import React from 'react';
import axios from 'axios';
// import './../Ant.css';
import {
    Link
  } from 'react-router-dom';
import { Grid } from 'antd-mobile';
import AppHeader from './AppHeader';
// import { Card } from 'antd-mobile'
var moment = require('moment');

let checklogin = () => {
  if(localStorage.getItem(window.BPdata.time)) {
      if(localStorage.getItem(window.BPdata.time) !== "0" ) {
          return true;
      }
  }
  return false;
}

class ThreeGameHomePage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "home";
        this.state = {
            step: "login",
            message : "",
            result_list : [ {lottery_number: '--'}],
            form : {username: '', phone: '',verify_code: '', password: ''} ,
            settrade: {
                "thaidate":"29-11-21 13:11",
                "set":"--",
                "value":"--",
                "created_at":"2021-11-29 12:41:10",
                "number":"--"
            },
            eventone  : [],
            eventtwo   : [],
            wallet : [
                {
                    wallet_id: 1,
                    credit : 0
                },
                {
                    wallet_id: 2,
                    credit : 0
                },
                {
                    wallet_id: 3,
                    credit : 0
                }
            ],
         };


    }

    currencyFormat(num) {
        return num;
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              //localStorage.setItem(window.BPdata.time, true);

              } else {
                this.props.history.push("/more");
                }
        } else {
          this.props.history.push("/more");
        }

        if(localStorage.getItem('wallet')) {

          if(JSON.parse(localStorage.getItem("wallet")) != null) {
            let wallet = JSON.parse(localStorage.getItem("wallet"));
            this.setState({wallet: wallet})
          }
        }

        this.fetchData(window.BPdata.apiUrlCMS+'/2d');
        // http://dep.thahtaylay.com/api/v1/cms/2d
    }

    fetchData(url) {

        // let dataurl = url;

        // window.BPdata.liveurl =  setTimeout(() => this.fetchData(url), 30000);

        // axios.get(dataurl, {
        //   method: "GET", // *GET, POST, PUT, DELETE, etc.
        //   mode: "no-cors", // no-cors, cors, *same-origin
        //   cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //   //credentials: "same-origin", // include, *same-origin, omit
        //   crossDomain: true,
        //   headers: {
        //       "Content-Type": "application/json; charset=utf-8",
        //       "X-Trident-Token" : window.BPdata.token,
        //       "BP-Agent"         : window.BPdata.rand_compound,
        //         "BP-Time"         : localStorage.getItem(window.BPdata.time)
        //       // "Content-Type": "application/x-www-form-urlencoded",
        //   },
        //   redirect: "follow", // manual, *follow, error
        //   referrer: "no-referrer", // no-referrer, *client
        //   //body: JSON.stringify(data), // body data type must match "Content-Type" header
        // })
        // .then(resp => {
        //     // console.log(resp.data)

        //     // {"thaidate":"29-11-21 13:11",
        //     // "set":"1,600.24",
        //     // "value":"64,409.60M",
        //     // "created_at":"2021-11-29 12:41:10",
        //     // "number":"49"}
        //     this.setState({
        //         settrade : {
        //             thaidate: resp.data.data.thaidate,
        //             set: resp.data.data.set,
        //             value: resp.data.data.value,
        //             created_at: resp.data.data.created_at,
        //             number: resp.data.data.number
        //         },
        //         eventone  : resp.data.eventone,
        //         eventtwo   : resp.data.eventtwo,

        //     });
        //     //this.setState({ isLoading: false });

        // })
        // .catch(function(error) {
        //   //console.log(error.tostring());
        // });

        let NUM_ROWS = 5;

        let dataurl = window.BPdata.apiUrl+'/service/bet-list?limit='+NUM_ROWS+'&game_type=2';
        // let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            // data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token"  : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"          : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          this.bet_list = resp.data.bet_list.data;
          this.result_list = resp.data.result_list.data;

            this.setState({
              result_list: this.result_list,
              next_event : resp.data.next_event,
            });

        })
        .catch(function(error) {
          //console.log(error);
        });

    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    childui() {

        let ui = []

        this.state.result_list.forEach(function(each,key){

            ui.push(
                    <div className="adm-card row grid-90 ">
                            <div className="grid-100">
                                    <div className="grid-50 left">
                                      {/* {window.lang.title.date}&nbsp; &nbsp;|&nbsp;&nbsp; */}
                                         {moment(each.schedule_at, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')} 
                                    </div>
                                    <div className="grid-40 left color-theme-gold text-right">
                                        { each.lottery_number }
                                    </div>

                            </div>
                            <div className="row">

                                <div className="grid-90  pl-20 pt-4">
                                  <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                </div>
                            </div>
                    </div>)

        })

        return ui

    }

    walletBlock() {

    let childui = [];


          if(this.state.wallet[0]) {
            childui.push(<div className="row pb-10">
                <br />
                 <div className="adm-card row mt-10 color-white">
                        <div className="adm-card-body grid-100">
                            <div className="row">
                                <div className="grid-10 left text-center pl-10">
                                  <img src="/img/main_wallet.png" width="30" />
                                </div>
                                <div className="grid-40 left text-left pl-10 pocket-session">
                                    { window.lang.title.main_wallet }
                                    <br />
                                    

                                </div>
                                <div className="grid-30 left text-right pocket-session right-pocket">
                                  <span className='walletcolor'>
                                    { (this.state.wallet) ? this.currencyFormat(this.state.wallet[0].credit) : 0 }  Ks
                                  </span>
                                </div>
                                <div className="grid-10 left text-right frame-img-center">
                                  <Link to="/more/wallet-store/transfer">
                                    <img src="/img/plus-circle.png" width="23" height="10px" />
                                  </Link>
                                </div>

                            </div>
                            <div className="row">
                                <div className="grid-90  pl-20 pt-4">
                                  <hr class="pocket-line" style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="grid-10 left text-center pl-10">
                                  <img src="/img/commission_wallet.png" width="30" />
                                </div>
                                <div className="grid-40 left text-left pl-10 ">
                                    { window.lang.title.commission_wallet }
                                    <br />
                                    

                                </div>
                                <div className="grid-30 left text-right ml-10">
                                  <span className='walletcolor'>
                                    { (this.state.wallet) ? this.currencyFormat(this.state.wallet[2].credit) : 0 }  Ks
                                  </span>
                                </div>
                                <div className="grid-10 left text-right frame-img-center">
                                  <Link to="/more/wallet-store/transfer">
                                    <img src="/img/plus-circle.png" width="23" height="10px" />
                                  </Link>
                                </div>

                            </div>
                        </div>
                </div>
            </div>)
          } else {
            if(this.state.is_verified == 0) {
              childui.push(<div className="row pb-10">
                <br />
                 <div className="adm-card row mt-10">
                        <div className="adm-card-body grid-100">
                            <div className="row">
                                <div className="grid-100 left text-center">
                                    You have been banned
                                </div>
                                <br />
                                <hr />
                            </div>
                        </div>
                </div>
            </div>)
            } else {
              //Please Login
              childui.push("")
            }

          }
      return childui;
  }

    getCurrentDay() {
        var day = new Date();
        var num_day = day.getDay();
        return num_day;
    }

    currentDayFull() {

        var num_day = this.getCurrentDay();

        // alert(num_day);

        return window.lang.messages.num_day[num_day].text;

        console.log(window.lang.messages.num_day[1]);


        if (num_day == 1) {
          return window.lang.messages.num_day[1].text;
        }
        if (num_day == 2) {
          return window.lang.messages.good_morning;
        }
        if (num_day == 3) {
          return window.lang.messages.good_morning;
        }
        if (num_day == 4) {
          return window.lang.messages.good_morning;
        }
        if (num_day == 5) {
          return window.lang.messages.good_morning;
        }
        if (num_day == 6) {
          return window.lang.messages.good_morning;
        }
        if (num_day==0) {
          return window.lang.messages.num_day[0];
        }

    }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">

                <AppHeader titleType="2" title={window.lang.title.threed} isLoading="false" />


                    <div className="main-content">

                    {(checklogin()) ? ((this.state.wallet[0]) ?
                            this.walletBlock() : "" ) : ""}

                    <div className="adm-card row grid-90 bg-noise mb-10">

                            <div className="adm-card-body grid-100">
                                    <div className="grid-50 left current-number-block pl-20 text-left">
                                        <span className="blink_me_static " >{ this.state.result_list[0].lottery_number }</span>


                                    </div>
                                    <div className="grid-40 mt-20 left color-white text-center">
                                      {window.lang.title.date}&nbsp; &nbsp; <br /><br />
                                        {/* ထီထွက်ခဲ့သောအချိန်&nbsp; &nbsp;<br /> */}
                                          {moment(this.state.result_list[0].schedule_at, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')}
                                          <br />
                                          { this.currentDayFull() }
                                    </div>

                            </div>
                            <div className="adm-card-body row">

                                <div className="grid-90  pl-20 pt-4 pb-4">
                                  <hr class="narrow-bar" style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                </div>
                            </div>

                            <div className="adm-card-body grid-100">
                                    <div className="grid-49 left">
                                        <Link to="/more/bet-list-3d" className="">
                                        <img src="/img/list/_history_list.png" height="30px"/>
                                        <br /><br />
                                        <span>{window.lang.title.bet_list}</span>
                                        </Link>

                                    </div>

                                    <div className="grid-49 left">
                                        <Link to="/more/3d-winner-top-ten-list" className="">
                                        <img src="/img/list/_winner.png" height="30px"/>
                                        <br /><br />
                                        <span>{window.lang.title.winner}</span>
                                        </Link>
                                    </div>


                            </div>
                        </div>
                    <br />

                    <div className="adm-card row grid-90 bg-noise mb-10">
                            <br />
                            { this.childui() }

                    </div>
                    <br />
                    </div>
                    <div className="grid-100 text-center mt-10">
                        {(checklogin() ) ? ((this.state.next_event == 1) ? 
                        <Link to="/three-game-set" className="btn btn-yellow btn-small1 grid-20 scrollup">{ window.lang.betgroup.bet }</Link> :
                         <button className="btn btn-yellow btn-small1 grid-20 scrollup pointer-not-allowed">{ window.lang.betgroup.bet }</button> ) : "" }

                    </div>
                    <br />
                </div>

                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>

            </div>
        );
    }
}

export default ThreeGameHomePage;
