import "./webpack.config";
//import Loadable from 'react-loadable';
import React from 'react';
import ReactDOM from 'react-dom';


import { createStore } from 'redux'
import { Provider } from 'react-redux';

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";

// import { initializeApp } from 'firebase/app';
// import { getMessaging } from 'firebase/messaging';

import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';

import rootReducer from './reducers/rootReducer'
import App from './App';


import registerServiceWorker from './registerServiceWorker';
// import { initializeFirebase } from './components/push-notification';
import { BrowserRouter } from 'react-router-dom';

import './index.css'



// Initialize Firebase (make sure to replace with your actual config)
const firebaseConfig = {
  apiKey: "AIzaSyDnIAPFA01b1FS_KOrz1g4LgxveeCzMtlQ",
  authDomain: "thahtaylay-96fff.firebaseapp.com",
  projectId: "thahtaylay-96fff",
  storageBucket: "thahtaylay-96fff.appspot.com",
  messagingSenderId: "918243368430",
  appId: "1:918243368430:web:0ca0f9a3b13b1d48d402ff",
  measurementId: "G-XQ1SQ4W65H"
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Request permission and get the token
async function requestPermissionAndGetToken() {
  try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
          const token = await getToken(messaging, { vapidKey: 'BKryqU9GSjInSR18uCGtt7x_j8eGWDGi6nOMzmiB-1djg-EKz2uJ0hKgF2vyPtBSxczyAPGCEfkcroGdXbu7So8' });
          // console.log('Token:');
        //   console.log('FCM Token:', token);
          subscribeToTopic(token);
      } else {
          console.log('Permission not granted');
      }
  } catch (error) {
      console.error('Error getting permission or token', error);
  }
}

// Example in your main script or a component
// async function requestNotificationPermission() {
//     const permission = await Notification.requestPermission();
//     if (permission === 'granted') {
//         console.log('Notification permission granted.');
//     } else {
//         console.log('Notification permission denied.');
//     }
// }

// requestNotificationPermission();

async function unsubscribeUser() {
  try {
      const token = await getToken(messaging, { vapidKey: 'BKryqU9GSjInSR18uCGtt7x_j8eGWDGi6nOMzmiB-1djg-EKz2uJ0hKgF2vyPtBSxczyAPGCEfkcroGdXbu7So8' });
      
      if (token) {
          await deleteToken(messaging, token);
          console.log('Token unsubscribed successfully:', token);
      } else {
          console.error('No registration token available. Request permission to generate one.');
      }
  } catch (error) {
      if (error.code === 'messaging/token-unsubscribe-failed') {
          console.error('Token unsubscribe failed: Requested entity was not found.', error);
      } else {
          console.error('Error unsubscribing token:', error);
      }
  }
}

// Call the function when needed, e.g., during user logout
// unsubscribeUser();

requestPermissionAndGetToken();


async function subscribeToTopic(token) { 
    try {
        // const token = await messaging.getToken();
        const response = await fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/global  `, {
            method: 'POST',
            headers: new Headers({
                'Authorization': `key=AAAA1cuR3e4:APA91bHHBUjCbZWxUccJkrodFjX8100Bpe31uoIE81cOy6EhfWfF5NFFXxkfkp81In2jPukA_NzsjdoLwOOPlBXZdHEGFPOIK4Vvu4J8PxBHpiG9T5gwd6Iegf59VheWcpEDatAg9n1Q`,
                'Content-Type': 'application/json'
            })
        });
        if (response.status < 200 || response.status >= 400) {
            throw `Error subscribing to topic: ${response.status} - ${response.text()}`;
        }
        // console.log(`Subscribed to "your-topic-name"`);
    } catch (error) {
        console.error(error);
    }
}




// Handle incoming messages while the app is in the foreground
onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    showNotification(payload);
    // Customize your notification handling here
});



const showNotification = (payload) => {

  addNotification({
    title: payload.notification.title,
    subtitle: '',
    message: payload.notification.body,
    theme: 'darkblue',
  //  native: true // when using native, your OS will handle theming.
  });

    const { title, body } = payload.notification;
    const notificationOptions = {
        body,
        icon: 'https://thahtaylay.com/img/logo_small.png'
    };
    new Notification(title, notificationOptions);
};



const store = createStore(rootReducer)

ReactDOM.render(
     
        <BrowserRouter >
          <Provider store={store} >
              <App />
            </Provider> 
        </BrowserRouter>

    
    
    , document.getElementById('root')
);

// <Provider store={store} >
//             <App />
//           </Provider> 

// Loadable.preloadReady().then(() => {
//     ReactDOM.hydrate(
//         <MemoryRouter>
//             <BrowserRouter forceRefresh="false">
//                 <App />
//             </BrowserRouter>
//         </MemoryRouter>
//         , document.getElementById('root')
//     );    
// });


registerServiceWorker();
// initializeFirebase();
