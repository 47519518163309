import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
  import { Grid } from 'antd-mobile';
  import AppHeader from './../AppHeader';

var moment = require('moment');
//var moment = require('moment');

class WinnerTopTentwoDListPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            game_type : 1,
            step: "login",
            message : "",
            data : [
                {
                      first_name : "",
                      phone : "0923232333333",
                      win_amount : 0,
                      product_type : 1,
                }
            ],
            game_contest:{"id":48,"gcontest_id":38,"gcontest_name":" ","gcontest_type":1,"gcontest_set":"1,619.54","gcontest_value":"16,816.64","lottery_number":"46","num_prize":1,"payment_status":3,"commission_status":1,"schedule_at":"2022-11-25 11:00:00","start_date":"2022-11-24 17:00:00","expire_date":"2022-11-25 10:55:00","date_group":"2022-11-25","payment_second_chk":2,"commission_second_chk":1,"created_at":"2022-11-24T10:30:02.000000Z","updated_at":"2022-11-25T04:49:01.000000Z","deleted_at":null},
            setting:[{"option_id":8,"option_name":"minimum_amount","option_value":"10","autoload":"yes"},{"option_id":9,"option_name":"twod_multiple","option_value":"70","autoload":"yes"},{"option_id":10,"option_name":"threed_multiple","option_value":"300","autoload":"yes"},{"option_id":11,"option_name":"twod_commission","option_value":"5","autoload":"yes"},{"option_id":12,"option_name":"twod_maximum_amount","option_value":"5000","autoload":"yes"},{"option_id":13,"option_name":"threed_maximum_amount","option_value":"5000","autoload":"yes"}]
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.currencyFormat = this.currencyFormat.bind(this);
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

    currencyFormat(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    getAnnouncementsApi() {

        let dataurl = window.BPdata.apiUrlCMS+'/winner-top-ten-list?type='+this.state.game_type;
        let data = this.state.data;
        var self = this;
        // axios({
        //     method: 'get',
        //     url: dataurl,
        //     data: data,
        //     headers: {
        //       "Content-Type": "application/json; charset=utf-8",
        //         "X-Trident-Token" : window.BPdata.token,
        //         "BP-Agent"        : window.BPdata.rand_compound,
        //         "BP-Time"         : localStorage.getItem(window.BPdata.time)
        //       // "Content-Type": "application/x-www-form-urlencoded",
        //   },
        // })
        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(function (response) {
            if(response.data.data) {

                // console.log(response.data.data)

                if(response.data.data.data){

                    self.setState({
                        data : response.data.data.data,
                        setting : response.data.setting,
                        game_contest : response.data.game_contest,
                    });

                    // console.log('--------')
                    //
                    //
                    // console.log(response.data.game_contest)

                }

                // localStorage.setItem("noti", JSON.stringify(response.data.data) )
                //self.setState({  message : "Successfully updated" });
                //console.log(response.data.data)
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            //console.log(error);
        });

          // console.log(this.state.game_contest)


    }



    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'radio' ? target.checked : target.value;
        let rev_value;
        if(value) {
            value     = 1;
            rev_value = 0;
        } else {
            value     = 0;
            rev_value = 1;
        }
        const name = target.name;
        let str = this.state.contests;
        // console.log(name)
        // console.log(name.split("-").length)

        if(name.split("-").length>0) {
            let word  = name.split("-");
            let first = word[0];
            let id    = word[1];
            console.log(first)
            if(first === "per_result1") {
                str[id].per_result1 = value;
                str[id].per_result2 = rev_value;
            }

            if(first === "per_result2") {
                str[id].per_result2 = value;
                str[id].per_result1 = rev_value;
            }

            if(first === "over") {
                str[id].over = value;
                str[id].under = rev_value;
            }

            if(first === "under") {
                str[id].under = value;
                str[id].over = rev_value;
            }

        }



        this.setState({contests:str});
        //console.log(name)
        // this.setState({
        //   [name]: value
        // });
        console.log(this.state.contests)
    }

    displayBlock( ) {
        let data = this.state.data
        let ui = []
        let number = 0 ;

        if(data.length  > 0){

            // data.forEach(function(each){

            // if(data.length === 0) {
            //     ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
            //     return ui
            // }

            for (let i = 0; i < this.state.data.length; i++) {
                let each = data[i]
                console.log(data[i].phone );
                // console.log(data[i].id)
                ui.push(<div style={{ display: '-webkit-box', display: 'flex' }} className="box-paragraph">

                <div className="row p-4" style={{ lineHeight: 1 }}>
                

                            <div className=" grid-100 flex winner-lists">
                                {/* <div className='grid mhide' style={{paddingTop:"15px"}}>
                                   
                                </div> */}
                                    <div className="grid-10">
                                        <img src={"/img/avator/"+each.avator} style={{ height : '50px' }}/>
                                        <span style={{height : '30px',width : '30px',background:"#fff",color : "#000", borderRadius:"10px",lineHeight: "30px",marginTop: "-10px",position: "relative",display:"block",textAlign:"center"}}>{(number+=1) }</span>
                                    </div>
                                    <div className="grid-30">
                                        {each.first_name }
                                        <br /><br />
                                        {each.phone }
                                    </div>
                                    <div className="grid-20">
                                        {each.bet_amount } 
                                    </div>
                                    <div className="grid-20 color-theme-gold">
                                        { (each.product_type == 1) ? each.win_amount  : each.win_amount } 
                                    </div>

                            </div>
                    </div>
                  </div>);

                  // return ui

           }


        }

        return ui
    }

    componentDidMount() {

        window.scrollTo(0, 0);
        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "profile" });
            }

          }

        if(localStorage.getItem('noti')) {
            if(JSON.parse(localStorage.getItem("noti")) != null) {
              let noti = JSON.parse(localStorage.getItem("noti"));
              this.setState({data: noti})

            }
        }
        // if(localStorage.getItem('username')) {
        //         this.setState({
        //             form: {
        //                 username : localStorage.getItem('username')
        //             }
        //         });
        // }

        // localStorage.setItem(window.BPdata.time, 0);
        // localStorage.setItem('username', "");
        // else {
        //   localStorage.setItem(window.BPdata.time, 'profile');
        //   this.setState({ step: "home" });
        // }
        this.hisUrl('/more/announcements');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
        this.getAnnouncementsApi();
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">
                <div className="page-content">
                <AppHeader backLink="/two-game" titleType="3" title={window.lang.title.twod} isLoading="false" />

                    <div className="main-content text-center">

                        <div className="row" style={{ display: '-webkit-box', display: 'flex', padding: '5px' }}>
                          <div className="grid-80 left text-left ml-20">
                          {window.lang.BetListDetail.start} <br />
                              <span className="color-theme-gold">{moment(this.state.game_contest.schedule_at, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY hh:mm A')}
                              </span>
                          </div>
                          <div className="grid-20 left text-center win-number">
                            {window.lang.BetListDetail.number} <br />
                              <span className="color-theme-gold"  style={{ fontSize: "24px" }}>{this.state.game_contest.lottery_number}</span>
                          </div>
                        </div>
                        <div className="row text-left">
                            <br />
                            <br/>
                            <div className="row p-4" style={{ lineHeight: 1, display: 'flex' }}>
                                <div className="grid-100 mb-10 text-center flex  winning-title">
                                        <div className="grid-10 left">
                                            &nbsp;
                                        </div>
                                        <div className="grid-30 left">
                                            &nbsp;
                                        </div>
                                        <div className="grid-25 left text-center">
                                            { window.lang.betgroup.bet_amount }
                                        </div>
                                        <div className="grid-25 left text-center">
                                            { window.lang.betgroup.win }
                                        </div>

                                </div>
                            </div>
                            {this.displayBlock()}
                        </div>
                      {/*  <div className="ant-back-footer">
                            <div class="ant-back-top">
                                <div class="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null)
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>

            </div>
        );
    }
}

export default WinnerTopTentwoDListPage;
