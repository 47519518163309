// import React from 'react';
import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';

  import { Grid } from 'antd-mobile';
  import AppHeader from './../AppHeader';

const styles = {
    // bgColor: 'white',
    titleTextColor: "#e2e2e2",
    rowTitleColor: "#00008B",
    rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    animate: true,
    arrowIcon: "V",
    tabFocus: true
};


class FAQPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "home";
        this.state = {
        step: "login", message : "", form : {username: '', phone: '',verify_code: '', password: '' },
        title: "",
        rows: [
            {
                title: "Lorem ipsum dolor sit amet,",
                content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat,
                  ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus.
                  In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae.
                  Fusce sed commodo purus, at tempus turpis.`,
            },
            {
                title: "What is the package version",
                content: <p>current version is 2.0.1</p>,
            },
        ]
      };

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.getHelpCenterApi();
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    getHelpCenterApi() {
        var self = this;
        let dataurl = window.BPdata.apiUrlCMS+'/help-center';
        let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (response) {
            if(response.data.data) {
                self.setState({
                    rows : response.data.data,
                });

            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            //console.log(error);
        });
    }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }


    render() {
        return (
            <div className="page">
                <div className="page-content">
                <AppHeader backLink="/faq" titleType="2" title={window.lang.more.faq} isLoading="false" />

                    <div className="main-content page-contest1">
                        <div className="row">

                            <br />
                            <h3 className="color-theme-gold mr-20 ml-20" style={{ fontSize: "24px" , wordSpacing:"20px"}}>{window.lang.more.faq_title}</h3>
                            <br />
                            <p className="ml-20">
                          
                                {window.lang.more.faq_ans}
                            </p>
                        </div>
                      <br />

                      <div className="faq-style-wrapper">
                         <Faq
                            data={this.state}
                            styles={styles}
                            config={config}
                        />
                      </div>
                    <br />
                    </div>
                </div>

                <footer  className="App-footer">

                    <div className="App-footer-inner">
                      {/* {window.BPdata.footerMenu} */}
                      <Grid data={window.lang.footer}
                          columnNum={5}
                          renderItem={dataItem => (

                            <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                  { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                            </Link>
                          )}
                        />

                    </div>
                  </footer>
            </div>
        );
    }
}

export default FAQPage;
