/* eslint no-dupe-keys: 0 */
import React from 'react';
import Swiper from 'swiper/dist/js/swiper.min.js';

import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { ListView, Grid } from 'antd-mobile';


import ApzHeader from './../../ApzHeader';

// import ScrollMenu from 'react-horizontal-scrolling-menu';

import swal from 'sweetalert';
//import { FacebookProvider, CommentsCount } from 'react-facebook';
//import { askForPermissioToReceiveNotifications } from './../push-notification';
require('./../../../../BPdata.js')

const alert = Modal.alert;

var moment = require('moment');


let data = [
  {
    news_image: 'https://zos.alipayobjects.com/rmsportal/dKbkpPXKfvZzWCM.png',
    news_name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
    news_desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
  }
];


let NUM_ROWS = 3;
//let MAX_LENGTH = 90;
// One item component
// selected prop will be passed
const MenuItem = ({ activeMenu, data, text, selected }) => {
  return (

    <div
      className="menu-item"
    >
      {data.id === 0 ? (
        <Link to={`${data.url}`} className={(activeMenu === text) ? ' cont-wrap-link' : 'cont-wrap-link'} >{(window.BPdata.lang === 'mm') ? data.mmshortname : data.shortname}</Link>
      ) : (
        <Link to={`/news/league/${data.id}/${data.url}`} className={(activeMenu === text) ? ' cont-wrap-link' : 'cont-wrap-link'} >{(window.BPdata.lang === 'mm') ? data.mmshortname : data.shortname}</Link>
      )}
      {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}

      {/* <span>{text}</span> */}

      {/* </Link> */}
    </div>
  );
};


// All items component
// Important! add unique key
export const Menu = (list, selected, activeMenu) => list.map(el => {
  const { name, shortname } = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={activeMenu}
      text={shortname}
      key={name}
      data={el}
    />
  );
});


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });


const closeIcon = (
  <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
    <path
      fillRule="evenodd"
      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
      clipRule="evenodd"
    ></path>
  </svg>
);


let checklogin = () => {
  if (localStorage.getItem(window.BPdata.time)) {
    if (localStorage.getItem(window.BPdata.time) !== "0") {
      return true;
    }
  }
  return false;
}

class Apz3DDetailPage extends React.Component {
  constructor(props) {
    super(props);
    window.BPdata.footerMenu = "home";

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      login: 0,
      is_verified: 1,
      selected: 0,
      lobbyid: 0,
      dataSource,
      isLoading: true,
      // virtual data
      videoSlides: ['slide1.jpg', 'slide1.jpg', 'slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg', 'slide1.jpg', 'slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'NEWS',
      open: false,
      fopen: false,
      advopen: false,
      menu: window.BPdata.mainmenu,
      wallet: [],
      seconds: 5,
      isCounting: false,
      post_id: 0,
    };

    if(props.match.params.id) {
      this.state.post_id = props.match.params.id
    }


  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  onOpenModal = () => {
    this.setState({ open: true, fopen: true, advopen: true });
  };

  onCloseModal = () => {
    this.setState({ open: false, fopen: false, advopen: false });


    //localStorage.setItem('popup', false);
    // window.BPdata.popup = false
    // console.log(window.BPdata.popup)
  };

  close = () => {
    this.startCountdown();
    setTimeout(() => this.onCloseModal(), 5000)
  };

  startCountdown = () => {
    this.setState({ isCounting: true });
    const intervalId = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.seconds === 0) {
          clearInterval(intervalId);
          this.setState({ isCounting: false });
          return { seconds: 5 }
        }
        return { seconds: prevState.seconds - 1 }
      });
    }, 1000);
  }

  currencyFormat(num) {
    return num;
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  componentDidMount() {

    window.scrollTo(0, 0);

    // this.setState({ fopen: true });

    let menu = ["/"];

    localStorage.setItem("route", JSON.stringify(menu));
    //console.log(localStorage.getItem('route'));
    //console.log(window.BPdata.apiUrl)
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);



    const self = this;


    new Swiper('.swiper-video-container', {
      // ...
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      virtual: {
        slides: self.state.videoSlides,
        renderExternal(videoData) {
          // assign virtual slides data
          self.setState({
            virtualVideoData: videoData,
          });
        }
      },
      pagination: {
        el: '.swiper-vieo-pagination',
      },
    });




    // this.setState({ fopen: true });

    let checkday = 0;

    var d = new Date();
    var todaynum = d.getDay();
    var todayhr = d.getHours();

    if (localStorage.getItem('popup')) {
      if (localStorage.getItem('popup') != todaynum) {
        localStorage.setItem('popup', todaynum);
        this.setState({ open: true });

      } else {
        checkday = 1;
      }

    } else {
      localStorage.setItem('popup', todaynum);
      this.setState({ open: true });
    }


    // this.setState({ fopen: true });

   


    // this.setState({ open: true });

    // this.setState({ open: true });

    // if(localStorage.getItem('wallet')) {

    //   if(JSON.parse(localStorage.getItem("wallet")) != null) {
    //     let wallet = JSON.parse(localStorage.getItem("wallet"));
    //     this.setState({wallet: wallet})
    //   }
    // }

    //console.log(localStorage.getItem('popup'));
    //this.fetchIndexUserhData();
    clearTimeout(window.BPdata.liveurl);

    if (localStorage.getItem(window.BPdata.time)) {
      if (localStorage.getItem(window.BPdata.time) !== "0") {
        this.setState({ login: 1 })

        this.fetchIndexUserhData(window.BPdata.apiUrlCMS + '/cron');

      }
    } else {
      this.setState({ login: 0 })
    }


    this.fetchData();




    // if (navigator.userAgent.toLowerCase().indexOf("thahtaylay") > -1 ) {
    //   console.log("other")
    // } else {
    //     if(localStorage.getItem('androidpopup')) {
    //       if(localStorage.getItem('androidpopup') === 'reset') {
    //         localStorage.setItem('androidpopup', true);
    //         this.setState({ open: true });
    //       }

    //     } else {
    //       localStorage.setItem('androidpopup', true);
    //       this.setState({ open: true });
    //     }

    // }
  }

  childui(item) {

    let self = this;
    let ui = []
        //console.log("-------------"+data)

        if(Array.isArray(item) === false) {
            return ui;
        } else {

            let arlength = item.length;

            if(arlength  > 0){

                this.state.data.order_items.forEach(function(each,key){


                    ui.push(<tr key={"ro-"+each.id}>
                        <td  className={"text-center pointer " + (each.informed ? 'number-active' : '')}  >
                            { each.name }
                        </td>
                        {/* <td colSpan="1" className="text-right">

                            97

                        </td> */}
                        <td colSpan="1" className="text-right">

                            <small>{ (each.payment_status < 1) ? "-" : ( (each.win_amount == 0) ? "" : each.win_amount ) }</small>

                        </td>
                        <td colSpan="1" className="text-right">

                            { each.total }

                        </td>

                    </tr>)

                })

                return ui
            }
    }

    return ui;
}

  walletBlock() {

    let childui = [];

    if (this.state.login == 1) {
      if (this.state.wallet[0]) {
        childui.push(<div className="row game-wrap mt-60 mb-100">
          <br />
          <div class="game-content">
            <div class="grid-50">
              <Link to="#">
                <img src="/img/apz/apz-2d-logo.png" className="round-img1" alt="profile" />
              </Link>
            </div>
            <div class="grid-50">
              <Link to="#">
                <img src="/img/apz/apz-3d-logo.png" className="round-img1" alt="profile" />
              </Link>
            </div>
          </div>
        </div>)
      } else {
        if (this.state.is_verified == 0) {
          childui.push(<div className="row pb-10">
            <br />
            <div className="adm-card row mt-10">
              <div className="adm-card-body grid-100">
                <div className="row">
                  <div className="grid-100 left text-center">
                    You have been banned
                  </div>
                  <br />
                  <hr />
                </div>
              </div>
            </div>
          </div>)
        } else {
          //Please Login
          childui.push("")
        }

      }
    }
    return childui;
  }

  fetchIndexUserhData(url) {

    let dataurl = url;



    axios.get(dataurl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: "same-origin", // include, *same-origin, omit
      crossDomain: true,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Trident-Token": window.BPdata.token,
        "BP-Agent": window.BPdata.rand_compound,
        "BP-Time": localStorage.getItem(window.BPdata.time)
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(resp => {
        // console.log(resp.data)

        // {"thaidate":"29-11-21 13:11",
        // "set":"1,600.24",
        // "value":"64,409.60M",
        // "created_at":"2021-11-29 12:41:10",
        // "number":"49"}
        // this.setState({
        //     status_code : resp.data.status_code,
        //
        // });



        if (resp.data.status_code == "404") {

          localStorage.setItem(window.BPdata.time, 0);
          localStorage.setItem('username', "");

          swal({
            title: "Session Expire",
            text: window.lang.errors.contact_cs,
            icon: "warning",
            dangerMode: true,
          })
            .then(willDelete => {
              if (willDelete) {
                localStorage.setItem(window.BPdata.time, 0);
                localStorage.setItem('username', "");
                this.props.history.push("/more/login")
              }
            });

          // swal( window.lang.errors.contact_cs );
          // console.log(resp.data.status_code)



        }


        //this.setState({ isLoading: false });

      })
      .catch(function (error) {
        //console.log(error.tostring());
      });

    if (localStorage.getItem(window.BPdata.time)) {
      if (localStorage.getItem(window.BPdata.time) !== "0") {

        window.BPdata.liveurl = setTimeout(() => this.fetchIndexUserhData(url), 10000);

      }
    }



  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }

  fetchData() {



        let dataurl = window.BPdata.apiAPZUrl+'/service/bet-list/detail/'+this.state.post_id;
        // let dataurl = window.BPdata.apiUrl+'/news/detail/'+this.state.post_id;

        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"         : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {

            this.setState({
                data: resp.data.data,
                setting : resp.data.setting,
                isLoading: false,
                lottery : resp.data.lottery,
                lobbyid : resp.data.data.contest.gcontest_id,
            });

        })
        .catch(function(error) {
          //console.log(error);
          //console.log(error.tostring());
        });

  }



  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  displayBlock() {
      let ui = []
      // console.log("liked--------"+this.state.liked)
      if(this.state.data  != null){
          ui.push(
              <div className="main-content" key="1">
                  <div className="row content-box">
                    <div className="result-detail-content">
                      <p className="bet-st-time"><span className='text-result-detail'>{window.lang.BetListDetail.start} - </span> {moment.utc(this.state.data.created_at).local().format('DD MMM YYYY, hh:mm A') }</p>
                      <div className="inner-column">
                        <div className="inner-result"><span className='text-result-detail'>{window.lang.BetListDetail.number} - </span>
                      {this.state.data.contest.lottery_number ?  this.state.data.contest.lottery_number.toString().slice(-2) : "" }
                        </div>
                        <div className="inner-result"><span className='text-result-detail'>{window.lang.apz.contest_status} - </span>
                        { (this.state.data.contest.payment_status  < 3) ? window.lang.apz.process : "" }{ (this.state.data.contest.payment_status  == 3) ? window.lang.apz.finished : "" }{ (Number(this.state.data.contest.payment_status)  > 3) ? window.lang.apz.canceled : "" }
                        </div>
                      </div>
                      <div className="inner-column inner-second">
                        <div className="inner-result"><span className='text-result-detail'>{window.lang.BetListDetail.odds} - </span>
                      {this.state.data.contest_odd}
                        </div>
                        
                        <div className="inner-result"><span className='text-result-detail'>{window.lang.apz.slip_status} - </span>
                        { (this.state.data.payment_status  < 1) ? window.lang.apz.process : "" }{ (this.state.data.payment_status  == 1) ? window.lang.apz.finished : "" }{ (Number(this.state.data.payment_status)  > 2) ? window.lang.apz.canceled : "" }
                        </div>
                      </div>
                      <p className="bet-st-time"><span className='text-result-detail'>{window.lang.apz.result} - </span> {this.state.data.contest.rule_regulation}</p>
                    </div>

                    <div className="table-wrap">
                      {/* <table className="table  table-border" > */}
                      <table className="table-detail">
                          <thead>
                              <tr>
                                  <th className="text-center">2D</th>
                                  {/* <th className="text-right">Odds</th> */}
                                  <th className="text-right">{ window.lang.betgroup.win }</th>
                                    <th className="text-right">{ window.lang.betgroup.bet_amount }</th>
                              </tr>
                          </thead>
                          <tbody>

                              {this.childui(this.state.data.order_items)}

                              {/* <tr>
                                  <td colSpan="3" className="text-right">Commission Total (?)</td>
                                  <td className="text-right">{this.state.data.commission}</td>
                              </tr> */}

                              <tr>
                                  <td colSpan="2" className="text-right">{ window.lang.betgroup.total }</td>
                                  <td className="text-right">{this.state.data.grand_total}</td>
                                  {/* <td></td> */}
                              </tr>

                          </tbody>
                      </table>
                    </div>
                  </div>
              </div>
          )
      }

      if(ui.length === 0) {
          ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
    }

      return ui
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    const menu = Menu(this.state.menu, selected, leagueMenu);

    return (
      <div className="page">


        <div className="page-content apz-main-bg" id="homeicon">
          <ApzHeader backLink="/aungparzay/results" wallet={this.state.wallet} titleType="3" title={window.lang.title.bet_list}  isLoading="false" />


          {/* <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={this.onSelect}
              alignCenter={false}
              wheel={true}
              inertiaScrolling={true}
              // scrollToSelected={true}
            />

            {/* <button onClick={askForPermissioToReceiveNotifications} >
                  Click here to receive notifications
            </button> */}

          <div className="main-content ">

            <div className="row">
              <div className="grid-100 detail-header flex mt-30">
                <h2 className="title-detail-no">No-{this.state.lobbyid}</h2>
                <h2 className="title-detail-time">{window.lang.apz.close_time} { (this.state.data) ? moment(this.state.data.contest.schedule_at, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A') : "" }</h2>
              </div>
              { (this.state.isLoading) ?  (<div className="row text-center"><br /><img src="/img/loading.gif" alt="loading" height="50px"  /> </div> ) : this.displayBlock() }

              {/* <div class="table-wrap">
                <table class="table-detail">
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Number</th>
                            <th>Odds</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                        <tr>
                            <td>09 : 30</td>
                            <td>383</td>
                            <td>700</td>
                            <td>10 : 30</td>
                        </tr>
                        <tr>
                            <td>10 : 30</td>
                            <td>750</td>
                            <td>700</td>
                            <td>30000</td>
                        </tr>
                        <tr>
                            <td>10 : 45</td>
                            <td>984</td>
                            <td>700</td>
                            <td>2578</td>
                        </tr>
                        <tr>
                            <td>11 : 00</td>
                            <td>555</td>
                            <td>700</td>
                            <td>95643</td>
                        </tr>
                </table>
              </div> */}
            </div>


            {
              ((navigator.userAgent.toLowerCase().indexOf("thahtaylay") > -1) ? "" : <Modal open={this.state.fopen}
                onClose={this.onCloseModal}
                right
                classNames={{
                  overlay: 'customOverlay',
                  modal: 'fullimgcustomModal',
                }}
              >

                <div className='' style={{ width: "100%" }} >


                  <img src="/img/ads/atpbanner-mobile.jpg" className='splashscreen' />

                </div>

                <button onClick={this.onCloseModal} style={{ color: "#000", border: "1px solid #000", padding: "5px", paddingLeft: "10px", paddingRight: "10px", borderRadius: "15px", background: "#f2eaea", opacity: "0.6" }} >
                  {this.state.isCounting && <span style={{ color: "#000" }}>ကျော်မည် {this.state.seconds}</span>}
                </button>


              </Modal>)
            }



            {this.walletBlock()}


            {/* <HomeIconList></HomeIconList> */}

          </div>


          {/* <HighlightsVideoSlider></HighlightsVideoSlider> */}



        </div>

        <footer className="App-footer">

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}
            <Grid data={window.lang.apzfooter}
              columnNum={3}
              renderItem={dataItem => (

                <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{ width: '100%' }}>
                  {(window.BPdata.footerMenu === dataItem.check) ? (dataItem.text == "My Profile") ? <span className="color-white"><img src={dataItem.icon} height="35px" /><br />{(this.checklogin()) ? dataItem.text : "Login / Register"}</span> : <span className="text-white"><img src={dataItem.icon_off} height="35px" /><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon} height="35px" /><br />{dataItem.text} </span>}

                </Link>
              )}
            />

          </div>
        </footer>

      </div>
    );
  }
}

export default Apz3DDetailPage;
