window.avator = {
    "msg": "",
    "code": 0,
    "data": [
        {
            "id": "1",
            "name": "Profile 1",
            "profile_photo": "profile_1.png",
            "line_desc": ""
        },
        {
            "id": "2",
            "name": "Profile 2",
            "profile_photo": "profile_2.png",
            "line_desc": ""
        },
        {
            "id": "3",
            "name": "Profile 3",
            "profile_photo": "profile_3.png",
            "line_desc": ""
        },
        {
            "id": "4",
            "name": "Profile 4",
            "profile_photo": "profile_4.png",
            "line_desc": ""
        },
        {
            "id": "5",
            "name": "Profile 5",
            "profile_photo": "profile_5.png",
            "line_desc": ""
        },
        {
            "id": "6",
            "name": "Profile 6",
            "profile_photo": "profile_6.png",
            "line_desc": ""
        },
        {
            "id": "7",
            "name": "Profile 7",
            "profile_photo": "profile_7.png",
            "line_desc": ""
        },
        {
            "id": "8",
            "name": "Profile 8",
            "profile_photo": "profile_8.png",
            "line_desc": ""
        },
        {
            "id": "9",
            "name": "Profile 9",
            "profile_photo": "profile_9.png",
            "line_desc": ""
        },
        {
            "id": "10",
            "name": "Profile 10",
            "profile_photo": "profile_10.png",
            "line_desc": ""
        },
        {
            "id": "11",
            "name": "Profile 11",
            "profile_photo": "profile_11.png",
            "line_desc": ""
        },
        {
            "id": "12",
            "name": "Profile 12",
            "profile_photo": "profile_12.png",
            "line_desc": ""
        },
        {
            "id": "13",
            "name": "Profile 13",
            "profile_photo": "profile_13.png",
            "line_desc": ""
        },
        {
            "id": "14",
            "name": "Profile 14",
            "profile_photo": "profile_14.png",
            "line_desc": ""
        },
        {
            "id": "15",
            "name": "Profile 15",
            "profile_photo": "profile_15.png",
            "line_desc": ""
        },
        {
            "id": "16",
            "name": "Profile 16",
            "profile_photo": "profile_16.png",
            "line_desc": ""
        },
        {
            "id": "17",
            "name": "Profile 17",
            "profile_photo": "profile_7.png",
            "line_desc": ""
        },
        {
            "id": "18",
            "name": "Profile 18",
            "profile_photo": "profile_18.png",
            "line_desc": ""
        },
        {
            "id": "19",
            "name": "Profile 19",
            "profile_photo": "profile_19.png",
            "line_desc": ""
        },
        {
            "id": "20",
            "name": "Profile 20",
            "profile_photo": "profile_20.png",
            "line_desc": ""
        },
        {
            "id": "21",
            "name": "Profile 21",
            "profile_photo": "profile_21.png",
            "line_desc": ""
        },
        {
            "id": "22",
            "name": "Profile 22",
            "profile_photo": "profile_22.png",
            "line_desc": ""
        },
        {
            "id": "23",
            "name": "Profile 23",
            "profile_photo": "profile_23.png",
            "line_desc": ""
        },
        {
            "id": "24",
            "name": "Profile 24",
            "profile_photo": "profile_24.png",
            "line_desc": ""
        },
        {
            "id": "25",
            "name": "Profile 25",
            "profile_photo": "profile_25.png",
            "line_desc": ""
        },
        {
            "id": "26",
            "name": "Profile 26",
            "profile_photo": "profile_26.png",
            "line_desc": ""
        },
        {
            "id": "27",
            "name": "Profile 27",
            "profile_photo": "profile_27.png",
            "line_desc": ""
        },
        {
            "id": "28",
            "name": "Profile 28",
            "profile_photo": "profile_28.png",
            "line_desc": ""
        },
        {
            "id": "29",
            "name": "Profile 29",
            "profile_photo": "profile_29.png",
            "line_desc": ""
        },
        {
            "id": "30",
            "name": "Profile 30",
            "profile_photo": "profile_30.png",
            "line_desc": ""
        },
    ]
};
