/* eslint no-dupe-keys: 0 */
import React from 'react';
import Swiper from 'swiper/dist/js/swiper.min.js';

import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { ListView, Grid } from 'antd-mobile';


import ApzHeader from './../../ApzHeader';

// import ScrollMenu from 'react-horizontal-scrolling-menu';

import swal from 'sweetalert';
//import { FacebookProvider, CommentsCount } from 'react-facebook';
//import { askForPermissioToReceiveNotifications } from './../push-notification';
require('./../../../../BPdata.js')

const alert = Modal.alert;

//var moment = require('moment');


let data = [
  {
    news_image: 'https://zos.alipayobjects.com/rmsportal/dKbkpPXKfvZzWCM.png',
    news_name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
    news_desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
  }
];


let NUM_ROWS = 3;
//let MAX_LENGTH = 90;
// One item component
// selected prop will be passed
const MenuItem = ({ activeMenu, data, text, selected }) => {
  return (

      <div
      className="menu-item"
      >
        {data.id === 0 ? (
          <Link to={`${data.url}`} className={(activeMenu === text) ? ' cont-wrap-link' : 'cont-wrap-link' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        ) : (
          <Link to={`/news/league/${data.id}/${data.url}`} className={(activeMenu === text) ? ' cont-wrap-link' : 'cont-wrap-link' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        )}
        {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}

          {/* <span>{text}</span> */}

        {/* </Link> */}
      </div>
  );
};


// All items component
// Important! add unique key
export const Menu = (list,selected,activeMenu) => list.map(el => {
  const { name,shortname } = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={activeMenu}
      text={shortname}
      key={name}
      data={el}
    />
  );
});


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });


const closeIcon = (
  <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
    <path
      fillRule="evenodd"
      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
      clipRule="evenodd"
    ></path>
  </svg>
);


let checklogin = () => {
      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              return true;
          }
      }
      return false;
  }

class HomeLottoPage extends React.Component {
  constructor(props) {
    super(props);
    window.BPdata.footerMenu = "home";

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      login : 0,
      is_verified : 1,
      selected: 0,
      dataSource,
      isLoading: true,
      // virtual data
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'NEWS',
      open: false,
      fopen: false,
      advopen : false,
      menu : window.BPdata.mainmenu,
      wallet : [], 
      seconds: 5, 
      isCounting: false,
      game_contest: []
    };



  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  onOpenModal = () => {
    this.setState({ open: true, fopen: true,  advopen : true });
  };

  onCloseModal = () => {
    this.setState({ open: false , fopen: false, advopen : false });


    //localStorage.setItem('popup', false);
    // window.BPdata.popup = false
    // console.log(window.BPdata.popup)
  };

  close = () => {
    this.startCountdown();
    setTimeout(() => this.onCloseModal(), 5000  )
  };

  startCountdown = () => {
      this.setState({ isCounting: true });
      const intervalId = setInterval(() => {
          this.setState((prevState) => {
              if (prevState.seconds === 0) {
                  clearInterval(intervalId);
                  this.setState({ isCounting: false });
                  return { seconds: 5 }
              }
              return { seconds: prevState.seconds - 1 }
          });
      }, 1000);
  }

  currencyFormat(num) {
      return num;
      return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  componentDidMount() {

    window.scrollTo(0, 0);

    // this.setState({ fopen: true });

    let menu = ["/"];

    localStorage.setItem("route", JSON.stringify(menu) );
    //console.log(localStorage.getItem('route'));
    //console.log(window.BPdata.apiUrl)
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);



    const self = this;


      // this.setState({ open: true });

      // this.setState({ open: true });

      // if(localStorage.getItem('wallet')) {

      //   if(JSON.parse(localStorage.getItem("wallet")) != null) {
      //     let wallet = JSON.parse(localStorage.getItem("wallet"));
      //     this.setState({wallet: wallet})
      //   }
      // }

      //console.log(localStorage.getItem('popup'));
      //this.fetchIndexUserhData();
      clearTimeout(window.BPdata.liveurl);

      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({login: 1})

              this.fetchIndexUserhData(window.BPdata.apiUrlCMS+'/cron');

          }
      } else {
        this.setState({login: 0})
      }


      this.fetchLobbyData();
      this.fetchUserData();


  }

  walletBlock1() {

    let childui = [];

      if(this.state.login == 1) {
          if(this.state.wallet[0]) {
            childui.push(<div className="row">
                <br />
              <div class="game-content lotto-wrapper mb-100">
                <div class= "grid-50 lotto-first ml-10">
                  <p class="lotto-g-no">No-128</p>
                  <div className="timer-wrap">
                    <span class="timer">Close Time <span class="closetime-text ml-10">09 : 15</span></span>
                    <h2>00 : 05 : 20</h2>
                    <div className="btn-text btn-section">
                      {(checklogin()) ?  <Link to="/shoot" className="btn-lotto"><img src="/img/apz/play-btn-img.png" className="img-playnow" width="100%" height="50" alt="profile" /></Link> : "" }
                    </div>
                  </div>
                </div>
                <div class= "grid-50 lotto-first mr-10">
                  <p class="lotto-g-no">No-128</p>
                  <div className="timer-wrap">
                    <span class="timer">Close Time <span class="closetime-text ml-10">09 : 15</span></span>
                    <h2>00 : 05 : 20</h2>
                    <div className="btn-text btn-section">
                      {(checklogin()) ?  <Link to="/shoot" className="btn-lotto"><img src="/img/apz/play-btn-img.png" className="img-playnow" width="100%" height="50" alt="profile" /></Link> : "" }
                    </div>
                  </div>
                </div>
              </div>

              
            </div>)
          } else {
            if(this.state.is_verified == 0) {
              childui.push(<div className="row pb-10">
                <br />
                 <div className="adm-card row mt-10">
                        <div className="adm-card-body grid-100">
                            <div className="row">
                                <div className="grid-100 left text-center">
                                    You have been banned
                                </div>
                                <br />
                                <hr />
                            </div>
                        </div>
                </div>
            </div>)
            } else {
              //Please Login
              childui.push("")
            }

          }
      }
      return childui;
  }

  fetchIndexUserhData(url) {

      let dataurl = url;



      axios.get(dataurl, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: "same-origin", // include, *same-origin, omit
        crossDomain: true,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "X-Trident-Token" : window.BPdata.token,
            "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"         : localStorage.getItem(window.BPdata.time)
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer", // no-referrer, *client
        //body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      .then(resp => {
          // console.log(resp.data)

          // {"thaidate":"29-11-21 13:11",
          // "set":"1,600.24",
          // "value":"64,409.60M",
          // "created_at":"2021-11-29 12:41:10",
          // "number":"49"}
          // this.setState({
          //     status_code : resp.data.status_code,
          //
          // });



          if(resp.data.status_code == "404") {

            localStorage.setItem(window.BPdata.time, 0);
            localStorage.setItem('username', "");

            swal({
              title: "Session Expire",
              text: window.lang.errors.contact_cs,
              icon: "warning",
              dangerMode: true,
            })
            .then(willDelete => {
            if (willDelete) {
                localStorage.setItem(window.BPdata.time, 0);
                localStorage.setItem('username', "");
                this.props.history.push("/more/login")
            }
            });

              // swal( window.lang.errors.contact_cs );
              // console.log(resp.data.status_code)



          }


          //this.setState({ isLoading: false });

      })
      .catch(function(error) {
        //console.log(error.tostring());
      });

      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {

              window.BPdata.liveurl =  setTimeout(() => this.fetchIndexUserhData(url), 10000);

          }
      }



  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }

  fetchLobbyData() {
      //localStorage.getItem(window.BPdata.time)
      // api/v1/cms/lobbylist
      const url = window.BPdata.apiAPZUrlCMS+'/lobbylist?transfer_type=0&provider_id='+window.BPdata.provider_id;
      //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

      axios({
        method: 'post',
        url: url,
        data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
        headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"         : localStorage.getItem(window.BPdata.time)
          },
      })
      .then(resp => {

          if(resp.data) {
            
              this.setState({
                game_contest  : resp.data.game_contest.data,
                // wallet        : resp.data.customer_wallet,
              });

              // console.log(resp.data.game_contest.data)

              // localStorage.setItem("sbsetting", JSON.stringify(resp.data.setting) )

              // localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )

              // console.log(resp.data);
              this.setState({login: 1})

          } else {
              this.setState({login: 0})
          }


        })
        .catch(function(error) {
          // console.log(error);
        });
  }

  fetchUserData() {
        //localStorage.getItem(window.BPdata.time)
        const url = window.BPdata.apiUrl+'/cms/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        axios({
          method: 'post',
          url: url,
          data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
          headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(resp => {

            if(resp.data) {
                this.setState({
                  companyuserid : resp.data.data.companyuserid,
                  username      : resp.data.data.first_name,
                  wallet        : resp.data.customer_wallet,
                  is_verified   : resp.data.data.is_verified
                });

                localStorage.setItem("sbsetting", JSON.stringify(resp.data.setting) )

                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )



                // console.log(resp.data);


                this.setState({login: 1})

            } else {
                this.setState({login: 0})
            }


          })
          .catch(function(error) {
            // console.log(error);
          });
    }

    welcomeMessage() {

        var day = new Date();
        var hr = day.getHours();
        if (hr == 1) {
          return window.lang.messages.good_night;
        }
        if (hr == 2) {
          return window.lang.messages.good_morning;
        }
        if (hr == 3) {
          return window.lang.messages.good_morning;
        }
        if (hr == 4) {
          return window.lang.messages.good_morning;
        }
        if (hr == 5) {
          return window.lang.messages.good_morning;
        }
        if (hr == 6) {
          return window.lang.messages.good_morning;
        }
        if ((hr == 6) || (hr == 7) || (hr == 8) || (hr == 9) ) {
          return window.lang.messages.good_morning;
        }
        if ( (hr == 10) || (hr == 11) ) {
          return window.lang.messages.good_afternoon;
        }
        if (hr == 12) {
          return window.lang.messages.good_afternoon;
        }
        if ((hr == 13) || (hr == 14)) {
          return window.lang.messages.good_afternoon;
        }
        if ((hr == 15) || (hr == 16) ) {
            return window.lang.messages.good_afternoon;
        }
        if ( (hr == 17) || (hr == 18) ) {
          return window.lang.messages.good_evening;
        }
        if ( (hr == 19) || (hr == 20) || (hr == 21) || (hr == 22)) {
          return window.lang.messages.good_night;
        }
        if (hr == 23) {
          return window.lang.messages.good_night;
        }
        if (hr==0) {
          return window.lang.messages.good_night;
        }

    }

  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    const menu = Menu(this.state.menu, selected, leagueMenu);

    return (
      <div className="page">
        
        
        <div className="page-content apz-main-bg" id="homeicon">
            <ApzHeader backLink="" titleType="2" title="Bet Records" isLoading="false" />

              <div className="main-content ">       
              
                  <div className="row">
                    <div className="grid-100 lotto mt-30 mb-30">
                        <h2 class="records-sub-title pl-20">Today Records</h2>
                    </div>
                    <div className="total-lobby-wrap">
                        <a href="#" className="totally-lists">
                            <span className="lobby-list-no mb-10">No-233</span>
                            <span className="lobby-list-time">Close Time 09:15</span>
                        </a>
                        <a href="#" className="bet-total">18000 MMK</a>
                    </div>
                  </div>

              
                {/* <HomeIconList></HomeIconList> */}

              </div>

        </div>

        <footer  className="App-footer">

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}
            <Grid data={window.lang.apzfooter}
                columnNum={3}
                renderItem={dataItem => (

                  <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                        { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                  </Link>
                )}
              />

          </div>
        </footer>

      </div>
    );
  }
}

export default HomeLottoPage;
