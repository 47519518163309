import React,{Component} from 'react';
// import {Helmet} from "react-helmet";
import {
    Link
  } from 'react-router-dom';
  import { Grid } from 'antd-mobile';
  import AppHeader from './../AppHeader';
//import { FacebookProvider, Comments } from 'react-facebook';
import axios from 'axios';
import renderHTML from 'react-render-html';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import CommentList from "./../../parts/CommentList.jsx";
import CommentForm from "./../../parts/CommentForm";

var moment = require('moment');

let NUM_ROWS = 20;




class BetList3DDetailPage extends Component {
    constructor(props) {
      super(props);

      window.BPdata.footerMenu = "home";
      this.state = {
        data : {
            title : '',
            body : '',
            featured_img: ''
        },
        latest_data : [],
        isLoading: true,
        post_id : 1,
        leagueMenu: 'NEWS',
        menu : window.BPdata.mainmenu,
        comments: [],
        comment : null,
        advertise: {},
        loading: true,
        number : window.lang.BetListDetail.number,
        liked : 0,
        fetch : 0,
        order_items:[{
            "id":1,
            "contest_id":0,
            "orders_id":1,
            "product_id":"1",
            "sku":"00","product_type":"1","provider_type":"1","name":"00","coupon_code":null,"weight":"0","qty_ordered":"1","discount_percent":null,"price":"300.00","discount_amount":null,"total":"300.00","item_data":null,"message":"","payment_transaction_id":null,"package_id":null,"provider_transaction_id":null,"tracking_id":null,"tracking_status":null,"informed":0,"commission":0,"created_at":"2021-12-14T08:12:59.000000Z","updated_at":"2021-12-14T08:12:59.000000Z"
          }],
          lottery:[
            {"id":1,"title":"11:00 AM","time":11,"event":1,"lottery_multiple":"-","lottery_round":null,"lottery_commission":5,"lottery_type":1,"lottery_active":1,"created_at":null,"updated_at":"2023-05-10T05:05:41.000000Z"},{"id":2,"title":"12:01 PM","time":12,"event":2,"lottery_multiple":"-","lottery_round":null,"lottery_commission":5,"lottery_type":1,"lottery_active":1,"created_at":null,"updated_at":"2023-05-10T05:05:55.000000Z"},{"id":3,"title":"3:00 PM","time":15,"event":3,"lottery_multiple":"-","lottery_round":null,"lottery_commission":5,"lottery_type":1,"lottery_active":1,"created_at":null,"updated_at":"2023-05-10T05:06:29.000000Z"},{"id":4,"title":"4:30 PM","time":16,"event":4,"lottery_multiple":"-","lottery_round":null,"lottery_commission":5,"lottery_type":1,"lottery_active":1,"created_at":null,"updated_at":null},{"id":5,"title":"3d","time":null,"event":1,"lottery_multiple":700,"lottery_round":10,"lottery_commission":10,"lottery_type":2,"lottery_active":1,"created_at":null,"updated_at":null}
          ],
      };

      if(props.match.params.id) {
        this.state.post_id = props.match.params.id
      }

      // this.addComment = this.addComment.bind(this);

    }

    // addComment(comment) {

    //   //this.props.history.push("/forum/15");
    //   this.setState({
    //     loading: false,
    //     // comments: [ comment, ...this.state.comments],
    //     fetch : 1
    //     //comments: [...this.state.comments,comment],
    //     //comments: [this.state.comments],
    //     // comment : comment
    //   });
    //   this.fetchCommentData();
    // }

    UNSAFE_componentWillMount() {
      window.BPdata.lang = localStorage.getItem("lang");
    }

    componentDidMount() {



        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);
            // this.setState({ step: "form" });

          } else {
             this.props.history.push("/more/login");
            }
        } else {
            this.props.history.push("/more/login");
        }

        this.fetchData();
        // this.fetchLatestData();

        let self = this;

        //console.log(localStorage.getItem('route'))
        // loading
        this.setState({ loading: true });

        this.hisUrl('/more/bet-list/'+this.state.post_id)
        clearTimeout(window.BPdata.liveurl);

        window.scrollTo(0, 0);

    }



    hisUrl(url) {
      if(localStorage.getItem("route")) {
        let menu = JSON.parse(localStorage.getItem("route"));
        //console.log(menu.length+"-------------")
        if(menu.length === "0") {
          let data = ["/"];
          localStorage.setItem("route", JSON.stringify(data) );
        }
         if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  //console.log("first");
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
              }
              window.BPdata.preurl =  menu[menu.length-2];
          }
      }
    }

    componentDidUpdate() {

    }

    fetchData() {


        if(JSON.parse(localStorage.getItem("wapp_latest_news"))) {
          let latest_news = JSON.parse(localStorage.getItem("wapp_latest_news"));
          let post_id = this.state.post_id;
          var result = latest_news.filter(function(obj, index){
            return obj.id_news===post_id;
          })

          if(result.length>0) {
            this.setState({
              data: result[0],
              isLoading: false
            })


          }
          //console.log(result)
        }


          let dataurl = window.BPdata.apiUrl+'/service/bet-list/detail/'+this.state.post_id;
          // let dataurl = window.BPdata.apiUrl+'/news/detail/'+this.state.post_id;

          axios.get(dataurl, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors", // no-cors, cors, *same-origin
            cache: "force-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            crossDomain: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            //body: JSON.stringify(data), // body data type must match "Content-Type" header
          })
          .then(resp => {

              this.setState({
                  data: resp.data.data,
                  setting : resp.data.setting,
                  lottery : resp.data.lottery,
                  isLoading: false
              });

          })
          .catch(function(error) {
            //console.log(error);
            //console.log(error.tostring());
          });

    }

    // fetchLatestData() {

    //   if(JSON.parse(localStorage.getItem("videoSlides")) != null) {
    //     let video_slides = JSON.parse(localStorage.getItem("videoSlides"));
    //     this.setState({
    //       latest_data: video_slides
    //     });
    //   }
    // }

    displayBlock() {
        let ui = []
        // console.log("liked--------"+this.state.liked)
        if(this.state.data  != null){
            ui.push(
                <div className="main-content" key="1">
                    <div className="row content-box">
                        

                        <div className="table-wrap">
                        <br />
                        <h2 className="color-gold" style={{ marginLeft:"10px" }}>{this.state.data.invoice_no}</h2><br />
                        <small style={{ marginLeft:"10px" }}>{window.lang.BetListDetail.start} : {moment.utc(this.state.data.created_at).local().format('DD MMM YYYY, hh:mm A') } </small><br/>
                        <br />
                        <b style={{ marginLeft:"10px" }}>{window.lang.BetListDetail.end} : {moment(this.state.data.contest.schedule_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, hh:mm A')} </b>
                        <br />
                        <br />
                        <b style={{ marginLeft:"10px" }}>{(this.state.data.contest.lottery_number) ? (this.state.number) +" : "+ this.state.data.contest.lottery_number : "" }</b>
                        <br />
                        <br />
                        <b style={{ marginLeft:"10px" }}>
                        {window.lang.BetListDetail.odds}:{this.state.data.contest_odd}
                        </b>
                        <br />
                        <br />
                        <b style={{ marginLeft:"10px" }}>{window.lang.apz.contest_status} </b> :&nbsp;
                        <b>{ (this.state.data.contest.payment_status  < 3) ? window.lang.apz.process : "" }{ (this.state.data.contest.payment_status  == 3) ? window.lang.apz.finished : "" }{ (Number(this.state.data.contest.payment_status)  > 3) ? window.lang.apz.canceled : "" }</b>
                        <br />
                        <b style={{ marginLeft:"10px" }}>{window.lang.apz.slip_status}  &nbsp;&nbsp;&nbsp;</b> :&nbsp; 
                        
                        <b >{ (this.state.data.payment_status  < 1) ? window.lang.apz.process : "" }{ (this.state.data.payment_status  == 1) ? window.lang.apz.finished : "" }{ (Number(this.state.data.payment_status)  > 2) ? window.lang.apz.canceled : "" }
                        </b>
                        <br />  <br />  
                      <table className="table-detail">
                            <thead>
                                <tr>
                                    <th className="text-center">3D</th>
                                    <th className="text-right">{ window.lang.betgroup.commission }</th>
                                    <th className="text-right">{ window.lang.betgroup.win }</th>
                                    <th className="text-right">{ window.lang.betgroup.bet_amount }</th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.childui(this.state.data.order_items)}

                                <tr>
                                    <td colSpan="3" className="text-right">{ window.lang.betgroup.commission_total } (?)</td>
                                    <td className="text-right">{this.state.data.commission}</td>
                                </tr>

                                <tr>
                                    <td colSpan="3" className="text-right">{ window.lang.betgroup.total }</td>
                                    <td className="text-right">{this.state.data.grand_total}</td>
                                </tr>

                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            )
        }

        if(ui.length === 0) {
            ui.push(<div className="main-content"><div className="text-center" key="0"><h2>Something was found.</h2></div></div>)
       }

        return ui
    }

    childui(item) {

        let self = this;
        let ui = []
            //console.log("-------------"+data)

            if(Array.isArray(item) === false) {
                return ui;
            } else {

                let arlength = item.length;

                if(arlength  > 0){

                    this.state.data.order_items.forEach(function(each,key){


                        ui.push(<tr key={"ro-"+each.id}>
                            <td  className={"text-center pointer " + (each.informed ? ((each.informed == 1) ? 'number-active' : 'color-orange') : '')}  >
                                { each.name }
                            </td>
                            <td colSpan="1" className="text-right">

                                { each.commission }

                            </td>
                            <td colSpan="1" className="text-right">

                                <small>{ (each.payment_status < 1) ? "-" : ( (each.win_amount == 0) ? "" : each.win_amount ) }</small>

                            </td>
                            <td colSpan="1" className="text-right">

                                { each.total }
 
                            </td>

                        </tr>)

                    })

                    return ui
                }
        }

        return ui;
    }



    render() {
        const { selected } = this.state;
        let leagueMenu = this.state.leagueMenu;
        // Create menu from items
        // const menu = Menu(this.state.menu, selected, leagueMenu);
        // const loadingSpin = this.state.loading ? "App-logo Spin" : "App-logo";

        return (
            <div className="page">
                <div className="page-content">
                <AppHeader backLink="/more/bet-list-3d" titleType="3" title={window.lang.title.threed} isLoading="false" />


                        { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  height="100px"  /> </div> ) : this.displayBlock() }


               {/*         <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-dark-red" >
                                { (JSON.parse(localStorage.getItem("route")) !== null)
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link>
                                </div>
                            </div>
                        </div>*/}

                </div>

                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>

            </div>
            );
        }
      }

export default BetList3DDetailPage;
