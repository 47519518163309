import React from "react";
import "./SideDrawer.css";
import {
    Link
  } from 'react-router-dom';

import { Modal }  from 'antd-mobile';


const alert = Modal.alert;

 let checklogin = () => {
      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              return true;
          }
      }
      return false;
  }
  



const sideDrawer = props => {
  let drawerClasses = ["side-drawer"];

  if (props.show) {
    drawerClasses = ["side-drawer", "open"];
  }




  return (
    
      <nav className={drawerClasses.join(" ")}>
              <div className="row drawerTop" >
                  <div className="grid-100" style={{marginRight:"16px" , marginLeft:"24px", paddingTop:"30px"}}>
                      <img src="/img/footer/_profile.png" style={{height: "50px", verticalAlign: "middle", marginRight:"16px" }} alt="User"/>
                      <b className="color-white">{localStorage.getItem('username')}</b>
                  </div>
                  
              </div>
              
              <ul>                     
                  
                  {(checklogin()) ?  <Link to="/more/myaccount" ><li > &nbsp; {window.lang.more.my_account}</li></Link> : ""}
                  {(checklogin()) ?  <Link to="/more/bet-list-2d"><li> &nbsp; 2D { window.lang.title.bet_list }</li></Link> : ""}
                  {(checklogin()) ?  <Link to="/more/bet-list-3d"><li> &nbsp; 3D { window.lang.title.bet_list }</li></Link> : ""}
                  <Link to="/contact-us"><li> &nbsp; {window.lang.more.contact_us}</li></Link>
                  {(checklogin()) ?  <Link to="/more/changepassword"><li> &nbsp; {window.lang.more.changepassword} </li></Link> : ""}
                  {(checklogin()) ?  <Link to="/more/feedback"><li> &nbsp; {window.lang.more.feedback} </li></Link> : ""}
                  <Link to="/faq"><li>&nbsp; {window.lang.more.faq}</li></Link>
                  <Link to="/download"><li>&nbsp; {window.lang.more.download_app}</li></Link>
                  <Link to="/share-app"><li>&nbsp; {window.lang.more.share_app}</li></Link>
                 
                  {(checklogin()) ? 
                    <div className="row mt-10" >
                      &nbsp; <a className="btn btn-success grid-80 pointer"  onClick={userLogout} style={{ fontSize: "15px" }}>{window.lang.form.logout}</a>
                  </div>
                  : ""}
                 
              </ul>


              

      </nav>
  );
};

let userLogout = () => {

      alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
          { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
          {
          text: 'ထွက်မည်',
          onPress: () => {
                  localStorage.setItem(window.BPdata.time, 0);
                  localStorage.setItem('username', "");
                  // this.setState({ step: "login" , message : "Successfully Log Out" }); 
                  // sideDrawer({ show : "close"})
                  window.location.reload();
              }
              
          },
      ])
  }

export default sideDrawer;


// https://codesandbox.io/s/b2dfd?file=/src/components/sidemenu/SideDrawer.js