import React from 'react';
import axios from 'axios';
import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';

  import { Grid } from 'antd-mobile';
  import AppHeader from './../AppHeader';

import swal from 'sweetalert';

const alert = Modal.alert;
var moment = require('moment');

class MyAccPage extends React.Component {


    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "myaccount";
        this.state = { step: "profile", message : "", form : {username: '', phone: '', profile_photo : 'profile_blank.png', verify_code: '', password: '' },
        id:"",companyuserid:"",date_of_birth:"",username:"",phone:"",profile_photo:"profile_blank.png",created_at:"",gem:"",league_points:"0",max_league_points:"0",rank:"", editform : false};
        this.userLogout         = this.userLogout.bind(this);
        this.langSwitch         = this.langSwitch.bind(this);
        this.versionSwitch      = this.versionSwitch.bind(this);
        this.userNameEdit       = this.userNameEdit.bind(this);
        this.userNameSubmit     = this.userNameSubmit.bind(this);
        this.emptyCache         = this.emptyCache.bind(this);

    }


    lpad(value, padding) {
        var zeroes = new Array(padding+1).join("0");
        return (zeroes + value).slice(-padding);
    }



    userNameEdit() {
        this.setState({editform : true});
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

    fetchUserData() {
        //localStorage.getItem(window.BPdata.time)
        const url = window.BPdata.apiUrl+'/cms/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        ///api/customer?transfer_type=2

        axios({
          method: 'post',
          url: url,
          data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
          headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(resp => {


            if(resp.data.data) {
                this.setState({
                  id : resp.data.data.id,
                  companyuserid : resp.data.data.companyuserid,
                  username : resp.data.data.first_name,
                  profile_photo : resp.data.data.profile_photo,
                  phone : resp.data.data.phone,
                  created_at : resp.data.data.created_at,
                  transaction : resp.data.last10trans,
                  wallet : resp.data.customer_wallet,
                });

                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )
            } else {

                localStorage.setItem(window.BPdata.time, 0);
                localStorage.setItem('username', "");

                swal({
                  title: "Session Expire",
                  text: window.lang.errors.contact_cs,
                  icon: "warning",
                  dangerMode: true,
                })
                .then(willDelete => {
                if (willDelete) {
                    localStorage.setItem(window.BPdata.time, 0);
                    localStorage.setItem('username', "");
                    this.props.history.push("/more/login")
                }
                });


            }


          })
          .catch(function(error) {
            // console.log(error);
          });
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              //localStorage.setItem(window.BPdata.time, true);
                    this.fetchUserData();

              } else {
                this.props.history.push("/more");
                }
          } else {
              this.props.history.push("/more");
          }




        this.hisUrl('/more/myaccount');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        // this.state.username
        // let form = {...this.state.form};
        // form[e.target.name] = e.target.value;
        this.setState({
            username : e.target.value
        });
    }

    versionSwitch() {
        if(localStorage.getItem('appview') === '0') {
            localStorage.setItem('appview', '1');
        } else {
            localStorage.setItem('appview', '0');
        }

        this.props.history.push("/more/myaccount");
    }

    langSwitch() {

        if(localStorage.getItem('lang') === 'en') {
            localStorage.setItem('lang', 'mm');
            window.lang = window.mmlang;
        } else {
            localStorage.setItem('lang', 'en');
            window.lang = window.enlang;
        }

        // console.log(window.lang)
        this.props.history.push("/more/myaccount");
    }

    userLogout() {

        alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
            { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
            {
            text: 'ထွက်မည်',
            onPress: () => {
                    this.sendPlayerLogoutApi();
                    localStorage.setItem(window.BPdata.time, 0);
                    localStorage.setItem('username', "");
                    this.setState({ step: "login" , message : "Successfully Log Out" });
                    this.props.history.push("/");
                }
        
            },
        ])
    }



    userNameSubmit() {
        var self = this;
        let dataurl = window.BPdata.apiUrl+'/user/name/edit';
        axios({
            method: 'post',
            url: dataurl,
            data: {salt : localStorage.getItem(window.BPdata.time), username : this.state.username},
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            if(response.data.success) {
                self.setState({
                    message : "Successfully Login",
                    editform : false
                });
                // localStorage.setItem(window.BPdata.time, self.state.form.phone );
                // localStorage.setItem('username', response.data.username );
                // self.props.history.push("/contest");
            } else {
                self.setState({  message : "Something was wrong", editform : true });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    checklogin() {
      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              return true;
          }
      }
      return false;
    }


    sendPlayerLogoutApi(form) {
        // console.log(this.state.gender)
        
        var self = this;
        let formdata = { 'user_id' : 313, };

        let dataurl = window.BPdata.apiUrlCMS+'/player/logout';
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(function (response) {

            if(response.data.status == 403) {
                self.setState({  message : response.data.message, error : response.data.error, status : response.data.status  });
            } else if(response.data.status == 200) {
                self.setState({  message : "Successfully Submited",  status : response.data.status  });
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" , status : response.data.data.status  });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });

    }


    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }

    profileBlock() {
      return <div>
          <div className="row">
              <h2>Hi {((this.state.form.username)? this.state.form.username : "Customer")}</h2>
              <p>Welcome to ThaHtayLay App</p>
              <br />
              <img src="/img/logo.png" className="medium-logo" />
              <br />
              <input type="submit" className="btn btn-blue grid-50" value="Log Out" onClick={this.userLogout} />
              {/* <a className="btn btn-blue grid-50" onClick={this.userLogout}>Logout</a> */}
          </div>
      </div>
   }

    homeBlock() {
        return <div>
            <div className="row">
                <a href="/more/login" className="btn btn-success grid-50">Log in</a><br /><br />
                <a href="/more/sign-up" className="btn btn-blue grid-50">Sign up</a>
            </div>
        </div>
    }

    emptyCache(){

        // alert("ok");

        if('caches' in window){

            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
        }
    }

    render() {
        return (
            <div className="page">


                <div className="page-content text-left pt-10">
                <AppHeader titleType="2" title={window.lang.title.my_account} isLoading="false" />

                    <div className="page-contest">
                        <br />

                        <div className="row">
                            <div className="grid-20 left">
                                <img src={"/img/avator/"+this.state.profile_photo}  height="60pt"/>
                            </div>
                            <div className="grid-80 right">
                                {this.state.username} - {this.state.id} <br />
                                {(this.state.phone.length) ? "******"+this.state.phone.substring(this.state.phone.length-4) : "" }<br /><br />
                                <Link to="/more/myaccount/edit-user" className="btn btn-small grid-30"><img src="/img/_edit.png" height="12pt"/> <span className="color-white">Edit</span></Link>
                                <br /><br />

                            </div>
                        </div>
                        <br /><br /><br /><br />
                  {/*      <div className="row">
                            <div className="grid-100">
                                <img src="/img/channellist.png" alt="" />
                                {(this.checklogin()) ?  <Link to="/more/myaccount" > &nbsp; {window.lang.more.my_account}</Link> : ""}
                                <span style={{ "lineHeight": "40px" }}>&gt;</span>
                                <br /><br />
                            </div>
                        </div>*/}

                        <Link to="/more/changepassword">
                        <div className="row ">
                            <div className="grid-10 left mb-10">
                                <img src="/img/list/_faq.png" alt="" height="40px" />

                            </div>
                            <div className="grid-80 left">
                                <span style={{ "lineHeight": "40px" }} class="pl-10">  &nbsp; {window.lang.more.changepassword} </span>

                            </div>
                            <div className="grid-10 right">
                                <span style={{ "lineHeight": "40px" }}>&gt;</span>
                            </div>
                        </div>
                        </Link>

                        <Link to="/more/statement">
                        <div className="row ">
                            <div className="grid-10 left mb-10">
                                <img src="/img/list/_faq.png" alt="" height="40px" />

                            </div>
                            <div className="grid-80 left">
                                <span style={{ "lineHeight": "40px" }} class="pl-10">  &nbsp; {window.lang.more.statement} </span>

                            </div>
                            <div className="grid-10 right">
                                <span style={{ "lineHeight": "40px" }}>&gt;</span>
                            </div>
                        </div>
                        </Link>

                        <Link to="/faq">
                        <div className="row ">
                            <div className="grid-10 left mb-10">
                                <img src="/img/list/_faq.png" alt="" />

                            </div>
                            <div className="grid-80 left">
                                <span style={{ "lineHeight": "40px" }} class="pl-10">  &nbsp; {window.lang.more.faq} </span>

                            </div>
                            <div className="grid-10 right">
                                <span style={{ "lineHeight": "40px" }}>&gt;</span>
                            </div>
                        </div>
                        </Link>

                        <div className="row">
                            <div className="grid-10 left mb-10">
                                <img src="/img/list/_feedback.png" alt="" />
                            </div>
                            <div className="grid-80 left">

                                {(this.checklogin()) ?  <Link to="/more/feedback"> <span style={{ "lineHeight": "40px" }} class="pl-10">  &nbsp; {window.lang.more.feedback} </span>  </Link> : ""}

                            </div>
                            <div className="grid-10 right">
                                <span style={{ "lineHeight": "40px" }}>&gt;</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="grid-10 left mb-10">
                                <img src="/img/list/_download.png" alt="" />
                            </div>
                            <div className="grid-80 left">

                                <Link to="/download">
                                     <span style={{ "lineHeight": "40px" }} class="pl-10">  &nbsp; {window.lang.more.download_app} </span>

                                </Link>

                            </div>
                            <div className="grid-10 right">
                                <span style={{ "lineHeight": "40px" }}>&gt;</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="grid-10 left mb-10">
                                <img src="/img/list/_share.png" alt="" />
                            </div>
                            <div className="grid-80 left">

                                <Link to="/share-app">
                                    <span style={{ "lineHeight": "40px" }} class="pl-10"> &nbsp; {window.lang.more.share_app}
                                    </span>
                                </Link>

                            </div>
                            <div className="grid-10 right">
                                <span style={{ "lineHeight": "40px" }}>&gt;</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="grid-10 left mb-10">
                                <img src="/img/list/_share.png" alt="" />
                            </div>
                            <div className="grid-80 left">

                                <a href="/version.php">
                                    <span style={{ "lineHeight": "40px" }} class="pl-10"> &nbsp; {window.lang.more.version_code} : 2.2.4
                                    </span>
                                </a>

                            </div>
                            <div className="grid-10 right">

                            </div>
                        </div>

                        <div className="row">
                            <div className="grid-10 left mb-10">
                                <img src="/img/list/_share.png" alt="" />
                            </div>
                            <div className="grid-80 left">
                            {/*
                                <a href="/version.php" className="ml-10 btn btn-small color-white p-4">
                                    <span height="12px"> &nbsp; {window.lang.more.version_check}
                                    </span>
                                </a>
                              */}
                              <a  className="ml-10 btn btn-small color-white p-4" onClick={this.emptyCache}>
                                  <span height="12px"> &nbsp; {window.lang.more.version_check}
                                  </span>
                              </a>
                            </div>
                            <div className="grid-10 right">
                            </div>
                        </div>


                        <br />
                        <br />
                        <br />
                        <br />

                        <div className="row">
                            <div className="grid-100 text-center mb-10">
                                <a className="btn btn-yellow   pointer"  onClick={this.userLogout}><img src="/img/_logout.png" height="12px" />&nbsp;&nbsp;{window.lang.form.logout}</a>
                            </div>
                        </div>

                    {/*
                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null)
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link>
                            </div>
                        </div>
                    </div>
                     */}
                    </div>

                </div>

                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
            </div>
        );
    }
}

export default MyAccPage;
