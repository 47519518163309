import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
  import { Grid } from 'antd-mobile';

  import NewsVideoSlider from './../../NewsVideoSlider';

  import AppHeader from './../../AppHeader';

class FishingInfoPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "ABOUT";
        // window.BPdata.isfooter = "off";
        window.BPdata.footerIs = "true";

        this.state = { step: "desktop", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {

        // if(localStorage.getItem(window.BPdata.time)) {
        //   if(localStorage.getItem(window.BPdata.time) !== "0" ) {
        //     //localStorage.setItem(window.BPdata.time, true);
        //     this.setState({
        //         step : "desktop"
        //     });

        //   } else {
        //      this.props.history.push("/more/login");
        //      return ;
        //     }
        // } else {
        //     this.props.history.push("/more/login");
        //     return ;
        // }


        window.scrollTo(0, 0);

    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    // inputChangeHandler(e) {
    //     let form = {...this.state.form};
    //     form[e.target.name] = e.target.value;
    //     this.setState({
    //         form
    //     });
    // }

    desktopBlock() {
        let ui = []

        ui.push(<iframe src={window.BPdata.dashboardUrl+"/bg/fishingurl?im=1&token="+localStorage.getItem(window.BPdata.time)}  style={{height:window.innerHeight+10,width:window.innerWidth-18}}  title="Sport Iframe" className="wwbetdesktop"></iframe>)

        // ui.push(<iframe src={window.BPdata.dashboardUrl+"/bg/fishingurl?im="+localStorage.getItem("appview")+"&token="+localStorage.getItem(window.BPdata.time)}  style={{height:window.innerHeight+10,width:window.innerWidth-18}}  title="Sport Iframe" className="wwbetdesktop"></iframe>)

        return ui;
    }

    mobileBlock() {

        let ui = []

        ui.push(<iframe src={window.BPdata.dashboardUrl+"/bg/fishingurl?im="+localStorage.getItem("appview")+"&token="+localStorage.getItem(window.BPdata.time)}  style={{height:window.innerHeight+10,width:window.innerWidth}}  title="Sport Iframe" className="wwbetmobile"></iframe>)

        // ui.push(
        // <div className="page-content text-center">
        //     <div className="main-content">
        //         <iframe src={window.BPdata.dashboardUrl+"/bg/fishingurl?im="+localStorage.getItem("appview")+"&token="+localStorage.getItem("time")}  style={{height:window.innerHeight+10,width:window.innerWidth-18}} title="Sport Iframe"></iframe>
        //     </div>
        // </div>)

        return ui;

    }


    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "desktop":   return this.desktopBlock();
                case "mobile":    return this.mobileBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }


    render() {
        return (
            <div className="page">
                <div className="page-content">

                    <AppHeader backLink="/bg-fishing-master-info" titleType="2" title="Fishing Master" isLoading="false" />
                    
                    <div className="main-content text-center">

                        <div className="row">
                            <div className="grid-100">
                            <NewsVideoSlider />
                            {/* <div className="grid-100 text-center">
                                Download Tha Htay Lay Mobile App &nbsp;&nbsp;<a className="btn btn-success" href="">Download</a>
                            </div>*/}
                            </div>
                        </div>

                        <div class="adm-card row grid-90 bg-noise pt-20">
                            <div className="el-card__body"><p>
                            BG ငါးပစ် Game ၏ပစ်နည်းတစ်ချိုကိုအပြောင်းအလဲပြုလုပ်ထားပါသည်။Screen ပေါ်ရှိမည်သည့်အကောင်းကိုမဆိုမျှား  (၂) ချက်ဆက်တိုက်နှိပ်ပါက ထိုအကောင်းကိုပစ်မှတ်ထားပြီး♻️Auto♻️ ဆက်တိုက်ပစ်မှာဖြစ်ပါသည်။ ♻️Auto♻️ ကိုရပ်လိုပါက 🚫Lock🚫 ကိုနှိပ်၍ရပ်နိုင်ပါသည်။

                            </p></div>
                        </div>

                            {/* fishing div 3 */}
                        {/* <div className="grid-100 text-center mt-10">
                            <Link to="/bg-fishing-master">
                                <div className="grid-30   package-block" >
                                    <img src="/img/sample-1.jpeg" alt=" Fishing Master" class="football_card_logo" style={{marginBottom:"10px"}}  />
                                    <br/>
                                    <h6 class="title-fish">
                                    Fishing Master
                                    <span class="mean_coin_text"><img src="/img/money-sign.png" alt=""  />
                                    1 = 10 ကျည်ဆံ</span></h6>
                                </div>
                            </Link>
                            <Link to="/bg-fishing-master">
                                <div className="grid-30   package-block" >
                                    <img src="/img/sample-2.jpeg" alt=" Fishing Master" class="football_card_logo" style={{marginBottom:"10px"}}  /><br/>
                                    <h6 class="title-fish">
                                    Xiyou Fishing
                                    <span class="mean_coin_text"><img src="/img/money-sign.png" alt=""  />
                                    1 = 100 ကျည်ဆံ</span></h6>
                                </div>
                            </Link>


                            <Link to="/bg-fishing-master">
                                <div className="grid-30   package-block">
                                    <img src="/img/sample-3.jpeg" alt=" Fishing Master" class="football_card_logo" style={{marginBottom:"10px"}}  /><br/>
                                    <h6 class="title-fish">
                                    DaiSeh Fishing
                                    <span class="mean_coin_text"><img src="/img/money-sign.png" alt=""  />
                                    1 = 10 ကျည်ဆံ</span></h6>
                                </div>
                            </Link>

                        </div> */}
                        {/* end fishing div 3 */}


                        {/* <div class="adm-card row grid-90    ">
                        <div className="adm-card-body grid-100">
                            <img src="/_nuxt/img/Fishing_Master-en.18470e7.png" alt=" Fishing Master" class="football_card_logo" /> <h6>
                                Fishing Master
                                <span class="mean_coin_text"><img src="/_nuxt/img/coin_icon.6db8d5a.png" alt=""  />
                                    1 = 10 ကျည်ဆံ</span></h6></div> <div class="el-col el-col-8 non_ani"><img src="/_nuxt/img/Xiyou_Fishing-en.0f63909.png" alt="Xiyou Fishing" class="football_card_logo" /> <h6>
                                Xiyou Fishing
                                <span class="mean_coin_text"><img src="/_nuxt/img/coin_icon.6db8d5a.png" alt=""  />
                                    1 = 100 ကျည်ဆံ</span></h6></div> <div class="el-col el-col-8 non_ani"><img src="/_nuxt/img/DaiSeh_Fishing-en.a7fca0c.png" alt="Poker Roulette" class="football_card_logo" / > <h6 class="title-fish">
                                DaiSeh Fishing
                                <span class="mean_coin_text"><img src="/img/money-sign.png" alt=""  />
                                    1 = 10 ကျည်ဆံ</span></h6></div>
                            </div> */}
                            
                               
                            </div>
                            <div className="btn-agree">
                                <br />
                                <Link to="/bg-fishing-master">
                                {/* <img src="/img/_verify.png" alt="" class="img-mark" style={{marginBottom:"10px"}}  /> */}
                                    <button class="action-btn">သ​ဘောတူပါသည်</button>
                                </Link>
                            </div>

                            {/* <div className="adm-card row grid-90 bg-noise">

                                <div className="adm-card-body grid-100">
                                        <div className="grid-20 left">
                                            <img src="/img/list/viber_white.png" height="30px"/>
                                        </div>
                                        <div className="grid-80 right text-left">
                                            {window.lang.messages.connect}&nbsp;&nbsp;{window.lang.messages.viber}

                                        </div>

                                </div>
                                <div className="adm-card-body row">

                                    <div className="grid-90  pl-20 pt-4 pb-4">
                                        <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                    </div>
                                </div>

                                <div className="adm-card-body grid-100 pt-4 pb-4">

                                        <div className="grid-100 left text-left pl-20">
                                            {window.lang.messages.connect_one}
                                            <br /><br /><br />
                                        </div>

                                </div>

                                <div className="adm-card-body row">
                                        <div className="grid-50 left text-left pl-20">
                                            
                                        </div>
                                        <div className="grid-40 right color-blue">


                                        </div>

                                </div>
                                <div className="adm-card-body row">
                                        <div className="grid-50 left text-left  pl-20">
                                            sss
                                        </div>
                                        <div className="grid-40 right color-blue">
                                            sddsds

                                        </div>

                                </div>
                            </div> */}
                        </div>

                        

                    <footer  className="App-footer">

                        <div className="App-footer-inner">
                        {/* {window.BPdata.footerMenu} */}
                            <Grid data={window.lang.footer}
                            columnNum={5}
                            renderItem={dataItem => (

                                <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                    { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                                </Link>
                            )}
                            />

                        </div>
                    </footer>
            </div>
        );
    }
}

export default FishingInfoPage;
