/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import { Grid } from 'antd-mobile';
// import AppHeader from './../AppHeader';
import ApzHeader from './../../ApzHeader';

import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 20;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_LENGTH = 90;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class BetResultsPage extends React.Component {

  constructor(props) {
    super(props);

    window.BPdata.footerMenu = "home";

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [{created_at : "2022-03-01T11:00:02.000000Z"}];
    this.state = {
      game_type: 1,
      betlist : 'betlist',
      dataSource: dataSource.cloneWithRows(this.data),
      isLoading: true,
      advertise: []

    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;
      //console.log(TOTAL_INDEX)
      this.state.isLoading = true;

    }

    this.changeSubMenu      = this.changeSubMenu.bind(this);

  }

  componentDidMount() {

     window.scrollTo(0, 0);

  

    if(localStorage.getItem(window.BPdata.time)) {
      if(localStorage.getItem(window.BPdata.time) !== "0" ) {
        //localStorage.setItem(window.BPdata.time, true);
              // this.fetchUserData();
              console.log("ok")

        } else {
          this.props.history.push("/more");
          }
    } else {
        this.props.history.push("/more");
    }

    // setTimeout(() => this.lv.scrollTo(0, 0), 800);

    // simulate initial Ajax
    this.fetchData();

    
    
  }

  componentWillUnmount() {
    clearInterval(this.state)
  }


  changeSubMenu(step) {
      // if(step === 'betlist') {
        this.setState({ betlist : step});


        // if(this.state.betlist  === 'betlist') {
        //   this.setState({ game_type : 1});
          
        //   // this.data = this.bet_list;
        // } else {
        //   this.setState({ game_type : 2});
        //   //console.log(this.result_list)
        //   // if(this.result_list.length > 0) {
        //   //   this.data = this.result_list;
        //   // }
          
        //  }

        this.fetchData();
          
      // }

  }

  fetchData() {

        // let dataurl = window.BPdata.apiAPZUrl+'/service/bet-list?limit='+NUM_ROWS;
        let dataurl = window.BPdata.apiAPZUrl+'/service/bet-list?limit=100';
        // let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            // data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token"  : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"          : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {
          // console.log(resp.data.bet_list.twodata.data);
          // console.log('---')
          // console.log(resp.data.bet_list.twodata.data)
          // if(resp.data.status_code == "404") {
          //   this.props.history.push("/more/login")
          // }

          this.bet_list = resp.data.bet_list.twodata;
          
          this.result_list = resp.data.bet_list.threedata;

          // console.log(this.state.betlist)

          if(this.state.betlist == "betlist") {

            this.data = resp.data.bet_list.twodata.data;

            

          } else {

            this.data = resp.data.bet_list.threedata.data;

          
          }

          

          // console.log("-----------------");

            this.setState({
              dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false
            });

            // localStorage.setItem("home_advertise", JSON.stringify(resp.data.advertise) )
            // localStorage.setItem("homeData", JSON.stringify({
            //   dataSource: this.data.reverse(),
            //   isLoading: false,
            //   advertise: resp.data.advertise
            // }) )

            // this.data = { ...this.data, ...genData(++this.pageIndex) };
            // console.log(this.data)
            NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

            //console.log(this.state.advertise)

        })
        .catch(function(error) {
          //console.log(error);
        });
    // fetch(REQUEST_URL)
    //   .then((response) => response.json())
    //   .then((responseData) => {
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(responseData[0].data),
    //     });
    //   })
    //   .done();
  }


  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {

      return;
    }
    // console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) {
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      //console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {

        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };

        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }

    }, 1000);
  }

  render() {
 
    let index = this.data.length - 1;
    let ui;
    let adcount = 0;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const obj = this.data[index--];
     

      if (this.state.betlist == "betlist") {

        if(obj) {
          // if(obj.contest) {
          //   console.log(obj.contest.gcontest_id);
          // }
          
          // if(obj.contest.gcontest_id) {
          //   console.log(obj.contest.gcontest_id);
          // }
          
            ui =  <div className='ticket-check' style={{ display: '-webkit-box', display: 'flex', padding: '10px' }}>

              <div className="row p-4 today-card-results" style={{ lineHeight: 1 }}>
                            <Link to={"/aungparzay/2d-detail/"+obj.invoice_no} className="flex verticle-align-middle onecard-result">
                                    <div className="grid-70 left-card">
                                        <span className="today-card-no">NO - {(obj.contest) ? obj.contest.gcontest_id : "-"  }  {( obj.win_amount) ? <img className='win-img' src="/img/apz/bonuswith-goldcolor.png" alt="win" height="30px"  /> : "" } </span>
                                         <span className="today-card-time">{window.lang.apz.close_time} {(obj.contest) ? moment(obj.contest.schedule_at).format('hh:mm A') : "" }</span>
                                         <span className="today-card-time">{moment.utc(obj.created_at).local().format('DD-MM-YYYY') }  </span>
                                         {/* <span className='lobby-check'>                                          
                                          { (obj.payment_status == 0) ? "Process" : "" }
                                          { (obj.payment_status  == 1) ? "Finished" : "" }
                                          { (obj.payment_status  == 2) ? "Canceled" : "" }
                                          </span> */}
                                    </div>
                                    {/* <div className="grid-33 left">
                                    </div> */}
                                    <div className="grid-50 right-card text-center">

                                    { ( obj.win_amount) ? <span className='rubin'>WIN  
                                      <small className='win-amount-status rubin'>{ (obj.payment_status < 1) ? "" : ( (obj.win_amount == 0) ? "" : obj.win_amount ) } </small>
                                      {/* { ( obj.win_amount) ? <span>MMK</span> : "" } */} </span> : "" }

                                      {/* { (obj.win_amount != 0 ) ? "*" : "" } */}
                                      <span className='rubin'>{window.lang.apz.bet} <small className='grand rubin'> {obj.grand_total}</small></span>
                                      <span className='lobby-check'>                                          
                                          { (obj.payment_status == 0) ? window.lang.apz.process : "" }
                                          { (obj.payment_status  == 1) ? window.lang.apz.finished : "" }
                                          { (obj.payment_status  == 2) ? window.lang.apz.canceled : "" }
                                          </span>

                                    </div>
                            </Link>
                      </div>

                </div>;

        }
      } else {

          ui =  <div className='ticket-check' style={{ display: '-webkit-box', display: 'flex', padding: '10px' }}>

          <div className="row p-4 today-card-results" style={{ lineHeight: 1 }}>
          <Link to={"/aungparzay/3d-detail/"+obj.invoice_no} className="flex verticle-align-middle onecard-result">
                                    <div className="grid-70 left-card">
                                        <span className="today-card-no">NO - {(obj.contest) ? obj.contest.gcontest_id : "-"  }  {( obj.win_amount) ? <img className='win-img' src="/img/apz/bonuswith-goldcolor.png" alt="win" height="30px"  /> : "" } </span>
                                         {/* <span className="today-card-time">Close Time {moment.utc(obj.created_at).local().format('hh:mm A') }</span> */}
                                         <span className="today-card-time">{window.lang.apz.close_time} {(obj.contest) ? moment(obj.contest.schedule_at).format('hh:mm A') : "" }</span>
                                         <span className="today-card-time">{moment.utc(obj.created_at).local().format('DD-MM-YYYY') }  </span>
                                         {/* <span className='lobby-check'>                                          
                                          { (obj.payment_status == 0) ? "Process" : "" }
                                          { (obj.payment_status  == 1) ? "Finished" : "" }
                                          { (obj.payment_status  == 2) ? "Canceled" : "" }
                                          </span> */}
                                    </div>
                                    {/* <div className="grid-33 left">
                                    </div> */}
                                    <div className="grid-50 right-card text-center">

                                    { ( obj.win_amount) ? <span className='rubin'>WIN  
                                      <small className='win-amount-status rubin'>{ (obj.payment_status < 1) ? "" : ( (obj.win_amount == 0) ? "" : obj.win_amount ) } </small>
                                      {/* { ( obj.win_amount) ? <span>MMK</span> : "" } */} </span> : "" }

                                      {/* { (obj.win_amount != 0 ) ? "*" : "" } */}
                                      <span className='rubin'>{window.lang.apz.bet} <small className='grand rubin'> {obj.grand_total}</small></span>
                                      <span className='lobby-check'>                                          
                                          { (obj.payment_status == 0) ? window.lang.apz.process : "" }
                                          { (obj.payment_status  == 1) ? window.lang.apz.finished : "" }
                                          { (obj.payment_status  == 2) ? window.lang.apz.canceled : "" }
                                          </span>

                                    </div>
                            </Link>
                       
                  </div>

            </div>;
      }

      return (
          ui
      );
    };
    return (
      <div className="page">
        <div className="page-content apz-main-bg">
        <ApzHeader backLink="/" titleType="3" title={window.lang.title.bet_list} isLoading="false" />

            <div className="main-content">

                <div className="row">
                  <br />
                  <div className="btn-tab flex">
                    
                    <div className={ (this.state.betlist == "betlist") ? 'grid-50  left text-center tab-holder active' : 'grid-50 left text-center tab-holder'}  onClick={this.changeSubMenu.bind(this,"betlist")}  >
                      { window.lang.title.bet_2d }
                    </div>
                    <div className={ (this.state.betlist == "resultlist") ? 'grid-50 right text-center tab-holder active' : 'grid-50 right text-center tab-holder'}  onClick={this.changeSubMenu.bind(this,"resultlist")}>
                      { window.lang.title.bet_3d }
                    </div>
                  </div>

                </div>
                <div className="row text-left">


                    <ListView
                      ref={el => this.lv = el}
                      dataSource={this.state.dataSource}
                      renderHeader={() => ""}
                      renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
                        {this.state.isLoading ? <img src="/img/loading.gif" alt="loading" height="50px"  /> : ''}
                      </div>)}
                      renderRow={row}
                     // renderSeparator={separator}
                      className="am-list"
                      pageSize={10}
                      useBodyScroll
                      // onScroll={() => { console.log('scroll'); }}
                      scrollRenderAheadDistance={500}
                      onEndReached={this.onEndReached}
                      onEndReachedThreshold={10}
                    />
                </div>
          </div>
        </div>

        <footer className="App-footer">

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}
            <Grid data={window.lang.apzfooter}
              columnNum={3}
              renderItem={dataItem => (

                <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{ width: '100%' }}>
                  {(window.BPdata.footerMenu === dataItem.check) ? (dataItem.text == "My Profile") ? <span className="color-white"><img src={dataItem.icon} height="35px" /><br />{(this.checklogin()) ? dataItem.text : "Login / Register"}</span> : <span className="text-white"><img src={dataItem.icon_off} height="35px" /><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon} height="35px" /><br />{dataItem.text} </span>}

                </Link>
              )}
            />

          </div>
        </footer>
      </div>
    );
  }
}

export default BetResultsPage;
