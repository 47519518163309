import React from 'react';
import axios from 'axios';
// import './../Ant.css';
import { Grid } from 'antd-mobile';
import AppHeader from './AppHeader';
import {
    Link
  } from 'react-router-dom';
var moment = require('moment');

let checklogin = () => {
  if(localStorage.getItem(window.BPdata.time)) {
      if(localStorage.getItem(window.BPdata.time) !== "0" ) {
          return true;
      }
  }
  return false;
}

class TwoGameHomePage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "home";
        this.state = {
            step: "login",
            message : "",
            form : {username: '', phone: '',verify_code: '', password: ''} ,
            settrade: {
                "thaidate":"29-11-21 13:11",
                "set":"--",
                "value":"--",
                "created_at":"2021-11-29 12:41:10",
                "number":"--"
            },
            eventone  : [],
            eventtwo   : [],
            eventthrid : [],
            eventfourth : [],
            wallet : [
                {
                    wallet_id: 1,
                    credit : 0
                },
                {
                    wallet_id: 2,
                    credit : 0
                },
                {
                    wallet_id: 3,
                    credit : 0
                }
            ],
         };


    }

    currencyFormat(num) {
        return num;
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    getCurrentHour() {
        var day = new Date();
        var hr = day.getHours();
        return hr;
    }

    getPickedLuckyNum() {

          
          var day = new Date();
          var hr = day.getHours();
          var min = day.getMinutes();
          var num_day = day.getDay();

          if(num_day == 6) {
            return this.state.settrade.number_live;
          }

          if(num_day == 0) {
            return this.state.settrade.number_live;
          }

          if ( hr >= 12 && hr < 13 ) {

              if(!Array.isArray(this.state.eventtwo)) {
                  return this.state.eventtwo.lottery_number;

              }
          }

          // if ( hr > 16 ) {

          //   if ( min > 30 ) {

          //     if(!Array.isArray(this.state.eventfourth)) {
          //       // return day.getMinutes();
          //       return this.state.eventfourth.lottery_number;
          //     }

          //   }
            
          // }

          // if ( hr > 17 ) {

          //     if(!Array.isArray(this.state.eventfourth)) {
          //       // return day.getMinutes();
          //       return this.state.eventfourth.lottery_number;
          //     }
            
          // }
          
          // else if ( hr > 15 && hr < 16 ) {
          //     if(Array.isArray(this.state.eventthrid)) {
          //         return this.state.eventthrid.lottery_number;
          //         return ;
          //     }
          // }

          return this.state.settrade.number_live;

          // this.state.settrade.number =

          // this.state.settrade.number


          // (!Array.isArray(this.state.eventthrid))
          // (!Array.isArray(this.state.eventfourth))

          return hr;

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    getCurrentDay() {
        var day = new Date();
        var num_day = day.getDay();
        return num_day;
    }

    currentDayFull() {

        var num_day = this.getCurrentDay();

        // alert(num_day);

        return window.lang.messages.num_day[num_day].text;

        console.log(window.lang.messages.num_day[1]);


        if (num_day == 1) {
          return window.lang.messages.num_day[1].text;
        }
        if (num_day == 2) {
          return window.lang.messages.good_morning;
        }
        if (num_day == 3) {
          return window.lang.messages.good_morning;
        }
        if (num_day == 4) {
          return window.lang.messages.good_morning;
        }
        if (num_day == 5) {
          return window.lang.messages.good_morning;
        }
        if (num_day == 6) {
          return window.lang.messages.good_morning;
        }
        if (num_day==0) {
          return window.lang.messages.num_day[0];
        }

    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.fetchData(window.BPdata.apiUrlCMS+'/2d');

        this.fetchWalletData();

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              //localStorage.setItem(window.BPdata.time, true);

              } else {
                this.props.history.push("/more");
                }
        } else {
          this.props.history.push("/more");
        }

        if(localStorage.getItem('wallet')) {

          if(JSON.parse(localStorage.getItem("wallet")) != null) {
            let wallet = JSON.parse(localStorage.getItem("wallet"));
            this.setState({wallet: wallet})
          }
        }

        // http://dep.thahtaylay.com/api/v1/cms/2d
    }

    fetchWalletData() {

        // alert("ok");
        //localStorage.getItem(window.BPdata.time)
        let self = this;
        let url = window.BPdata.apiUrlCMS+'/player/show?provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        // console.log(url)
        axios({
          method: 'post',
          url: url,
          data: { token : localStorage.getItem(window.BPdata.time) },
          headers: { 'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
             },
        })
        .then(resp => {

            if(resp.data.data) {
                this.setState(prevState => ({

                  wallet : resp.data.customer_wallet,

                }))




                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )

            } 


          })
          .catch(function(error) {
            // console.log(error);
          });


    }

    fetchData(url) {

        let dataurl = url;



        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            // console.log(resp.data)

            // {"thaidate":"29-11-21 13:11",
            // "set":"1,600.24",
            // "value":"64,409.60M",
            // "created_at":"2021-11-29 12:41:10",
            // "number":"49"}
            this.setState({
                settrade : {
                    thaidate: resp.data.data.thaidate,
                    set: resp.data.data.set,
                    value: resp.data.data.value,
                    created_at: resp.data.data.created_at,
                    number_live: resp.data.data.number,
                    number: ""
                },
                eventone  : resp.data.eventone,
                eventtwo   : resp.data.eventtwo,
                eventthrid : resp.data.eventthrid,
                eventfourth : resp.data.eventfourth,

            });

            this.getPickedLuckyNum();
            //this.setState({ isLoading: false });

        })
        .catch(function(error) {
          //console.log(error.tostring());
        });

        window.BPdata.liveurl =  setTimeout(() => this.fetchData(url), 7000);


    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    walletBlock() {

    let childui = [];


          if(this.state.wallet[0]) {
            childui.push(<div className="row pb-10">
                 <div className="adm-card row mt-10 color-white">
                        <div className="adm-card-body grid-100">
                          <div className="row pocket-wrapper">
                                <div className="grid-10 left text-center pl-10">
                                  <img src="/img/main_wallet.png" width="30" />
                                </div>
                                <div className="grid-40 left text-left pocket-session">
                                    { window.lang.title.main_wallet }
                                </div>
                                <div className="grid-30 left text-right pocket-session right-pocket">
                                  <span className='walletcolor'>{ (this.state.wallet) ? this.currencyFormat(this.state.wallet[0].credit) : 0 } Ks</span>
                                </div>
                                <div className="grid-10 left text-right frame-img-center">
                                  <Link to="/more/wallet-store/transfer">
                                    <img src="/img/plus-circle.png" height="10px" width="23" />
                                  </Link>
                                </div>

                            </div>
                            <div className="row">
                                <div className="grid-90  pl-20 pt-4">
                                  <hr class="pocket-line" style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                </div>
                            </div>
                            <div className="row pocket-wrapper">
                                <div className="grid-10 left text-center pl-10">
                                  <img src="/img/commission_wallet.png" width="30" />
                                </div>
                                <div className="grid-40 left text-left pocket-session">
                                    { window.lang.title.commission_wallet } 

                                </div>
                                <div className="grid-30 left text-right pocket-session right-pocket">
                                  <span  className='walletcolor'> { (this.state.wallet) ? this.currencyFormat(this.state.wallet[2].credit) : 0 } Ks</span>
                                </div>
                                <div className="grid-10 left text-right frame-img-center">
                                  <Link to="/more/wallet-store/transfer">
                                    <img src="/img/plus-circle.png" height="10px" width="23" />
                                  </Link>
                                </div>

                            </div>
                        </div>
                </div>
            </div>)
          } else {
            if(this.state.is_verified == 0) {
              childui.push(<div className="row pb-10">
                <br />
                 <div className="adm-card row mt-10">
                        <div className="adm-card-body grid-100">
                            <div className="row">
                                <div className="grid-100 left text-center">
                                    You have been banned
                                </div>
                                <br />
                                <hr />
                            </div>
                        </div>
                </div>
            </div>)
            } else {
              //Please Login
              childui.push("")
            }

          }
      return childui;
  }


    render() {
        return (
            <div className="page">
                <div className="page-content text-center">

                <AppHeader titleType="2" title={window.lang.title.twod} isLoading="false" />


                    <div className="main-content">

                    {(checklogin()) ? ((this.state.wallet[0]) ?
                            this.walletBlock() : "" ) : ""}
                    <div className="adm-card row grid-90 bg-noise mb-10">

                            <div className="adm-card-body grid-100">
                                    <div className="grid-50 left current-number-block text-left pl-20">

                                        <span className="blink_me pl-20" >
                                          { this.getPickedLuckyNum() } </span>

                                    </div>
                                    <div className="grid-40 mt-20 left color-white text-left">
                                          {window.lang.title.date}&nbsp; &nbsp;|&nbsp;&nbsp;
                                            {moment(this.state.settrade.created_at, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A')}
                                        <br /><br />
                                        {moment(this.state.settrade.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY')}
                                        <br />
                                        { this.currentDayFull() }

                                    </div>

                            </div>
                            <div className="adm-card-body row">

                                <div className="grid-90  pl-20 pt-4 pb-4">
                                  <hr class="narrow-bar" style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                </div>
                            </div>

                            <div className="adm-card-body grid-100">
                                    <div className="grid-33 left">
                                        <Link to="/more/bet-list-2d" className="">
                                        <img src="/img/list/_history_list.png" height="30px"/>
                                        <br />
                                        <span class="mt-4 d-block">{window.lang.title.bet_list}</span>
                                        </Link>

                                    </div>

                                    <div className="grid-33 left">
                                        <Link to="/more/2d-winner-top-ten-list" className="">
                                        <img src="/img/list/_winner.png" height="30px"/>
                                        <br />
                                        <span class="mt-4 d-block">{window.lang.title.winner}</span>
                                        </Link>
                                    </div>

                                    <div className="grid-33 left">
                                        <Link to="/more/thai-off-day" className="">
                                        <img src="/img/list/_close_day.png" height="30px"/>
                                        <br />
                                        <span class="mt-4 d-block">{window.lang.title.close_day}</span>
                                        </Link>
                                    </div>

                            </div>
                        </div>
                    <br />

                    <div className="adm-card row grid-90 bg-noise pt-20">

                            <div className="adm-card-body grid-100">
                                    <div className="grid-33 left">
                                        11:00 AM
                                    </div>
                                    <div className="grid-25 left color-theme-divone">
                                            { (!Array.isArray(this.state.eventone)) ? ((this.state.eventone.lottery_number) ? this.state.eventone.lottery_number : "--"  ): "--" }
                                    </div>
                                    <div className="grid-20 left">
                                        <b >Set</b> :
                                        <br />
                                        <b >Value</b> :
                                        <br />

                                    </div>
                                    <div className="grid-10 left color-theme-gold">
                                        { (!Array.isArray(this.state.eventone)) ? this.state.eventone.gcontest_set : this.state.settrade.set }<br />

                                        { (!Array.isArray(this.state.eventone)) ? this.state.eventone.gcontest_value : this.state.settrade.value }<br />

                                    </div>

                            </div>
                            <div className="adm-card-body row">

                                <div className="grid-90  pl-20 pt-4 pb-4">
                                  <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                </div>
                            </div>

                            <div className="adm-card-body grid-100">
                                    <div className="grid-33 left">
                                        12:01 PM
                                    </div>
                                    <div className="grid-25 left color-theme-divtwo">
                                        { (!Array.isArray(this.state.eventtwo)) ? ((this.state.eventtwo.lottery_number) ? this.state.eventtwo.lottery_number : "--"  ): "--" }

                                    </div>
                                    <div className="grid-20 left">
                                        <b >Set</b> :
                                        <br />
                                        <b >Value</b> :
                                        <br />

                                    </div>
                                    <div className="grid-10 left color-theme-gold">
                                        { (!Array.isArray(this.state.eventtwo)) ? this.state.eventtwo.gcontest_set : this.state.settrade.set }<br />

                                        { (!Array.isArray(this.state.eventtwo)) ? this.state.eventtwo.gcontest_value : this.state.settrade.value } <br />

                                    </div>

                            </div>
                            <div className="adm-card-body row">

                                <div className="grid-90  pl-20 pt-4 pb-4">
                                  <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                </div>
                            </div>

                            <div className="adm-card-body grid-100">
                                    <div className="grid-33 left">
                                        3:00 PM
                                    </div>
                                    <div className="grid-25 left color-theme-divtwo">
                                        { (!Array.isArray(this.state.eventthrid)) ? ((this.state.eventthrid.lottery_number) ? this.state.eventthrid.lottery_number : "--"  ): "--" }

                                    </div>
                                    <div className="grid-20 left">
                                        <b >Set</b> :
                                        <br />
                                        <b >Value</b> :
                                        <br />

                                    </div>
                                    <div className="grid-10 left color-theme-gold">
                                        { (!Array.isArray(this.state.eventthrid)) ? this.state.eventthrid.gcontest_set : this.state.settrade.set }<br />

                                        { (!Array.isArray(this.state.eventthrid)) ? this.state.eventthrid.gcontest_value : this.state.settrade.value } <br />

                                    </div>

                            </div>
                            <div className="adm-card-body row">

                                <div className="grid-90  pl-20 pt-4 pb-4">
                                  <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                </div>
                            </div>

                            <div className="adm-card-body grid-100">
                                    <div className="grid-33 left">
                                        4:30 PM
                                    </div>
                                    <div className="grid-25 left color-theme-divtwo">
                                        { (!Array.isArray(this.state.eventfourth)) ? ((this.state.eventfourth.lottery_number) ? this.state.eventfourth.lottery_number : "--"  ): "--" }

                                    </div>
                                    <div className="grid-20 left">
                                        <b >Set</b> :
                                        <br />
                                        <b >Value</b> :
                                        <br />

                                    </div>
                                    <div className="grid-10 left color-theme-gold">
                                        { (!Array.isArray(this.state.eventfourth)) ? this.state.eventfourth.gcontest_set : this.state.settrade.set }<br />

                                        { (!Array.isArray(this.state.eventfourth)) ? this.state.eventfourth.gcontest_value : this.state.settrade.value } <br />

                                    </div>

                            </div>
                    </div>
                    <br />
                    </div>
                    <div className="grid-100 text-center">
                       {(checklogin()) ?  <Link to="/two-game-set" className="btn btn-yellow btn-small1 grid-20 scrollup">{ window.lang.betgroup.bet }</Link> : "" }
                    </div>
                    <br />
                </div>

                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
            </div>
        );
    }
}

export default TwoGameHomePage;
