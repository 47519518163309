/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView,Grid } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import axios from 'axios';

var moment = require('moment');

let NUM_ROWS = 100;
let LIMIT_ROWS = 100;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_TITLE_LENGTH = 50;
let MAX_LENGTH = 90;
let FIRST_MAX_LENGTH = 400;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class HomeListPage extends React.Component {
  constructor(props) {
    super(props);



    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      isApp : false,
      dataSource: dataSource.cloneWithRows(this.data),
      news      : [],
      isLoading: false,
      advertise: []

    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;
      //console.log(TOTAL_INDEX)
      this.state.isLoading = false;

    }
  }

  componentDidMount() {

    if(localStorage.getItem('home_advertise')) {

      if(JSON.parse(localStorage.getItem("home_advertise")) != null) {
        let home_advertise = JSON.parse(localStorage.getItem("home_advertise"));
        this.setState({advertise: home_advertise})
      }
    }

    // if(localStorage.getItem('latest_news')) {
    //   if(JSON.parse(localStorage.getItem("latest_news")) != null) {
    //     let latest_news = JSON.parse(localStorage.getItem("latest_news"));
    //     this.setState({news: latest_news})

    //   }
    // }




    var isApp = /Thahtaylay/i.test(navigator.userAgent);

    if (isApp) {

        this.setState({
            isApp : "true"
        });

    } else {

      this.setState({
          isApp : "false"
      });

    }

    // this.setState({
    //     isApp : "true"
    // });

    // this.fetchData();

    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // // simulate initial Ajax
    // setTimeout(() => {
    //   this.fetchData();
    // }, 600);

  }

  componentWillUnmount() {
    // clearInterval(this.state)
  }

  // fetchData() {

  //       const url = window.BPdata.apiUrlCMS+"/news";

  //       axios.get(url, {
  //         method: "GET", // *GET, POST, PUT, DELETE, etc.
  //         mode: "no-cors", // no-cors, cors, *same-origin
  //         cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //         //credentials: "same-origin", // include, *same-origin, omit
  //         crossDomain: true,
  //         headers: {
  //             "Content-Type": "application/json; charset=utf-8",
  //             "X-Trident-Token" : window.BPdata.token,
  //             // "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //         redirect: "follow", // manual, *follow, error
  //         referrer: "no-referrer", // no-referrer, *client
  //         //body: JSON.stringify(data), // body data type must match "Content-Type" header
  //       })
  //       .then(resp => {
  //         // console.log(this.data.concat(resp.data));
  //         this.data = resp.data.news.data;
  //         // console.log("-----------------");

  //           this.setState({
  //             news      : this.data,
  //             // dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
  //             isLoading: false,
  //             // advertise: resp.data.advertise
  //           });

  //           localStorage.setItem("latest_news", JSON.stringify(resp.data.news) )

  //           localStorage.setItem("home_advertise", JSON.stringify(resp.data.advertise) )

  //           //NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

  //           //console.log(this.state.advertise)

  //       })
  //       .catch(function(error) {
  //         //console.log(error);
  //       });
  // }



  render() {
    // const separator = (sectionID, rowID) => (
    //   <div
    //     key={`${sectionID}-${rowID}`}
    //     style={{
    //       backgroundColor: '#F5F5F9',
    //       height: 8,
    //       borderTop: '1px solid #ECECED',
    //       borderBottom: '1px solid #ECECED',
    //     }}
    //   />
    // );
    // let index = this.data.length - 1;
    // let ui;
    // let adcount = 0;

    return (
      <div className="page">
      <div className="text-center homeiconlist">
        <Grid data={window.lang.homeiconlist}
              columnNum={2}
              renderItem={dataItem => (
                (dataItem.linktype == "local") ?
                (this.state.isApp == "true") ?
                  <Link to={dataItem.extraurl} className="cont-wrap-link left-wrap">
                      <div style={{ color: '#888', fontSize: '14px', marginTop: '0px',textAlign: 'center',  marginBottom:'15px'}}>

                        <div className="home-icon-margin" style={{ background: '#957000',padding:'7px', borderRadius: '20px'}} >
                          <img src={dataItem.icon} className="thumbnail-icon "/>
                          <h3 className="sportsbook hvr-pulse-shrink">{dataItem.text}</h3>
                        </div>
                      </div>
                  </Link> :
                  <Link to={dataItem.url} className="cont-wrap-link right-wrap">
                      <div style={{ color: '#888', fontSize: '14px', marginTop: '0px',textAlign: 'center',  marginBottom:'15px'}}>

                        <div className="home-icon-margin" style={{ background: '#957000',padding:'7px', borderRadius: '20px'}} >
                          <img src={dataItem.icon} className="thumbnail-icon "/>
                          <h3 className="sportsbook hvr-pulse-shrink">{dataItem.text}</h3>
                        </div>
                      </div>
                  </Link>
                :
                <a href={dataItem.url} className="cont-wrap-link left-wrap" target="_blank">
                    <div style={{ color: '#888', fontSize: '14px', marginTop: '0px',textAlign: 'center',  marginBottom:'15px'}}>
                      <div className="home-icon-margin" style={{ background: '#957000',padding:'7px', borderRadius: '20px'}} >
                        <img src={dataItem.icon} className="thumbnail-icon "/>
                        <h3 className="sportsbook hvr-pulse-shrink">{dataItem.text}</h3>
                      </div>
                    </div>
                </a>

              )}
            />
        </div>

      </div>
    );
  }
}

export default HomeListPage;
