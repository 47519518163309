import React from 'react';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {
    Link
  } from 'react-router-dom';
import { Grid } from 'antd-mobile';
import AppHeader from './AppHeader';
import mmph from 'myanmar-phonenumber';

class PasswordRestPage extends React.Component {
    constructor(props) {
        super(props)

        //passwordreset
        this.state = { step: "passwordreset", message : "", username: '', form : { phone: '',otpcode: '', password: '' }, seconds: 60, isCounting: false};
        this.validatePhone      = this.validatePhone.bind(this);
        this.resendOTP           = this.resendOTP.bind(this);
        this.checkValidationApi = this.checkValidationApi.bind(this);
        this.changePasswordApi  = this.changePasswordApi.bind(this);
        this.showHide           = this.showHide.bind(this);
    }

    startCountdown = () => {
        this.setState({ isCounting: true });
        const intervalId = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.seconds === 0) {
                    clearInterval(intervalId);
                    this.setState({ isCounting: false });
                    return { seconds: 60 }
                }
                return { seconds: prevState.seconds - 1 }
            });
        }, 1000);
    }
    
    handleOtpRequest = () => {
        // Send the phone number to the server to request a new OTP
        // ...
        this.startCountdown();
    }



    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }
        }
       // console.log(localStorage.getItem('route'));
    }

    showHide() {

        if(this.state.show_hide == false) {
            this.setState({ show_hide: true });
        } else {
            this.setState({ show_hide: false });
        }

    }

    checklogin() {
      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              return true;
          }
      }
      return false;
    }

    componentDidMount() {
        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "profile" });
            }

          }
        // if(localStorage.getItem('username')) {
        //         this.setState({
        //             form: {
        //                 username : localStorage.getItem('username')
        //             }
        //         });
        // }

        this.hisUrl('/more/password-reset');
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/sign-up');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    validatePhone() {
        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)
        //phone : mmph.normalizeInput(this.state.form.phone)
        if(form.phone.length > 6) {
            if(mmph.isValidMMPhoneNumber(form.phone)){
                data = {
                    phone : this.state.form.phone
                }
                this.setState({  message : "SMS Sending" });
                this.sendSMSApi(data);
            } else {
                this.setState({  message : window.lang.messages.invalid_phone });
            }

        } else {
            //console.log(this.state.message)
            this.setState({  message : window.lang.messages.invalid_phone });
        }

    }

    resendOTP() {

        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)
        if(form.phone.length >= 6) {
            if(mmph.isValidMMPhoneNumber(form.phone)){
                data = {
                    first_name : this.state.form.username,
                    phone : this.state.form.phone
                }
                this.setState({  message : "လုပ်ဆောင်နေပါသည်။" });
                this.resendSMSApi(data);
            } else {
                this.setState({  message : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။" });
            }

        } else {
            //console.log(this.state.message)
            this.setState({  message : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။" });
        }
    }

    resendSMSApi(formdata) {
        var self = this;
        let dataurl = window.BPdata.apiUrlCMS+'/player/password-reset';
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
            "X-Trident-Token" : window.BPdata.token,
            "BP-Agent"         : window.BPdata.rand_compound
          },
        })
        .then(function (response) {
            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } else if(response.data.data.status == 200) {
                self.setState({  message : "SMS ပို့ပြီးပါပြီ", step : "verify" });
                self.handleOtpRequest();
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }


    sendSMSApi(formdata) {
        var self = this;
        let dataurl = window.BPdata.apiUrlCMS+'/player/password-reset';
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
            "X-Trident-Token" : window.BPdata.token,
            "BP-Agent"         : window.BPdata.rand_compound
          },
        })
        .then(function (response) {
            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } else if(response.data.data.status == 200) {
                self.setState({  message : "SMS ပို့ပြီးပါပြီ", step : "verify" });
                self.handleOtpRequest();
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    checkValidationApi() {
        let formdata = this.state.form ;
        var self = this;

        // let dataurl = window.BPdata.apiUrl+'/user/verifycode';
        let dataurl = window.BPdata.apiUrlCMS+'/player/verifycode';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"        : window.BPdata.rand_compound
            },
        })
        .then(function (response) {
            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } else if(response.data.data.status == 200) {
                self.setState({  message : response.data.data.message , step : "password" });
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    changePasswordApi() {
        if(this.state.form.password.length >= 6) {
            let formdata = this.state.form ;
            var self = this;
            let dataurl = window.BPdata.apiUrlCMS+'/player/pwd';
            axios({
                method: 'post',
                url: dataurl,
                data: formdata,
                headers: { 'Content-Type': 'application/json;charset=UTF-8',
                    "X-Trident-Token" : window.BPdata.token,
                    "BP-Agent"         : window.BPdata.rand_compound
                },
            })
            .then(function (response) {

                if(response.data.data.status == 403) {
                    self.setState({  message : response.data.data.message });
                } else if(response.data.data.status == 200) {
                    self.setState({  message : response.data.data.message , step : "profile" });
                    // localStorage.setItem(window.BPdata.time, self.state.form.phone );
                    localStorage.setItem(window.BPdata.time, response.data.data.token );
                    localStorage.setItem('username', self.state.form.username );
                }  else {
                    self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
                }

                //console.log(response.data.success);
            })
            .catch(function (error) {
                // console.log(error);
            });
        } else {
            this.setState({  message : "စကားဝှက်မှာအနည်းဆုံးအက္ခရာ ၆ လုံးရှိရမည်" });
        }

    }

    viewHandler() {

        switch(this.state.step) {
            // case "line-up":   return "";
            case "passwordreset": return this.passwordResetBlock();
            case "verify": return this.verifyBlock();
            case "password": return this.passwordBlock();
            case "profile": return this.profileBlock();
            default:      return "";
          }
    }


    passwordResetBlock() {
        return <div className="form-box">


                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.phone}</label>
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left text-right">
                            <div className="form-group">
                            <input type="text" className="form-control text-center" placeholder="09**********" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} /><br />
                            </div>
                            <Link to="/more/login" className="color-theme-gold">{window.lang.form.login}</Link> | &nbsp;&nbsp;
                            <input type="submit" className="btn btn-yellow   pointer grid-40" value={window.lang.form.submit} onClick={this.validatePhone} /><br />
                        </div>

                    </div>
        </div>
    }
    verifyBlock() {
        return <div>
                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.verify_code} &nbsp;&nbsp;
                                                  {this.state.isCounting && <div>Time remaining: {this.state.seconds} seconds</div>}
                                                  {!this.state.isCounting && <a onClick={this.resendOTP} className="color-theme-gold   pointer">(Resend OTP)</a>}</label>
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-center">
                            <input type="text" className="form-control text-center"  name="otpcode" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.otpcode} maxLength="6" /><br />
                            </div>
                            <input type="submit" className="btn btn-yellow   pointer grid-50" value="Submit" onClick={this.checkValidationApi} /><br />
                        </div>

                    </div>
        </div>
    }

    passwordBlock() {
        return <div>
                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label>သင့်ရဲ့ လျှို့၀ှက်နံပါတ် အသစ်ကို ရိုက်ထည့်ပါ။</label>
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-center" style={{ position: 'relative' }}>
                            <input
                                type={this.state.show_hide ? 'text' : 'password'}
                                className="form-control text-center"  name="password" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.password}
                            />
                            <span className='changePwd'
                                onClick={this.showHide}
                                style={{
                                position: 'absolute',
                                right: '20px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                color: 'black'
                                }}
                            >
                                {this.state.show_hide ? <FaEye className='iconColor' style={{color:'black'}} /> : <FaEyeSlash style={{color:''}} />}
                            </span>
                            {/* <input type="password" className="form-control text-center"  name="password" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.password} /><br /> */}
                            </div>
                            <input type="submit" className="btn btn-yellow   pointer grid-50" value="Submit" onClick={this.changePasswordApi} /><br />
                        </div>

                    </div>
        </div>
    }

    profileBlock() {
        return <div>
            <div className="row">
                <h2>Hi {((this.state.form.username)? this.state.form.username : "Customer")}</h2>
                <p>Welcome to ThaHtayLay App</p>
                <br />
                <img src="/img/logo.png" className="medium-logo" />
                <br />
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                <AppHeader titleType="2" title={window.lang.form.password_reset} isLoading="false" />

                    <div className="main-content">
                        <br />
                        <br />
                         { (this.state.step != "profile") ?
                        <h1 className="color-theme-gold">{window.lang.form.password_reset}</h1> : "" }
                        <br />
                        <span>{this.state.message}</span>

                        { this.viewHandler() }
                    </div>
                </div>
                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
            </div>
        );
    }
}

export default PasswordRestPage;
