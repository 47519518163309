import React, { useState, useEffect } from 'react';

import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { ListView, Grid } from 'antd-mobile';

import ApzHeader from './../../ApzHeader';


import swal from 'sweetalert';
require('./../../../../BPdata.js')

const alert = Modal.alert;

var moment = require('moment');

const LobbyList = ({ items }) => {
  
  return (
    <div className="main-content lotto-page-content"> 
        {items.map((item) => (
          <LobbyItem key={item.id} item={item} />
        ))}
    </div>
  );
};

const LobbyItem = ({ item }) => {

  // const [timeRemaining, setTimeRemaining] = useState(item.schedule_at);
  var currentTime = new Date();

//  let init_timeRemaining = moment("2024-01-02 16:16:37", 'YYYY-MM-DD HH:mm:ss') - currentTime;


  let init_timeRemaining = moment(item.schedule_at, 'YYYY-MM-DD HH:mm:ss') - currentTime; 
  // + 60000;


  const [timeRemaining, setTimeRemaining] = useState(init_timeRemaining);
  const [disCount, setDisCount] = useState("");
  // const [timeRemaining, setTimeRemaining] = useState(item.countdown);

  useEffect(() => {

      // Calculate the remaining time

      // Check if the countdown has reached zero
      if (timeRemaining >  0) {

        setDisCount(  moment.utc(init_timeRemaining).format('HH : mm : ss') );

        const intervalId = setInterval(() => {
          setTimeRemaining((prevTime) => prevTime - 1000);
        }, 1000);


        return () => clearInterval(intervalId);
          
      } else {
         
        setDisCount( "00 : 00 : 00"  );
        // setDisCount( "-- : -- : --"  );

        return () => clearInterval();

      }
    
  }, [timeRemaining]);

  return (
    <Link to={"/aungparzay/shoot/"+item.id} key={"second"+item.id}>
      <div className=""  key={"second"+item.id} >
        <div class="game-content lotto-wrapper mb-100">
          <div class= "grid-100 lotto-first">
            <p class="lotto-g-no">No-{item.gcontest_id}</p>
            <div className="timer-wrap">
              <span class="timer">{window.lang.apz.close_time} <span class="closetime-text ml-10">{moment(item.schedule_at, 'YYYY-MM-DD hh:mm:ss').format('hh:mm')}</span></span>
              <h2>{ disCount } </h2>
              <div className="btn-text btn-section">
                {(checklogin()) ?  <Link to={"/aungparzay/shoot/"+item.id} className="btn-lotto">{(window.lang.lang == "en") ? <img src="/img/apz/golden-play-btn-img-en.png" className="img-playnow" width="100%" height="50" alt="profile" /> : <img src="/img/apz/golden-play-btn-img-mm.png" className="img-playnow" width="100%" height="50" alt="profile" /> } </Link> : <Link to={"/more/login"} className="btn-lotto">{(window.lang.lang == "en") ? <img src="/img/apz/golden-play-btn-img-en.png" className="img-playnow" width="100%" height="50" alt="profile" /> : <img src="/img/apz/golden-play-btn-img-mm.png" className="img-playnow" width="100%" height="50" alt="profile" /> }</Link> }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};


let checklogin = () => {
      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              return true;
          }
      }
      return false;
  }

class HomeLottoPage extends React.Component {
  constructor(props) {
    super(props);
    window.BPdata.footerMenu = "home";

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      login : 0,
      is_verified : 1,
      selected: 0,
      dataSource,
      isLoading: true,
      // virtual data
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'NEWS',
      open: false,
      fopen: false,
      advopen : false,
      menu : window.BPdata.mainmenu,
      wallet : [], 
      seconds: 5, 
      isCounting: false,
      disCount: "",
      game_contest: [],
      game_contest_finish  : [],
      game_contest_process  : [],
      lobbyItems : [
        { id: 1, name: 'Item 1', countdown: 60 },
        { id: 2, name: 'Item 2', countdown: 120 },
        { id: 3, name: 'Item 3', countdown: 30 },
      ]

    };



  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  onOpenModal = () => {
    this.setState({ open: true, fopen: true,  advopen : true });
  };

  onCloseModal = () => {
    this.setState({ open: false , fopen: false, advopen : false });


    //localStorage.setItem('popup', false);
    // window.BPdata.popup = false
    // console.log(window.BPdata.popup)
  };

  close = () => {
    this.startCountdown();
    setTimeout(() => this.onCloseModal(), 5000  )
  };

  startCountdown = () => {
      this.setState({ isCounting: true });
      const intervalId = setInterval(() => {
          this.setState((prevState) => {
              if (prevState.seconds === 0) {
                  clearInterval(intervalId);
                  this.setState({ isCounting: false });
                  return { seconds: 5 }
              }
              return { seconds: prevState.seconds - 1 }
          });
      }, 1000);
  }

  currencyFormat(num) {
      return num;
      return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
     // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  componentDidMount() {

    window.scrollTo(0, 0);

    // this.setState({ fopen: true });

    let menu = ["/"];

    localStorage.setItem("route", JSON.stringify(menu) );
    //console.log(localStorage.getItem('route'));
    //console.log(window.BPdata.apiUrl)
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);



    const self = this;

      // if(localStorage.getItem('wallet')) {

      //   if(JSON.parse(localStorage.getItem("wallet")) != null) {
      //     let wallet = JSON.parse(localStorage.getItem("wallet"));
      //     this.setState({wallet: wallet})
      //   }
      // }

      //console.log(localStorage.getItem('popup'));
      //this.fetchIndexUserhData();
      clearTimeout(window.BPdata.liveurl);

      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({login: 1})

              this.fetchIndexUserhData(window.BPdata.apiUrlCMS+'/cron');

          }
      } else {
        this.setState({login: 0})
      }


      this.fetchLobbyData();
      this.fetchUserData();

      // Set the target time (5 minutes from now)
      // var targetTime = new Date();
      // targetTime.setMinutes(targetTime.getMinutes() + 5);

      // Update the countdown every second
      
      // window.countdownInterval = setInterval(this.updateCountdown(targetTime), 1000);

  }

  lobbyui_process1() {
    let obj
    let rowID
    let main = []
    let ui = [];

    let adcount = 0;
    if(this.state.game_contest_process.length  > 0){
        // ui.push(<div className="am-list-header" key="0"><div className="list-header bg-white"><img src="/img/newsicon.png" className="list-icon-circle" alt={window.lang.title.latest_news}  /><span>{window.lang.title.latest_news} </span></div></div>)
        // Outer loop to create parent
        for (let i = 0; i < this.state.game_contest_process.length; i++) {
          obj = this.state.game_contest_process[i];
          rowID = i;

          
                ui.push(
              <Link to={"/aungparzay/shoot/"+obj.id}>
              <div className=""  key={"second"+obj.id} >
                <div class="game-content lotto-wrapper mb-100">
                  <div class= "grid-100 lotto-first">
                    <p class="lotto-g-no">No-{obj.gcontest_id}</p>
                    <div className="timer-wrap">
                      <span class="timer">{window.lang.apz.close_time} <span class="closetime-text ml-10">{moment(obj.schedule_at, 'YYYY-MM-DD hh:mm:ss').format('hh:mm')}</span></span>
                      <h2>00 : 05 : 20 { this.state.disCount }</h2>
                      <div className="btn-text btn-section">
                        {(checklogin()) ?  <Link to={"/aungparzay/shoot/"+obj.id} className="btn-lotto"><img src="/img/apz/play-btn-img.png" className="img-playnow" width="100%" height="50" alt="profile" /></Link> : "" }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              );
            
            
        
        }
        //main.push(<div key={0} width="100%">{ui}</div>)
        return ui
    }
  }

  lobbyui_finish() {
    let obj
    let rowID
    let main = []
    let ui = [];

    let adcount = 0;
    if(this.state.game_contest_finish.length  > 0){
        // ui.push(<div className="am-list-header" key="0"><div className="list-header bg-white"><img src="/img/newsicon.png" className="list-icon-circle" alt={window.lang.title.latest_news}  /><span>{window.lang.title.latest_news} </span></div></div>)
        // Outer loop to create parent
        for (let i = 0; i < this.state.game_contest_finish.length; i++) {
          obj = this.state.game_contest_finish[i];
          rowID = i;

          
                ui.push(
              <Link to={"/aungparzay/shoot/"+obj.id}>
              <div className=""  key={"second"+obj.id} >
                <div className="game-content lotto-wrapper close-wrapper mb-100">
                  <div className= "grid-100 lotto-first">
                    <p className="lotto-g-no">No-{obj.gcontest_id}</p>
                    <div className="timer-wrap">
                      <span className="timer-result">{window.lang.apz.result} : <span className="closetime-result"> {obj.rule_regulation}</span></span>
                      <div className="btn-section close-section flex mt-10" >
                        <div className="two-wrap close-section-content">
                            <h3 className="result-number">{ obj.rule_regulation.toString().slice(-2) }</h3>
                            <p className="title-result-section">2D {window.lang.apz.result}</p>
                        </div>

                        <div className="three-wrap close-section-content">
                            <h3 className="result-number">{ obj.rule_regulation.toString()
                            .slice(-3) }</h3>
                            <p className="title-result-section">3D {window.lang.apz.result}</p>
                        </div>
                      </div>
                      <span className="last-timer">{window.lang.apz.close_time} <span class="closetime-text ml-10">{moment(obj.schedule_at, 'YYYY-MM-DD hh:mm:ss').format('hh:mm')}</span></span>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
              );
            
            
        
        }
        //main.push(<div key={0} width="100%">{ui}</div>)
        return ui
    }
  }

  fetchIndexUserhData(url) {

      let dataurl = url;



      axios.get(dataurl, {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "no-cors", // no-cors, cors, *same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        //credentials: "same-origin", // include, *same-origin, omit
        crossDomain: true,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "X-Trident-Token" : window.BPdata.token,
            "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"         : localStorage.getItem(window.BPdata.time)
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrer: "no-referrer", // no-referrer, *client
        //body: JSON.stringify(data), // body data type must match "Content-Type" header
      })
      .then(resp => {
          // console.log(resp.data)

          // {"thaidate":"29-11-21 13:11",
          // "set":"1,600.24",
          // "value":"64,409.60M",
          // "created_at":"2021-11-29 12:41:10",
          // "number":"49"}
          // this.setState({
          //     status_code : resp.data.status_code,
          //
          // });



          if(resp.data.status_code == "404") {

            localStorage.setItem(window.BPdata.time, 0);
            localStorage.setItem('username', "");

            swal({
              title: "Session Expire",
              text: window.lang.errors.contact_cs,
              icon: "warning",
              dangerMode: true,
            })
            .then(willDelete => {
            if (willDelete) {
                localStorage.setItem(window.BPdata.time, 0);
                localStorage.setItem('username', "");
                this.props.history.push("/more/login")
            }
            });

              // swal( window.lang.errors.contact_cs );
              // console.log(resp.data.status_code)



          }


          //this.setState({ isLoading: false });

      })
      .catch(function(error) {
        //console.log(error.tostring());
      });

      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {

              window.BPdata.liveurl =  setTimeout(() => this.fetchIndexUserhData(url), 10000);

          }
      }



  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }

  fetchLobbyData() {
      //localStorage.getItem(window.BPdata.time)
      // api/v1/cms/lobbylist
      const url = window.BPdata.apiAPZUrlCMS+'/lobbylist?transfer_type=0&provider_id='+window.BPdata.provider_id;
      //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

      axios({
        method: 'post',
        url: url,
        data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
        headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"         : localStorage.getItem(window.BPdata.time)
          },
      })
      .then(resp => {

          if(resp.data) {
            
              this.setState({
                game_contest_finish  : resp.data.game_contest.finish.data,
                game_contest_process  : resp.data.game_contest.process.data,
                // wallet        : resp.data.customer_wallet,
              });

              //console.log(resp.data.game_contest.data)

              // localStorage.setItem("sbsetting", JSON.stringify(resp.data.setting) )

              // localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )

              // console.log(resp.data);
              this.setState({login: 1})

          } else {
              this.setState({login: 0})
          }


        })
        .catch(function(error) {
          // console.log(error);
        });

        window.BPdata.liveurl =  setTimeout(() => this.fetchLobbyData(url), 10000);
  }

  fetchUserData() {
        //localStorage.getItem(window.BPdata.time)
        const url = window.BPdata.apiUrl+'/cms/player/show?transfer_type=0&provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        axios({
          method: 'post',
          url: url,
          data: { time : window.BPdata.rand_compound+"kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
          headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(resp => {

            if(resp.data) {
                this.setState({
                  companyuserid : resp.data.data.companyuserid,
                  username      : resp.data.data.first_name,
                  wallet        : resp.data.customer_wallet,
                  is_verified   : resp.data.data.is_verified
                });

                localStorage.setItem("sbsetting", JSON.stringify(resp.data.setting) )

                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )



                // console.log(resp.data);


                this.setState({login: 1})

            } else {
                this.setState({login: 0})
            }


          })
          .catch(function(error) {
            // console.log(error);
          });
    }

  

  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items

    return (
      <div className="page apz-gawli">
        
        
        <div className="page-content apz-main-bg" id="homeicon">
            <ApzHeader backLink="/" titleType="1" title={window.lang.apz.name} isLoading="false" />

              <div className="grid-100 lotto mt-30 mb-30">
                  <img src="/img/apz/abz-logo-n.png" className="round-img1 aungparzay-icon" alt="profile" />
                     {/* <div className="grid-100 text-center">
                        Download Tha Htay Lay Mobile App &nbsp;&nbsp;<a className="btn btn-success" href="">Download</a>
                      </div>*/}
              </div>

              <div className="main-content lotto-page-content">    
              
                  
              <LobbyList items={this.state.game_contest_process} />

                {/* { this.lobbyui_process() } */}

                { this.lobbyui_finish() }

              
                {/* <HomeIconList></HomeIconList> */}

              </div>

        </div>

        <footer  className="App-footer apz-gawli-footer">

          <div className="App-footer-inner apz-footer">
            {/* {window.BPdata.footerMenu} */}
            <Grid data={window.lang.apzfooter}
                columnNum={3}
                renderItem={dataItem => (

                  <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                        { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="35px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="35px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="35px"/><br />{dataItem.text} </span> }

                  </Link>
                )}
              />

          </div>
        </footer>

      </div>
    );
  }
}

export default HomeLottoPage;
