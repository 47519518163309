// useFirebaseMessaging.js
import { useEffect } from "react";
import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "./firebaseConfig";

const VAPID_KEY = 'YOUR_PUBLIC_VAPID_KEY'; // Replace with your actual VAPID key

export const useFirebaseMessaging = () => {
    useEffect(() => {
        async function requestPermissionAndGetToken() {
            try {
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    const token = await getToken(messaging, { vapidKey: VAPID_KEY });
                    console.log('FCM Token:', token);
                } else {
                    console.log('Permission not granted');
                }
            } catch (error) {
                console.error('Error getting permission or token:', error);
            }
        }

        requestPermissionAndGetToken();

        // Handle incoming messages while the app is in the foreground
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            // Customize your notification handling here
        });
    }, []);
};
