// import React from 'react';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
  import { Grid } from 'antd-mobile';
  import AppHeader from './../AppHeader';


import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  FacebookIcon
} from "react-share";

const styles = {
    // bgColor: 'white',
    titleTextColor: "#00008B",
    rowTitleColor: "#00008B",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    animate: true,
    arrowIcon: "V",
    tabFocus: true
};

let NUM_ROWS = 10;

let URL = "http://thahtaylay.com/";

class SharePage extends React.Component {
    constructor(props) {
            super(props)
            window.BPdata.footerMenu = "HelpCenter";

            this.state = {
                banners: [],
                select_banners: [],
                step: "login",
                message : "",
                title: "",
                promotion_id : 0,

            };
          this.changeSubMenu      = this.changeSubMenu.bind(this);

        }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    changeSubMenu(id) {

        // alert(id)
        let banners = this.state.banners;
        let select_banners = [];


        for(let i = 0; i< this.state.banners.length; i++){

            if(banners[i].promotion_id == id) {
                select_banners.push(banners[i])
            }

        }

        if(id == 0) {
            select_banners = this.state.banners;
        }
        // console.log(select_banners)
        // let check = Object.keys(banners).forEach(key => banners[key].promotion_id = id);

        //  console.log(check);
        //  return ;
        // let index = banners.findIndex(obj => obj.promotion_id == id);

        // console.log(index);

        this.setState({ promotion_id : id , select_banners : select_banners });

    }

    fetchData() {

        const url = window.BPdata.apiUrlCMS+'/news?limit='+NUM_ROWS+'&start=10&featured=0';

        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {

          let data = resp.data.banners;

          localStorage.setItem("banners", JSON.stringify(data) )

          // console.log(localStorage.getItem("banners")+"-----------");

          if(JSON.parse(localStorage.getItem("banners"))) {
            let video_slides = resp.data.banners;
            this.setState({banners: video_slides})
          }

            this.changeSubMenu(0);

          })
          .catch(function(error) {
            // console.log(error);
          });
      }


    componentDidMount() {

        if(localStorage.getItem('banners')) {

          if(JSON.parse(localStorage.getItem("banners")) != null) {
            let video_slides = JSON.parse(localStorage.getItem("banners"));
            this.setState({banners: video_slides})
          }
        }

        this.fetchData();

        window.scrollTo(0, 0);


    }


    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }


    render() {
        return (
            <div className="page">
                <div className="page-content">
                <AppHeader backLink="" titleType="2" title={window.lang.more.share_app} isLoading="false" />

                    <div className="main-content ">
                        <div className="row">

                            <div className="grid-100 text-center">
                                <br />
                                <br />
                                <h2 class="color-theme-gold" style={{ "font-size": "24px"}}>{window.lang.messages.share_your_friend}</h2>

                                <br />
                                <br />
                              {/*  Facebook
                                Twitter
                                Google+
                                Line
                                Whatapp
                                Messager
                                Wechat
                                SMS*/}

                                <div className="row mt-10">
                                    <FacebookShareButton
                                        url={URL}
                                        quote={"Shwe Bet"}
                                        hashtag={"#shwebet"}
                                        description={"Football Gaming in Myanmar"}
                                        className="social_app grid-20 left"
                                      >
                                        <img src="/img/social/facebook.png" height="50px"/>
                                        <br />
                                        <span>Facebook</span>
                                    </FacebookShareButton>

                                    <TelegramShareButton
                                        url={URL}
                                        title={"Shwe Bet"}
                                        hashtag={"#shwebet"}
                                        description={"Football Gaming in Myanmar"}
                                        className="social_app grid-20 left"
                                      >
                                        <img src="/img/social/telegram.png" height="50px"/>
                                        <br />
                                        <span>Telegram</span>
                                    </TelegramShareButton>

                                    <LineShareButton
                                        url={URL}
                                        title={"Shwe Bet"}
                                        description={"Football Gaming in Myanmar"}
                                        className="social_app grid-20 left"
                                      >
                                        <img src="/img/social/line.png" height="50px"/>
                                        <br />
                                        <span>Line</span>
                                    </LineShareButton>

                                    <WhatsappShareButton
                                        url={URL}
                                        quote={"Shwe Bet"}
                                        hashtag={"#shwebet"}
                                        description={"Football Gaming in Myanmar"}
                                        className="social_app grid-20 left"
                                      >
                                        <img src="/img/social/whatapp.png" height="50px"/>
                                        <br />
                                        <span>Whatapp</span>
                                    </WhatsappShareButton>

                                     <ViberShareButton
                                        url={URL}
                                        title={"Shwe Bet"}
                                        description={"Football Gaming in Myanmar"}
                                        className="social_app grid-20 left"
                                      >
                                        <img src="/img/list/viber1.png" height="50px"/>
                                        <br />
                                        <span>Viber</span>
                                    </ViberShareButton>

                                </div>

                                <div className="row">


                                    {/*<FacebookMessengerShareButton
                                        appId="333"
                                        url={"http://shwebet.com/"}
                                        quote={"Shwe Bet"}
                                        hashtag={"#shwebet"}
                                        description={"Football Gaming in Myanmar"}
                                        className="social_app grid-20 left"
                                      >
                                        <img src="/img/social/twitter.png" height="50px"/>

                                    </FacebookMessengerShareButton>*/}



                                </div>

                            </div>
                        </div>
                      <br />

                    <br />
                    </div>
                </div>

                <footer  className="App-footer">

                    <div className="App-footer-inner">
                      {/* {window.BPdata.footerMenu} */}
                      <Grid data={window.lang.footer}
                          columnNum={5}
                          renderItem={dataItem => (

                            <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                  { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                            </Link>
                          )}
                        />

                    </div>
                  </footer>
            </div>
        );
    }
}

export default SharePage;
