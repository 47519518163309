import React, { useState } from "react";
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {
    Link
  } from 'react-router-dom';
import mmph from 'myanmar-phonenumber';
import { Grid } from 'antd-mobile';



class SignUpPage extends React.Component {
    constructor(props) {
        super(props)
                   // case "line-up":   return "";
            // case "register":   return this.registerBlock();
            // case "home":    return this.homeBlock();
            // case "verify": return this.verifyBlock();
            // case "password": return this.passwordBlock();
            // case "profile": return this.profileBlock();
        this.state = { step: "register", message : "", form : {first_name: '', phone: '',otpcode: '', password: '', promo_code: '' }, seconds: 60, isCounting: false};

        //this.state = { step: "register", message : "", form : {username: '', phone: '',otpcode: '', password: '' }};
        this.validatePhone      = this.validatePhone.bind(this);
        this.resendOTP           = this.resendOTP.bind(this);
        this.checkValidationApi = this.checkValidationApi.bind(this);
        this.changePasswordApi  = this.changePasswordApi.bind(this);

        this.changestate        = this.changestate.bind(this);
        this.showHide           = this.showHide.bind(this);

    }

    startCountdown = () => {
        this.setState({ isCounting: true });
        const intervalId = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.seconds === 0) {
                    clearInterval(intervalId);
                    this.setState({ isCounting: false });
                    return { seconds: 60 }
                }
                return { seconds: prevState.seconds - 1 }
            });
        }, 1000);
    }

    handleOtpRequest = () => {
        // Send the phone number to the server to request a new OTP
        // ...
        this.startCountdown();
    }

    changestate() {
        this.setState({ step : "register" });
        // this.handleOtpRequest();
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {
        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "profile" });
            }

          }
        if(localStorage.getItem('username')) {
                this.setState({
                    form: {
                        first_name : localStorage.getItem('username')
                    }
                });
        }

        this.hisUrl('/more/sign-up');
    }

    showHide() {

        if(this.state.show_hide == false) {
            this.setState({ show_hide: true });
        } else {
            this.setState({ show_hide: false });
        }

    }

    checklogin() {
      if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              return true;
          }
      }
      return false;
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/sign-up');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    validatePhone() {
        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)
        if(form.phone.length >= 6) {
            if(mmph.isValidMMPhoneNumber(form.phone)){
                data = {
                    first_name : this.state.form.username,
                    phone : this.state.form.phone,
                    promo_code : this.state.form.promo_code,
                }
                this.setState({  message : "လုပ်ဆောင်နေပါသည်။" });
                this.sendSMSApi(data);
            } else {
                this.setState({  message : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။" });
            }

        } else {
            //console.log(this.state.message)
            this.setState({  message : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။" });
        }

    }

    resendOTP() {

        let form = this.state.form
        let data
        //console.log(this.state.form.phone.length)
        if(form.phone.length >= 6) {
            if(mmph.isValidMMPhoneNumber(form.phone)){
                data = {
                    first_name : this.state.form.username,
                    phone : this.state.form.phone
                }
                this.setState({  message : "လုပ်ဆောင်နေပါသည်။" });
                this.resendSMSApi(data);
            } else {
                this.setState({  message : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။" });
            }

        } else {
            //console.log(this.state.message)
            this.setState({  message : "ဖုန်းနံပါတ် မှားယွင်းနေပါသည်။" });
        }
    }

    resendSMSApi(formdata) {
        var self = this;
        let dataurl = window.BPdata.apiUrlCMS+'/player/password-reset';
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
            "X-Trident-Token" : window.BPdata.token,
            "BP-Agent"         : window.BPdata.rand_compound
          },
        })
        .then(function (response) {
            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } else if(response.data.data.status == 200) {
                self.setState({  message : "SMS ပို့ပြီးပါပြီ", step : "verify" });
                self.handleOtpRequest();
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }



    sendSMSApi(formdata) {
        var self = this;
        let dataurl = window.BPdata.apiUrlCMS+'/player';
        // let dataurl = window.BPdata.apiUrlCMS+"/player/login";
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound
            },
        })
        .then(function (response) {
            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } else if(response.data.data.status == 200) {
                self.setState({  message : "SMS ပို့ပြီးပါပြီ", step : "verify" });
                self.handleOtpRequest();
            }  else {
                self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    checkValidationApi() {
        let formdata = this.state.form ;
        var self = this;

        // let dataurl = window.BPdata.apiUrl+'/user/verifycode';
        let dataurl = window.BPdata.apiUrlCMS+'/player/verifycode';
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: { 'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound
            },
        })
        .then(function (response) {
            if(response.data.data.status == 403) {
                self.setState({  message : response.data.data.message });
            } else if(response.data.data.status == 200) {
                self.setState({  message : response.data.data.message , step : "password" });
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    changePasswordApi() {
        if(this.state.form.re_new_password.length >= 6) {
            let formdata = this.state.form ;
            var self = this;
            let dataurl = window.BPdata.apiUrlCMS+'/player/pwd';
            axios({
                method: 'post',
                url: dataurl,
                data: formdata,
                headers: { 'Content-Type': 'application/json;charset=UTF-8',
                    "X-Trident-Token" : window.BPdata.token,
                    "BP-Agent"         : window.BPdata.rand_compound
                },
            })
            .then(function (response) {

                if(response.data.data.status == 403) {
                    self.setState({  message : response.data.data.message });
                } else if(response.data.data.status == 200) {
                    self.setState({  message : response.data.data.message , step : "profile" });
                    // localStorage.setItem(window.BPdata.time, self.state.form.phone );
                    localStorage.setItem(window.BPdata.time, response.data.data.token );
                    localStorage.setItem('username', self.state.form.username );
                }  else {
                    self.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
                }

                //console.log(response.data.success);
            })
            .catch(function (error) {
                // console.log(error);
            });
        } else {
            this.setState({  message : "စကားဝှက်မှာအနည်းဆုံးအက္ခရာ ၆ လုံးရှိရမည်" });
        }

    }

    viewHandler() {

        switch(this.state.step) {
            // case "line-up":   return "";
            case "register":   return this.registerBlock();
            case "home":    return this.homeBlock();
            case "verify": return this.verifyBlock();
            case "password": return this.passwordBlock();
            case "profile": return this.profileBlock();
            default:      return "";
          }
    }

    homeBlock() {
        return <div>
            <br />
            <br />
            {/* <h1 className="color-theme-gold">SHWE SPORTS</h1> */}
            <img src="/img/shwesport.jpg" alt="profile" className="medium-logo"/>
            <br />
            <span>{this.state.message}</span>
            <br />
            <br />
            <div className="row">
                <Link to="/more/login" className="btn btn-yellow   pointer grid-50" style={{fontSize: "1rem"}}>{window.lang.form.login}</Link><br /><br />
                <Link to="/more/sign-up" className="btn btn-yellow grid-50" onClick={this.changestate} style={{fontSize: "1rem"}}>{window.lang.form.register}</Link>
            </div>
        </div>
    }

    registerBlock() {
        return <div className="row">
                <div className="row">
                    <div className="grid-100 grid-s-90">
                        <img src="/img/logo.png" height="250px" />
                        <br />
                        { (this.state.message !==  "") ?
                        <span>{this.state.message}
                        <br />
                        <br /></span>
                        : "" }
                    </div>
                </div>
                <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.name}</label>
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-center">
                            <input type="text" className="form-control text-center" name="username" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.username} /><br />
                            </div>
                        </div>

                    </div>
                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.phone}</label>
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left text-right">
                            <div className="form-group">
                            <input type="text" className="form-control text-center" placeholder="09**********" name="phone" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.phone} /><br />
                            </div>
                        </div>

                    </div>

                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.promocode}</label>
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 text-right">
                            <div className="form-group">
                            <input type="text" className="form-control text-center" placeholder="" name="promo_code" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.promo_code} /><br />
                            </div>
                            <br />
                            <Link to="/more/login" className="color-theme-gold">{window.lang.form.login}</Link> | &nbsp;&nbsp;
                            <input type="submit" className="btn btn-yellow   pointer grid-40" value={window.lang.form.submit} onClick={this.validatePhone} /><br />
                        </div>

                    </div>
        </div>
    }

    verifyBlock() {

        return <div>
                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label>{window.lang.form.verify_code} &nbsp;&nbsp;
                                                  {this.state.isCounting && <div>Time remaining: {this.state.seconds} seconds</div>}
                                                  {!this.state.isCounting && <a onClick={this.resendOTP} className="color-theme-gold   pointer">(Resend OTP)</a>}
                                </label>
                                <br />
                                { (this.state.message !==  "") ?
                                <span>{this.state.message}
                                <br />
                                <br /></span>
                                : "" }
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-center">
                            <input type="text" className="form-control text-center"  name="otpcode" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.otpcode} maxLength="6" /><br />
                            </div>
                            { (this.state.isCounting) ? <input type="submit" className="btn btn-yellow   pointer grid-50" value="Submit" onClick={this.checkValidationApi}  /> : <input type="submit" className="btn btn-yellow   pointer grid-50 disabled" value="Submit"  />}<br />
                        </div>

                    </div>
        </div>
    }

    passwordBlock() {
        return <div>
                    <div className="row page-contest">
                        <div className="grid-90 grid-s-90 left">
                            <div className="form-group text-left">
                                <label className="passLine">New Password</label>
                                {/* <br /> */}
                                { (this.state.message !==  "") ?
                                <span>{this.state.message}
                                {/* <br /> */}
                                </span>
                                : "" }
                            </div>
                        </div>
                        <div className="grid-90 grid-s-90 left">
                        <div className="form-group text-center" style={{ position: 'relative' }}>
                            <input
                                type={this.state.show_hide ? 'text' : 'password'}
                                className="form-control text-left"  value={this.state.form.password     } name="password" onChange={(e) => this.inputChangeHandler.call(this, e)}  placeholder={window.lang.form.new_password}
                            />
                            <span className='changePwd'
                                onClick={this.showHide}
                                style={{
                                position: 'absolute',
                                right: '20px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                color: 'black'
                                }}
                            >
                                {this.state.show_hide ? <FaEye className='iconColor' style={{color:'black'}} /> : <FaEyeSlash style={{color:''}} />}
                            </span>
                            </div>
                            <div className="form-group text-left mt-20">
                                <label className="passLine">Confirm Password</label>
                                {/* <br /> */}
                                { (this.state.message !==  "") ?
                                <span>{this.state.message}
                                {/* <br /> */}
                                </span>
                                : "" }
                            </div>
                            <div className="form-group text-center" style={{ position: 'relative' }}>
                            <input
                                type={this.state.show_hide ? 'text' : 'password'}
                                c className="form-control text-left"  value={this.state.form.re_new_password} name="re_new_password" onChange={(e) => this.inputChangeHandler.call(this, e)} placeholder={window.lang.form.re_new_password}
                            />
                            <span className='changePwd'
                                onClick={this.showHide}
                                style={{
                                position: 'absolute',
                                right: '20px',
                                top: '40%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                color: 'black'
                                }}
                            >
                                {this.state.show_hide ? <FaEye className='iconColor' style={{color:'black'}} /> : <FaEyeSlash style={{color:''}} />}
                            </span>
                            <br />

                            <br/>
                            </div>
                            <input type="submit" className="btn btn-yellow   pointer grid-50" value="Submit" onClick={this.changePasswordApi} /><br />
                        </div>

                    </div>
        </div>
    }

    profileBlock() {
        return <div>
            <div className="row">
                <br />
                { (this.state.message !==  "") ?
                        <span>{this.state.message}
                        <br />
                        <br /></span>
                        : "" }
                <h2>Mingalarpar {this.state.form.username}</h2>
                <p>Welcome to ThaHtayLay</p>
                <br />
                <img src="/img/logo.png" className="medium-logo" />
                <br />
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">
                <div className="page-content text-center">
                    <div className="main-content">
                        { (this.state.step !==  "home") ? <div className="row">
                        <br /><br />
                        <h1 className="color-theme-gold">{window.lang.form.register}</h1>
                        <br /></div>: ""}
                        { this.viewHandler() }
                    </div>
                </div>
                <footer  className="App-footer">

                    <div className="App-footer-inner">
                      {/* {window.BPdata.footerMenu} */}
                      <Grid data={window.lang.footer}
                          columnNum={5}
                          renderItem={dataItem => (

                            <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                  { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                            </Link>
                          )}
                        />

                    </div>
                  </footer>
            </div>
        );
    }
}

export default SignUpPage;
