import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Link
} from 'react-router-dom';

import swal from 'sweetalert';

import PopUp from "../../PopUp";
import { Grid } from 'antd-mobile';
import ApzHeader from '../../ApzHeader';
import ApzHeaderClone from './../../ApzHeaderClone';
import LoadingSpinner from "./../../../../LoadingSpinner";
import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'
var moment = require('moment');


let checklogin = () => {
    if (localStorage.getItem(window.BPdata.time)) {
        if (localStorage.getItem(window.BPdata.time) !== "0") {
            return true;
        }
    }
    return false;
}

const LobbyItem = ({ item }) => {

    // const [timeRemaining, setTimeRemaining] = useState(item.schedule_at);
    var currentTime = new Date();
  
  //  let init_timeRemaining = moment("2024-01-02 16:16:37", 'YYYY-MM-DD HH:mm:ss') - currentTime;
  
  
    let init_timeRemaining = moment(item.schedule_at, 'YYYY-MM-DD HH:mm:ss') - currentTime;
  
  
    const [timeRemaining, setTimeRemaining] = useState(init_timeRemaining);
    const [disCount, setDisCount] = useState("");
    // const [timeRemaining, setTimeRemaining] = useState(item.countdown);
  
    useEffect(() => {
  
        // Calculate the remaining time
  
        // Check if the countdown has reached zero
        if (timeRemaining >  0) {
  
          setDisCount(  moment.utc(init_timeRemaining).format('HH : mm : ss') );
  
          const intervalId = setInterval(() => {
            setTimeRemaining((prevTime) => prevTime - 1000);
          }, 1000);
  
  
          return () => clearInterval(intervalId);
            
        } else {
           
          setDisCount( "00 : 00 : 00"  );
          // setDisCount( "-- : -- : --"  );
  
          return () => clearInterval();
  
        }
      
    }, [timeRemaining]);
  
    return (
        <p>{ disCount }  </p>
    );
  };

class Bet2DSetPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "home";

        this.state = {
            bullet: 0,
            rcondi: 1,
            today_event: 0,
            fast: 0,
            toggle: false,
            isHeaderLoading: true,
            seen: false,
            step: "form",
            message: "",
            alertId: '',
            lottery: [
                {

                }
            ],
            wallet: [
                {
                    wallet_id: 1,
                    credit: 0
                },
                {
                    wallet_id: 2,
                    credit: 0
                },
                {
                    wallet_id: 3,
                    credit: 0
                }
            ],
            event: {},
            form: {
                shootnumbersingle: "",
                shootnumbertotal: "",
                shootfirst: "-",
                shootsecond: "-",
                shootthird: "",
                shootfourth: "",
                shootfive: "",
                mycart: [], amount: "", commission: 0, total: 0, rmycart: []
            },
            eventone: [],
            eventtwo: [],
            setting: [],
            twogame: [{ id: 0, title: '', max_amount: 0, min_amount: 0, amount: 0, gameplay_type: 0, game_type: 1, complete: 0 }],
            temp_numbers: [],
            editform: false,
            isEvent: false,
            post_id: 0,
            isLoading : true,
        };

        if (props.match.params.id) {
            this.state.post_id = props.match.params.id
        }

        this.togglesPop = this.togglesPop.bind(this);
        this.fastAction = this.fastAction.bind(this);
        this.keyboardAction = this.keyboardAction.bind(this);
        this.rulesAction = this.rulesAction.bind(this);
        this.betAction = this.betAction.bind(this);
        this.submitAction = this.submitAction.bind(this);
        this.backAction = this.backAction.bind(this);
        this.keyboardBackAction = this.keyboardBackAction.bind(this);
        this.endSessionAction = this.endSessionAction.bind(this);

        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);
        this.setReverse = this.setReverse.bind(this);

        this.btnfastType = this.btnfastType.bind(this);


        this.previewIndviAmountEdit = this.previewIndviAmountEdit.bind(this);
        this.previewIndviAmountDelete = this.previewIndviAmountDelete.bind(this);
        this.previewIndviAmountSave = this.previewIndviAmountSave.bind(this);

        this.inputEditChangeHandler = this.inputEditChangeHandler.bind(this);

        this.btnfastTimeSelect = this.btnfastTimeSelect.bind(this);
        this.btnTypeChange = this.btnTypeChange.bind(this);
        this.btnHandle = this.btnHandle.bind(this);

        this.setAmount = this.setAmount.bind(this);
        this.clearPos = this.clearPos.bind(this);
        this.randomNum = this.randomNum.bind(this);
        this.chooseKeyboardNumber = this.chooseKeyboardNumber.bind(this);
    }

    clearPos() {

        // console.log('---remove char--')

        // let shootnumber = this.state.form.shootnumber;

        let shootnumbersingle = this.state.form.shootnumbertotal.toString();
        // console.log(shootnumbersingle)
        //if(shootnumbersingle.length > 0){
        shootnumbersingle = shootnumbersingle.substring(0, shootnumbersingle.length - 1);
        // console.log(shootnumbersingle)
        // }

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,           // copy all other key-value pairs 
                shootnumbersingle: shootnumbersingle,
                shootnumbertotal: shootnumbersingle
            }
        }))

        // console.log('---remove char--')
        // console.log(this.state.form.shootnumbertotal.toString().length)

        if (this.state.form.shootnumbertotal.toString().length - 1 == 0) {

            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,           // copy all other key-value pairs 
                    shootfirst: "-",
                    shootsecond: "-",
                    shootthird: "",
                    shootfourth: "",
                    shootfive: "",
                }
            }))
        }

        if (this.state.form.shootnumbertotal.toString().length - 1 == 1) {

            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,           // copy all other key-value pairs 
                    shootfirst: this.state.form.shootnumbertotal.toString()[0],
                    shootsecond: "-",
                    shootthird: "",
                    shootfourth: "",
                    shootfive: "",
                }
            }))
        }



    }

    getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        let mathrand = Math.floor(Math.random() * (max - min + 1) + min);
        return mathrand.toString();
        // The maximum is inclusive and the minimum is inclusive
    }

    randomNum() {

        // let shootnumber = this.state.form.shootnumber;

        let shootnumbersingle = this.getRandomIntInclusive(0, 9) + this.getRandomIntInclusive(0, 9)

        // this.setState(prevState => ({
        //     ...prevState,
        //     form: {
        //         ...prevState.form,           // copy all other key-value pairs 
        //         shootnumbersingle : shootnumbersingle,
        //         shootnumbertotal : shootnumbersingle
        //     }
        // }))

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,           // copy all other key-value pairs 
                shootnumbersingle: shootnumbersingle,
                shootnumbertotal: shootnumbersingle,
                shootfirst: shootnumbersingle.toString()[0],
                shootsecond: shootnumbersingle.toString()[1],
            }
        }))


    }

    chooseKeyboardNumber() {
        // this.state.temp_numbers.push()
        // console.log(this.state.form.shootnumbertotal.toString())
        // console.log(this.state.form.shootnumbertotal.toString().length )
        if(this.state.form.shootnumbertotal.toString().length < 2) {
            return ;
        }
        let shootnumbersingle = this.state.form.shootnumbertotal.toString();
        this.setState((prevState) => ( {
            temp_numbers: [...prevState.temp_numbers, shootnumbersingle],
            form: {
                ...prevState.form,           // copy all other key-value pairs 
                shootnumbersingle: "",
                shootnumbertotal: "",
                shootfirst: "-",
                shootsecond: "-",
            }
        }));
        console.log(this.state.temp_numbers)
    }

    setAmount(text) {

        // let shootnumber = this.state.form.shootnumber;

        if (this.state.form.shootnumbertotal.toString().length < 2) {
            let shootnumbersingle = this.state.form.shootnumbertotal.toString();

            shootnumbersingle = shootnumbersingle + text;

            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,           // copy all other key-value pairs 
                    shootnumbersingle: shootnumbersingle,
                    shootnumbertotal: shootnumbersingle
                }
            }))
        }

        if (this.state.form.shootnumbertotal.toString().length == 0) {

            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,           // copy all other key-value pairs 
                    shootfirst: text
                }
            }))
        }

        if (this.state.form.shootnumbertotal.toString().length == 1) {

            this.setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,           // copy all other key-value pairs 
                    shootfirst: this.state.form.shootnumbertotal.toString()[0],
                    shootsecond: text
                }
            }))
        }

        // shootfirst: "",
        // shootsecond: "",
        // shootthird: "",
        // shootfourth: "",
        // shootfive: "",
        // console.log(this.state.form.shootnumbertotal)
    }

    percentage(partialValue, totalValue) {
        return ((100 * partialValue) / totalValue);
    }

    currencyFormat(num) {
        return num;
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    btnfastTimeSelect(e, id) {
        // this.setState()
        // id
        // console.log(id)
        this.setState({ step: "form", today_event: id - 1 });

        // this.fetchData(window.BPdata.apiUrlCMS+'/2d/formdata?token='+localStorage.getItem(window.BPdata.time)+"&type="+id);
        // this.fetchData(window.BPdata.apiUrlCMS + '/2d/formdata?type=' + id);
        this.fetchData(window.BPdata.apiAPZUrlCMS + '/2d/formdata?contest_id=' + this.state.post_id);

    }
    btnTypeChange(e, id) {
        // this.state.today_event = id;
        // console.log("ok")
        this.setState({ today_event: id });

        // console.log(this.state.lottery[0].even)
    }


    previewIndviAmountEdit(e, id) {
        // alert(e)
        // alert(id)
        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        // console.log(mycart);

        mycart[id]["edit_amount"] = 1;



        this.setState(prevState => ({
            form: {
                ...prevState.form,
                mycart: mycart
            },
        }))

        //this.previewIndviAmountSaveOption(id, mycart);

        //this.setState({editform : true});
    }

    previewIndviAmountSaveOption(id) {
        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        // console.log(mycart);

        mycart[id]["edit_amount"] = 1;



        let total = 0;
        let commission = 0;
        // let commission_percent = this.state.setting[3].option_value;
        let commission_percent = this.state.lottery[this.state.today_event].lottery_commission;


        mycart.forEach(function (each, key) {

            let amount = Number(each.amount);

            // let commission_amount = Math.round((commission_percent/ 100) * amount);
            let commission_amount = ((commission_percent / 100) * amount);

            // mycart[each.num_id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : number, "commission" : commission_amount, "product_type" : 1 , "edit_amount" : 0 , "max_amount" : each.max_amount , "total_amount" : each.total_amount };

            commission += commission_amount;
            total += amount;

            // console.log(commission)
            // console.log(total)

        })

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


        this.setState(prevState => ({
            form: {
                ...prevState.form,
                mycart: mycart,
                commission: commission,
                total: total
            },
        }))

    }

    previewIndviAmountSave(e, id) {
        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        // console.log(mycart);

        mycart[id]["edit_amount"] = 0;



        let total = 0;
        let commission = 0;
        // let commission_percent = this.state.setting[3].option_value;
        let commission_percent = this.state.lottery[this.state.today_event].lottery_commission;


        mycart.forEach(function (each, key) {

            let amount = Number(each.amount);

            // let commission_amount = Math.round((commission_percent/ 100) * amount);
            let commission_amount = ((commission_percent / 100) * amount);

            // mycart[each.num_id] = { "id" : each.id, "title" : each.title, "complete" : each.complete , "amount" : number, "commission" : commission_amount, "product_type" : 1 , "edit_amount" : 0 , "max_amount" : each.max_amount , "total_amount" : each.total_amount };

            commission += commission_amount;
            total += amount;

            // console.log(commission)
            // console.log(total)

        })

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


        this.setState(prevState => ({
            form: {
                ...prevState.form,
                mycart: mycart,
                commission: commission,
                total: total
            },
        }))

        // this.setState(prevState => ({
        //     form : {
        //         ...prevState.form,
        //         mycart: mycart,
        //         commission : commission,
        //         total : total
        //     }
        // }));


    }

    previewIndviAmountDelete(e, id) {

        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        delete mycart[id];


        let amount = this.state.form.amount;
        let number = Number(amount.replace(/^0+/, ''));

        let total = 0;
        let commission = 0;
        // let commission_percent = this.state.setting[3].option_value;
        let commission_percent = this.state.lottery[this.state.today_event].lottery_commission;

        // let mycart = [...this.state.form.mycart];

        mycart.forEach(function (each, key) {

            let commission_amount = ((commission_percent / 100) * each.amount);



            mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": each.amount, "commission": commission_amount, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };

            commission += commission_amount;
            total += parseInt(each.amount);

        })

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


        this.setState({
            form: {
                mycart: mycart,
                rmycart: this.state.form.rmycart,
                amount: this.state.form.amount,
                commission: commission,
                total: total
            }
        });

        // this.setState(prevState => ({
        //     form : {
        //         ...prevState.form,
        //         mycart: mycart
        //     },
        // }))

        // alert("ok");
    }

    inputEditChangeHandler(e, id, amount, max_amount, total_amount) {

        // console.log(e)
        // console.log(id)
        // console.log(amount)
        // let form = {...this.state.form};
        // form[e.target.name] = e.target.value;
        // this.setState({
        //     form
        // });

        let value = e.target.value;
        let min_amount = this.state.setting[0].option_value;

        if (isNaN(e.target.value)) {
            this.showErrorAlert("Set bet minimum amount must be " + min_amount + " Ks ", 'single');
            return;
        }

        // if(Number(value) < min_amount) {
        //     this.showErrorAlert("Minimum amount should have "+ (Number(min_amount)) +" Ks ", 'single');
        //     return ;
        // }

        let temp_max_amount = (Number(value) + Number(total_amount));

        // console.log(temp_max_amount)
        // console.log(max_amount < temp_max_amount)
        // console.log(max_amount-total_amount)

        if (Number(max_amount) < temp_max_amount) {

            this.showErrorAlert("သတ်မှတ်ထားသော ပမာဏထက် ကျော်လွန်နေပါသည်။", 'single');

            // this.showErrorAlert("This number set amount almost full. Maximum amount should have "+ (Number(max_amount)-Number(total_amount)) +" Ks ", 'single');
            return;
        }

        // alert(value)

        let mycart = [...this.state.form.mycart];

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

        // console.log(mycart[id]);

        let total = 0;
        let commission = 0;
        // let commission_percent = this.state.setting[3].option_value;
        let commission_percent = this.state.lottery[this.state.today_event].lottery_commission;

        // let commission_amount = Math.round((commission_percent/ 100) * value);
        let commission_amount = ((commission_percent / 100) * value);

        mycart[id]["amount"] = value;
        mycart[id]["commission"] = commission_amount;

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                mycart: mycart
            },
        }))

        this.previewIndviAmountSaveOption(id);

    }

    btnfastType(e, type) {
        // alert(type)
        // $data = [];
        // for (let i = 0; i < cars.length; i++) {
        //   text += cars[i] + "<br>";
        // }
        let filter;
        let filter2;



        let twogame = [...this.state.twogame];
        // twogame[key]["complete"] = e.target.checked;

        let pre_mycart = this.state.form.mycart;
        let mycart = [...this.state.form.mycart];

        let total = 0;
        let commission = 0;
        let rcondi = 1;

        twogame.forEach(function (each, key) {

            let title = each.title;



            if (type == 1) {

                filter = 49;
                filter2 = 100;

                if (key > filter && key < filter2) {
                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };
                }

                rcondi = 0;

            } else if (type == 2) {

                filter = 50;
                if (key < filter) {
                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };
                }

                rcondi = 0;

            } else if (type == 3) {


                let lastnum = ["1", "3", "5", "7", "9"];

                // console.log(title[1])

                // console.log(lastnum.indexOf(title[1]))

                // console.log(lastnum.indexOf(title[1]) )

                if (lastnum.indexOf(title[1]) != "-1") {

                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };
                }

                rcondi = 0;

            } else if (type == 4) {


                let lastnum = ["0", "2", "4", "6", "8"];

                if (lastnum.indexOf(title[1]) != "-1") {

                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };
                }

                rcondi = 0;

            } else if (type == 5) {

                if (title[1] + title[0] == title) {

                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };

                }

                rcondi = 0;

            }
            else if (type >= 6 && type <= 15) {

                //Round

                let lastnum = ["0"];

                if (type == 6) {
                    lastnum = ["0"];
                } else if (type == 7) {
                    lastnum = ["1"];
                } else if (type == 8) {
                    lastnum = ["2"];
                } else if (type == 9) {
                    lastnum = ["3"];
                } else if (type == 10) {
                    lastnum = ["4"];
                } else if (type == 11) {
                    lastnum = ["5"];
                } else if (type == 12) {
                    lastnum = ["6"];
                } else if (type == 13) {
                    lastnum = ["7"];
                } else if (type == 14) {
                    lastnum = ["8"];
                } else if (type == 15) {
                    lastnum = ["9"];
                }


                if (lastnum.indexOf(title[0]) != "-1" || lastnum.indexOf(title[1]) != "-1") {

                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };
                }


            } else if (type >= 16 && type <= 25) {

                //Head

                let lastnum = ["0"];

                if (type == 16) {
                    lastnum = ["0"];
                } else if (type == 17) {
                    lastnum = ["1"];
                } else if (type == 18) {
                    lastnum = ["2"];
                } else if (type == 19) {
                    lastnum = ["3"];
                } else if (type == 20) {
                    lastnum = ["4"];
                } else if (type == 21) {
                    lastnum = ["5"];
                } else if (type == 22) {
                    lastnum = ["6"];
                } else if (type == 23) {
                    lastnum = ["7"];
                } else if (type == 24) {
                    lastnum = ["8"];
                } else if (type == 25) {
                    lastnum = ["9"];
                }


                if (lastnum.indexOf(title[0]) != "-1") {

                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };
                }

            } else if (type >= 26 && type <= 35) {

                //Tail

                let lastnum = ["0"];

                if (type == 26) {
                    lastnum = ["0"];
                } else if (type == 27) {
                    lastnum = ["1"];
                } else if (type == 28) {
                    lastnum = ["2"];
                } else if (type == 29) {
                    lastnum = ["3"];
                } else if (type == 30) {
                    lastnum = ["4"];
                } else if (type == 31) {
                    lastnum = ["5"];
                } else if (type == 32) {
                    lastnum = ["6"];
                } else if (type == 33) {
                    lastnum = ["7"];
                } else if (type == 34) {
                    lastnum = ["8"];
                } else if (type == 35) {
                    lastnum = ["9"];
                }


                if (lastnum.indexOf(title[1]) != "-1") {

                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };
                }

            } else if (type >= 36 && type <= 39) {

                //Consetellation Power

                let lastnum = ["0"];

                if (type == 36) {
                    lastnum = ["07", "18", "24", "35", "69"];
                } else if (type == 37) {
                    lastnum = ["70", "81", "42", "53", "96","07", "18", "24", "35", "69"];
                } else if (type == 38) {
                    lastnum = ["05", "16", "27", "38", "49"];
                } else if (type == 39) {
                    lastnum = ["50", "61", "72", "83", "94","05", "16", "27", "38", "49"];
                }


                if (lastnum.indexOf(title) != "-1") {

                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };
                }

            }
            else if (type > 39) {


                let lastnum = ["0", "1"];

                if (type == 40) {
                    lastnum = ["0", "1"];
                } else if (type == 41) {
                    lastnum = ["2", "3"];
                } else if (type == 42) {
                    lastnum = ["4", "5"];
                } else if (type == 43) {
                    lastnum = ["6", "7"];
                } else if (type == 44) {
                    lastnum = ["8", "9"];
                }


                if (lastnum.indexOf(title[0]) != "-1") {

                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };
                }

            }


            commission += commission;
            total += 0;



        })

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


        this.setState(prevState => ({
            fast: type,
            step: 'form',
            rcondi: rcondi,
            form: {
                ...prevState.form,
                mycart: mycart,
                commission: commission,
                total: total
                // rmycart: this.state.form.rmycart,
                // amount : this.state.form.amount,
                // commission : this.state.form.commission,
                // total : this.state.form.total,

            },
            twogame: twogame,
            today_event: this.state.today_event

            // wallet : resp.data.customer_wallet,
            // permit : {
            //   ...prevState.permit,
            //   customer_banks : resp.data.data.customer_banks,
            //   specfic_bank_accounts : resp.data.bank_accounts,
            //   bank_accounts : resp.data.bank_accounts,
            // }
        }))


    }

    showSuccessAlert(message, type) {

        let output = "";
        if (type == "multi") {

            for (var i = 0; i < message.length; i++) {
                output += message[i];
            }

        } else {
            output = message;
        }

        // console.log(output)

        let alertId = StatusAlertService.showSuccess(output);
        // console.log(alertId)
        this.setState({ alertId });
    }

    removeAlert(message, type) {

        let output = "";
        if (type == "multi") {

            for (var i = 0; i < message.length; i++) {
                output += message[i];
            }

        } else {
            output = message;
        }

        // console.log(output)

        let alertId = StatusAlertService.showError(output);
        // console.log(alertId)
        this.setState({ alertId });

        // StatusAlertService.removeAlert(this.state.alertId);
    }

    showErrorAlert(message, type) {
        // let message = "message";
        // let alertId = StatusAlertService.showError(message);
        // // console.log(alertId)
        // this.setState({ alertId });

        let output = "";
        if (type == "multi") {

            for (var i = 0; i < message.length; i++) {
                output += message[i];
            }

        } else {
            output = message;
        }

        // console.log(output)

        let alertId = StatusAlertService.showError(output);
        // console.log(alertId)
        this.setState({ alertId });

    }

    togglesPop() {
        this.setState({
            seen: !this.state.seen
        });

    }

    getCurrentHour() {
        var day = new Date();
        var hr = day.getHours();

        if (hr >= 17) {
            if (this.getCurrentDay() != 5) {
                hr = 1;
            }
        }

        return hr;
    }

    getCurrentDay() {
        var day = new Date();
        var num_day = day.getDay();
        return num_day;
    }


    hisUrl(url) {
        if (localStorage.getItem("route")) {
            let menu = JSON.parse(localStorage.getItem("route"));
            //console.log(menu.length+"-------------")
            if (menu.length === "0") {
                let data = ["/"];
                localStorage.setItem("route", JSON.stringify(data));
            } else {
                if (menu[menu.length - 1] !== url) {
                    menu.push(url)
                    localStorage.setItem("route", JSON.stringify(menu));
                }
            }

        }

    }

    componentDidMount() {

        window.scrollTo(0, 0);

        if (localStorage.getItem('wallet')) {

            if (JSON.parse(localStorage.getItem("wallet")) != null) {
                let wallet = JSON.parse(localStorage.getItem("wallet"));
                this.setState({ wallet: wallet })
            }
        }

        if (localStorage.getItem(window.BPdata.time)) {
            if (localStorage.getItem(window.BPdata.time) !== "0") {
                //localStorage.setItem(window.BPdata.time, true);

                this.setState({ step: "form" });
                // this.setState({ step: "message" });

                //this.setState({ step: "form" });

            } else {
                this.props.history.push("/more/login");
            }
        } else {
            this.props.history.push("/more/login");
        }



        this.fetchData(window.BPdata.apiAPZUrlCMS + '/2d/formdata?contest_id=' + this.state.post_id);

        // this.fetchTwodData(window.BPdata.apiAPZUrlCMS+'/2d');


        // http://dep.thahtaylay.com/api/v1/cms/2d
        this.fetchUserData();
    }

    fetchUserData() {
        //localStorage.getItem(window.BPdata.time)
        const url = window.BPdata.apiUrl + '/cms/player/show?transfer_type=0&provider_id=' + window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);
    
        ///api/customer?transfer_type=2
    
        axios({
          method: 'post',
          url: url,
          data: { time: window.BPdata.rand_compound + "kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "X-Trident-Token": window.BPdata.token,
            "BP-Agent": window.BPdata.rand_compound,
            "BP-Time": localStorage.getItem(window.BPdata.time)
          },
        })
          .then(resp => {
    
            if (resp.data) {
              this.setState({
                companyuserid: resp.data.data.companyuserid,
                username: resp.data.data.first_name,
                wallet: resp.data.customer_wallet,
                is_verified: resp.data.data.is_verified,
                isHeaderLoading: false
              });
    // console.log(resp.data.customer_wallet)
              localStorage.setItem("sbsetting", JSON.stringify(resp.data.setting))
    
              localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet))
    
              // console.log(resp.data);
    
              this.setState({ login: 1 })
    
            } else {
              this.setState({ login: 0 })
            }
    
    
          })
          .catch(function (error) {
            // console.log(error);
          });
      }


    fetchTwodData(url) {

        let dataurl = url;

        // window.BPdata.liveurl = setTimeout(() => this.fetchTwodData(url), 10000);

        axios.get(dataurl, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "no-cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            crossDomain: true,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-Trident-Token": window.BPdata.token,
                "BP-Agent": window.BPdata.rand_compound,
                "BP-Time": localStorage.getItem(window.BPdata.time)
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
            .then(resp => {

                this.setState(prevState => ({
                    settrade: {
                        thaidate: resp.data.data.thaidate,
                        set: resp.data.data.set,
                        value: resp.data.data.value,
                        created_at: resp.data.data.created_at,
                        number: resp.data.data.number
                    },
                    eventone: resp.data.eventone,
                    eventtwo: resp.data.eventtwo,
                }))

            })
            .catch(function (error) {
                //console.log(error.tostring());
            });


    }

    fetchData(url) {

        let dataurl = url;

        axios({
            method: 'get',
            mode: "no-cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            //credentials: "same-origin", // include, *same-origin, omit
            crossDomain: true,
            url: dataurl,
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-Trident-Token": window.BPdata.token,
                "BP-Agent": window.BPdata.rand_compound,
                "BP-Time": localStorage.getItem(window.BPdata.time)
                // "Content-Type": "application/x-www-form-urlencoded",
            },
        })
            .then(resp => {
                // console.log("ok")
                if (resp.data.is_event) {

                    this.setState({
                        twogame: resp.data.data.data,
                        event: resp.data.event,
                        setting: resp.data.setting,
                        isLoading: false,
                        isEvent: resp.data.is_event,
                        lottery: resp.data.lottery,
                        is_offthaiday: resp.data.is_offthaiday,
                    });

                } else {

                    this.setState({
                        twogame: resp.data.data.data,
                        event: resp.data.event,
                        setting: resp.data.setting,
                        lottery: resp.data.lottery,
                        isLoading: false,
                        isEvent: false,
                        is_offthaiday: 1,
                    });

                }

                if (resp.data.wallet[0]) {

                    this.setState({
                        wallet: resp.data.wallet,
                    });

                    localStorage.setItem("wallet", JSON.stringify(resp.data.wallet))
                }

            })
            .catch(function (error) {
                //   console.log(error.tostring());
            });

            // window.BPdata.liveurl =  setTimeout(() => this.fetchData(url), 3000);

    }

    formChangeHandler(e) {
        let form = { ...this.state.form };
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }



    inputChangeHandler(e, key, unique_id, dbid, title, max_amount, total_amount) {
        // alert(dbid);
        let mycarts = [];

        let pre_twogame = this.state.twogame;

        let twogame = [...this.state.twogame];
        twogame[key]["complete"] = e.target.checked;

        // console.log(twogame)

        let mycart = [...this.state.form.mycart];

        let rmycart = [...this.state.form.rmycart];

        if (e.target.checked == false) {
            // console.log(e.target.checked)
            delete mycart[dbid]
        } else {

            //toggle R
            if (this.state.toggle == true) {
                // reverse twogame data complete
                // insert reverse mycart
                // insert mycart
                // twogame[key]["complete"] = e.target.checked;
                // mycart[dbid] = { "id" : dbid, "title" : title, "complete" : e.target.checked , "amount" : 0, "commission" : 0, "product_type" : 1 };

                let index = pre_twogame.findIndex(obj => obj.title == (title[1] + title[0]));
                // let fullindex = twogame.find(obj => obj.title==(each.title[1]+each.title[0]));

                // item = twogame.find(item=>item.title==(each.title[1]+each.title[0]));
                let chosenum = pre_twogame[index];


                rmycart[chosenum["num_id"]] = { "id": chosenum["id"], "num_id": chosenum["num_id"], "title": chosenum["title"], "complete": chosenum["complete"], "product_type": chosenum["product_type"] };

                // selftmycart not working
                mycart[chosenum["num_id"]] = { "id": chosenum["id"], "num_id": chosenum["num_id"], "title": chosenum["title"], "complete": chosenum["complete"], "product_type": chosenum["product_type"], "edit_amount": 0, "max_amount": chosenum["max_amount"], "total_amount": chosenum["total_amount"] };

                if (chosenum) {
                    chosenum["complete"] = true;
                }

            }

            //straight data insert
            mycart[dbid] = { "id": unique_id, "num_id": dbid, "title": title, "complete": e.target.checked, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": max_amount, "total_amount": total_amount };
        }

        // mycart.push({ "id" : dbid, "title" : title, "complete" : e.target.checked});

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        this.setState(prevState => ({
            rcondi: 1,
            form: {
                ...prevState.form,
                mycart: mycart,
                rmycart: rmycart,
                amount: this.state.form.amount,
                commission: this.state.form.commission,
                total: this.state.form.total

            },
            twogame: twogame
        }))


        // console.log(this.state.form.mycart)
        // console.log(this.state.mycart);
    }

    setReverse(e, toggle) {

        // console.log(toggle)

        let item;
        var index;

        let pre_mycart = this.state.form.mycart;
        let mycart = [...this.state.form.mycart];

        let pre_rmycart = this.state.form.rmycart;
        let rmycart = [...this.state.form.rmycart];

        let twogame = this.state.twogame;

        let artwogame = [...this.state.twogame];

        Object.keys(pre_rmycart).forEach(key => pre_rmycart[key] === undefined && delete pre_rmycart[key]);

        Object.keys(pre_rmycart).forEach(key => pre_rmycart[key] == null && delete pre_rmycart[key]);

        Object.keys(pre_mycart).forEach(key => pre_mycart[key] === undefined && delete pre_mycart[key]);

        Object.keys(pre_mycart).forEach(key => pre_mycart[key] == null && delete pre_mycart[key]);

        if (this.state.toggle == false) {

            pre_mycart.forEach(function (each, key) {

                // let retitle = each.title[0];
                // console.log(retitle)

                //two game index and mycart key

                if (each.title[1] + each.title[0] != each.title) {

                    index = twogame.findIndex(obj => obj.title == (each.title[1] + each.title[0]));
                    // let fullindex = twogame.find(obj => obj.title==(each.title[1]+each.title[0]));

                    // item = twogame.find(item=>item.title==(each.title[1]+each.title[0]));
                    let chosenum = artwogame[index];

                    // console.log(chosenum)

                    rmycart[chosenum["num_id"]] = { "id": chosenum["id"], "num_id": chosenum["num_id"], "title": chosenum["title"], "complete": chosenum["complete"], "product_type": chosenum["product_type"], "edit_amount": 0, "max_amount": chosenum.max_amount, "total_amount": chosenum.total_amount };

                    // alert(each.total_amount+each.title[1]+each.title[0])

                    // selftmycart not working
                    mycart[chosenum["num_id"]] = { "id": chosenum["id"], "num_id": chosenum["num_id"], "title": chosenum["title"], "complete": chosenum["complete"], "product_type": chosenum["product_type"], "edit_amount": 0, "max_amount": chosenum.max_amount, "total_amount": chosenum.total_amount };

                    // alert(each.total_amount+each.title[1]+each.title[0])

                    if (chosenum) {
                        chosenum["complete"] = true;
                    }



                }

            })

            // Object.keys(rmycart).forEach(key => rmycart[key] === undefined && delete rmycart[key]);

            // Object.keys(rmycart).forEach(key => rmycart[key] == null && delete rmycart[key]);

            // Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

            // Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);

            // console.log(artwogame[index])
            this.setState({
                form: {
                    shootnumbersingle: "",
                    shootnumbertotal: "",
                    shootfirst: "-",
                    shootsecond: "-",
                    shootthird: "",
                    shootfourth: "",
                    shootfive: "",
                    mycart: mycart,
                    rmycart: rmycart,
                    amount: this.state.form.amount,
                    commission: this.state.form.commission,
                    total: this.state.form.total
                },
                twogame: artwogame,
                toggle: true
            });


            // console.log(rmycart)

        } else {

            //undefiend clear


            // rmycart loop
            // twogame index
            // twogame uncomplete and mycart remove spec data



            // console.log("twogame ------- ")
            // console.log(pre_rmycart)

            pre_rmycart.forEach(function (each, key) {


                let fullindex = twogame.find(obj => obj.id == (each.id));
                index = twogame.findIndex(obj => obj.id == (each.id));
                //console.log(each)

                // index = twogame.findIndex(obj => obj.id==(each.id));

                // console.log(key + "full ---" +fullindex.id)

                // console.log(key + "---" +index)

                // console.log("listen ----- ")

                // console.log(artwogame[index])

                let chosenum = artwogame[index];

                if (chosenum) {
                    chosenum["complete"] = false;
                }

                // artwogame[index]["complete"] = 0;

                delete mycart[fullindex.id];
                delete rmycart[fullindex.id];




            })

            // Object.keys(rmycart).forEach(key => rmycart[key] === undefined && delete rmycart[key]);

            // Object.keys(rmycart).forEach(key => rmycart[key] == null && delete rmycart[key]);

            // Object.keys(mycart).forEach(key => (mycart[key] === undefined) && delete mycart[key]);
            // Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);



            this.setState({
                form: {
                    shootnumbersingle: "",
                    shootnumbertotal: "",
                    shootfirst: "-",
                    shootsecond: "-",
                    shootthird: "",
                    shootfourth: "",
                    shootfive: "",
                    mycart: mycart,
                    rmycart: rmycart,
                    amount: this.state.form.amount,
                    commission: this.state.form.commission,
                    total: this.state.form.total
                },
                twogame: artwogame,
                toggle: false
            });

        }

    }


    betAction() {

        // console.log(this.state.form.mycart)

        // console.log("----------")

        let self = this;

        if (this.state.form.amount > 0 || this.state.form.amount != "") {

            let min_amount = this.state.setting[0].option_value;

            if (isNaN(this.state.form.amount)) {
                // this.showErrorAlert("Set bet minimum amount must be "+ min_amount +" Ks ", 'single');
                this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် လျော့နည်းနေပါသည်။", 'single');
                return;
            }


            if (Number(this.state.form.amount) < min_amount) {
                // this.showErrorAlert("Set bet minimum amount must be "+ min_amount +" Ks ", 'single');
                this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် လျော့နည်းနေပါသည်။", 'single');
                return;
            }

            let twod_maximum_amount = this.state.twogame[0]['max_amount'];
            // let maximum_amount = twod_maximum_amount;

            // if(this.state.form.amount > maximum_amount) {
            //     this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် ကျော်လွန်နေပါသည်။", 'single');
            //     // this.showErrorAlert("Set bet maximum amount must be "+ maximum_amount +" Ks ", 'single');
            //     return ;
            // }


            if (this.state.form.mycart.length < 1) {
                this.showErrorAlert("Please select numbers!!! ", 'single');
                return;
            }

            if (this.state.form.amount[this.state.form.amount.length - 1] != 0) {
                this.showErrorAlert("အလျော်အစားတွက်ချက်ရာတွင် အခက်အခဲရှိပါသောကြောင့် 50 ဆတိုးကိန်းများသာ ထိုးပေးပါရန်" + this.state.form.amount[this.state.form.amount.length - 1], 'single');
                return;
            }

            if (this.state.form.amount[this.state.form.amount.length - 2] != 0 && this.state.form.amount[this.state.form.amount.length - 2] != 5) {
                this.showErrorAlert("အလျော်အစားတွက်ချက်ရာတွင် အခက်အခဲရှိပါသောကြောင့် 50 ဆတိုးကိန်းများသာ ထိုးပေးပါရန်" + this.state.form.amount[this.state.form.amount.length - 2], 'single');
                return;
            }

            let pre_mycart = this.state.form.mycart;

            let mycart = [...this.state.form.mycart];

            let amount = this.state.form.amount;
            let number = Number(amount.replace(/^0+/, ''));

            let total = 0;
            let commission = 0;
            let commission_percent = this.state.lottery[this.state.today_event].lottery_commission;

            Object.keys(pre_mycart).forEach(key => pre_mycart[key] === undefined && delete pre_mycart[key]);

            Object.keys(pre_mycart).forEach(key => pre_mycart[key] == null && delete pre_mycart[key]);

            //todototalamount
            pre_mycart.forEach(function (each, key) {

                let commission_amount = ((commission_percent / 100) * number);

                mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": number, "commission": commission_amount, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };

                commission += commission_amount;
                total += number;

            })

            Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

            Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


            this.setState(prevState => ({
                form: {
                    ...prevState.form,
                    mycart: mycart,
                    rmycart: this.state.form.rmycart,
                    amount: this.state.form.amount,
                    commission: commission,
                    total: total
                }
            }))


            this.setState({ step: "list" });


            // console.log(this.state.form.mycart)
        } else {

            this.showErrorAlert("Set bet amount!!!", 'single');
            return;
        }


    }

    endSessionAction() {
        this.setState({ step: "form" });

        // 
        // let event_time_id = parseInt(this.state.today_event) + 1;

        // this.fetchData(window.BPdata.apiUrlCMS + '/2d/formdata?type=' + event_time_id);
        this.fetchData(window.BPdata.apiAPZUrlCMS + '/2d/formdata?contest_id=' + this.state.post_id);


        this.setState({ form: { mycart: [], rmycart: [], amount: "", commission: 0, total: 0 } });


        // this.setState(prevState => ({
        //     step : "form",
        //     form : {
        //         ...prevState.form,
        //         mycart: [], 
        //         rmycart :[],
        //         amount : "",
        //         commission : 0,
        //         total : 0

        //     }
        // }))

        // this.fetchData(window.BPdata.apiUrlCMS+'/2d/formdata?type='+this.state.today_event);


    }

    backAction() {
        this.setState({ step: "form" });
    }

    keyboardBackAction() {

        let filter;
        let filter2;



        let twogame = [...this.state.twogame];
        // twogame[key]["complete"] = e.target.checked;

        let pre_mycart = this.state.form.mycart;
        let mycart = [...this.state.form.mycart];

        let total = 0;
        let commission = 0;
        let rcondi = 1;

        // console.log(this.state.temp_numbers)
        // return ;
        let temp_numbers = this.state.temp_numbers;

        twogame.forEach(function (each, key) {

            let title = each.title;


            

                //Consetellation Power


                let lastnum = temp_numbers;
                


                if (lastnum.indexOf(title) != "-1") {

                    twogame[key]["complete"] = 1;
                    mycart[each.num_id] = { "id": each.id, "num_id": each.num_id, "title": each.title, "complete": each.complete, "amount": 0, "commission": 0, "product_type": 1, "edit_amount": 0, "max_amount": each.max_amount, "total_amount": each.total_amount };
                }

            


            commission += commission;
            total += 0;



        })

        Object.keys(mycart).forEach(key => mycart[key] === undefined && delete mycart[key]);

        Object.keys(mycart).forEach(key => mycart[key] == null && delete mycart[key]);


        this.setState(prevState => ({
            form: {
                ...prevState.form,
                mycart: mycart,
                commission: commission,
                total: total
                // rmycart: this.state.form.rmycart,
                // amount : this.state.form.amount,
                // commission : this.state.form.commission,
                // total : this.state.form.total,

            },
            twogame: twogame,
            today_event: this.state.today_event
        }))
        
        this.setState({ step: "form" });
        
    }

    keyboardAction() {


        this.setState({ step: "shoot", fast: 0 });


        // if(this.state.fast == true) {
        //     this.setState({ step : "form" });
        // } else {
        //     this.setState({ step : "fast" , fast : 0});
        // }

    }
    rulesAction() {
        this.setState({ step: "rule", fast: 0 });
    }
    fastAction() {

        if (this.state.fast > 0) {

            let event_time_id = parseInt(this.state.today_event) + 1;

            // this.fetchData(window.BPdata.apiUrlCMS + '/2d/formdata?type=' + event_time_id);

            this.fetchData(window.BPdata.apiAPZUrlCMS + '/2d/formdata?contest_id=' + this.state.post_id);



            this.setState({ step: "fast", fast: 0, form: { mycart: [], rmycart: [] } });


        } else {

            this.setState({ step: "fast", fast: 0 });

        }

        // if(this.state.fast == true) {
        //     this.setState({ step : "form" });
        // } else {
        //     this.setState({ step : "fast" , fast : 0});
        // }

    }

    submitAction() {
        // alert("ok");
        if (!this.state.event.id) {
            this.showErrorAlert("Something was wrong!!!", 'single');
            return;
        }

        if (this.state.form.mycart.length < 1) {
            this.showErrorAlert("Please select numbers!!!", 'single');
            return;
        }

        let twod_maximum_amount = this.state.twogame[0]['max_amount'];
        let maximum_amount = twod_maximum_amount;

        if (this.state.form.amount > maximum_amount) {
            this.showErrorAlert("သတ်မှတ်ထားသော ငွေပမာဏထက် ကျော်လွန်နေပါသည်။", 'single');
            // this.showErrorAlert("Set bet maximum amount must be "+ maximum_amount +" Ks ", 'single');
            return;
        }

        let mycarts = [];
        let formdata = [];

        mycarts = this.state.form.mycart.filter(function (el) {
            return el != null;
        });

        let message = "";
        mycarts.forEach(function (each, key) {

            // console.log(each.max_amount-(each.amount+each.total_amount) + "-" + key)
            if ((Number(each.max_amount) - (Number(each.amount) + Number(each.total_amount))) < 0) {
                message += each.title + ", ";


                //swal("Please check these numbers " + (Number(each.max_amount)-(Number(each.amount)+Number(each.total_amount))))
            }
        })

        if (message != "") {
            swal("Please check these numbers " + message)
            // this.showErrorAlert(message, 'single');
            return;
        }

        this.setState({
            isLoading: true,
        });

        if (this.state.bullet == 1) {

            swal("လုပ်ဆောင်နေပါသည်။");
            return;
        }

        if (this.state.bullet == 0) {

            this.setState({
                bullet: 1,
            });
        }

        formdata = {
            game_type: 1, mycart: mycarts,
            amount: this.state.form.amount,
            commission: this.state.form.commission,
            total: this.state.form.total,
            contest_id: this.state.post_id,
            lottery_even: this.state.today_event

        };

        var self = this;
        const dataurl = window.BPdata.apiAPZUrlCMS + "/checkout?lang="+window.lang.lang;
        axios({
            method: 'post',
            url: dataurl,
            data: formdata,
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token": window.BPdata.token,
                "BP-Agent": window.BPdata.rand_compound,
                "BP-Time": localStorage.getItem(window.BPdata.time)
            },
        })
            .then(function (response) {

                // console.log(response.data.status_code)

                if (response.data.status_code == 404) {
                    self.removeAlert(response.data.message, 'multi');
                }

                if (response.data.status_code == 200) {
                    // self.showSuccessAlert(response.data.message, 'multi');
                    // self.setState({ step : "message" });
                    self.setState({ step: "message", toggle: false });


                }

                self.setState({
                    bullet: 0,
                    isLoading: false,
                });

                if (response.data.time) {

                    localStorage.setItem(window.BPdata.time, response.data.time);

                }
                // self.showSuccessAlert(response.data.message, 'multi');

                // console.log(response.data.message)

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    childui(item) {

        let self = this;
        let amount = this.state.form.amount;
        let ui = []
        //console.log("-------------"+data)

        if (Array.isArray(item) === false) {
            return ui;
        } else {

            let arlength = item.length;

            if (arlength > 0) {

                this.state.form.mycart.forEach(function (each, key) {


                    ui.push(<tr key={"ro-" + each.id}>

                        {(Number(each.max_amount) < (Number(each.amount) + Number(each.total_amount))) ? <td className="text-center pointer color-blue"> {each.title} </td> : <td className="text-center pointer"> {each.title}</td>}

                        <td colSpan="1" className="text-center">
                            {(each.edit_amount) ?
                                <input value={each.amount} onChange={(e) => self.inputEditChangeHandler.call(self, e, each.num_id, each.amount, each.max_amount, each.total_amount)} name="amount" className="form-control" /> : each.amount}

                        </td>
                        {/* <td colSpan="1" className="text-center">

                                { ((each.max_amount - each.total_amount) <= 0 ) ? "Limited" : (each.max_amount - each.total_amount) }

                            </td>*/}

                        {/* <td colSpan="1" className="text-center">

                            {each.commission}

                        </td> */}

                        <td>
                            {(each.edit_amount) ?
                                <b className="color-blue" onClick={(e) => self.previewIndviAmountSave.call(e, each, each.num_id)}><img src="/img/_save.png" height="30" /> </b> :



                                <b className="color-blue" onClick={(e) => self.previewIndviAmountEdit.call(e, each, each.num_id)} ><img src="/img/_edit.png" height="30" /> &nbsp;&nbsp; </b>}
                            <span className="" onClick={(e) => self.previewIndviAmountDelete.call(e, each, each.num_id)} >&nbsp;&nbsp;<img src="/img/_delete.png" height="30" /></span>
                        </td>

                    </tr>)

                })

                return ui
            }
        }

        return ui;
    }

    shootBlock() {

        let childui = [];

        childui.push(<div className="row">
            {/* <i className="pe-7s-back pe-2x pe-va " onClick={this.backAction}></i>
        <br /> */}
            <div className="fast-keyboard-wrap">
                <div className="random-no-wrapper">
                    <div className="flex choose-number">
                        <h3 className="timer">Betting Numbers</h3>
                        <img onClick={this.keyboardBackAction} src="/img/apz/close-btn.png" className="result" height="30" alt="result" />
                    </div>
                    <div className="number-bychoice">
                        {this.state.temp_numbers.map((value, index) => (
                            <React.Fragment key={index}>
                            <div key={index} className="grid-17 bychoice-inner">
                                {value}
                            </div>
                            {index < this.state.temp_numbers.length - 1 && (index + 1) % 5 === 0 && <br />} {/* Add line break after every 5 elements */}
                            </React.Fragment>
                        ))}
                    </div>
                    {/* <div className="number-bychoice flex">
                        <div className="grid-20 bychoice-inner">
                            32
                        </div> <br />
                        <div className="grid-20 bychoice-inner">
                            39
                        </div>
                        <div className="grid-20 bychoice-inner">
                            78
                        </div>
                        <div className="grid-20 bychoice-inner">
                            98
                        </div>
                        <div className="grid-20 bychoice-inner">
                            51
                        </div>
                    </div> */}
                    <div className="result-img resultbykey text-center mt-20">
                        {/* <p class="last-result-no">{this.state.form.shootnumbertotal}</p> */}
                        <p className="choose-no-key">{ this.state.form.shootfirst }{ this.state.form.shootsecond }</p>
                    </div>

                    <div className="random-keyboard-wrapper">

                        <div className="random-calculator-no flex text-center ">
                            <div className="grid-30 random-number">
                                <p onClick={this.setAmount.bind(this, 0)} className='pointer'>0</p>
                            </div>
                            <div className="grid-30 random-number">
                                <p onClick={this.setAmount.bind(this, 1)} className='pointer'>1</p>
                            </div>
                            <div className="grid-30 random-number">
                                <p onClick={this.setAmount.bind(this, 2)} className='pointer'>2</p>
                            </div>
                            <div className="grid-30 random-number">
                                <p onClick={this.setAmount.bind(this, 3)} className='pointer'>3</p>
                            </div>
                        </div>
                        <div className="random-calculator-no flex text-center mt-10">
                            <div className="grid-30 random-number">
                                <p onClick={this.setAmount.bind(this, 4)} className='pointer'>4</p>
                            </div>
                            <div className="grid-30 random-number">
                                <p onClick={this.setAmount.bind(this, 5)} className='pointer'>5</p>
                            </div>
                            <div className="grid-30 random-number">
                                <p onClick={this.setAmount.bind(this, 6)} className='pointer'>6</p>
                            </div>
                            <div className="grid-30 random-number">
                                <p onClick={this.setAmount.bind(this, 7)} className='pointer'>7</p>
                            </div>
                        </div>
                        <div className="random-calculator-no flex text-center mt-10">
                            <div className="grid-30 random-number">
                                <p class="random-r-text" onClick={this.randomNum.bind(this)}>R</p>
                            </div>
                            <div className="grid-30 random-number">
                                <p onClick={this.setAmount.bind(this, 8)} className='pointer'>8</p>
                            </div>
                            <div className="grid-30 random-number">
                                <p onClick={this.setAmount.bind(this, 9)} className='pointer'>9</p>
                            </div>
                            <div className="grid-30 random-number">
                                <p class="backspace-img" onClick={this.clearPos.bind(this)}><img src="/img/apz/mingcute_delete-back-fill.png" className="back" alt="back"  /></p>
                            </div>
                        </div>

                        {(checklogin()) ? <button className="btn-bet text-center mt-20 mb-10" onClick={this.chooseKeyboardNumber} >{ window.lang.more.apzchoicekey }</button> : ""}

                    </div>
                </div>
            </div>
        </div>)
        //Please Login


        return childui;
    }

    rulesBlock() {

        let childui = [];

        childui.push(<div className="two-game-wrapper rule-popup">
            {/* <i className="pe-7s-back pe-2x pe-va " onClick={this.backAction}></i>
        <br /> */}
              <div className="title-wrapper rules-lightbox text-center">
              <img onClick={this.backAction} src="/img/apz/close-btn.png" className="result rules-closebtn" height="30" alt="result" />
                {(window.lang.lang == "en") ?
                    <img src="/img/apz/apz-rule-title-righ-en.png" className="news-content" height="50px" alt="profile" />
                :
                    <img src="/img/apz/apz-rule-title-righ.png" className="news-content" height="50px" alt="profile" />
                }
                
              </div>
              
              <div className="rules-section flex">
                <div className="rules-right">
                    <p>1</p>
                </div>
                <div className="rules-left">
                    <p className="rules-text">{window.lang.more.apz_rule_one}</p>
                </div>
              </div>

              <div className="apz-ruleshead text-center">
                <h2>2D</h2>
                <img src="/img/apz/union.png" className="news-content" width="30%" alt="profile" />
              </div>
              <div className="rules-section flex">
                <div className="rules-right">
                    <p>2</p>
                </div>
                <div className="rules-left">
                    <p className="rules-text">{window.lang.more.apz_rule_two}</p>
                </div>
              </div>

              <div className="apz-ruleshead text-center">
                <h2>3D</h2>
                <img src="/img/apz/union.png" className="news-content" width="30%" alt="profile" />
              </div>
              <div className="rules-section rules-section-divider flex">
                <div className="rules-right">
                    <p>3</p>
                </div>
                <div className="rules-left">
                    <p className="rules-text">{window.lang.more.apz_rule_three}</p>
                </div>
              </div>

              <div className="gawlipit-section text-center">
              {(window.lang.lang == "en") ?
                    <img src="/img/apz/apz-rule-title-2-en.png" className="news-content" height="50px" alt="profile" />
                :
                    <img src="/img/apz/apz-rule-title-2.png" className="news-content" height="50px" alt="profile" />
                }
              </div>
              <div className="rules-section flex">
                <div className="rules-right gawli-rule-right">
                  <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
                </div>
                <div className="rules-left">
                    <p className="rules-text">{window.lang.more.gawlipyit_play_1}</p>
                </div>
              </div>
              <div className="rules-section flex">
                <div className="rules-right gawli-rule-right">
                  <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
                </div>
                <div className="rules-left">
                    <p className="rules-text">{window.lang.more.gawlipyit_play_2}</p>
                </div>
              </div>
              <div className="rules-section flex">
                <div className="rules-right gawli-rule-right">
                  <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
                </div>
                <div className="rules-left">
                    <p className="rules-text">{window.lang.more.gawlipyit_play_3}</p>
                </div>
              </div>
              <div className="rules-section flex">
                <div className="rules-right gawli-rule-right">
                  <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
                </div>
                <div className="rules-left">
                    <p className="rules-text">{window.lang.more.gawlipyit_play_4}</p>
                </div>
              </div>
              <div className="rules-section flex">
                <div className="rules-right gawli-rule-right">
                  <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
                </div>
                <div className="rules-left">
                    <p className="rules-text">{window.lang.more.gawlipyit_play_5}</p>
                </div>
              </div>
        </div>)
        //Please Login


        return childui;
    }

    fastBlock() {
        return <div className="two-game-wrapper">

            {/* <div className="grid-100 text-left">
                <i className="pe-7s-back pe-2x pe-va " onClick={this.backAction}></i>
            </div> */}

            <div className="grid-100 text-left">
                <i className="pe-7s-back pe-2x pe-va " onClick={this.backAction}></i>
                <br />
                <br />
                <h3 className="fast-titleset mb-20 ml-10">
                {window.lang.betgroup.select_number_group}
                </h3>
                
                <br />
                <span className="grid-20">
                    <span onClick={(e) => this.btnfastType.call(this, e, 1)} className="fast-chose-txt btn btn-secondary mr-10">{window.lang.betgroup.big}</span>
                </span>
                <span className="grid-20">
                    <span onClick={(e) => this.btnfastType.call(this, e, 2)} className="fast-chose-txt btn btn-secondary mr-10">{window.lang.betgroup.small}</span>

                </span>
                <span className="grid-20">
                    <span onClick={(e) => this.btnfastType.call(this, e, 3)} className="fast-chose-txt btn btn-secondary mr-10">{window.lang.betgroup.odd}</span>
                </span>
                <span className="grid-20">
                    <span onClick={(e) => this.btnfastType.call(this, e, 4)} className="fast-chose-txt btn btn-secondary mr-10">{window.lang.betgroup.even}</span>
                </span>
                <span className="grid-20">
                    <span onClick={(e) => this.btnfastType.call(this, e, 5)} className="fast-chose-txt btn btn-secondarym mt-10 mr-10">{window.lang.betgroup.same}</span>
                </span>

            </div>

            <div className="grid-100 text-left">
                <br />
                <h3 className="fast-titleset mb-20 ml-10">
                {window.lang.betgroup.round}
                </h3>
                <br />
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button mt-10 mb-20" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 6)} >0</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 7)} >1</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 8)} >2</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 9)} >3</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 10)} >4</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 11)} >5</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 12)} >6</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 13)} >7</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 14)} >8</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 15)} >9</span>
                    </label>
                </span>

            </div>

            <div className="grid-100 text-left">
                <br />
                <h3 className="fast-titleset mb-20 ml-10">
                {window.lang.betgroup.head}
                </h3>
                <br />
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button mt-10 mb-20" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 16)} >0</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 17)} >1</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 18)} >2</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 19)} >3</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 20)} >4</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 21)} >5</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 22)} >6</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 23)} >7</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 24)} >8</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 25)} >9</span>
                    </label>
                </span>

            </div>

            <div className="grid-100 text-left">
                <br />
                <h3 className="fast-titleset mb-20 ml-10">
                {window.lang.betgroup.tail}
                </h3>
                <br />
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button mt-10 mb-20" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 26)} >0</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 27)} >1</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 28)} >2</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 29)} >3</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 30)} >4</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 31)} >5</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 32)} >6</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 33)} >7</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 34)} >8</span>
                    </label>
                </span>
                <span className="grid-9">
                    <label role="checkbox" className="2d-fast-button" aria-checked="true">
                        <span className="fast-chose-txt bp-fast-checkbox-button__inner" onClick={(e) => this.btnfastType.call(this, e, 35)} >9</span>
                    </label>
                </span>

            </div>

            <div className="grid-100 text-left">
                <br />
                <h3 className="fast-titleset mb-20 ml-10">
                {window.lang.betgroup.consetellation} {window.lang.betgroup.power}
                </h3>
                <br />
            </div>

            <div className="grid-100 text-left flex consetell-wrap mt-10">
                <br />
                <div className="grid-60 text-left consetell-inner">
                    <span className="fast-chose-txt btn btn-secondary" style={{ textAlign: "left" }} onClick={(e) => this.btnfastType.call(this, e, 36)} >{window.lang.betgroup.consetellation} 07,18,24,35,69</span>
                </div>
               <div className="grid-30 text-left">
                    <span className="fast-chose-txt btn btn-secondary consetell-inner-r" style={{ textAlign: "left" }} onClick={(e) => this.btnfastType.call(this, e, 37)} >{window.lang.betgroup.consetellation} R</span>
               </div>
                <br />
            </div>

            <div className="grid-100 text-left flex consetell-wrap mt-20">
                <br />
                <div className="grid-60 text-left consetell-inner">
                    <span className="fast-chose-txt btn btn-secondary" style={{ textAlign: "left" }} onClick={(e) => this.btnfastType.call(this, e, 38)} >{window.lang.betgroup.power} 05, 16, 27, 38,49</span>
                </div>
                <div className="grid-30 text-left">
                    <span className="fast-chose-txt btn btn-secondary consetell-inner-r" style={{ textAlign: "left" }} onClick={(e) => this.btnfastType.call(this, e, 39)} >
                    {window.lang.betgroup.power} R</span>
                </div>
                <br />
            </div>

            <div className="grid-100 text-left">
                <br />
                <h3 className="fast-titleset mb-20 ml-10">
                {window.lang.betgroup.select_number}
                </h3>
                <br />
                <span className="grid-20">
                    <span onClick={(e) => this.btnfastType.call(this, e, 40)} className="fast-chose-txt btn btn-secondary mr-10 mt-10">00-19</span>
                </span>
                <span className="grid-20">
                    <span onClick={(e) => this.btnfastType.call(this, e, 41)} className="fast-chose-txt btn btn-secondary mr-10">20-39</span>

                </span>
                <span className="grid-20">
                    <span onClick={(e) => this.btnfastType.call(this, e, 42)} className="fast-chose-txt btn btn-secondary mr-10">40-59</span>
                </span>
                <span className="grid-20">
                    <span onClick={(e) => this.btnfastType.call(this, e, 43)} className="fast-chose-txt btn btn-secondary mr-10">60-79</span>
                </span>
                <span className="grid-20">
                    <span onClick={(e) => this.btnfastType.call(this, e, 44)} className="fast-chose-txt btn btn-secondary mr-10 mt-10">80-99</span>
                </span>

            </div>

        </div>
    }

    selecttimeBlock() {

        // ယနေ့ထီပိတ်ရက်ဖြစ်ပါသည်
        let lottery = this.state.lottery;

        var day = new Date();
        var hr = day.getHours();
        // getCurrentHour not real

        if (this.getCurrentDay() == 6) {

            return <div className="row">
                <div className="grid-100">
                    <h2>{window.lang.betgroup.lottery_holiday}</h2>
                </div>
            </div>


        } else {

            if (this.state.is_offthaiday == 1) {

                return <div className="row">
                    <div className="grid-100">
                        <h2>{window.lang.betgroup.lottery_holiday}</h2>
                    </div>
                </div>

            }


            if (this.getCurrentDay() == 0) {

                if (hr < 17) {

                    return <div className="row">
                        <div className="grid-100">
                            <h2>{window.lang.betgroup.tom_lottery_holiday}</h2>
                        </div>
                    </div>

                }
            }


            if (this.getCurrentDay() == 5) {

                if (hr > 17) {

                    return <div className="row">
                        <div className="grid-100">
                            <h2>{window.lang.betgroup.tom_lottery_holiday}</h2>
                        </div>
                    </div>

                }
            }



            return <div className="row">
                <div className="grid-100">
                    <h2>{window.lang.betgroup.select_time}  </h2>
                    <br />
                    <br />


                    {
                        this.btnHandle()
                    }
                    <br />
                    <div className="row text-center color-white">
                        {(this.getCurrentHour() < 16) ?
                            <span className='btn btn-yellow pointer grid-90' onClick={(e) => this.btnfastTimeSelect.call(this, e, this.state.today_event)}>{window.lang.betgroup.bet}</span> :
                            <span className='btn btn-yellow pointer grid-90 pointer-not-allowed'  >{window.lang.betgroup.bet_close}</span>}
                    </div>
                </div>
            </div>

        }

    }

    btnHandle() {
        let ui = [];
        let lottery = this.state.lottery
        let event = 0;
        lottery.forEach(number => {
            if (number.lottery_type == 1 && number.lottery_active == 1) {
                event += 1;
                ui.push(
                    <div>
                        {(this.getCurrentHour() < number.time) ?
                            <button className={"btn  grid-90  settwo"} onClick={(e) => this.btnTypeChange.call(this, e, number.id)} >{number.title}</button> :
                            <button className="btn btn-primary grid-90 pointer-not-allowed" disabled>{number.title}</button>
                        }
                        <br /><br />
                    </div>
                )
            }
        });

        return ui;
    }

    formBlock() {
        return <div className="row">

            {(this.state.isEvent == true) ?
                <div >

                    {(this.state.wallet[0]) ?
                        <div className="row pb-10">
                            <div className="top-shoot shoot-rule">
                                <div className="grid-40 single-shoot-game text-left ml-20">
                                    <h3 className="single-slot">NO-{this.state.event.gcontest_id}</h3>
                                    {/* <p>{moment.utc(this.state.event.created_at).local().format('DD-MM-YYYY')} </p> */}
                                    <LobbyItem key={this.state.event.id} item={this.state.event} /> 
                                </div>
                                <div className="grid-60 rule-btn">
                                {(checklogin()) ? <span onClick={this.rulesAction} className="btn btn-no-keyboard ml-10"><img src="/img/apz/gridicons_notice-outline.png" className="icon" alt="icon" />{ window.lang.more.apzrules }</span> : ""}
                                    {/* {(checklogin()) ? <Link to="/aungparzay/rules" className="btn-rule"><img src="/img/apz/gridicons_notice-outline.png" className="icon" alt="icon" /><span>{ window.lang.more.apzrules }</span></Link> : ""} */}
                                </div>
                            </div>

                            <div className="betting-numbers-wrap amount-title ml-20 text-left mt-30">
                                <h3 className="timer">{window.lang.apz.choose_betting_types}</h3>
                                <div className="choose_types">
                                    {(checklogin()) ? <Link to={"/aungparzay/bet-2d/" + this.state.post_id} className="btn-lotto btn-bet-now">2D</Link> : ""}
                                    {(checklogin()) ? <Link to={"/aungparzay/bet-3d/" + this.state.post_id} className="btn-lotto btn-bet-now inactive ml-20 isRevertMobile">3D</Link> : ""}
                                </div>
                                <div className="choose_types">
                                    {(checklogin()) ? <Link to={"/aungparzay/bet-3d/" + this.state.post_id} className="btn-lotto btn-bet-now inactive isMobile">3D</Link> : ""}
                                </div>
                            </div>

                            <div className="enter-amount-wrap amount-title mt-20 text-left">
                                <h3 className="timer">{ window.lang.BetListDetail.amount }</h3>

                                <div className="form-group min-amount mt-30">
                                    <input type="text" className="form-control shoot-amount" placeholder="100 Ks (min)" name="amount" onChange={(e) => this.formChangeHandler.call(this, e)} value={this.state.form.amount} />
                                </div>

                                <div className="bottom-amount mt-60">
                                    {(checklogin()) ? <Link to="#" className="btn-lotto btn-fast mr-10" onClick={this.fastAction}>{ window.lang.betgroup.fast }</Link> : ""}
                                    {(checklogin()) ? <Link to="#" className={`btn-lotto mr-10 ${this.state.toggle ? 'btn-rsuccess' : 'btn-reverse'}`} onClick={(e) => this.setReverse.call(this, e, 'on')}>R</Link> : ""}
                                    {(checklogin()) ? <button className="btn-lotto btn-bet-now" onClick={this.betAction} >{ window.lang.more.apzbetnow }</button> : ""}

                                </div>
                            </div>

                            <div className="betting-numbers-wrap amount-title ml-20 text-left">
                                <h3 className="timer" >{ window.lang.BetListDetail.apzchoosebet }</h3>
                            </div>
                            <div className="number-range mt-30 flex text-center">
                                {/* {(checklogin()) ? <Link to="#" className="btn-no-range mr-10"><span>From 000</span><img src="/img/apz/drop-down-img.png" className="dropdown-icon" alt="icon" /></Link> : ""} */}
                                {/* <div className="selet-option">
                                <select id="paginate" className="bg-aungparzay-darkred btn-no-range" onChange={this.paginateChange} value={this.state.setPaginate}>
                                    <option value="0">From 000</option>
                                    <option value="1">From 100</option>
                                    <option value="2">From 200</option>
                                    <option value="3">From 300</option>
                                    <option value="4">From 400</option>
                                    <option value="5">From 500</option>
                                    <option value="6">From 600</option>
                                    <option value="7">From 700</option>
                                    <option value="8">From 800</option>
                                    <option value="9">From 900</option>
                                </select>apzchoosebykeyboard
                               </div> */}
                                {/* {(checklogin()) ? <span onClick={this.keyboardAction} className="btn btn-no-keyboard ml-10">{ window.lang.BetListDetail.apzchoosebykeyboard }</span> : "-"} */}
                            </div>
                        </div>
                        : ""}

                    {/* <div className="row grid-95 text-left" >
                        <div className="popover__wrapper pl-20">
                            <a href="#" className="pl-20">
                                <b className="popover__title flex verticle-align-middle"><img src="/img/color_meaning.png" /> &nbsp;&nbsp;<span>{window.lang.betgroup.color_meaning}</span> </b>
                            </a>
                            <div className="popover__content">
                                <div className="popover__message">

                                    {window.lang.betgroup.color_box_one} &nbsp;
                                    <span className='color-yellow'>{window.lang.betgroup.color_three}</span> <br />
                                    {window.lang.betgroup.color_box_two} &nbsp;
                                    <span className='color-green'>{window.lang.betgroup.color_two}</span>
                                    <br />
                                    {window.lang.betgroup.color_box_three}
                                    <span className='color-red'>{window.lang.betgroup.color_four}</span> &nbsp;
                                    <br />
                                    {window.lang.betgroup.color_box_four}&nbsp;

                                    <span className='color-black'>{window.lang.betgroup.color_one}</span>


                                </div>
                            </div>
                        </div>
                    </div>
                    <br /> */}

                    <div className="row pb-10">
                        {this.state.twogame.map((item, i) => (
                            (this.percentage(item.total_amount, item.max_amount) < 100) ?
                                <span className="grid-15 apz-number-box"><label role="checkbox" className={`bp-checkbox-button ${item.complete ? 'is-checked' : ''}`} aria-checked="true" key={i}><input type="checkbox" className="bp-checkbox-button__original" value="000" onClick={(e) => this.inputChangeHandler.call(this, e, i, item.id, item.num_id, item.title, item.max_amount, item.total_amount)} defaultChecked={!!item.complete} />
                                    <span className="bp-checkbox-button__inner apz-inner-no">{item.title}
                                    </span>
                                </label>
                                </span>
                                :
                                <span className="grid-15 "><label role="checkbox" className={`bp-checkbox-button ${item.complete ? 'is-checked' : ''}`} aria-checked="true" key={i}>
                                    <span className="bg-gray bp-checkbox-button__inner disabled">{item.title}

                                    </span>
                                </label>
                                </span>

                        ))}
                    </div>
                </div>
                : window.lang.messages.not_available_yet}
        </div>
    }

    progressBarUi(percent, i) {

        let ui = []

        if (percent <= 50) {
            if (percent < 20) {
                percent = 20;
            }
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success" key={"pro-" + i}>
                    <div className="bp-progress-bar">
                        <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-yellow" style={{ width: percent + "%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        } else if (percent > 50 && percent < 90) {
            if (percent < 60) {
                percent = 60;
            }
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success" key={"pro-" + i}>
                    <div className="bp-progress-bar">
                        <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-green" style={{ width: percent + "%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        } else if (percent > 90 && percent < 100) {
            if (percent < 95) {
                percent = 80;
            }
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success">
                    <div className="bp-progress-bar">
                        <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-red" style={{ width: percent + "%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        } else {
            ui.push(
                <div role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" className="bp-progress numberProgessBar bp-progress--line is-success">
                    <div className="bp-progress-bar">
                        <div className="bp-progress-bar__outer" style={{ height: "6px" }}><div className="bp-progress-bar__inner bg-black" style={{ width: "100%" }}></div></div></div>
                    <div className="bp-progress__text" style={{ fontSize: "14.4px" }}><i className="bp-icon-circle-check"></i></div>
                </div>
            )
        }
        return ui;
    }

    listBlock() {
        return <div className="row">

            <div className="grid-95 ml-10 text-right">
                <span class="text-primary btn btn-yellow btn-small" onClick={this.backAction}><i className="pe-7s-plus pe-2x pe-va pointer"></i> {window.lang.BetListDetail.add}</span>
            </div>

            <div className="grid-95 ml-10 text-center">
                {window.lang.BetListDetail.odds}:{this.state.lottery[this.state.event.lottery_setting_id - 1].lottery_multiple}
            </div>

            <div className="grid-95 ml-10">
                <table className="table  table-border league-table" >
                    <thead>
                        <tr>
                            <th>2D</th>
                            <th>{window.lang.betgroup.bet_amount}</th>
                            {/* <th>{window.lang.betgroup.commission}</th> */}
                            <th>{window.lang.betgroup.action}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {this.childui(this.state.form.mycart)}

                        {/* <tr>
                            <td colSpan="2">{window.lang.betgroup.commission_total} (?)</td>
                            <td>{this.currencyFormat(this.state.form.commission)} Ks</td>
                            <td></td>
                        </tr> */}

                        <tr>
                            <td colSpan="2">{window.lang.betgroup.total}</td>
                            <td>{this.currencyFormat(this.state.form.total)} Ks</td>
                            {/* <td></td> */}
                        </tr>

                    </tbody>
                </table>
            </div>

            <button className="btn btn-yellow   pointer grid-95 " onClick={this.submitAction}>{window.lang.form.submit}</button>
            <br />
            <br />
        </div>
    }

    messageBlock() {
        return <div className="row">

            <div className="adm-card row grid-90 bg-noise ">

                <div className="adm-card-body grid-100 ">
                    <div className="grid-100 text-center ptp-10 pbp-10">

                        <img src="/img/apz/apz_success.png" height="100px" />
                        <br />
                        <br />
                        {/* <p>ထီထိုးခြင်း အောင်မြင်ပါသည်။</p> */}
                        <p class="success-text">{window.lang.BetListDetail.success}</p>
                    </div>


                </div>
            </div>
            <br />
            <br />

            {/* <button className="btn btn-yellow   pointer grid-90" onClick={this.endSessionAction}>အတည်ပြုသည်</button> */}
            <button className="btn btn-sus-text btn-yellow   pointer grid-90" onClick={this.endSessionAction}>{window.lang.BetListDetail.btn}</button>
            <br />
            <br />
        </div>
    }

    viewHandler() {


        switch (this.state.step) {
            // case "selecttime": return this.selecttimeBlock();
            case "form": return this.formBlock();
            case "list": return this.listBlock();
            case "message": return this.messageBlock();
            case "fast": return this.fastBlock();
            case "shoot": return this.shootBlock();
            case "rule": return this.rulesBlock();
            default: return "";
        }
    }

    render() {
        return (
            <div className="page">
                <div className="page-content apz-main-bg text-center">
                    {/* <ApzHeader backLink={"/aungparzay/shoot/" + this.state.post_id} titleType="2" title={window.lang.title.twod} isLoading="false" /> */}
                    {(this.state.isHeaderLoading) ?
                        <ApzHeaderClone backLink={"/aungparzay/shoot/" + this.state.post_id} titleType="2" title={window.lang.title.twod} isLoading="false" />
                        : <ApzHeader backLink={"/aungparzay/shoot/" + this.state.post_id} wallet={this.state.wallet} titleType="2" title={window.lang.title.twod} isLoading="false" />
                    }

                    <div className="main-content two_home">
                        <br />
                        {this.state.seen ? <PopUp toggle={this.togglesPop} /> : null}
                        {/*<button onClick={this.showErrorAlert}>Show success alert</button> */}
                        <div className="grid"><StatusAlert /></div>

                        {this.state.isLoading ? <div className="grid-100 text-center"><LoadingSpinner /></div> : this.viewHandler() }


                    </div>
                </div>

                <footer className="App-footer">

                    <div className="App-footer-inner">
                        {/* {window.BPdata.footerMenu} */}
                        <Grid data={window.lang.apzfooter}
                            columnNum={3}
                            renderItem={dataItem => (

                                <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{ width: '100%' }}>
                                    {(window.BPdata.footerMenu === dataItem.check) ? (dataItem.text == "My Profile") ? <span className="color-white"><img src={dataItem.icon} height="35px" /><br />{(this.checklogin()) ? dataItem.text : "Login / Register"}</span> : <span className="text-white"><img src={dataItem.icon_off} height="35px" /><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon} height="35px" /><br />{dataItem.text} </span>}

                                </Link>
                            )}
                        />

                    </div>
                </footer>

            </div>
        );
    }
}

export default Bet2DSetPage;
