import React, { useState, useEffect } from 'react';

import {
  Link
} from 'react-router-dom';
import PopUp from "../../PopUp";
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { Grid } from 'antd-mobile';
import ApzHeader from './../../ApzHeader';
import ApzHeaderClone from './../../ApzHeaderClone';
import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'

// import ScrollMenu from 'react-horizontal-scrolling-menu';

import swal from 'sweetalert';

require('./../../../../BPdata.js')
// require('./../../../../BPdata.js')

const alert = Modal.alert;

var moment = require('moment');

let checklogin = () => {
  if (localStorage.getItem(window.BPdata.time)) {
    if (localStorage.getItem(window.BPdata.time) !== "0") {
      return true;
    }
  }
  return false;
}

const LobbyItem = ({ item }) => {

  // const [timeRemaining, setTimeRemaining] = useState(item.expire_date);
  var currentTime = new Date();

  //  let init_timeRemaining = moment("2024-01-02 16:16:37", 'YYYY-MM-DD HH:mm:ss') - currentTime;


  // let init_timeRemaining = moment(item.expire_date, 'YYYY-MM-DD HH:mm:ss') - currentTime + 60000;

  let init_timeRemaining = moment(item.schedule_at, 'YYYY-MM-DD HH:mm:ss') - currentTime;


  const [timeRemaining, setTimeRemaining] = useState(init_timeRemaining);
  const [disCount, setDisCount] = useState("");
  // const [timeRemaining, setTimeRemaining] = useState(item.countdown);

  useEffect(() => {

    // Calculate the remaining time

    // Check if the countdown has reached zero
    if (timeRemaining > 0) {

      setDisCount(moment.utc(init_timeRemaining).format('HH : mm : ss'));

      const intervalId = setInterval(() => {
        setTimeRemaining((prevTime) => prevTime - 1000);
      }, 1000);


      return () => clearInterval(intervalId);

    } else {

      setDisCount("00 : 00 : 00");
      // setDisCount( "-- : -- : --"  );

      return () => clearInterval();

    }

  }, [timeRemaining]);

  return (
    <p>{disCount}  </p>
  );
};

class ShootPage extends React.Component {
  constructor(props) {
    super(props);
    window.BPdata.footerMenu = "home";


    this.state = {
      login: 0,
      is_verified: 1,
      selected: 0,
      isLoading: true,
      isHeaderLoading: true,
      alertId: '',
      form: {
        shootnumbersingle: "",
        shootnumbertotal: "",
        shootfirst: "",
        shootsecond: "",
        shootthird: "",
        shootfourth: "",
        shootfive: "",
      },
      detail: {
        shoot_record: []
      },
      leagueMenu: 'NEWS',
      open: false,
      fopen: false,
      advopen: false,
      toggle: false,
      seen: false,
      step: "shoot",
      menu: window.BPdata.mainmenu,
      wallet: [],
      seconds: 10,
      isCounting: false,
      post_id: 0,
      is_offday: 0
    };

    if (props.match.params.id) {
      this.state.post_id = props.match.params.id
    }

    this.togglesPop = this.togglesPop.bind(this);
    this.setAmount = this.setAmount.bind(this);
    this.clearPos = this.clearPos.bind(this);
    this.randomNum = this.randomNum.bind(this);
    this.rulesAction = this.rulesAction.bind(this);
    this.backAction = this.backAction.bind(this);
    this.shotDataNumber = this.shotDataNumber.bind(this);
    this.showErrorAlert = this.showErrorAlert.bind(this);
    this.removeAlert = this.removeAlert.bind(this);

  }

  showErrorAlert(message, type) {
    // let message = "message";
    // let alertId = StatusAlertService.showError(message);
    // // console.log(alertId)
    // this.setState({ alertId });
    console.log(message)

    let output = "";
    if (type == "multi") {

      for (var i = 0; i < message.length; i++) {
        output += message[i];
      }

    } else {
      output = message;
    }

    // console.log(output)

    let alertId = StatusAlertService.showError("oddd");

    // let alertId = StatusAlertService.showError(output);
    // console.log(alertId)
    this.setState({ alertId });

  }

  startCountdown = () => {
    this.setState({ isCounting: true });
    const intervalId = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.seconds === 0) {
          clearInterval(intervalId);
          this.setState({ isCounting: false });
          return { seconds: 10 }
        }
        return { seconds: prevState.seconds - 1 }
      });
    }, 1000);
  }

  removeChar(i) {

    this.state.form.shootnumbertotal.removeCharAt = function (i) {
      var tmp = this.split(''); // convert to an array
      tmp.splice(i - 1, 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
      return tmp.join(''); // reconstruct the string
    }


    // console.log("crt/r2002_2".removeCharAt(4));
  }

  backAction() {
    this.setState({ step: "shoot" });
  }

  togglesPop() {
    this.setState({
        seen: !this.state.seen
    });

  }

  rulesAction() {

    this.setState({ step: "rule", fast: 0 });
  }

  setAmount(text) {

    // let shootnumber = this.state.form.shootnumber;

    if (this.state.form.shootnumbertotal.toString().length < 5) {
      let shootnumbersingle = this.state.form.shootnumbertotal.toString();

      shootnumbersingle = shootnumbersingle + text;

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootnumbersingle: shootnumbersingle,
          shootnumbertotal: shootnumbersingle
        }
      }))
    }

    if (this.state.form.shootnumbertotal.toString().length == 0) {

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootfirst: text
        }
      }))
    }

    if (this.state.form.shootnumbertotal.toString().length == 1) {

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootfirst: this.state.form.shootnumbertotal.toString()[0],
          shootsecond: text
        }
      }))
    }

    if (this.state.form.shootnumbertotal.toString().length == 2) {

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootfirst: this.state.form.shootnumbertotal.toString()[0],
          shootsecond: this.state.form.shootnumbertotal.toString()[1],
          shootthird: text
        }
      }))
    }

    if (this.state.form.shootnumbertotal.toString().length == 3) {

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootfirst: this.state.form.shootnumbertotal.toString()[0],
          shootsecond: this.state.form.shootnumbertotal.toString()[1],
          shootthird: this.state.form.shootnumbertotal.toString()[2],
          shootfourth: text
        }
      }))
    }

    if (this.state.form.shootnumbertotal.toString().length == 4) {

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootfirst: this.state.form.shootnumbertotal.toString()[0],
          shootsecond: this.state.form.shootnumbertotal.toString()[1],
          shootthird: this.state.form.shootnumbertotal.toString()[2],
          shootfourth: this.state.form.shootnumbertotal.toString()[3],
          shootfive: text
        }
      }))
    }

    // shootfirst: "",
    // shootsecond: "",
    // shootthird: "",
    // shootfourth: "",
    // shootfive: "",
    // console.log(this.state.form.shootnumbertotal)
  }

  clearPos() {

    // console.log('---remove char--')

    // let shootnumber = this.state.form.shootnumber;

    let shootnumbersingle = this.state.form.shootnumbertotal.toString();
    // console.log(shootnumbersingle)
    //if(shootnumbersingle.length > 0){
    shootnumbersingle = shootnumbersingle.substring(0, shootnumbersingle.length - 1);
    // console.log(shootnumbersingle)
    // }

    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,           // copy all other key-value pairs 
        shootnumbersingle: shootnumbersingle,
        shootnumbertotal: shootnumbersingle
      }
    }))

    // console.log('---remove char--')
    // console.log(this.state.form.shootnumbertotal.toString().length)

    if (this.state.form.shootnumbertotal.toString().length - 1 == 0) {

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootfirst: "",
          shootsecond: "",
          shootthird: "",
          shootfourth: "",
          shootfive: "",
        }
      }))
    }

    if (this.state.form.shootnumbertotal.toString().length - 1 == 1) {

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootfirst: this.state.form.shootnumbertotal.toString()[0],
          shootsecond: "",
          shootthird: "",
          shootfourth: "",
          shootfive: "",
        }
      }))
    }

    if (this.state.form.shootnumbertotal.toString().length - 1 == 2) {

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootfirst: this.state.form.shootnumbertotal.toString()[0],
          shootsecond: this.state.form.shootnumbertotal.toString()[1],
          shootthird: "",
          shootfourth: "",
          shootfive: "",
        }
      }))
    }

    if (this.state.form.shootnumbertotal.toString().length - 1 == 3) {

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootfirst: this.state.form.shootnumbertotal.toString()[0],
          shootsecond: this.state.form.shootnumbertotal.toString()[1],
          shootthird: this.state.form.shootnumbertotal.toString()[2],
          shootfourth: "",
          shootfive: "",
        }
      }))
    }

    if (this.state.form.shootnumbertotal.toString().length - 1 == 4) {

      this.setState(prevState => ({
        ...prevState,
        form: {
          ...prevState.form,           // copy all other key-value pairs 
          shootfirst: this.state.form.shootnumbertotal.toString()[0],
          shootsecond: this.state.form.shootnumbertotal.toString()[1],
          shootthird: this.state.form.shootnumbertotal.toString()[2],
          shootfourth: this.state.form.shootnumbertotal.toString()[3],
          shootfive: ""
        }
      }))
    }




  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    let mathrand = Math.floor(Math.random() * (max - min + 1) + min);
    return mathrand.toString();
    // The maximum is inclusive and the minimum is inclusive
  }

  randomNum() {

    // let shootnumber = this.state.form.shootnumber;

    let shootnumbersingle = this.getRandomIntInclusive(0, 9) + this.getRandomIntInclusive(0, 9) + this.getRandomIntInclusive(0, 9) + this.getRandomIntInclusive(0, 9) + this.getRandomIntInclusive(0, 9);

    // this.setState(prevState => ({
    //     ...prevState,
    //     form: {
    //         ...prevState.form,           // copy all other key-value pairs 
    //         shootnumbersingle : shootnumbersingle,
    //         shootnumbertotal : shootnumbersingle
    //     }
    // }))

    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,           // copy all other key-value pairs 
        shootnumbersingle: shootnumbersingle,
        shootnumbertotal: shootnumbersingle,
        shootfirst: shootnumbersingle.toString()[0],
        shootsecond: shootnumbersingle.toString()[1],
        shootthird: shootnumbersingle.toString()[2],
        shootfourth: shootnumbersingle.toString()[3],
        shootfive: shootnumbersingle.toString()[4],
      }
    }))


  }

  currencyFormat(num) {
    return num;
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  componentDidMount() {

    window.scrollTo(0, 0);

    // this.setState({ fopen: true });

    let menu = ["/"];

    localStorage.setItem("route", JSON.stringify(menu));
    //console.log(localStorage.getItem('route'));
    //console.log(window.BPdata.apiUrl)
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // this.setState({ fopen: true });

    let checkday = 0;

    var d = new Date();
    var todaynum = d.getDay();
    var todayhr = d.getHours();

    if (localStorage.getItem('popup')) {
      if (localStorage.getItem('popup') != todaynum) {
        localStorage.setItem('popup', todaynum);
        this.setState({ open: true });

      } else {
        checkday = 1;
      }

    } else {
      localStorage.setItem('popup', todaynum);
      this.setState({ open: true });
    }

    //console.log(localStorage.getItem('popup'));
    //this.fetchIndexUserhData();
    clearTimeout(window.BPdata.liveurl);

    if (localStorage.getItem(window.BPdata.time)) {
      if (localStorage.getItem(window.BPdata.time) !== "0") {
        this.setState({ login: 1 })

        this.fetchIndexUserhData(window.BPdata.apiUrlCMS + '/cron');

      }
    } else {
      this.setState({ login: 0 })
    }


    this.fetchUserData();

    this.fetchLobbyDetailData();

  }


  customerShotRecord() {

    let ui = [];
    let arlength = this.state.detail.shoot_record.length;

    // console.log(arlength)
    

    if (arlength > 0) {

      // data.forEach(function(each){
      for (let i = 0; i < arlength; i++) {
        let each = this.state.detail.shoot_record[i]
        // {(!this.state.is_offday) ?  : }
        const luckyNumber = i === 15 ? "user-list-dark flex special-number" : "user-list-dark flex";

        ui.push(<div className={(!this.state.is_offday) ? "user-list-dark flex" : luckyNumber }>
          <div className="grid-30">
            <p className="table-content">
              {i + 1}</p>
          </div>
          <div className="grid-30">
            <p className="table-content">
              {moment.utc(each.created_at).local().format('hh:mm:ss')}</p>
          </div>
          <div className="grid-30">
            <p className="table-content">{each.first_name}</p>
          </div>
          <div className="grid-30">
            <p className="table-content">
              {
                String(each.shoot_number).padStart(5, '0')

              }</p>
          </div>
        </div>)
      }

    }

    // console.log(status+"-------")
    // if(status === 1) {
    //     console.log(status+"-------")
    //     this.setState({status : 0});
    // }

    return ui;
  }

  // start paginate test

  // customerShotRecord(currentPage, perPage) {
  //   let ui = [];
  //   const shootRecord = this.state.detail.shoot_record;
  //   const totalRecords = shootRecord.length;
  //   const startIndex = (currentPage - 1) * perPage;
  //   const endIndex = Math.min(startIndex + perPage - 1, totalRecords - 1);

  //   if (totalRecords > 0) {
  //     for (let i = startIndex; i <= endIndex; i++) {
  //       const each = shootRecord[i];

  //       ui.push(
  //         <div className="user-list-dark flex" key={i}>
  //           <div className="grid-40">
  //             <p className="table-content">
  //               {moment(each.created_at, 'YYYY-MM-DD hh:mm:ss').format('hh:mm')}
  //             </p>
  //           </div>
  //           <div className="grid-40">
  //             <p className="table-content">{each.first_name}</p>
  //           </div>
  //           <div className="grid-40">
  //             <p className="table-content">
  //               {String(each.shoot_number).padStart(5, '0')}
  //             </p>
  //           </div>
  //         </div>
  //       );
  //     }
  //   }

  //   return ui;
  // }

  // end paginate test

  removeAlert(message, type) {


    let output = "";
    if (type == "multi") {

      for (var i = 0; i < message.length; i++) {
        output += message[i];
      }

      // console.log(output)

    } else {
      output = message;
    }

    // console.log(output)

    let alertId = StatusAlertService.showError(output);
    // console.log(alertId)
    this.setState({ alertId });

    // StatusAlertService.removeAlert(this.state.alertId);
  }

  shotDataNumber() {
    //localStorage.getItem(window.BPdata.time)
    // api/v1/cms/lobbylist
    const url = window.BPdata.apiAPZUrlCMS + '/shotnumber?lang=' + window.lang.lang;
    //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

    // console.log( this.state.post_id)
    // return;
    let self = this;

    axios({
      method: 'post',
      url: url,
      data: { "game_contest_id": this.state.detail.id, "customer_id": "1", "shoot_number": this.state.form.shootnumbertotal },
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "X-Trident-Token": window.BPdata.token,
        "BP-Agent": window.BPdata.rand_compound,
        "BP-Time": localStorage.getItem(window.BPdata.time)
      },
    })
      .then(resp => {

        if (resp.data) {


          // console.log(resp.data.message)
          if (resp.data.status_code == 404) {
            self.removeAlert(resp.data.message, 'multi');
          }

          if (resp.data.status_code == 200) {
            self.startCountdown();
            this.setState(prevState => ({
              ...prevState,
              form: {
                ...prevState.form,           // copy all other key-value pairs 
                shootnumbersingle: "",
                shootnumbertotal: "",
                shootfirst: "",
                shootsecond: "",
                shootthird: "",
                shootfourth: "",
                shootfive: "",
              }
            }))
          }
          // else if(resp.data.status_code == 200) {
          //   this.fetchLobbyDetailData();
          // }  else {
          //     this.setState({  message : "တစ်ခုခုတော့မှားနေပြီ" });
          // }

          this.fetchLobbyDetailData();

          //console.log(resp.data.game_contest.data)

          // localStorage.setItem("sbsetting", JSON.stringify(resp.data.setting) )

          // localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )

          // console.log(resp.data);
          this.setState({ login: 1 })

        } else {
          this.setState({ login: 0 })
        }


      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  shootBlock() {

    let childui = [];

    if (this.state.login == 1) {
      if (this.state.wallet[0]) {
        childui.push(<div className="row">
          <br />
          <div className="top-shoot">
            <div className="grid-40 single-shoot-game ml-20">
              <h3 className="single-slot">NO-{this.state.detail.gcontest_id}</h3>
              <LobbyItem key={this.state.detail.id} item={this.state.detail} />
            </div>
            <div className="grid-60 rule-btn">
              {(checklogin()) ? <span onClick={this.rulesAction} className="btn btn-no-keyboard mr-10"><img src="/img/apz/gridicons_notice-outline.png" className="icon" alt="icon" />{window.lang.more.apzrules}</span> : ""}
              {/* {(checklogin()) ? <Link to={"/aungparzay/rules/" + this.state.post_id} className="btn-rule mr-10"><img src="/img/apz/gridicons_notice-outline.png" className="icon" alt="icon" /><span>{window.lang.more.apzrules}</span></Link> : ""} */}
              {
                (checklogin() && !this.state.is_offday) ? <Link to={"/aungparzay/bet-2d/" + this.state.post_id} className="btn-bet">{window.lang.more.apzbetnow}</Link> : ""}
            </div>
          </div>
          <div className="random-no-wrapper">
            <div className="result-img text-center mt-30">
              <img src="/img/apz/result-lotto.png" className="result" alt="result" />
              <p className="last-result-no">{(!this.state.is_offday) ? this.state.detail.total_shots : this.state.detail.rule_regulation}</p>
            </div>
            {(!this.state.is_offday) ? "" : 
              <div className="total-shot-no">
                <p className="last-total-no"><span className='timer-result'>Total Shoot : <span className='closetime-result'> {this.state.detail.total_shots}</span></span></p>
              </div>
            }
            {(!this.state.is_offday) ?
              <div className="random-shoot-wrapper">
                <div className="rolling-no">
                  <div className="grid-20 type-shoot-setting">
                    <img src="/img/apz/shoot-people-1.png" className="gawli" alt="gawli" />
                    <p className="type-shoot-number">{this.state.form.shootfirst}</p>
                    {/* { this.state.form.shootnumbertotal } */}
                  </div>
                  <div className="grid-20 type-shoot-setting">
                    <img src="/img/apz/shoot-people-2.png" className="gawli" alt="gawli" />
                    <p className="type-shoot-number">{this.state.form.shootsecond}</p>
                  </div>
                  <div className="grid-20 type-shoot-setting">
                    <img src="/img/apz/shoot-people-3.png" className="gawli" alt="gawli" />
                    <p className="type-shoot-number">{this.state.form.shootthird}</p>
                  </div>
                  <div className="grid-20 type-shoot-setting">
                    <img src="/img/apz/shoot-people-4.png" className="gawli" alt="gawli" />
                    <p className="type-shoot-number">{this.state.form.shootfourth}</p>
                  </div>
                  <div className="grid-20 type-shoot-setting">
                    <img src="/img/apz/shoot-people-5.png" className="gawli" alt="gawli" />
                    <p className="type-shoot-number">{this.state.form.shootfive}</p>
                  </div>
                </div>
                <div className="random-calculator-no flex text-center mt-20">
                  <div className="grid-30 random-number">
                    <p onClick={this.setAmount.bind(this, 0)} className='pointer'>0</p>
                  </div>
                  <div className="grid-30 random-number">
                    <p onClick={this.setAmount.bind(this, 1)} className='pointer'>1</p>
                  </div>
                  <div className="grid-30 random-number">
                    <p onClick={this.setAmount.bind(this, 2)} className='pointer'>2</p>
                  </div>
                  <div className="grid-30 random-number">
                    <p onClick={this.setAmount.bind(this, 3)} className='pointer'>3</p>
                  </div>
                </div>
                <div className="random-calculator-no flex text-center mt-10">
                  <div className="grid-30 random-number">
                    <p onClick={this.setAmount.bind(this, 4)} className='pointer'>4</p>
                  </div>
                  <div className="grid-30 random-number">
                    <p onClick={this.setAmount.bind(this, 5)} className='pointer'>5</p>
                  </div>
                  <div className="grid-30 random-number">
                    <p onClick={this.setAmount.bind(this, 6)} className='pointer'>6</p>
                  </div>
                  <div className="grid-30 random-number">
                    <p onClick={this.setAmount.bind(this, 7)} className='pointer'>7</p>
                  </div>
                </div>
                <div className="random-calculator-no flex text-center mt-10">
                  <div className="grid-30 random-number">
                    <p className="backspace-img" onClick={this.randomNum.bind(this)} >{(window.BPdata.lang == "en")?<img src="/img/apz/random_en.png" className="back" alt="random"  /> : <img src="/img/apz/random_mm.png" className="back" alt="random"  /> }</p>
                  </div>
                  
                  <div className="grid-30 random-number">
                    <p onClick={this.setAmount.bind(this, 8)} className='pointer'>8</p>
                  </div>
                  <div className="grid-30 random-number">
                    <p onClick={this.setAmount.bind(this, 9)} className='pointer'>9</p>
                  </div>
                  <div className="grid-30 random-number">
                    <p className="backspace-img"><img src="/img/apz/mingcute_delete-back-fill.png" className="back" alt="back" onClick={this.clearPos.bind(this)} /></p>
                  </div>
                </div>
                <div className="random-calculator-no flex text-center mt-10">
                  {(checklogin()) ? <button className={this.state.isCounting ? "inactive btn-bet text-center mt-20 mb-10" : "btn-bet text-center mt-20 mb-10"} onClick={this.shotDataNumber} disabled={this.state.isCounting ? "disabled" : ""}>{this.state.isCounting ? <div>{this.state.seconds}</div> : window.lang.form.shoot}</button> : ""}
                </div>

               {/* {(checklogin()) ? <button className={this.state.isCounting ? "inactive btn-bet text-center mt-20 mb-10" : "btn-bet text-center mt-20 mb-10"} onClick={this.shotDataNumber} disabled={this.state.isCounting ? "disabled" : ""}>{window.lang.form.shoot}{this.state.isCounting && <div>{this.state.seconds}</div>}</button> : ""} */}

              </div>
              : ""}
            <div className="user-list-wrapper mt-30 text-center">

              <div className="user-list flex" >
                <div className="grid-30">
                  <p className="table-header">{window.lang.more.apzid}</p>
                </div>
                <div className="grid-30">
                  <p className="table-header">{window.lang.more.apztime}</p>
                </div>
                <div className="grid-30">
                  <p className="table-header">{window.lang.more.apzphone}</p>
                </div>
                <div className="grid-30">
                  <p className="table-header">{window.lang.more.apzshootno}</p>
                </div>
              </div>
              {this.customerShotRecord()}
            </div>
          </div>
        </div>)
      } else {


        if (this.state.is_verified == 0) {
          childui.push(<div className="row pb-10">
            <br />
            <div className="adm-card row mt-10">
              <div className="adm-card-body grid-100">
                <div className="row">
                  <div className="grid-100 left text-center">
                    You have been banned
                  </div>
                  <br />
                  <hr />
                </div>
              </div>
            </div>
          </div>)
        } else {

          childui.push(<div className="row">
            <br />
            <div className="top-shoot">
              <div className="grid-30 single-shoot-game">
                <h3 className="single-slot">NO-{this.state.detail.id}</h3>
                <p>{moment(this.state.detail.schedule_at, 'YYYY-MM-DD hh:mm:ss').format('hh:mm')} </p>
              </div>
              <div className="grid-63 rule-btn">
                {(checklogin()) ? <Link to="#" className="btn-rule mr-10"><img src="/img/apz/gridicons_notice-outline.png" className="icon" alt="icon" /><span>Rules</span></Link> : ""}
              </div>
            </div>
            <div className="random-no-wrapper">
              <div className="result-img text-center mt-30">
                <img src="/img/apz/result-lotto.png" className="result" alt="result" />
                <p className="last-result-no">{this.state.detail.rule_regulation}</p>
              </div>
              <div className="user-list-wrapper mt-30 text-center">

                <div className="user-list flex" >
                  <div className="grid-40">
                    <p className="table-header">Time</p>
                  </div>
                  <div className="grid-40">
                    <p className="table-header">Phone</p>
                  </div>
                  <div className="grid-40">
                    <p className="table-header">Shoot No</p>
                  </div>
                </div>
                {this.customerShotRecord()}
              </div>
            </div>
          </div>)
          //Please Login

        }

      }
    } else {
      childui.push(<div className="row">
        {this.customerShotRecord()}
      </div>)
    }
    return childui;
  }

  rulesBlock() {

    let childui = [];

    childui.push(<div className="two-game-wrapper rule-popup">
      {/* <i className="pe-7s-back pe-2x pe-va " onClick={this.backAction}></i>
    <br /> */}
      <div className="title-wrapper rules-lightbox text-center">
        <img onClick={this.backAction} src="/img/apz/close-btn.png" className="result rules-closebtn" height="30" alt="result" />
        {/* <img src="/img/apz/apz-rule-title.png" className="news-content" height="50px" alt="profile" /> */}
        {(window.lang.lang == "en") ?
              <img src="/img/apz/apz-rule-title-righ-en.png" className="news-content" height="50px" alt="profile" />
          :
              <img src="/img/apz/apz-rule-title-righ.png" className="news-content" height="50px" alt="profile" />
          }
      </div>

      <div className="rules-section flex">
        <div className="rules-right">
          <p>1</p>
        </div>
        <div className="rules-left">
          <p className="rules-text">{window.lang.more.apz_rule_one}</p>
        </div>
      </div>

      <div className="apz-ruleshead text-center">
        <h2>2D</h2>
        <img src="/img/apz/union.png" className="news-content" width="30%" alt="profile" />
      </div>
      <div className="rules-section flex">
        <div className="rules-right">
          <p>2</p>
        </div>
        <div className="rules-left">
          <p className="rules-text">{window.lang.more.apz_rule_two}</p>
        </div>
      </div>

      <div className="apz-ruleshead text-center">
        <h2>3D</h2>
        <img src="/img/apz/union.png" className="news-content" width="30%" alt="profile" />
      </div>
      <div className="rules-section rules-section-divider flex">
        <div className="rules-right">
          <p>3</p>
        </div>
        <div className="rules-left">
          <p className="rules-text">{window.lang.more.apz_rule_three}</p>
        </div>
      </div>

      <div className="gawlipit-section text-center">
          {(window.lang.lang == "en") ?
                    <img src="/img/apz/apz-rule-title-2-en.png" className="news-content" height="50px" alt="profile" />
                :
                    <img src="/img/apz/apz-rule-title-2.png" className="news-content" height="50px" alt="profile" />
                }
      </div>
      <div className="rules-section flex">
        <div className="rules-right gawli-rule-right">
          <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
        </div>
        <div className="rules-left">
          <p className="rules-text">{window.lang.more.gawlipyit_play_1}</p>
        </div>
      </div>
      <div className="rules-section flex">
        <div className="rules-right gawli-rule-right">
          <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
        </div>
        <div className="rules-left">
          <p className="rules-text">{window.lang.more.gawlipyit_play_2}</p>
        </div>
      </div>
      <div className="rules-section flex">
        <div className="rules-right gawli-rule-right">
          <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
        </div>
        <div className="rules-left">
          <p className="rules-text">{window.lang.more.gawlipyit_play_3}</p>
        </div>
      </div>
      <div className="rules-section flex">
        <div className="rules-right gawli-rule-right">
          <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
        </div>
        <div className="rules-left">
          <p className="rules-text">{window.lang.more.gawlipyit_play_4}</p>
        </div>
      </div>
      <div className="rules-section flex">
        <div className="rules-right gawli-rule-right">
          <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
        </div>
        <div className="rules-left">
          <p className="rules-text">{window.lang.more.gawlipyit_play_5}</p>
        </div>
      </div>
    </div>)
    //Please Login


    return childui;
  }

  fetchIndexUserhData(url) {

    let dataurl = url;



    axios.get(dataurl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: "same-origin", // include, *same-origin, omit
      crossDomain: true,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Trident-Token": window.BPdata.token,
        "BP-Agent": window.BPdata.rand_compound,
        "BP-Time": localStorage.getItem(window.BPdata.time)
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(resp => {

        if (resp.data.status_code == "404") {

          localStorage.setItem(window.BPdata.time, 0);
          localStorage.setItem('username', "");

          swal({
            title: "Session Expire",
            text: window.lang.errors.contact_cs,
            icon: "warning",
            dangerMode: true,
          })
            .then(willDelete => {
              if (willDelete) {
                localStorage.setItem(window.BPdata.time, 0);
                localStorage.setItem('username', "");
                this.props.history.push("/more/login")
              }
            });

          // swal( window.lang.errors.contact_cs );
          // console.log(resp.data.status_code)



        }


        //this.setState({ isLoading: false });

      })
      .catch(function (error) {
        //console.log(error.tostring());
      });

    if (localStorage.getItem(window.BPdata.time)) {
      if (localStorage.getItem(window.BPdata.time) !== "0") {

        window.BPdata.liveurl = setTimeout(() => this.fetchIndexUserhData(url), 10000);

      }
    }



  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }

  fetchLobbyDetailData() {

    const url = window.BPdata.apiAPZUrlCMS + '/lobbylist/' + this.state.post_id;
    //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

    axios({
      method: 'post',
      url: url,
      data: { time: window.BPdata.rand_compound + "kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "X-Trident-Token": window.BPdata.token,
        "BP-Agent": window.BPdata.rand_compound,
        "BP-Time": localStorage.getItem(window.BPdata.time)
      },
    })
      .then(resp => {

        if (resp.data) {

          // console.log()

          const dateObject = moment(resp.data.data.schedule_at, "YYYY-MM-DD HH:mm:ss");

          const differenceInMs = dateObject.valueOf() - new Date().getTime();

          if (differenceInMs < 0) {
            this.setState({
              is_offday: 1
            });
          }
          // console.log(differenceInMs)
          // console.log(dateObject.format('YYYY-MM-DD HH:mm:ss'))

          // const range = moment.range(start, resp.data.data.expire_date );

          // if( > resp.data.data.expire_date  )
          this.setState({
            detail: resp.data.data
          });

          //console.log(resp.data.game_contest.data)

          // console.log(resp.data);
          this.setState({ login: 1 })

        } else {
          this.setState({ login: 0 })
        }


      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  fetchUserData() {
    //localStorage.getItem(window.BPdata.time)
    const url = window.BPdata.apiUrl + '/cms/player/show?transfer_type=0&provider_id=' + window.BPdata.provider_id;
    //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

    ///api/customer?transfer_type=2

    axios({
      method: 'post',
      url: url,
      data: { time: window.BPdata.rand_compound + "kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "X-Trident-Token": window.BPdata.token,
        "BP-Agent": window.BPdata.rand_compound,
        "BP-Time": localStorage.getItem(window.BPdata.time)
      },
    })
      .then(resp => {

        if (resp.data) {
          this.setState({
            companyuserid: resp.data.data.companyuserid,
            username: resp.data.data.first_name,
            wallet: resp.data.customer_wallet,
            is_verified: resp.data.data.is_verified,
            isHeaderLoading: false
          });
// console.log(resp.data.customer_wallet)
          localStorage.setItem("sbsetting", JSON.stringify(resp.data.setting))

          localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet))

          // console.log(resp.data);

          this.setState({ login: 1 })

        } else {
          this.setState({ login: 0 })
        }


      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  viewHandler() {


    switch (this.state.step) {
        // case "selecttime": return this.selecttimeBlock();
        // case "form": return this.formBlock();
        // case "list": return this.listBlock();
        // case "message": return this.messageBlock();
        // case "fast": return this.fastBlock();
        case "shoot": return this.shootBlock();
        case "rule": return this.rulesBlock();
        default: return "";
    }
  }

  render() {
    const { selected } = this.state;
    // Create menu from items

    return (
      <div className="page">


        <div className="page-content apz-main-bg" id="homeicon">
        {(this.state.isHeaderLoading) ?
          <ApzHeaderClone backLink="/aungparzay/home" titleType="1" title="Home" isLoading="false" />
         : <ApzHeader backLink="/aungparzay/home" wallet={this.state.wallet} titleType="2" title="Home" isLoading="false" />
          }
          <div className="main-content">
          {this.state.seen ? <PopUp toggle={this.togglesPop} /> : null}
            {/* { window.screen.width }<br />
                { window.screen.height }<br /> */}
            <div className="grid"><StatusAlert /></div>
            {this.viewHandler()}

          </div>

        </div>

        <footer className="App-footer">

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}
            <Grid data={window.lang.apzfooter}
              columnNum={3}
              renderItem={dataItem => (

                <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{ width: '100%' }}>
                  {(window.BPdata.footerMenu === dataItem.check) ? (dataItem.text == "My Profile") ? <span className="color-white"><img src={dataItem.icon} height="35px" /><br />{(this.checklogin()) ? dataItem.text : "Login / Register"}</span> : <span className="text-white"><img src={dataItem.icon_off} height="35px" /><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon} height="35px" /><br />{dataItem.text} </span>}

                </Link>
              )}
            />

          </div>
        </footer>

      </div>
    );
  }
}

export default ShootPage;
