// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDnIAPFA01b1FS_KOrz1g4LgxveeCzMtlQ",
    authDomain: "thahtaylay-96fff.firebaseapp.com",
    projectId: "thahtaylay-96fff",
    storageBucket: "thahtaylay-96fff.appspot.com",
    messagingSenderId: "918243368430",
    appId: "1:918243368430:web:0ca0f9a3b13b1d48d402ff",
    measurementId: "G-XQ1SQ4W65H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

export { messaging };
