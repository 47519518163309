/* eslint no-dupe-keys: 0 */
import React from 'react';
import Swiper from 'swiper/dist/js/swiper.min.js';

import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { ListView, Grid } from 'antd-mobile';

import NewsVideoSlider from './../../NewsVideoSlider';
// import HomeListPage from './HomeListPage';
import HomeIconList from './../../news/HomeIconList';

import ApzHeader from './../../ApzHeader';

// import ScrollMenu from 'react-horizontal-scrolling-menu';

import swal from 'sweetalert';
//import { FacebookProvider, CommentsCount } from 'react-facebook';
//import { askForPermissioToReceiveNotifications } from './../push-notification';
require('./../../../../BPdata.js')

const alert = Modal.alert;

//var moment = require('moment');


let data = [
  {
    news_image: 'https://zos.alipayobjects.com/rmsportal/dKbkpPXKfvZzWCM.png',
    news_name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
    news_desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
  }
];


let NUM_ROWS = 3;
//let MAX_LENGTH = 90;
// One item component
// selected prop will be passed
const MenuItem = ({ activeMenu, data, text, selected }) => {
  return (

    <div
      className="menu-item"
    >
      {data.id === 0 ? (
        <Link to={`${data.url}`} className={(activeMenu === text) ? ' cont-wrap-link' : 'cont-wrap-link'} >{(window.BPdata.lang === 'mm') ? data.mmshortname : data.shortname}</Link>
      ) : (
        <Link to={`/news/league/${data.id}/${data.url}`} className={(activeMenu === text) ? ' cont-wrap-link' : 'cont-wrap-link'} >{(window.BPdata.lang === 'mm') ? data.mmshortname : data.shortname}</Link>
      )}
      {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}

      {/* <span>{text}</span> */}

      {/* </Link> */}
    </div>
  );
};


// All items component
// Important! add unique key
export const Menu = (list, selected, activeMenu) => list.map(el => {
  const { name, shortname } = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={activeMenu}
      text={shortname}
      key={name}
      data={el}
    />
  );
});


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });


const closeIcon = (
  <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
    <path
      fillRule="evenodd"
      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
      clipRule="evenodd"
    ></path>
  </svg>
);


let checklogin = () => {
  if (localStorage.getItem(window.BPdata.time)) {
    if (localStorage.getItem(window.BPdata.time) !== "0") {
      return true;
    }
  }
  return false;
}

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    window.BPdata.footerMenu = "home";

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.state = {
      login: 0,
      is_verified: 1,
      selected: 0,
      dataSource,
      isLoading: true,
      // virtual data
      videoSlides: ['slide1.jpg', 'slide1.jpg', 'slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg', 'slide1.jpg', 'slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'NEWS',
      open: false,
      fopen: false,
      advopen: false,
      menu: window.BPdata.mainmenu,
      wallet: [],
      seconds: 5,
      isCounting: false,
      post_id: 0
    };

    if (props.match.params.id) {
      this.state.post_id = props.match.params.id
    }

  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  onOpenModal = () => {
    this.setState({ open: true, fopen: true, advopen: true });
  };

  onCloseModal = () => {
    this.setState({ open: false, fopen: false, advopen: false });


    //localStorage.setItem('popup', false);
    // window.BPdata.popup = false
    // console.log(window.BPdata.popup)
  };

  close = () => {
    this.startCountdown();
    setTimeout(() => this.onCloseModal(), 5000)
  };

  startCountdown = () => {
    this.setState({ isCounting: true });
    const intervalId = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.seconds === 0) {
          clearInterval(intervalId);
          this.setState({ isCounting: false });
          return { seconds: 5 }
        }
        return { seconds: prevState.seconds - 1 }
      });
    }, 1000);
  }

  currencyFormat(num) {
    return num;
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  componentDidMount() {

    window.scrollTo(0, 0);

    // this.setState({ fopen: true });

    let menu = ["/"];

    localStorage.setItem("route", JSON.stringify(menu));
    //console.log(localStorage.getItem('route'));
    //console.log(window.BPdata.apiUrl)
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);



    const self = this;


    new Swiper('.swiper-video-container', {
      // ...
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      virtual: {
        slides: self.state.videoSlides,
        renderExternal(videoData) {
          // assign virtual slides data
          self.setState({
            virtualVideoData: videoData,
          });
        }
      },
      pagination: {
        el: '.swiper-vieo-pagination',
      },
    });




    // this.setState({ fopen: true });

    let checkday = 0;

    var d = new Date();
    var todaynum = d.getDay();
    var todayhr = d.getHours();

    if (localStorage.getItem('popup')) {
      if (localStorage.getItem('popup') != todaynum) {
        localStorage.setItem('popup', todaynum);
        this.setState({ open: true });

      } else {
        checkday = 1;
      }

    } else {
      localStorage.setItem('popup', todaynum);
      this.setState({ open: true });
    }


    // this.setState({ fopen: true });

    if (checkday == 1) {

      if (localStorage.getItem('fpopup')) {
        if (localStorage.getItem('fpopup') != todaynum) {

          localStorage.setItem('fpopup', todaynum);
          localStorage.setItem('fpopup_time', todayhr);
          this.setState({ fopen: true });

          this.close();

        }

        if (localStorage.getItem('fpopup') == todaynum) {

          if (localStorage.getItem('fpopup_time') != todayhr) {

            localStorage.setItem('fpopup_time', todayhr);
            this.setState({ fopen: true });

            this.close();

          }

        }


      } else {

        localStorage.setItem('fpopup', todaynum);
        this.setState({ fopen: true });

        this.close();

      }

    }


    // this.setState({ open: true });

    // this.setState({ open: true });

    // if(localStorage.getItem('wallet')) {

    //   if(JSON.parse(localStorage.getItem("wallet")) != null) {
    //     let wallet = JSON.parse(localStorage.getItem("wallet"));
    //     this.setState({wallet: wallet})
    //   }
    // }

    //console.log(localStorage.getItem('popup'));
    //this.fetchIndexUserhData();
    clearTimeout(window.BPdata.liveurl);

    if (localStorage.getItem(window.BPdata.time)) {
      if (localStorage.getItem(window.BPdata.time) !== "0") {
        this.setState({ login: 1 })

        this.fetchIndexUserhData(window.BPdata.apiUrlCMS + '/cron');

      }
    } else {
      this.setState({ login: 0 })
    }


    this.fetchUserData();




    // if (navigator.userAgent.toLowerCase().indexOf("thahtaylay") > -1 ) {
    //   console.log("other")
    // } else {
    //     if(localStorage.getItem('androidpopup')) {
    //       if(localStorage.getItem('androidpopup') === 'reset') {
    //         localStorage.setItem('androidpopup', true);
    //         this.setState({ open: true });
    //       }

    //     } else {
    //       localStorage.setItem('androidpopup', true);
    //       this.setState({ open: true });
    //     }

    // }
  }

  fetchIndexUserhData(url) {

    let dataurl = url;



    axios.get(dataurl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: "same-origin", // include, *same-origin, omit
      crossDomain: true,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-Trident-Token": window.BPdata.token,
        "BP-Agent": window.BPdata.rand_compound,
        "BP-Time": localStorage.getItem(window.BPdata.time)
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(resp => {
        // console.log(resp.data)

        // {"thaidate":"29-11-21 13:11",
        // "set":"1,600.24",
        // "value":"64,409.60M",
        // "created_at":"2021-11-29 12:41:10",
        // "number":"49"}
        // this.setState({
        //     status_code : resp.data.status_code,
        //
        // });



        if (resp.data.status_code == "404") {

          localStorage.setItem(window.BPdata.time, 0);
          localStorage.setItem('username', "");

          swal({
            title: "Session Expire",
            text: window.lang.errors.contact_cs,
            icon: "warning",
            dangerMode: true,
          })
            .then(willDelete => {
              if (willDelete) {
                localStorage.setItem(window.BPdata.time, 0);
                localStorage.setItem('username', "");
                this.props.history.push("/more/login")
              }
            });

          // swal( window.lang.errors.contact_cs );
          // console.log(resp.data.status_code)



        }


        //this.setState({ isLoading: false });

      })
      .catch(function (error) {
        //console.log(error.tostring());
      });

    if (localStorage.getItem(window.BPdata.time)) {
      if (localStorage.getItem(window.BPdata.time) !== "0") {

        window.BPdata.liveurl = setTimeout(() => this.fetchIndexUserhData(url), 10000);

      }
    }



  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }

  fetchUserData() {
    //localStorage.getItem(window.BPdata.time)
    const url = window.BPdata.apiUrl + '/cms/player/show?transfer_type=0&provider_id=' + window.BPdata.provider_id;
    //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

    ///api/customer?transfer_type=2

    axios({
      method: 'post',
      url: url,
      data: { time: window.BPdata.rand_compound + "kiuwernxyzxfyfysdsdfdsfydf373sdfdsf" },
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        "X-Trident-Token": window.BPdata.token,
        "BP-Agent": window.BPdata.rand_compound,
        "BP-Time": localStorage.getItem(window.BPdata.time)
      },
    })
      .then(resp => {

        if (resp.data) {
          this.setState({
            companyuserid: resp.data.data.companyuserid,
            username: resp.data.data.first_name,
            wallet: resp.data.customer_wallet,
            is_verified: resp.data.data.is_verified
          });

          localStorage.setItem("sbsetting", JSON.stringify(resp.data.setting))

          localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet))



          // console.log(resp.data);


          this.setState({ login: 1 })

        } else {
          this.setState({ login: 0 })
        }


      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  welcomeMessage() {

    var day = new Date();
    var hr = day.getHours();
    if (hr == 1) {
      return window.lang.messages.good_night;
    }
    if (hr == 2) {
      return window.lang.messages.good_morning;
    }
    if (hr == 3) {
      return window.lang.messages.good_morning;
    }
    if (hr == 4) {
      return window.lang.messages.good_morning;
    }
    if (hr == 5) {
      return window.lang.messages.good_morning;
    }
    if (hr == 6) {
      return window.lang.messages.good_morning;
    }
    if ((hr == 6) || (hr == 7) || (hr == 8) || (hr == 9)) {
      return window.lang.messages.good_morning;
    }
    if ((hr == 10) || (hr == 11)) {
      return window.lang.messages.good_afternoon;
    }
    if (hr == 12) {
      return window.lang.messages.good_afternoon;
    }
    if ((hr == 13) || (hr == 14)) {
      return window.lang.messages.good_afternoon;
    }
    if ((hr == 15) || (hr == 16)) {
      return window.lang.messages.good_afternoon;
    }
    if ((hr == 17) || (hr == 18)) {
      return window.lang.messages.good_evening;
    }
    if ((hr == 19) || (hr == 20) || (hr == 21) || (hr == 22)) {
      return window.lang.messages.good_night;
    }
    if (hr == 23) {
      return window.lang.messages.good_night;
    }
    if (hr == 0) {
      return window.lang.messages.good_night;
    }

  }

  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    const menu = Menu(this.state.menu, selected, leagueMenu);

    return (
      <div className="page">


        <div className="page-content apz-main-bg" id="homeicon">
          <ApzHeader backLink={"/aungparzay/shoot/" + this.state.post_id} titleType="2" title="Home" isLoading="false" />


          {/* <ScrollMenu
              data={menu}
              arrowLeft={ArrowLeft}
              arrowRight={ArrowRight}
              selected={selected}
              onSelect={this.onSelect}
              alignCenter={false}
              wheel={true}
              inertiaScrolling={true}
              // scrollToSelected={true}
            />

            {/* <button onClick={askForPermissioToReceiveNotifications} >
                  Click here to receive notifications
            </button> */}

          <div className="main-content ">

            <div className="row">
              <div className="title-wrapper text-center">
                <img src="/img/apz/apz-rule-title.png" className="news-content" height="50px" alt="profile" />
              </div>
              
              <div className="rules-section flex">
                <div className="rules-right">
                    <p>1</p>
                </div>
                <div className="rules-left">
                    <p className="rules-text">တစ်ရက်လျှင် ပွဲစဉ် (60)ရှိပြီး (15)မိနစ်ခြားတစ်ကြိမ် ကစားလို့ ရပါသည်။</p>
                </div>
              </div>

              <div className="apz-ruleshead text-center">
                <h2>2D</h2>
                <img src="/img/apz/union.png" className="news-content" width="30%" alt="profile" />
              </div>
              <div className="rules-section flex">
                <div className="rules-right">
                    <p>2</p>
                </div>
                <div className="rules-left">
                    <p className="rules-text">2D ပေါက်ဂဏန်းအဖြစ် သတ်မှတ်ရာတွင် ဂေါ်လီပစ်ဂဏန်း အားလုံး စုစုပေါင်းရလာဒ်မှ နောက်ဆုံးဂေါ်လီပစ်သူ၏ 
(16)ယောက်မြှောက် ဂဏန်းကို နှုတ်၍ ကျန်ရလာဒ်ဂဏန်း၏ နောက်ဆုံးဂဏန်း (2)လုံးကို 2D ပေါက်ဂဏန်းအဖြစ် သတ်မှတ်ပါသည်။</p>
                </div>
              </div>

              <div className="apz-ruleshead text-center">
                <h2>3D</h2>
                <img src="/img/apz/union.png" className="news-content" width="30%" alt="profile" />
              </div>
              <div className="rules-section rules-section-divider flex">
                <div className="rules-right">
                    <p>3</p>
                </div>
                <div className="rules-left">
                    <p className="rules-text">3D ပေါက်ဂဏန်းအဖြစ် သတ်မှတ်ရာတွင် ဂေါ်လီပစ်ဂဏန်း အားလုံး စုစုပေါင်းရလာဒ်မှ နောက်ဆုံးဂေါ်လီပစ်သူ၏ 
(16)ယောက်မြှောက် ဂဏန်းကို နှုတ်၍ ကျန်ရလာဒ်ဂဏန်း၏ နောက်ဆုံးဂဏန်း (3)လုံးကို 3D ပေါက်ဂဏန်းအဖြစ် သတ်မှတ်ပါသည်။</p>
                </div>
              </div>

              <div className="gawlipit-section text-center">
                <img src="/img/apz/apz-rule-title-2.png" className="news-content" height="50px" alt="profile" />
              </div>
              <div className="rules-section flex">
                <div className="rules-right gawli-rule-right">
                  <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
                </div>
                <div className="rules-left">
                    <p className="rules-text">လူကြီးမင်းစိတ်ကြိုက်ကစားမည့်အခန်းကိုရွေးချယ်ပါ။</p>
                </div>
              </div>
              <div className="rules-section flex">
                <div className="rules-right gawli-rule-right">
                  <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
                </div>
                <div className="rules-left">
                    <p className="rules-text">ဂေါ်လီပစ်ရန် လူကြီးမင်း ကြိုက်နှစ်သက်သည့် ဂဏန်း(5)လုံး ရွေးချယ်၍ နှိပ်ပါ (သို့မဟုတ်) ကျပန်းခလုတ်ကို နှိပ်ပါ။</p>
                </div>
              </div>
              <div className="rules-section flex">
                <div className="rules-right gawli-rule-right">
                  <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
                </div>
                <div className="rules-left">
                    <p className="rules-text">လူကြီးမင်းစိတ်ကြိုက်ဂဏန်းရလျှင် “ဂေါ်လီပစ်ရန်” ကိုနှိပ်ပါ။</p>
                </div>
              </div>
              <div className="rules-section flex">
                <div className="rules-right gawli-rule-right">
                  <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
                </div>
                <div className="rules-left">
                    <p className="rules-text">(10)စက္ကန့်လျှင်တစ်ကြိမ်ပစ်ခွင့်ရှိပါတယ်။အကြိမ်အရေအတွက် ကန့်သတ်ထားခြင်းမရှိပါ။</p>
                </div>
              </div>
              <div className="rules-section flex">
                <div className="rules-right gawli-rule-right">
                  <img src="/img/apz/gawli-list-img.png" className="news-content" height="5px" width="30" alt="profile" />
                </div>
                <div className="rules-left">
                    <p className="rules-text">2D/3D ထိုးမည်ဆိုပါက အက်ပလီကေးရှင်း၏ ညာဘက်အပေါ်ရှိ 2D/3D ထိုးရန် နေရာကိုနှိပ်ပေးပါ။</p>
                </div>
              </div>

            </div>


            {/* <HomeIconList></HomeIconList> */}

          </div>


          {/* <HighlightsVideoSlider></HighlightsVideoSlider> */}



        </div>

        <footer className="App-footer">

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}
            <Grid data={window.lang.apzfooter}
              columnNum={3}
              renderItem={dataItem => (

                <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{ width: '100%' }}>
                  {(window.BPdata.footerMenu === dataItem.check) ? (dataItem.text == "My Profile") ? <span className="color-white"><img src={dataItem.icon} height="25px" /><br />{(this.checklogin()) ? dataItem.text : "Login / Register"}</span> : <span className="text-white"><img src={dataItem.icon_off} height="25px" /><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon} height="25px" /><br />{dataItem.text} </span>}

                </Link>
              )}
            />

          </div>
        </footer>

      </div>
    );
  }
}

export default HomePage;
