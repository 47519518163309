import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import { Grid  } from 'antd-mobile';
import Modal from 'react-responsive-modal';
import SimpleReactValidator from "simple-react-validator";
// import SweetAlert from 'react-bootstrap-sweetalert';
import AppHeader from './../AppHeader';
import swal from 'sweetalert';

var moment = require('moment');

class AddBank extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "wallet";
        this.state = {
                show : "false",
                step: "list",
                open: true,
                advopen : true,
                message : "",
                form : {
                    user_id     : 1,
                    provider_id : 1,
                    bank_type    : 1,
                    holdername   : "",
                    card_number     : "",
                },
                permit : {
                    bank_types : [
                        { id : 1, shortcode : "K Pay" , image : "/img/banks/active/kbz.png", non_image : "/img/banks/in-active/kbz.png" },
                        { id : 2, shortcode : "Wave Moneny" , image : "/img/banks/active/wave.png", non_image : "/img/banks/in-active/wave.png"  },
                        { id : 3, shortcode : "Aya Pay" , image : "/img/banks/active/aya_pay.png", non_image : "/img/banks/in-active/aya_pay.png" },
                        { id : 4, shortcode : "CB Pay" , image : "/img/banks/active/cb_pay.png", non_image : "/img/banks/in-active/cb_pay.png"  }
                    ],
                    customer_banks : [
                         { id : 1, bank_type: 1,bank_acc_name : "", bank_num : "" }
                    ],
                    payment: [
                          { id : 1, shortcode : "K Pay" , image : "/img/banks/active/kbz.png", non_image : "/img/banks/in-active/kbz.png" },
                          { id : 2, shortcode : "Wave Moneny" , image : "/img/banks/active/wave.png", non_image : "/img/banks/in-active/wave.png"  },
                          { id : 3, shortcode : "Aya Pay" , image : "/img/banks/active/aya_pay.png", non_image : "/img/banks/in-active/aya_pay.png" },
                          { id : 4, shortcode : "CB Pay" , image : "/img/banks/active/cb_pay.png", non_image : "/img/banks/in-active/cb_pay.png"  }
                    ],
                }
            };
        this.submitForm         = this.submitForm.bind(this);
        this.userLogout         = this.userLogout.bind(this);
        this.redirectLink       = this.redirectLink.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            messages: {
                required: "This is a required field.",
                email: "Please enter a valid email.",
                regex: "Please enter a phone number in this format: 09123456789",
            },
        });
    }

    filterBankType(bank_type) {
        let bank_type_group = this.state.permit.bank_types.filter(function (el, id) {
              return el.id == bank_type;
            });

        // console.log(bank_type_group[0])

        // console.log(bank_type_group[0]);

        return bank_type_group[0];
        //console.log(customer_bank)
    }

    changeSubMenu(step) {
        // console.log("sdf");

        if(step == "show") {
            this.setState({ show : "true"});
        } else {
            this.setState({ show : "false"});
        }

        this.setState({ step : step});

        this.fetchData();

    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "form" });
            }else {
                this.props.history.push("/more");
                }
          } else {
              this.props.history.push("/more");
          }

        if(localStorage.getItem('username')) {
                this.setState({
                    form: {
                        username : localStorage.getItem('username')
                    }
                });
        }

        this.fetchData();

        // localStorage.setItem(window.BPdata.time, 0);
        // localStorage.setItem('username', "");
        // else {
        //   localStorage.setItem(window.BPdata.time, 'profile');
        //   this.setState({ step: "home" });
        // }
        this.hisUrl('/more/wallet-store/add-bank');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;

        this.setState({
            form
        });

        // console.log(form)
    }

    userLogout() {
        //console.log(this.state.step);
        let data = window.confirm("Are you sure want to logout?");
        if(data){
            localStorage.setItem(window.BPdata.time, 0);
            localStorage.setItem('username', "");
            this.setState({ step: "login" , message : "Successfully Log Out" });
            //this.profileBlock()
            this.viewHandler();
        }
    }

    fetchData() {
        //localStorage.getItem(window.BPdata.time)
        let self = this;
        const url = window.BPdata.apiUrlCMS+'/player/show?provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        axios({
          method: 'post',
          url: url,
          data: { token : localStorage.getItem(window.BPdata.time) },
          headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(resp => {

            if(resp.data.data) {

                this.setState(prevState => ({
                  wallet : resp.data.customer_wallet,
                  form : {
                      ...prevState.form,
                      user_id : resp.data.data.id,
                      provider_id :  window.BPdata.provider_id,
                      bank_type : 1
                      // bank_type : 1
                  },
                  permit : {
                    ...prevState.permit,
                    customer_banks : resp.data.data.customer_banks,
                    specfic_bank_accounts : resp.data.bank_accounts,
                    bank_accounts : resp.data.bank_accounts,
                    bank_types : resp.data.bank_types,
                  }
                }))


                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )

            } else {

                swal( window.lang.errors.contact_cs );

            }

          })
          .catch(function(error) {
            // console.log(error);
          });


    }

    redirectLink(to) {
        this.props.history.push(to);
    }

    submitForm() {

        let self = this;

        let dataurl = window.BPdata.apiUrlCMS+"/player/bank-create";
         let data = {
                'user_id'     : this.state.form.user_id,
                'provider_id' : window.BPdata.provider_id,
                'bank_type'   : this.state.form.bank_type,
                'holdername'  : this.state.form.holdername,
                'card_number' : this.state.form.card_number,
         }

        axios({
            method: 'post',
            url: dataurl,
            data: this.state.form,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        }).then(response => {

                if(response.data.status_code == "200") {


                    // Swal.fire({
                    //   icon: 'success',
                    //   title: 'Success...',
                    //   html: 'Successfully submited'
                    // })

                    this.setState(prevState => ({
                        form : {
                            ...prevState.form,
                            bank_type : 1,
                            holdername : "",
                            card_number : "",
                            provider_id :  window.BPdata.provider_id
                        }
                    }))

                    // this.state.form.bank_type = 1;
                    // this.state.form.holdername = "";
                    // this.state.form.card_number = "";

                     swal("Successfully submited");

                     // if (response.data.data.customer_banks.length == 2) {
                         this.props.history.push("/more/wallet-store/withdrawal");
                     // }

                     // self.props.history.push("/more");
                }


                if(response.data.status_code == "404") {

                    //this.isloading = "false";
                    // $(".display-none").css('display','flex');

                    //console.log(response.data.message);

                    let message = "";
                    if(response.data.message.length > 0) {
                        for (var i = 0; i < response.data.message.length; i++) {
                            message += response.data.message[i] + " | ";
                        }
                        swal(message);

                    } else {
                        swal( window.lang.errors.contact_cs );
                    }

                }

            })
            .catch(e => {
               //console.log(e)
              // this.errors.push(e)
            })

    }

    onListBankTypeChange(e, id) {

        let form = {...this.state.form};
        form[e.target.name] = id;

        this.setState({
            form
        });


        if(e === undefined){
            return ;
        } else {
            e.preventDefault();
        }



    }



    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "form": return this.profileBlock();
                case "list": return this.listBlock();
                default:        return "";
              }
        }

    }

    showDetail(id,bank_type,bank_acc_name,bank_num) {

        this.setState(prevState => ({
                show : "true",
                step : "form",
                form : {
                    ...prevState.form,
                    id              : id,
                    bank_type       : bank_type,
                    holdername      : bank_acc_name,
                    card_number     : bank_num,
                }
            }))


    }

    listBlock() {
        return <div className="row pt-30 " >




                { this.state.permit.customer_banks.map(({ id, bank_type, bank_acc_name, bank_num, created_at }, index) =>

                    <div className="adm-card row grid-90 bg-noise mt-10">

                              <div className="adm-card-body grid-100">
                                    <div className="grid-50 left">
                                      <img src={this.filterBankType(bank_type).image}  height="100%"/>
                                    </div>
                                    <div className="grid-50 right">
                                        {bank_acc_name} <br />
                                        {bank_num} <br />
                                        {this.filterBankType(bank_type).shortcode}

                                    </div>

                              </div>

                              <div className="adm-card-body row">

                                  <div className="grid-90  pl-20 pt-4 pb-4">
                                    <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                  </div>
                              </div>

                              <div className="adm-card-body grid-100 pt-4 pb-4 ">

                                      <div className="grid-50 left text-left">
                                            {<span onClick={() => this.deleteData(id)} className="ml-20 btn-small btn-yellow">Delete</span>}

                                      </div>
                                      <div className="grid-50 right">
                                        <b className="color-theme-gold">
                                          {moment.utc(created_at).local().format('DD-MM-YYYY') } </b><br/>
                                        <span>{moment.utc(created_at).local().format('hh:mm A') }</span> <br />

                                      </div>

                              </div>

                    </div>

                )}

        </div>
    }

    deleteData(bank_id) {
        //localStorage.getItem(window.BPdata.time)
        let self = this;
        const url = window.BPdata.apiUrlCMS+'/player/bank-delete?provider_id='+window.BPdata.provider_id+'&bank_id='+bank_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        axios({
          method: 'post',
          url: url,
          data: { token : localStorage.getItem(window.BPdata.time) },
          headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
            },
        })
        .then(response => {




                if(response.data.status_code == "200") {

                    // this.setState(prevState => ({
                    //     form : {
                    //         ...prevState.form,
                    //         bank_type : 1,
                    //         holdername : "",
                    //         card_number : "",
                    //         provider_id :  window.BPdata.provider_id
                    //     }
                    // }))


                     swal("Successfully submited");


                    this.fetchData();
                     // }

                     // self.props.history.push("/more");
                }


                if(response.data.status_code == "404") {

                    //this.isloading = "false";
                    // $(".display-none").css('display','flex');

                    //console.log(response.data.message);

                    let message = "";
                    if(response.data.message.length > 0) {
                        for (var i = 0; i < response.data.message.length; i++) {
                            message += response.data.message[i] + " | ";
                        }
                        swal(message);

                    } else {
                        swal( window.lang.errors.contact_cs );
                    }

                }




          })
          .catch(function(error) {
            // console.log(error);
          });


    }

    profileBlock() {
      return <div className="grid-90 text-left page-contest">

                <div className="form-group row text-left ">
                    <div className="col-md-12 grid-100 grid-s-90 left">
                        <br />
                        <div className="row text-center">
                            { (this.state.show == "false") ?
                            window.lang.messages.choose_withdraw_bank : "" }

                        </div>
                        <br />
                         <div  className="row text-center">
                         { (this.state.show == "false") ?
                         this.state.permit.bank_types.map((dataItem, i) => (

                                 (dataItem.id == this.state.form.bank_type ) ? <img src={dataItem.image} alt={window.lang.title.forum} style={{height:"80px",marginBottom:"5px",marginRight:"15px", borderRadius: '15px'}} className="bg-gray"  onClick={(e) => this.onListBankTypeChange.call(this, e, dataItem.id)} name="bank_type"  /> : <img src={dataItem.non_image} alt={window.lang.title.forum} style={{height:"80px",marginBottom:"5px",marginRight:"15px", borderRadius: '15px'}} className="bg-gray"  onClick={(e) => this.onListBankTypeChange.call(this, e, dataItem.id)}  name="bank_type" />

                         ))  :
                            <img src={this.state.permit.bank_types[this.state.form.bank_type].image} style={{height:"80px",marginBottom:"5px",marginRight:"15px", borderRadius: '15px'}} className="bg-gray"  name="bank_type" />
                          }
                         </div>

                    </div>
                </div>
                <div className="form-group row text-left">
                    <div className="col-md-12 grid-100 grid-s-90 left">
                        <label  className="col-md-4 control-label text-logo-color text-left">{window.lang.form.name}</label>
                        {this.validator.message("name", this.state.form.holdername, "required")}
                        <br />
                        { (this.state.show == "false") ?
                        <input required="required" type="text" name="holdername" class="form-control" value={this.state.form.holdername} onChange={(e) => this.inputChangeHandler.call(this, e)} /> :
                        this.state.form.holdername }

                    </div>
                </div>

                <div className="form-group row text-left">
                    <div className="col-md-12 grid-100 grid-s-90 left">
                        <label  className="col-md-4 control-label text-logo-color text-left">{ window.lang.form.acc_phone_num } {window.lang.form.bank_num}</label>
                        {this.validator.message("name", this.state.form.card_number, "required")}
                        <br />
                        { (this.state.show == "false") ?
                        <input required="required" type="text" name="card_number"  class="form-control" value={this.state.form.card_number} onChange={(e) => this.inputChangeHandler.call(this, e)} /> :
                        this.state.form.card_number }

                    </div>
                </div>

                <div className="form-group row text-left">
                    <div className="col-md-12 grid-100 grid-s-90 left" >
                        <br />
                        { (this.state.show == "false") ?
                        <input type="submit" className="btn btn-yellow grid-100" value={window.lang.form.submit} onClick={this.submitForm} /> : "" }

                        <br />


                    </div>
                </div>



        </div>
   }

   onOpenModal = () => {
     this.setState({ open: true,  advopen : true });
   };

   onCloseModal = () => {
     this.setState({ open: false , advopen : false });

   };

    render() {
        return (
            <div className="page">

                
                <div className="page-content text-center">
                <AppHeader backLink="/more/wallet-store/withdrawal" titleType="3" title={window.lang.form.add_bank_account} isLoading="false" />
                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                  <div className="color-black row" style={{ width : "320px", height : "400px"}}>
                    <div className="grid-100 left text-center" >
                      <br /><br /><br /><br />
                      <a href="http://thahtaylay.com" target="_blank" rel="noopener noreferrer" className="selectDisable" ><img src="/img/logo.png" alt="Thahtay Lay" height="100px" className="selectDisable" /></a>
                    </div>

                    <div className="grid-100 left text-left color-black" >
                      <br /><br />
                      <b className="color-red">ငွေထုတ်ရာတွင်သတိပြုသင့်သည့်အချက်များ</b><br />
                      <p className="color-black">
                      <br />
                      -ငွေလက်ခံမည့်အကောင့်များပြောင်းချင်ပါက (14)ရက်တစ်ကြိမ်သာ ပြောင်းနိုင်ပါမည်။<br />
                      -ငွေလက်ခံမည့်အကောင့်သစ်ထည့်သွင်းပြီး ပထမအကြိမ်ငွေထုတ်မည်ဆိုပါက (24)နာရီပြည့်ပြီးမှသာလျှင်ငွေထုတ်လို့ရပါမည်။<br />
                      -ဒုတိယအကြိမ်မှစ၍ နောက်ပိုင်းငွေထုတ်ရာတွင်(24)နာရီစောင့်ဆိုင်းရန်မလိုပါ။ ငွေထုတ်တင်ပြပြီး(10)မိနစ်အတွင်း လူကြီးမင်း၏ အကောင့်ထဲသို့ ငွေလက်ခံရရှိပါမည်။
                      </p>
                    </div>

                  </div>
                </Modal>

                    <div className="main-content">
                    <div className="row">

                        <div className="grid-60 left">

                        </div>

                        <div className="grid-40 right text-right">

                            <span className="text-primary btn btn-yellow btn-small" onClick={() => this.changeSubMenu('list')}>{window.lang.form.bank_list}</span>
                            {/*<Link to="/more/wallet-store/bank-list" className="text-primary btn btn-success">Bank List</Link>*/}

                        </div>
                    </div>
                    <br />
                    <span>{this.state.message}</span>

                    { this.viewHandler() }
                    </div>
                </div>
                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
            </div>
        );
    }
}

export default AddBank;
